import React, { useState, useRef, useEffect } from "react";
import { useDispatch, connect, useSelector } from "react-redux";
import guruGLogo from "../../Images/logo-sm.png";


import { GiSkullCrossedBones } from "react-icons/gi";
import { TiTick } from "react-icons/ti";
import { BsX } from "react-icons/bs";
import LoadingComponent from "./../LoadingComponent";
import moment from "moment";

function RequestedPreOrderDetail() {
    const dispatch = useDispatch()
    const {isLoadingOnLogin} = useSelector(state=> state.mainState)
  return (
    <div className="orderDetailsShowing--container">
    <div className="Modal--heading">
      <span style={{ color: "#3e445e" }}>Pre Order Details</span>
    </div>
    <p className="privacy-loading--onFetching">
      {isLoadingOnLogin && <LoadingComponent />}
    </p>
    <div className="modal--print_export">
      <div className="modal--print_export--button">
        <button>Print</button>
        <button>Export As PDF</button>
      </div>
    </div>
    <hr />
    <div className="modal-orderDetails--heading">
      <div className="modal-orderDetails--heading__logo">
        <img src={guruGLogo} alt="Gurug Logo" />
      </div>
      <div className="modal-orderDetails--heading__description">
        <p>Guru-G Food</p>
        <p>Maitidevi, Kathmandu, Nepal</p>
        <p>021-590654, 9852030654</p>
        <p>contact@gurungfood.com</p>
      </div>
    </div>
    <hr />
    </div>
  )
}

export default RequestedPreOrderDetail