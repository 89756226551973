import React,{useEffect,useState} from 'react'
import {useHistory} from 'react-router'

function LoadingToRedirect() {
    const [count,setCount] = useState(5);
    let history = useHistory();
    useEffect(()=> {
        const interval = setInterval(()=> {
            setCount((currentCount)=> --currentCount)
        },1000);
        // redirect to login page when count is zero
        count === 0 && history.push("/");
        // cleanup
        return ()=> clearInterval(interval)
    },[count])
    return (
        <div className="protectRoute--counting">
            Redirecting in {count} seconds
        </div>
    )
}

export default LoadingToRedirect
