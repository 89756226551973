import React, { useState, useEffect, useRef } from "react";
import { useDispatch, connect } from "react-redux";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { getCookie } from "../../FrontendHelper";

const mapStateToProps = (store) => {
  const { updateButtonText, isEnabledUser } = store.mainState;
  return { updateButtonText, isEnabledUser };
};

function EditOperatingLocation({
  imgUrl,
  updateButtonText,
  isEnabledUser,
  editingItem,
  opLocation,
  acticStatus,
}) {
  const dispatch = useDispatch();
  const clearFileInputRef = useRef(null);

  const [serviceRate, setServiceRate] = useState("");
  const [serviceRateType, setServiceRateType] = useState("FLAT");
  const [baseDeliveryCharge, setBaseDeliveryCharge] = useState(null);
  const [deliveryChargePerKm, setDeliveryChargePerKm] = useState(null);

  const [operatingLocation, setOperatingLocation] = useState("");
  const [contactPersonName, setContactPersonName] = useState("");
  const [contactPersonNumber, setContactPersonNumber] = useState("");
  const [myActiveStatus, setMyActiveStatus] = useState("active");
  const [loading, setLoading] = useState(false);

  const [image, setImage] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  console.log("The editing item in the edit page", editingItem);
  const handleLoyalitySubmit = (e) => {
    e.preventDefault();
    if (image === "") {
      dispatch({
        type: "EDIT_OPERATING_LOCATION_REQUEST",
        payload: {
          locationName: operatingLocation.toUpperCase(),
          id: editingItem.id,
          isActive: myActiveStatus === "active" ? true : false,
          operatingLocationContactPersonName: contactPersonName,
          operatingLocationContactPersonNumber: contactPersonNumber,
          serviceType: serviceRateType,
          serviceFee: serviceRate,
          baseDeliveryCharge: baseDeliveryCharge,
          deliveryChargePerKm: deliveryChargePerKm,
        },
      });
    } else {
      dispatch({
        type: "EDIT_OPERATING_LOCATION_REQUEST",
        payload: {
          locationName: operatingLocation.toUpperCase(),
          id: editingItem.id,
          isActive: myActiveStatus === "active" ? true : false,
          operatingLocationImage: imageUrl ? imageUrl : imgUrl,
          serviceType: serviceRateType,
          serviceFee: serviceRate,
          baseDeliveryCharge: baseDeliveryCharge,
          deliveryChargePerKm: deliveryChargePerKm,
        },
      });
    }
  };

  useEffect(() => {
    const Token = getCookie("token");
    let myDataform = new FormData();
    myDataform.append("attachment", image);
    setLoading(true);
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_FAKE}/restaurant/v1/api/admin/operating-location/add-image`,
      // url: "https://app.gurugfood.com:8443/restaurant/v1/api/admin/add-delivery-user/image",
      data: myDataform,
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => {
        setImageUrl(response.data.imageUrl);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [image]);

  useEffect(() => {
    if (acticStatus) {
      setMyActiveStatus("active");
    } else {
      setMyActiveStatus("de-activate");
    }
    setOperatingLocation(opLocation);
    setImageUrl(imgUrl);
    setContactPersonName(editingItem.operatingLocationContactPersonName);
    setContactPersonNumber(editingItem.operatingLocationContactPersonNumber);
    setServiceRate(editingItem.serviceCharge);
    setServiceRateType(editingItem.serviceType);
    setBaseDeliveryCharge(editingItem.baseDeliveryCharge);
    setDeliveryChargePerKm(editingItem.deliveryChargePerKm);
  }, [editingItem]);

  return (
    <div>
      <ToastContainer />
      <div className="Modal--heading">Edit Operating Location</div>
      <div className="Modal--form">
        <form>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Image : </label>
              <input
                type="file"
                ref={clearFileInputRef}
                name="image"
                accept="image/*"
                onChange={(e) => setImage(e.target.files[0])}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="added_by">Operating Location Name: </label>
              <input
                type="text"
                value={operatingLocation}
                onChange={(e) => setOperatingLocation(e.target.value)}
                placeholder="Operating Location Name"
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="added_by">Contact Person Name: </label>
              <input
                type="text"
                value={contactPersonName}
                onChange={(e) => setContactPersonName(e.target.value)}
                placeholder="Contact Person Name"
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="added_by">Contact Person Number: </label>
              <input
                type="text"
                value={contactPersonNumber}
                onChange={(e) => setContactPersonNumber(e.target.value)}
                placeholder="Contact Person Number"
              />
            </p>
          </div>

          <div className="Modal--form--title">
            <p>
              <label htmlFor="added_by">Type: </label>
              <select
                value={serviceRateType}
                onChange={(e) => setServiceRateType(e.target.value)}
              >
                <option value="FLAT">FLAT</option>
                <option value="PERCENTAGE">PERCENTAGE</option>
              </select>
            </p>
          </div>
          {serviceRateType === "PERCENTAGE" ? (
            <div className="Modal--form--title">
              <p>
                <label htmlFor="title">Service Rate (%): </label>
                <input
                  type="number"
                  value={serviceRate}
                  onChange={(e) => setServiceRate(e.target.value)}
                  placeholder="Service Rate(%)"
                />
              </p>
            </div>
          ) : (
            <div className="Modal--form--title">
              <p>
                <label htmlFor="title">Service Rate(Flat): </label>
                <input
                  type="number"
                  value={serviceRate}
                  onChange={(e) => setServiceRate(e.target.value)}
                  placeholder="Service Rate(FLAT)"
                />
              </p>
            </div>
          )}

          <div className="Modal--form--title">
            <p>
              <label htmlFor="added_by">Base Delivery Charge(Rs.): </label>
              <input
                type="number"
                value={baseDeliveryCharge}
                onChange={(e) => setBaseDeliveryCharge(e.target.value)}
                placeholder="Base Delivery Charge"
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="added_by">Delivery Charge Per/KM(Rs.): </label>
              <input
                type="number"
                value={deliveryChargePerKm}
                onChange={(e) => setDeliveryChargePerKm(e.target.value)}
                placeholder="Delivery Charge Per/KM"
              />
            </p>
          </div>
          <div className="Modal--form--checkbox">
            <p>
              <input
                type="radio"
                name="activStatus"
                checked={myActiveStatus === "active" ? true : false}
                value="active"
                onChange={(e) => setMyActiveStatus(e.target.value)}
              />
              <label htmlFor="added_by">Activate </label>
            </p>
            <p>
              <input
                type="radio"
                name="activStatus"
                checked={myActiveStatus === "de-activate" ? true : false}
                value="de-activate"
                onChange={(e) => setMyActiveStatus(e.target.value)}
              />
              <label htmlFor="added_by">De-Activate </label>
            </p>
          </div>

          <div className="modal--container__group--buttons">
            <button
              style={{ marginBottom: "50px" }}
              type="submit"
              className="btn btn-primary"
              onClick={handleLoyalitySubmit}
            >
              {updateButtonText}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(EditOperatingLocation);
