import React, { useState, useEffect } from 'react'
import { useDispatch, connect } from 'react-redux'
import 'react-toastify/dist/ReactToastify.min.css'
import { ToastContainer } from 'react-toastify';

const mapStateToProps = store => {
    const { buttonText ,isEnabledUser} = store.mainState
    return { buttonText ,isEnabledUser}
}

function SetDeliveryCharge({ buttonText ,isEnabledUser}) {
    const dispatch = useDispatch();
    const [baseDeliveryFee, setBaseDeliveryFee] = useState("")
    const [deliveryFeePerKm, setDeliveryFeePerKm] = useState("")
    const handleLoyalitySubmit = (e) => {
        e.preventDefault();
        dispatch({
            type: "SET_DELIVERY_FEE_REQUEST",
            payload: {
                "baseDeliveryCharge": baseDeliveryFee,
                "deliveryChargePerKm": deliveryFeePerKm
            }
        })


    }

    return (
        <div>
            <ToastContainer />
            <div className="Modal--heading">
                Add Delivery Fee
            </div>
            <div className="Modal--form">
                <form>
                    <div className="Modal--form--title">
                        <p><label htmlFor="title">Base Delivery Charge: </label><input value={baseDeliveryFee} onChange={(e) => setBaseDeliveryFee(e.target.value)} placeholder="Base Delivery Charge" /></p>
                    </div>
                    <div className="Modal--form--title">
                        <p><label htmlFor="title">Delivery Charge Per KM: </label><input value={deliveryFeePerKm} onChange={(e) => setDeliveryFeePerKm(e.target.value)} placeholder="Delivery Charge/KM" /></p>
                    </div>

                    <div className="modal--container__group--buttons">
                        <button style={{ marginBottom: "50px" }} type="submit" className="btn btn-primary" onClick={handleLoyalitySubmit} >
                            {buttonText}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, null)(SetDeliveryCharge)

