import React, { useState, useEffect } from 'react'
import Layout from '../../Layout/Layout'
import { AiFillEdit } from 'react-icons/ai'
import { BsX } from "react-icons/bs";
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi'
import { connect, useDispatch } from 'react-redux'
import LoadingComponent from '../LoadingComponent'
import Footer from '../Z_Footer/Footer'
import UpdateReferralLoyality from './UpdateReferralLoyality'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import UpdateExchangeRate from './UpdateExchangeRate';
import moment from 'moment';

const mapStateToProps = store => {
    const { exChangeReferalData, isLoadingOnLogin, isToogleSidebar } = store.mainState;
    return { exChangeReferalData, isLoadingOnLogin, isToogleSidebar }
}

function ExchangePoint({ exChangeReferalData, isLoadingOnLogin, isToogleSidebar }) {
    const [openUpdatingModal, setOpenUpdatingModal] = useState(false);
    const [point, setPoint] = useState("")
    const [amount, setAmount] = useState("")
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({
            type: "FETCH_EXCHANGE_REFERAL_REQUEST"
        })
    }, [])
    const getLocalDateTime = (mydate) => {
        var stillUtc = moment.utc(mydate).toDate();
        var local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
        return local
    }
    return (
        <Layout>
            <div>
                <div className="group_list">
                    <h4>Manage Referral And Loyalty</h4>
                </div>
                <div className="user_groups">
                    <div className="user_groups--button_n_search">
                        <div className="user_groups--button_n_search--button">
                            <h4>Referral And Loyalty</h4>
                            <button onClick={() => dispatch({ type: "ADD_EXCHANGE_LOYALITY_MODAL" })}>
                                Add <i className="bi bi-plus c-plus" />
                            </button>
                        </div>
                    </div>
                    <p className="privacy-loading--onFetching">{isLoadingOnLogin && <LoadingComponent />}</p>
                    <div className="table-container">
                        <table className="table_x">
                            <thead>
                                <tr>
                                    {/* <th className="loyality--id">ID</th> */}
                                    <th className="loyality--exchange--schemeName">Scheme Name</th>
                                    <th className="loyality--exchange--addedBy">Added By</th>
                                    <th className="loyality--exchange--addedDate">Added Date</th>
                                    <th className="loyality--exchange--loyalityPoint">Loyalty Point</th>
                                    <th className="loyality--exchange--equiAmount">Equivalent Amount</th>
                                    <th className="loyality--exchange--actions">Actions</th>
                                </tr></thead>
                            <tbody>
                                {exChangeReferalData !== null &&
                                    <tr >
                                        <td>{exChangeReferalData.configKey}</td>
                                        {/* <td>{exChangeReferalData.lastUpdatedBy}</td>
                                        <td>{exChangeReferalData.updateDate}</td> */}
                                        <td>{exChangeReferalData.addedBy}</td>
                                        <td>{getLocalDateTime(exChangeReferalData.updateDate)}</td>
                                        <td>{exChangeReferalData.loyaltyPoint}</td>
                                        <td>{exChangeReferalData.redeemedAmount}</td>
                                        <td> <Tippy content="Edit" placement="top">
                                            <span>
                                                <AiFillEdit className="edit--icon" onClick={() => {
                                                    setOpenUpdatingModal(true);
                                                    setPoint(exChangeReferalData.loyaltyPoint);
                                                    setAmount(exChangeReferalData.redeemedAmount)

                                                }} /> </span>
                                        </Tippy></td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                        {/* update referral And loyalty modal */}
                        <div className={`${openUpdatingModal ? "mymodal modal_activated" : "mymodal"}`}>
                            <div className={`${!isToogleSidebar ? "manage_modal--container " : "manage_modal--container sidebarOpend_F_modal"}`}>
                                {/* <UpdateReferralLoyality item={updatingConfigureKey} myValue={updatingConfigValue} /> */}
                                <UpdateExchangeRate myPoint={point} myAmount={amount} />
                            </div>
                            <div className={`${!isToogleSidebar ? "task--rectangle" : "task--rectangle withsidebarOpend"}`}>
                                <div className="task--rectangle__circle">
                                    <div className='circle'> <span onClick={() => {
                                        setOpenUpdatingModal(!openUpdatingModal)
                                        dispatch({
                                            type: "FETCH_EXCHANGE_REFERAL_REQUEST",
                                        })
                                    }}><BsX /></span><p>Add</p></div>
                                </div>
                            </div>
                        </div>
                        <div className="pagination">
                            <p>Showing 0 to 1 of 1 entries</p>
                            <nav aria-label="...">
                                <ul className="pagination">
                                    <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronLeft /></a>
                                    </li>
                                    <li className="page-item"><a className="page-link" href="#">1</a></li>
                                    <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronRight /></a>
                                    </li>
                                </ul>
                            </nav>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        </Layout>
    )
}

export default connect(mapStateToProps, null)(ExchangePoint)
