import React, { useState, useRef, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import "react-toastify/dist/ReactToastify.min.css";
import { toast, ToastContainer } from "react-toastify";
import { BsPhone } from "react-icons/bs";
import { IoLocationOutline } from "react-icons/io5";
import { DatePicker } from "antd";
import moment from "moment";

const mapStateToProps = (store) => {
  const {
    buttonText,
    internalAdsLinks,
    isEnabledUsers,
    searchRestaurantList,
    allPreOrderList,
    campaignList,
  } = store.mainState;
  return {
    buttonText,
    internalAdsLinks,
    isEnabledUsers,
    searchRestaurantList,
    allPreOrderList,
    campaignList,
  };
};

function AddAdvertisement({
  buttonText,
  internalAdsLinks,
  isEnabledUsers,
  searchRestaurantList,
  allPreOrderList,
  campaignList,
}) {
  const [typeOfAdd, setTypeOfAdd] = useState("External");
  const clearFileInputRef = useRef();
  const dispatch = useDispatch();
  const [addsName, setAddsName] = useState("");
  const [addsDetails, setAddsDetails] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [addsImage, setAddsImage] = useState("");
  const [companyUrl, setCompanyUrl] = useState("");
  const [internalLink, setInternalLink] = useState("");
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [searchKeyWordPreOrder, setSearchKeyWordPreOrder] = useState("");
  const [searchKeyWordCampaign, setSearchKeyWordCampaign] = useState("");
  const [startFrom, setStartFrom] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startFrom1, setStartFrom1] = useState("");
  const [endDate1, setEndDate1] = useState("");

  const [restaurantItem, setRestaurnatId] = useState(null);
  const [preOrderItem, setPreOrderItem] = useState(null);
  const [campaginItem, setCampaignItem] = useState(null);
  const [advertisementFor, setAdvertisementFor] = useState();

  useEffect(() => {
    dispatch({ type: "FETCH_INTERNAL_ADS_LINK_REQUEST" });
    dispatch({
      type: "CLEAR_PREORDER_SEARCH_LIST",
    });
    dispatch({
      type: "CLEAR_CAMPAIGN_SEARCH_LIST",
    });
  }, [typeOfAdd]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (typeOfAdd === "External") {
      if (companyUrl === "") {
        formData.append("advertisementLinkType", "EXTERNAL");
        formData.append("advertisementName", addsName);
        formData.append("advertisementDetails", addsDetails);
        formData.append("advertisementFor", advertisementFor);
        formData.append(
          "startDate",
          moment(startFrom1).format("YYYY-MM-DD HH:mm:ss")
        );
        formData.append(
          "expiryDate",
          moment(endDate1).format("YYYY-MM-DD HH:mm:ss")
        );
        formData.append("companyName", companyName);
        formData.append("content", addsImage);
        dispatch({
          type: "POST_ADVERTISEMENT_REQUEST",
          payload: formData,
        });
      } else {
        formData.append("advertisementLinkType", "EXTERNAL");
        formData.append("advertisementName", addsName);
        formData.append("advertisementDetails", addsDetails);
        formData.append("advertisementFor", advertisementFor);

        formData.append(
          "startDate",
          moment(startFrom1).format("YYYY-MM-DD HH:mm:ss")
        );
        formData.append(
          "expiryDate",
          moment(endDate1).format("YYYY-MM-DD HH:mm:ss")
        );
        formData.append("companyName", companyName);
        formData.append("content", addsImage);
        formData.append("companyUrl", companyUrl);
        dispatch({
          type: "POST_ADVERTISEMENT_REQUEST",
          payload: formData,
        });
      }
    } else {
      if (companyUrl === "") {
        formData.append("advertisementLinkType", "INTERNAL");
        formData.append("advertisementName", addsName);
        formData.append("advertisementDetails", addsDetails);
        formData.append("advertisementFor", advertisementFor);

        formData.append(
          "startDate",
          moment(startFrom).format("YYYY-MM-DD HH:mm:ss")
        );
        formData.append(
          "expiryDate",
          moment(endDate).format("YYYY-MM-DD HH:mm:ss")
        );

        formData.append("companyName", companyName);
        formData.append("content", addsImage);
        formData.append("internalLink", internalLink);
        if (restaurantItem !== null && internalLink == "RESTAURANT") {
          formData.append("restaurantId", restaurantItem.id);
        }
        if (preOrderItem !== null && internalLink == "PREORDER") {
          formData.append("preOrderId", preOrderItem.planId);
        }
        if (campaginItem !== null && internalLink == "CAMPAIGN") {
          formData.append("campaignId", campaginItem.id);
        }
        dispatch({
          type: "POST_ADVERTISEMENT_REQUEST",
          payload: formData,
        });
      } else {
        formData.append("advertisementLinkType", "INTERNAL");
        formData.append("advertisementName", addsName);
        formData.append("advertisementFor", advertisementFor);

        formData.append("advertisementDetails", addsDetails);
        formData.append(
          "startDate",
          moment(startFrom).format("YYYY-MM-DD HH:mm:ss")
        );
        formData.append(
          "expiryDate",
          moment(endDate).format("YYYY-MM-DD HH:mm:ss")
        );
        formData.append("companyName", companyName);
        formData.append("content", addsImage);
        formData.append("companyUrl", companyUrl);
        formData.append("internalLink", internalLink);
        if (restaurantItem !== null && internalLink == "RESTAURANT") {
          formData.append("restaurantId", restaurantItem.id);
        }
        if (preOrderItem !== null && internalLink == "PREORDER") {
          formData.append("preOrderId", preOrderItem.planId);
        }
        if (campaginItem !== null && internalLink == "CAMPAIGN") {
          formData.append("campaignId", campaginItem.id);
        }
        dispatch({
          type: "POST_ADVERTISEMENT_REQUEST",
          payload: formData,
        });
      }
    }
  };
  useEffect(() => {
    if (isEnabledUsers === "b") {
      setAddsName("");
      setAddsDetails("");
      setCompanyName("");
      setAddsImage("");
      setCompanyUrl("");
      clearFileInputRef.current.value = "";
    }
  }, [isEnabledUsers]);
  useEffect(() => {
    let currentPage = 1;
    let postsPerPage = 50;
    let keyWord = searchKeyWord;
    dispatch({
      type: "FETCH_RESTAURANT_LIST_REQUEST",
      payload: { keyWord, currentPage, postsPerPage },
    });
  }, [searchKeyWord]);
  useEffect(() => {
    let currentPage = 1;
    let postsPerPage = 50;
    let keyWord = searchKeyWordPreOrder;
    dispatch({
      type: "GET_ALL_PREORDER_LIST_REQUEST",
      payload: { keyWord, currentPage, postsPerPage },
    });
  }, [searchKeyWordPreOrder]);
  useEffect(() => {
    let currentPage = 1;
    let postsPerPage = 50;
    let keyWord = searchKeyWordCampaign;
    dispatch({
      type: "FETCH_CAMPAIGN_LIST_REQUEST",
      payload: { keyWord, currentPage, postsPerPage },
    });
  }, [searchKeyWordCampaign]);

  const onChange = (value, dateString) => {
    setStartFrom(value);
  };
  const onChange1 = (value, dateString) => {
    setEndDate(value);
  };
  const onChange2 = (value, dateString) => {
    console.log("startFrom1", moment(value).format("YYYY-MM-DD HH:mm:ss"));
    setStartFrom1(value);
  };
  const onChange3 = (value, dateString) => {
    console.log("endDate1", moment(endDate1).format("YYYY-MM-DD HH:mm:ss"));
    setEndDate1(value);
  };
  return (
    <div>
      <ToastContainer />
      <div className="Modal--heading">Add Advertisement</div>
      <div className="addSelector--button">
        <div className="addSelector--button__container">
          <div
            className={typeOfAdd === "External" && "external--link"}
            onClick={() => setTypeOfAdd("External")}
          >
            External Link
          </div>
          <p>a</p>
          <div
            className={typeOfAdd === "Internal" && "external--link"}
            onClick={() => setTypeOfAdd("Internal")}
          >
            Internal Link
          </div>
        </div>
      </div>
      {typeOfAdd === "External" && (
        <div className="Modal--form">
          <form>
            <div className="Modal--form--title">
              <p>
                <label htmlFor="added_by">Ads Image*: </label>
                <input
                  type="file"
                  accept="image/*"
                  ref={clearFileInputRef}
                  onChange={(e) => setAddsImage(e.target.files[0])}
                />
              </p>
            </div>
            <div className="Modal--form--title">
              <p>
                <label htmlFor="added_by">Advertisement Name*: </label>
                <input
                  type="text"
                  value={addsName}
                  placeholder="Advertisement Name..."
                  onChange={(e) => setAddsName(e.target.value)}
                />
              </p>
            </div>
            <div className="Modal--form--title">
              <p>
                <label htmlFor="title">Advertisement For: </label>
                <select
                  value={advertisementFor}
                  onChange={(e) => setAdvertisementFor(e.target.value)}
                >
                  <option>Select</option>
                  <option value="ALL">All</option>
                  <option value="CUSTOMER">Customer</option>
                  <option value="MERCHANT">Merchant</option>
                </select>
              </p>
            </div>
            <div className="Modal--form--title">
              <p>
                <label htmlFor="added_by">Advertisement Details*: </label>
                <input
                  type="text"
                  value={addsDetails}
                  placeholder="Advertisement Details..."
                  onChange={(e) => setAddsDetails(e.target.value)}
                />
              </p>
            </div>
            <div className="Modal--form--title">
              <p>
                <label htmlFor="added_by">Company Name*: </label>
                <input
                  type="text"
                  placeholder="Company Name..."
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </p>
            </div>

            <div className="Modal--form--title">
              <p>
                <label htmlFor="title">Company Url(Opt): </label>
                <input
                  type="url"
                  required
                  placeholder="Enter Url"
                  value={companyUrl}
                  onChange={(e) => setCompanyUrl(e.target.value)}
                />
              </p>
            </div>
            <div className="Modal--form--title">
              <p style={{ display: "flex", gap: "2rem", alignItems: "center" }}>
                <label htmlFor="title">Start Date : </label>
                <DatePicker
                  showTime
                  value={startFrom1}
                  onChange={onChange2}
                  style={{ height: "34px", marginTop: "1rem" }}
                />
              </p>
            </div>
            <div className="Modal--form--title">
              <p style={{ display: "flex", gap: "2rem", alignItems: "center" }}>
                <label htmlFor="title">Expiry Date : </label>
                <DatePicker
                  showTime
                  value={endDate1}
                  onChange={onChange3}
                  style={{
                    height: "34px",
                    paddingTop: "-1rem",
                    marginTop: "1rem",
                  }}
                />
              </p>
            </div>

            <div className="modal--container__group--buttons">
              <button
                style={{ marginBottom: "50px" }}
                type="submit"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                {buttonText}
              </button>
            </div>
          </form>
        </div>
      )}
      {typeOfAdd === "Internal" && (
        <div className="Modal--form">
          <form>
            <div className="Modal--form--title">
              <p>
                <label htmlFor="added_by">Internal Link: </label>
                <select
                  name=""
                  id=""
                  onChange={(e) => setInternalLink(e.target.value)}
                >
                  {internalAdsLinks !== undefined &&
                    internalAdsLinks.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                </select>
              </p>
            </div>
            {(typeOfAdd === "Internal") & (internalLink === "RESTAURANT") ? (
              <>
                <div className="searchMenu--box">
                  <input
                    type="text"
                    placeholder="Search Restaurant"
                    value={searchKeyWord}
                    onChange={(e) => setSearchKeyWord(e.target.value)}
                  />
                </div>
                {restaurantItem !== null && (
                  <div className="searchMenu--box--restaurant">
                    <p>
                      Selected Restaurant Name :{" "}
                      {restaurantItem?.restaurantName}
                    </p>
                    <p> Location : {restaurantItem.location}</p>
                  </div>
                )}

                <hr />
              </>
            ) : null}
            <div className="addAdvertisement--restaurant--container">
              {(typeOfAdd === "Internal") & (internalLink === "RESTAURANT")
                ? searchRestaurantList.data?.map((rest) => (
                    <>
                      <div
                        className="searchByRestaurant--body__item"
                        onClick={() => {
                          setPreOrderItem(rest);
                          dispatch({
                            type: "CLEAR_RESTAURANT_SEARCH_LIST",
                          });
                        }}
                      >
                        <div className="restaurant--details--container">
                          <p>{rest.restaurantName}</p>
                          <p>
                            <BsPhone className="my-ui--icons" />
                            {rest.phoneNumber}
                          </p>
                          <p>
                            <IoLocationOutline className="my-ui--icons" />
                            {rest.location}
                          </p>
                        </div>
                        <div className="restaurant--image--container">
                          <img src={rest.imageUrl} alt="Restaurant Image" />
                        </div>
                      </div>
                    </>
                  ))
                : null}
            </div>
            {(typeOfAdd === "Internal") & (internalLink === "PREORDER") ? (
              <>
                <div className="searchMenu--box">
                  <input
                    type="text"
                    placeholder="Search Preorder"
                    value={searchKeyWordPreOrder}
                    onChange={(e) => setSearchKeyWordPreOrder(e.target.value)}
                  />
                </div>
                {preOrderItem !== null && (
                  <div className="searchMenu--box--restaurant">
                    <p>Selected PreOrder Name : {preOrderItem?.planName}</p>
                    {/* <p> Location : {restaurantItem.location}</p> */}
                  </div>
                )}

                <hr />
              </>
            ) : null}
            <div className="addAdvertisement--restaurant--container">
              {(typeOfAdd === "Internal") & (internalLink === "PREORDER")
                ? allPreOrderList.data?.map((rest) => (
                    <>
                      <div
                        className="searchByRestaurant--body__item"
                        onClick={() => {
                          setPreOrderItem(rest);
                          dispatch({
                            type: "CLEAR_PREORDER_SEARCH_LIST",
                          });
                        }}
                      >
                        <div
                          className="restaurant--details--container"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                            width: "100%",
                          }}
                        >
                          <p>{rest.planName}</p>
                          {/* <p>
                            <BsPhone className="my-ui--icons" />
                            {rest.phoneNumber}
                          </p>
                          <p>
                            <IoLocationOutline className="my-ui--icons" />
                            {rest.location}
                          </p> */}
                        </div>
                        {/* <div className="restaurant--image--container">
                          <img src={rest.imageUrl} alt="Restaurant Image" />
                        </div> */}
                      </div>
                    </>
                  ))
                : null}
            </div>
            {(typeOfAdd === "Internal") & (internalLink === "CAMPAIGN") ? (
              <>
                <div className="searchMenu--box">
                  <input
                    type="text"
                    placeholder="Search Campaign"
                    value={searchKeyWordCampaign}
                    onChange={(e) => setSearchKeyWordCampaign(e.target.value)}
                  />
                </div>
                {campaginItem !== null && (
                  <div className="searchMenu--box--restaurant">
                    <p>Selected Campaign Name : {campaginItem?.campaignName}</p>
                    {/* <p> Location : {restaurantItem.location}</p> */}
                  </div>
                )}

                <hr />
              </>
            ) : null}
            <div className="addAdvertisement--restaurant--container">
              {(typeOfAdd === "Internal") & (internalLink === "CAMPAIGN")
                ? campaignList.data?.map((rest) => (
                    <>
                      <div
                        className="searchByRestaurant--body__item"
                        onClick={() => {
                          setCampaignItem(rest);
                          dispatch({
                            type: "CLEAR_CAMPAIGN_SEARCH_LIST",
                          });
                        }}
                      >
                        <div
                          className="restaurant--details--container"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <p>{rest.campaignName}</p>
                          {/* <p>
                            <BsPhone className="my-ui--icons" />
                            {rest.phoneNumber}
                          </p>
                          <p>
                            <IoLocationOutline className="my-ui--icons" />
                            {rest.location}
                          </p> */}
                        </div>
                        <div className="restaurant--image--container">
                          <img src={rest.campaignImage} alt="Campaign Image" />
                        </div>
                      </div>
                    </>
                  ))
                : null}
            </div>

            <div className="Modal--form--title">
              <p>
                <label htmlFor="added_by">Ads Image*: </label>
                <input
                  type="file"
                  accept="image/*"
                  ref={clearFileInputRef}
                  onChange={(e) => setAddsImage(e.target.files[0])}
                />
              </p>
            </div>
            <div className="Modal--form--title">
              <p>
                <label htmlFor="added_by">Advertisement Name*: </label>
                <input
                  type="text"
                  value={addsName}
                  placeholder="Advertisement Name..."
                  onChange={(e) => setAddsName(e.target.value)}
                />
              </p>
            </div>
            <div className="Modal--form--title">
              <p>
                <label htmlFor="title">Advertisement For: </label>
                <select
                  value={advertisementFor}
                  onChange={(e) => setAdvertisementFor(e.target.value)}
                >
                  <option>Select</option>
                  <option value="ALL">All</option>

                  <option value="CUSTOMER">Customer</option>
                  <option value="MERCHANT">Merchant</option>
                </select>
              </p>
            </div>
            <div className="Modal--form--title">
              <p>
                <label htmlFor="added_by">Advertisement Details*: </label>
                <input
                  type="text"
                  value={addsDetails}
                  placeholder="Advertisement Details..."
                  onChange={(e) => setAddsDetails(e.target.value)}
                />
              </p>
            </div>
            <div className="Modal--form--title">
              <p>
                <label htmlFor="added_by">Company Name*: </label>
                <input
                  type="text"
                  placeholder="Company Name"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </p>
            </div>

            <div className="Modal--form--title">
              <p>
                <label htmlFor="title">Url: </label>
                <input
                  type="url"
                  required
                  placeholder="Enter Url"
                  value={companyUrl}
                  onChange={(e) => setCompanyUrl(e.target.value)}
                />
              </p>
            </div>
            <div className="Modal--form--title">
              <p style={{ display: "flex", gap: "2rem", alignItems: "center" }}>
                <label htmlFor="title">Start Date : </label>
                <DatePicker
                  showTime
                  value={startFrom}
                  onChange={onChange}
                  style={{ height: "34px", marginTop: "1rem" }}
                />
              </p>
            </div>
            <div className="Modal--form--title">
              <p style={{ display: "flex", gap: "2rem", alignItems: "center" }}>
                <label htmlFor="title">Expiry Date : </label>
                <DatePicker
                  showTime
                  value={endDate}
                  onChange={onChange1}
                  style={{
                    height: "34px",
                    paddingTop: "-1rem",
                    marginTop: "1rem",
                  }}
                />
              </p>
            </div>

            <div className="modal--container__group--buttons">
              <button
                style={{ marginBottom: "50px" }}
                type="submit"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                {buttonText}
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default connect(mapStateToProps, null)(AddAdvertisement);
