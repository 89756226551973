import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import { AiFillEdit } from "react-icons/ai";
// import {RiAddBoxFill} from 'react-icons/ri'
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { BsX, BsReverseLayoutTextSidebarReverse } from "react-icons/bs";
import { connect, useDispatch } from "react-redux";
import LoadingComponent from "../LoadingComponent";
import Footer from "../Z_Footer/Footer";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { GiSkullCrossedBones } from "react-icons/gi";
import { TiTick } from "react-icons/ti";
import moment from "moment";
import AddPreOrderPlan from "./AddPreOrderPlan";
import { BsEyeFill, BsCheckCircle } from "react-icons/bs";
import { AiOutlineFieldTime } from "react-icons/ai";
import PreOrderDetails from "./PreOrderDetails";
import EditPreOrder from "./EditPreOrder";
import { useHistory } from "react-router";
import dayjs from "dayjs";

const mapStateToProps = (store) => {
  const {
    buttonText,
    isLoadingOnLogin,
    allPreOrderList,
    isToogleSidebar,
    isEnabledUser,
    isVerifyChanged,
    isPreOrderSettled,
  } = store.mainState;
  return {
    buttonText,
    isLoadingOnLogin,
    allPreOrderList,
    isToogleSidebar,
    isEnabledUser,
    isVerifyChanged,
    isPreOrderSettled,
  };
};

function GetPreOrderList({
  buttonText,
  isLoadingOnLogin,
  allPreOrderList,
  isToogleSidebar,
  isEnabledUser,
  isVerifyChanged,
  isPreOrderSettled,
}) {
  const [showAddPreOrderModal, setShowAddPreOrderModal] = useState(false);
  const [showEditPreOrderModal, setShowEditPreOrderModal] = useState(false);
  const [editinPreOrderItem, setEditingPreOrderItem] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const history = useHistory();

  const [preOrderType, setPreOrderType] = useState("All");
  const dispatch = useDispatch();
  // enable/disble pre order
  const [showSmModal, setShowSmModal] = useState(false);
  const [enableDisableItem, setEnableDisableItem] = useState({});
  // show pre order details
  const [showPreorderDetailsModal, setShowPreOrderDetailsModal] =
    useState(false);
  const [detailShowingItem, setDetailsShoingItem] = useState({});

  // get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  let pageNumbers = [];

  for (let i = 1; i <= allPreOrderList.totalPage; i++) {
    pageNumbers.push(i);
  }
  const getLocalDateTime = (mydate) => {
    var stillUtc = moment.utc(mydate).toDate();
    var local = moment(stillUtc).local().format("YYYY-MM-DD HH:mm:ss");
    return local;
  };

  useEffect(() => {
    dispatch({
      type: "GET_ALL_PREORDER_LIST_REQUEST",
      payload: { postsPerPage, currentPage },
    });
  }, [
    postsPerPage,
    currentPage,
    preOrderType,
    isVerifyChanged,
    isPreOrderSettled,
  ]);

  useEffect(() => {
    if (isEnabledUser === "b") {
      setShowAddPreOrderModal(false);
      setShowSmModal(false);
      setShowEditPreOrderModal(false);
      dispatch({
        type: "GET_ALL_PREORDER_LIST_REQUEST",
        payload: { postsPerPage, currentPage },
      });
    }
  }, [isEnabledUser, isVerifyChanged, isPreOrderSettled]);

  return (
    <Layout>
      <div>
        <div className="group_list">
          <h4>Manage Pre Order</h4>
        </div>
        <div className="user_groups">
          <div className="user_groups--button_n_search">
            <div className="user_groups--button_n_search--button">
              <h4>Pre Order</h4>
              <button onClick={() => setShowAddPreOrderModal(true)}>
                Add <i className="bi bi-plus c-plus" />
              </button>
              <label
                htmlFor="pageSize"
                style={{
                  marginLeft: "10px",
                  fontSize: "18px",
                  fontFamily: "bold",
                  color: "#3e445e",
                  marginRight: "-10px",
                }}
              >
                Per Page
              </label>
              <select
                name="pageSize"
                onChange={(e) => setPostsPerPage(e.target.value)}
              >
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </div>
            <div className="user_groups--button_n_search--search">
              {/* <input type="text" placeholder="Search" /> */}
            </div>
          </div>
          <p className="privacy-loading--onFetching">
            {isLoadingOnLogin && <LoadingComponent />}
          </p>
          <div className="table-container">
            <table className="table_x">
              <thead>
                <tr>
                  <th className="preOrder--name">Plan Name</th>
                  <th className="preOrder--startDate">PreOrder StartDate</th>
                  <th className="preOrder--startDate">PreOrder EndDate</th>
                  <th className="preOrder--contactname">Contact Person Name</th>
                  <th className="preOrder--contactNo">Phone No.</th>
                  <th className="preOrder--startDate">Delivery StartDate</th>
                  <th className="preOrder--startDate">Delivery EndDate</th>
                  <th className="preOrder--price">Price</th>
                  <th className="preOrder--price">Is Takeway</th>
                  <th className="preOrder--status">Status</th>
                  <th className="preOrder--actions">Actions</th>
                </tr>
              </thead>
              <tbody>
                {allPreOrderList !== undefined &&
                  allPreOrderList.data?.map((item, index) => {
                    console.log(
                      "preorder dates utc",
                      moment(item.preOrderStartDate)
                        .utc()
                        .local()
                        .format("YYYY-MM-DD hh:mm a")
                    );
                    return (
                      <tr key={index}>
                        <td>{item.planName}</td>
                        {/* <td>{item.description}</td> */}
                        <td>
                          {dayjs(
                            new Date(getLocalDateTime(item.preOrderStartDate))
                          ).format("YYYY MMM DD hh:mm a")}
                          {/* {moment(item.preOrderStartDate).format("YYYY-MM-DD HH:mm:ss")} */}
                        </td>
                        <td>
                          {dayjs(
                            new Date(getLocalDateTime(item.preOrderEndDate))
                          ).format("YYYY MMM DD hh:mm a")}
                          {/* {moment(item.preOrderEndDate).format("YYYY-MM-DD HH:mm:ss")} */}
                        </td>
                        <td>{item.planContactPersonFullName}</td>
                        <td>{item.planContactNumber}</td>
                        <td>
                          {dayjs(
                            new Date(getLocalDateTime(item.deliveryStartDate))
                          ).format("YYYY MMM DD hh:mm a")}
                          {/* {moment(item.deliveryStartDate).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )} */}
                        </td>
                        <td>
                          {dayjs(
                            new Date(getLocalDateTime(item.deliveryEndDate))
                          ).format("YYYY MMM DD hh:mm a")}
                          {/* {moment(item.deliveryEndDate).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )} */}
                        </td>
                        <td>Rs. {item.planPrice}</td>
                        <td>
                          {" "}
                          {item.orderType === "both" ||
                          item.orderType === "BOTH" ? (
                            <div>
                              <span
                                style={{
                                  backgroundColor: "green",
                                  borderRadius: "4px",
                                  color: "white",
                                  margin: "2px",
                                }}
                              >
                                Pick Up
                              </span>
                              <span
                                style={{
                                  backgroundColor: "green",
                                  borderRadius: "4px",
                                  color: "white",
                                }}
                              >
                                Delivery
                              </span>
                            </div>
                          ) : item.orderType === "delivery" ||
                            item.orderType === "DELIVERY" ? (
                            <span
                              style={{
                                backgroundColor: "green",
                                borderRadius: "4px",
                                color: "white",
                              }}
                            >
                              Delivery
                            </span>
                          ) : item.orderType === "pickup" ||
                            item.orderType === "PICKUP" ? (
                            <span
                              style={{
                                backgroundColor: "green",
                                borderRadius: "4px",
                                color: "white",
                                margin: "2px",
                              }}
                            >
                              Pickup
                            </span>
                          ) : item.isTakeAwayAllowed ? (
                            <div>
                              <span
                                style={{
                                  backgroundColor: "green",
                                  borderRadius: "4px",
                                  color: "white",
                                  margin: "2px",
                                }}
                              >
                                Pick Up
                              </span>
                              <span
                                style={{
                                  backgroundColor: "green",
                                  borderRadius: "4px",
                                  color: "white",
                                }}
                              >
                                Delivery
                              </span>
                            </div>
                          ) : (
                            <span
                              style={{
                                backgroundColor: "green",
                                borderRadius: "4px",
                                color: "white",
                              }}
                            >
                              Delivery
                            </span>
                          )}
                        </td>
                        <td>
                          {item.isActive ? (
                            <span
                              style={{
                                backgroundColor: "green",
                                borderRadius: "4px",
                                color: "white",
                              }}
                            >
                              Active
                            </span>
                          ) : (
                            <span
                              style={{
                                backgroundColor: "red",
                                borderRadius: "4px",
                                color: "white",
                              }}
                            >
                              Not Active
                            </span>
                          )}
                        </td>
                        <td>
                          <Tippy content="Edit" placement="top">
                            <span>
                              <AiFillEdit
                                className="edit--icon"
                                onClick={() => {
                                  setShowEditPreOrderModal(true);
                                  setEditingPreOrderItem(item);
                                }}
                              />{" "}
                            </span>
                          </Tippy>
                          <Tippy
                            content={
                              <span>
                                {item.isActive ? "De-activate" : "Activate"}
                              </span>
                            }
                            placement="top"
                          >
                            <span>
                              <BsCheckCircle
                                className="action--eye__admin"
                                onClick={() => {
                                  setShowSmModal(true);
                                  setEnableDisableItem(item);
                                }}
                              />{" "}
                            </span>
                          </Tippy>
                          <Tippy content="View" placement="bottom">
                            <span>
                              <BsEyeFill
                                className="action--edit__admin"
                                onClick={() => {
                                  setShowPreOrderDetailsModal(true);
                                  setDetailsShoingItem(item);
                                }}
                              />{" "}
                            </span>
                          </Tippy>
                          <Tippy content="Add Time Slot" placement="bottom">
                            <span>
                              <AiOutlineFieldTime
                                className="action--edit__admin"
                                onClick={() => {
                                  history.push(
                                    `/preorder-time-slot/${item.planId}`
                                  );
                                  history.push({
                                    pathname: `/preorder-time-slot/${item.planId}`,
                                    state: {
                                      startDate: item.deliveryStartDate,
                                      endDate: item.deliveryEndDate,
                                    },
                                  });
                                }}
                              />{" "}
                            </span>
                          </Tippy>
                          <Tippy content={<span>{"Order List"}</span>}>
                            <span>
                              <BsReverseLayoutTextSidebarReverse
                                style={{ fontSize: "12px" }}
                                className="action--eye__admin"
                                onClick={() =>
                                  history.push({
                                    pathname: `/requested-pre-order-by-id/${item.planId}`,
                                    state: {
                                      data: item.preOrderSettlementData,
                                    },
                                  })
                                }
                              />
                            </span>
                          </Tippy>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {/* add pre order plan modal */}
            <div
              className={`${
                showAddPreOrderModal ? "mymodal modal_activated" : "mymodal"
              }`}
            >
              <div
                className={`${
                  !isToogleSidebar
                    ? "manage_modal--container "
                    : "manage_modal--container sidebarOpend_F_modal"
                }`}
              >
                <AddPreOrderPlan />
              </div>
              <div
                className={`${
                  !isToogleSidebar
                    ? "task--rectangle"
                    : "task--rectangle withsidebarOpend"
                }`}
              >
                <div className="task--rectangle__circle">
                  <div className="circle">
                    {" "}
                    <span
                      onClick={() => {
                        setShowAddPreOrderModal(false);
                      }}
                    >
                      <BsX />
                    </span>
                    <p>Add</p>
                  </div>
                </div>
              </div>
            </div>
            {/* edit pre order plan modal */}
            <div
              className={`${
                showEditPreOrderModal ? "mymodal modal_activated" : "mymodal"
              }`}
            >
              <div
                className={`${
                  !isToogleSidebar
                    ? "manage_modal--container "
                    : "manage_modal--container sidebarOpend_F_modal"
                }`}
              >
                <EditPreOrder editinPreOrderItem={editinPreOrderItem} />
              </div>
              <div
                className={`${
                  !isToogleSidebar
                    ? "task--rectangle"
                    : "task--rectangle withsidebarOpend"
                }`}
              >
                <div className="task--rectangle__circle">
                  <div className="circle">
                    {" "}
                    <span
                      onClick={() => {
                        setShowEditPreOrderModal(false);
                      }}
                    >
                      <BsX />
                    </span>
                    <p>Edit</p>
                  </div>
                </div>
              </div>
            </div>
            {/* show pre order details */}
            <div
              className={`${
                showPreorderDetailsModal ? "mymodal modal_activated" : "mymodal"
              }`}
            >
              <div
                className={`${
                  !isToogleSidebar
                    ? "manage_modal--container "
                    : "manage_modal--container sidebarOpend_F_modal"
                }`}
              >
                <PreOrderDetails
                  detailShowingItem={detailShowingItem}
                  setShowPreOrderDetailsModal={setShowPreOrderDetailsModal}
                />
              </div>
              <div
                className={`${
                  !isToogleSidebar
                    ? "task--rectangle"
                    : "task--rectangle withsidebarOpend"
                }`}
              >
                <div className="task--rectangle__circle">
                  <div className="circle">
                    {" "}
                    <span
                      onClick={() => {
                        setShowPreOrderDetailsModal(false);
                      }}
                    >
                      <BsX />
                    </span>
                    <p></p>
                  </div>
                </div>
              </div>
            </div>
            {/* for Enable/Disable Security Question  */}
            <div
              className={`${
                showSmModal ? "delete--admin" : "delete--admin__hidden"
              }`}
            >
              <div className="delete--admin--container">
                <div className="delete--admin--container__heading"></div>
                <>
                  <div className="delete--admin--container__body">
                    {enableDisableItem.isActive ? (
                      <h1>Are you sure to de-activate pre-order plan ??</h1>
                    ) : (
                      <h1>Are you sure to activate pre-order plan ??</h1>
                    )}
                    <div>
                      {isEnabledUser === "a" && (
                        <div className="deleting--something">
                          {" "}
                          <p>Processing...</p>
                        </div>
                      )}
                    </div>
                    <div>
                      {" "}
                      {isEnabledUser === "b" && (
                        <div className="deleting--something">
                          {" "}
                          <TiTick className="success--icon" /> <p>Successful</p>
                        </div>
                      )}
                    </div>
                    <div>
                      {" "}
                      {isEnabledUser === "c" && (
                        <div className="deleting--something">
                          {" "}
                          <GiSkullCrossedBones
                            className="success--icon"
                            style={{ fontSize: "30px" }}
                          />{" "}
                          <p>Try Again</p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="delete--admin--container__footer">
                    <button onClick={() => setShowSmModal(!showSmModal)}>
                      Cancel
                    </button>
                    {enableDisableItem.isActive ? (
                      <button
                        type="submit"
                        onClick={(e) => {
                          e.preventDefault();
                          let enableDisableId = enableDisableItem.planId;
                          dispatch({
                            type: "DE_ACTIVATE_PRE_ORDER_REQUEST",
                            payload: {
                              id: enableDisableId,
                              confirmText: {
                                confirmationText: "okay",
                              },
                            },
                          });
                        }}
                      >
                        Ok
                      </button>
                    ) : (
                      <button
                        type="submit"
                        onClick={(e) => {
                          e.preventDefault();
                          let enableDisableId = enableDisableItem.planId;
                          dispatch({
                            type: "ACTIVATE_PRE_ORDER_REQUEST",
                            payload: {
                              id: enableDisableId,
                              confirmText: {
                                confirmationText: "okay",
                              },
                            },
                          });
                        }}
                      >
                        Ok
                      </button>
                    )}
                  </div>
                </>
              </div>
            </div>

            <div className="pagination">
              <p>
                Showing {indexOfFirstPost} to{" "}
                {indexOfLastPost > allPreOrderList.totalData
                  ? allPreOrderList.totalData
                  : indexOfLastPost}{" "}
                of {allPreOrderList.totalData} entries
              </p>
              <nav aria-label="...">
                <ul
                  className="pagination"
                  style={{ maxWidth: "400px", overflowX: "auto" }}
                >
                  {currentPage === 1 ? (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronLeft />
                      </a>
                    </li>
                  ) : (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronLeft
                          onClick={() => setCurrentPage(currentPage - 1)}
                        />
                      </a>
                    </li>
                  )}
                  {pageNumbers.map((x) => (
                    <li key={x} className="page-item">
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => setCurrentPage(x)}
                      >
                        {x}
                      </a>
                    </li>
                  ))}
                  {currentPage == pageNumbers.length ? (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronRight />
                      </a>
                    </li>
                  ) : (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronRight
                          onClick={() => setCurrentPage(currentPage + 1)}
                        />
                      </a>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default connect(mapStateToProps, null)(GetPreOrderList);
