import { toast } from "react-toastify";
import { authenticateAdmin } from "../FrontendHelper";
import {
  ADD_CATEGORY_INPUT,
  ADMIN_AUTH_FAILURE,
  ADMIN_AUTH_REQUEST,
  ADMIN_AUTH_SUCCESS,
  ADMIN_CREDENCIAL_INPUT,
  TOOGLE_SIDEBAR,
} from "./actionType";

const initialState = {
  buttonText: "Submit",
  updateButtonText: "Update",
  exportButton: "Export To Excel",
  transactionType: "unverified",
  addCartButton: "Add To Cart",
  placeButtonText: "Place An Order",
  operatingLoc: "",
  isToogleSidebar: false,
  isLoadingOnLogin: false,
  isSuccessOnLogin: false,
  isCustomerData: false,
  isNewCustomerAddes: false,
  isCartItemPresent: false,
  isEnabledUsers: "",
  myMenuCategoryId: null,
  myMenuItemId: null,
  adminCredencial: {
    email: "",
    password: "",
    // captchaResponse: ""
  },
  dashboardData: {},
  adminUsersList: {},
  adminProfile: {},
  searchUsersList: {},
  couponData: {},
  restaurantData: {},
  privacyData: "",
  termsAndConditionData: "",
  referral_loyalityData: [],
  advertisementList: [],
  internalAdsLinks: [],
  walletData: {},
  serviceRateData: {},
  orderList: {},
  phoneOrderList: {},
  orderDetails: {},
  activeDeliveryList: {},
  deliveryUsersList: {},
  deliveryDetails: {},
  kycRevokeLog: {},
  myNotificationList: {},
  exChangeReferalData: {},
  deliveryOrderList: {},
  userAnnouncementList: {},
  deliveryFeeData: {},
  searchedRestaurantName: {},
  settlementData: {},
  isSettlementSuccess: false,
  deliveryCommData: {},
  myCartId: null,
  myCustomerId: null,
  // phone order
  searchCustomerResult: {},
  searchRestaurantList: {},
  searchPreOrderList: {},
  searchMenuItemsList: {},
  cartItemArray: [],
  menuList: {},
  categoryList: {},
  // pre orders
  allPreOrderList: {},
  allPreOrderRequestedList: {},
  allMerchantPreOrderRequestedList: {},
  // operating location
  deliveryInfoBAToCartData: {},
  activeOperatingLocationList: {},
  operatingLocationList: {},
  campaignList: {},
  campaignOrderList: {},

  isBatchCreated: false,
  bachedList: {},
  kycList: {},
  preOrderTimeSlotList: [],

  // MODAL
  isAddCouponModal: false,
  isAddReataurantModal: false,
  isAddAdminRegisterModal: false,
  isReferralLoyaltyModal: false,
  isAddAdvertisementModal: false,
  isResellarModal: false,
  isResellarEditModal: false,
  isServiceRateModal: false,
  isDeliveryModal: false,
  isExchangeLoaylityModal: false,
  isAnnouncementModal: false,
  isSetDeliveryChagerModal: false,
  IsSetDeliveryCommModal: false,
  isAddNewCustomerModal: false,
  isDeliveryFormModal: false,
  isAddOperatingLocationModal: false,
  isAddResWithoutCsvModal: false,
  isAddCampaignModal: false,
  batchPackage: [],
  isVerifyChanged: false,
  isAddDeliverySuccess: false,
  userId: "",
  isPreOrderSettled: false,
  isOperationSuccessful: false,
  resellerList: {},
  preOrderMerchantList: {},
  isAddPreOrderMerchantList: false,
  isEditPreOrderMerchant: false,
  resellerDetail: {},
  ordersFromAllCampaign: {},
  CODVerificationPendingList: {},
  reconcilementList: {},
  isApproveSuccess: false,
  archiveList: {},
};

const orkReducer = (state = initialState, action) => {
  // sidebar toggler
  if (action.type === TOOGLE_SIDEBAR) {
    return { ...state, isToogleSidebar: !state.isToogleSidebar };
  }

  if (action.type === "SET_OERATING_LOCATION") {
    return { ...state, operatingLoc: action.payload };
  }
  if (action.type === "RECAPTCH_RESPONSE") {
    return {
      ...state,
      adminCredencial: {
        ...this.state.adminCredencial,
        captchaResponse: action.payload,
      },
    };
  }
  // fetch Admin dashboard Data
  if (action.type === "FETCH_DASHBOARD_DATA_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_DASHBOARD_DATA_SUCCESS") {
    return { ...state, dashboardData: action.payload, isLoadingOnLogin: false };
  }
  if (action.type === "FETCH_DASHBOARD_DATA_FAILURE") {
    toast.error("Sorry!!.Network Error.");
    return { ...state, isLoadingOnLogin: false };
  }
  // Admin Controller Section
  // Fetch Admin List
  if (action.type === "FETCH_ADMIN_USERS_REQUEST") {
    return { ...state, isLoadingOnLogin: "true" };
  }
  if (action.type === "FETCH_ADMIN_USERS_SUCCESS") {
    return {
      ...state,
      adminUsersList: action.payload,
      isLoadingOnLogin: false,
      buttonText: "Submit",
      isEnabledUsers: "",
    };
  }
  if (action.type === "FETCH_ADMIN_USERS_FAILURE") {
    toast.error("Sorry!!! Couldnot fetch Admin list");
    return { ...state, buttonText: "Submit", isLoadingOnLogin: false };
  }
  // Fetch admin profile
  if (action.type === "FETCH_ADMIN_BY_ID_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_ADMIN_BY_ID_SUCCESS") {
    return { ...state, adminProfile: action.payload, isLoadingOnLogin: false };
  }
  if (action.type === "FETCH_ADMIN_BY_ID_FAILURE") {
    toast.error("Sorry !!! Network Error.");
    return { ...state, isLoadingOnLogin: false };
  }
  // Add admin
  if (action.type === "POST_ADMIN_USERS_REQUEST") {
    return { ...state, buttonText: "Submitting" };
  }
  if (action.type === "POST_ADMIN_USERS_SUCCESS") {
    toast.success("New admin added Successfully !!!");
    return { ...state, buttonText: "Submit" };
  }
  if (action.type === "POST_ADMIN_USERS_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, buttonText: "Submit" };
  }
  // update admin
  if (action.type === "UPDATE_ADMIN_USERS_REQUEST") {
    return { ...state, updateButtonText: "Updating" };
  }
  if (action.type === "UPDATE_ADMIN_USERS_SUCCESS") {
    toast.success("Admin Updated Successfully !!!");
    return { ...state, updateButtonText: "Update" };
  }
  if (action.type === "UPDATE_ADMIN_USERS_FAILURE") {
    if (action.payload.data === undefined) {
      toast.error("Couldn't Update Admin !!!");
    } else {
      toast.error(action.payload.data.message);
    }
    return { ...state, updateButtonText: "Update" };
  }
  // Enable Disable Admin
  if (action.type === "ENABLE_DISABLE_ADMIN_REQUEST") {
    return { ...state, isEnabledUsers: "a" };
  }
  if (action.type === "ENABLE_DISABLE_ADMIN_SUCCESS") {
    toast.success(action.payload.message);
    return { ...state, isEnabledUsers: "b" };
  }
  if (action.type === "ENABLE_DISABLE_ADMIN_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, isEnabledUsers: "" };
  }

  // authorization/ Log in
  if (action.type === ADMIN_AUTH_REQUEST) {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === ADMIN_AUTH_SUCCESS) {
    toast.success("Login Successful !!!");
    authenticateAdmin(action.payload, () => {
      console.log("the data is saved in locaStorage");
    });
    return { ...state, isSuccessOnLogin: true, isLoadingOnLogin: false };
  }
  if (action.type === ADMIN_AUTH_FAILURE) {
    if (action.payload.error?.status === 400) {
      toast.error(action.payload.error.data.message);
    } else if (action.payload.error?.status === 500) {
      toast.error("Internal server error !!! Try Again Later.");
    } else {
      toast.error("Something went wrong. Try again later !!!");
    }

    return { ...state, isLoadingOnLogin: false };
  }

  // Log out
  if (action.type === "LOG_OUT_REQUEST") {
    return { ...state, isSuccessOnLogin: false };
  }
  // input form data
  if (action.type === ADMIN_CREDENCIAL_INPUT) {
    return { ...state, adminCredencial: action.payload.values };
  }
  if (action.type === ADD_CATEGORY_INPUT) {
    return { ...state, isPostingCategory: action.payload.values };
  }
  // // fetch Privacy data
  if (action.type === "FETCH_PRIVACY_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_PRIVACY_SUCCESS") {
    return { ...state, privacyData: action.payload, isLoadingOnLogin: false };
  }
  if (action.type === "FETCH_PRIVACY_FAILURE") {
    toast.error("Sorry!!.Network Error.");
    return { ...state, isLoadingOnLogin: false };
  }
  // fetch terms and conditions
  if (action.type === "FETCH_TERMS_CONDITION_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_TERMS_CONDITION_SUCCESS") {
    return {
      ...state,
      termsAndConditionData: action.payload,
      isLoadingOnLogin: false,
    };
  }
  if (action.type === "FETCH_TERMS_CONDITION_FAILURE") {
    toast.error("Sorry!!.Network Error.");
    return { ...state, isLoadingOnLogin: false };
  }
  // posting privacy and policy
  if (action.type === "POST_PRIVACY_REQUEST") {
    return { ...state, updateButtonText: "Updating" };
  }
  if (action.type === "POST_PRIVACY_SUCCESS") {
    toast.success("Privacy and Policy updated successfully !!!");
    return { ...state, updateButtonText: "Update" };
  }
  if (action.type === "POST_PRIVACY_FAILURE") {
    toast.error("Couldnot update !!!");
    return { ...state, updateButtonText: "Update" };
  }
  // posting terms and condition
  if (action.type === "POST_TERMS_N_CONDITION_REQUEST") {
    return { ...state, updateButtonText: "Updating" };
  }
  if (action.type === "POST_TERMS_N_CONDITION_SUCCESS") {
    toast.success("Terms and condition updated successfully !!!");
    return { ...state, updateButtonText: "Update" };
  }
  if (action.type === "POST_TERMS_N_CONDITION_FAILURE") {
    toast.error("Couldnot update. Try again.");
    return { ...state, updateButtonText: "Update" };
  }
  // Add mass notification
  if (action.type === "ADD_MASS_NOTIFICATION_REQUEST") {
    return { ...state, buttonText: "Submitting" };
  }
  if (action.type === "ADD_MASS_NOTIFICATION_SUCCESS") {
    toast.success(action.payload.message);
    return { ...state, buttonText: "Submit" };
  }
  if (action.type === "ADD_MASS_NOTIFICATION_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again!!!");
    }
    return { ...state, buttonText: "Submit" };
  }

  // fetch userslist data
  if (action.type === "FETCH_USERS_LIST_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_USERS_LIST_SUCCESS") {
    return {
      ...state,
      searchUsersList: action.payload,
      isLoadingOnLogin: false,
      isEnabledUsers: "",
    };
  }
  if (action.type === "FETCH_USERS_LIST_FAILURE") {
    // toast.error(action.payload.response.data.message)
    return { ...state, isLoadingOnLogin: false };
  }
  // fetch userslist by id for details data
  if (action.type === "FETCH_USER_BY_ID_REQUEST") {
    return { ...state };
  }
  if (action.type === "FETCH_USER_BY_ID_SUCCESS") {
    return { ...state, userDetails: action.payload };
  }
  if (action.type === "FETCH_USER_BY_ID_FAILURE") {
    toast.error("Sorry!!.Network Error.");
    return { ...state };
  }
  // // Enable users
  if (action.type === "ENABLE_USERS_REQUEST") {
    return { ...state, isEnabledUsers: "a" };
  }
  if (action.type === "ENABLE_USERS_SUCCESS") {
    toast.success(action.payload.message);
    return { ...state, isEnabledUsers: "b" };
  }
  if (action.type === "ENABLE_USERS_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, isEnabledUsers: "" };
  }
  // // Disable users
  if (action.type === "DISABLE_USERS_REQUEST") {
    return { ...state, isEnabledUsers: "a" };
  }
  if (action.type === "DISABLE_USERS_SUCCESS") {
    toast.success(action.payload.message);
    return { ...state, isEnabledUsers: "b" };
  }
  if (action.type === "DISABLE_USERS_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, isEnabledUsers: "" };
  }
  // fetch Coupon Codes
  if (action.type === "FETCH_COUPON_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_COUPON_SUCCESS") {
    return {
      ...state,
      couponData: action.payload,
      isLoadingOnLogin: false,
      buttonText: "Submit",
      updateButtonText: "Update",
      isEnabledUsers: "",
    };
  }
  if (action.type === "FETCH_COUPON_FAILURE") {
    toast.error("Sorry!!.Network Error.");
    return { ...state, isLoadingOnLogin: false };
  }
  // Add Coupon
  if (action.type === "POST_COUPON_REQUEST") {
    return { ...state, buttonText: "Submitting" };
  }
  if (action.type === "POST_COUPON_SUCCESS") {
    toast.success("Coupon code successfully added !!!");
    return { ...state, buttonText: "Submitted", isEnabledUsers: "yes" };
  }
  if (action.type === "POST_COUPON_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, buttonText: "Submit", isEnabledUser: "" };
  }
  // Update Coupon
  if (action.type === "UPDATE_COUPON_REQUEST") {
    return { ...state, updateButtonText: "Updating" };
  }
  if (action.type === "UPDATE_COUPON_SUCCESS") {
    toast.success("Coupon code successfully Updated !!!");
    return { ...state, updateButtonText: "Updated" };
  }
  if (action.type === "UPDATE_COUPON_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, updateButtonText: "Update" };
  }
  // Activate coupon
  if (action.type === "ACTIVATE_COUPON_REQUEST") {
    return { ...state, isEnabledUsers: "a" };
  }
  if (action.type === "ACTIVATE_COUPON_SUCCESS") {
    toast.success("Successfully Activated Coupon !!!");
    return { ...state, isEnabledUsers: "b" };
  }
  if (action.type === "ACTIVATE_COUPON_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, isEnabledUsers: "" };
  }
  // Deactive coupon
  if (action.type === "DEACTIVATE_COUPON_REQUEST") {
    return { ...state, isEnabledUsers: "a" };
  }
  if (action.type === "DEACTIVATE_COUPON_SUCCESS") {
    toast.success("Successfully De-Activated Coupon !!!");
    return { ...state, isEnabledUsers: "b" };
  }
  if (action.type === "DEACTIVATE_COUPON_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, isEnabledUsers: "" };
  }
  // fetch restaurant data
  if (action.type === "FETCH_RESTAURANTS_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_RESTAURANTS_SUCCESS") {
    return {
      ...state,
      restaurantData: action.payload,
      isLoadingOnLogin: false,
      isEnabledUsers: "",
      buttonText: "Submit",
      updateButtonText: "Update",
    };
  }
  if (action.type === "FETCH_RESTAURANTS_FAILURE") {
    toast.error("Sorry!!.Network Error.");
    return { ...state, isLoadingOnLogin: false };
  }
  // add Restaurant
  if (action.type === "ADD_RESTAURANTS_REQUEST") {
    return { ...state, buttonText: "Submitting", isEnabledUsers: "a" };
  }
  if (action.type === "ADD_RESTAURANTS_SUCCESS") {
    toast.success("Restaurant added successfully !!!");
    return { ...state, buttonText: "Submit", isEnabledUsers: "resAdded" };
  }
  if (action.type === "ADD_RESTAURANTS_FAILURE") {
    toast.success(action.payload.data.message);
    return { ...state, buttonText: "Submit", isEnabledUsers: "" };
  }
  // add Restaurant
  if (action.type === "ADD_RESTAURANTS_WIOUT_CSV_REQUEST") {
    return { ...state, buttonText: "Submitting", isEnabledUsers: "a" };
  }
  if (action.type === "ADD_RESTAURANTS_WIOUT_CSV_SUCCESS") {
    toast.success("Restaurant added successfully !!!");
    return { ...state, buttonText: "Submit", isEnabledUsers: "b" };
  }
  if (action.type === "ADD_RESTAURANTS_WIOUT_CSV_FAILURE") {
    toast.success(action.payload.data.message);
    return { ...state, buttonText: "Submit", isEnabledUsers: "" };
  }
  // search restuarnt by name
  if (action.type === "SEARCH_RESTAURANT_REQUEST") {
    return { ...state };
  }
  if (action.type === "SEARCH_RESTAURANT_SUCCESS") {
    // toast.success("Restaurant added successfully !!!")
    return { ...state, searchedRestaurantName: action.payload };
  }
  if (action.type === "SEARCH_RESTAURANT_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Internal Server Error !!!. Try Again Later");
    }
    return { ...state };
  }
  // Update Restaurant
  if (action.type === "UPDATE_RESTAURANTS_REQUEST") {
    return { ...state, updateButtonText: "Updating" };
  }
  if (action.type === "UPDATE_RESTAURANTS_SUCCESS") {
    toast.success("Restaurant Updated successfully !!!");
    return { ...state, updateButtonText: "Updated" };
  }
  if (action.type === "UPDATE_RESTAURANTS_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, updateButtonText: "Update" };
  }
  // Activate restaurant
  if (action.type === "ACTIVATE_RESTAURANT_REQUEST") {
    return { ...state, isEnabledUsers: "a" };
  }
  if (action.type === "ACTIVATE_RESTAURANT_SUCCESS") {
    toast.success("Successfully Activated Restaurant !!!");
    return { ...state, isEnabledUsers: "b" };
  }
  if (action.type === "ACTIVATE_RESTAURANT_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, isEnabledUsers: "" };
  }
  // Deactive restaurant
  if (action.type === "DEACTIVATE_RESTAURANT_REQUEST") {
    return { ...state, isEnabledUsers: "a" };
  }
  if (action.type === "DEACTIVATE_RESTAURANT_SUCCESS") {
    toast.success("Successfully De-Activated Restaurant !!!");
    return { ...state, isEnabledUsers: "b" };
  }
  if (action.type === "DEACTIVATE_RESTAURANT_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, isEnabledUsers: "" };
  }
  // Delete restaurant
  if (action.type === "DELETE_RESTAURANT_REQUEST") {
    return { ...state, isEnabledUsers: "a" };
  }
  if (action.type === "DELETE_RESTAURANT_SUCCESS") {
    toast.success("Successfully Deleted Restaurant !!!");
    return { ...state, isEnabledUsers: "b" };
  }
  if (action.type === "DELETE_RESTAURANT_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, isEnabledUsers: "" };
  }

  // fetch Refferal And Loyalty
  if (action.type === "FETCH_REFERAL_LOYALTY_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_REFERAL_LOYALTY_SUCCESS") {
    return {
      ...state,
      referral_loyalityData: action.payload,
      isLoadingOnLogin: false,
      buttonText: "Submit",
    };
  }
  if (action.type === "FETCH_REFERAL_LOYALTY_FAILURE") {
    toast.error("Sorry!!.Network Error.");
    return { ...state, isLoadingOnLogin: false };
  }
  // Post Refferal And Loyalty
  if (action.type === "POST_REFERAL_LOYALTY_REQUEST") {
    return { ...state, buttonText: "Submitting" };
  }
  if (action.type === "POST_REFERAL_LOYALTY_SUCCESS") {
    toast.success("Referral and Loyalty Added Successfully !!!");
    return { ...state, buttonText: "Submit" };
  }
  if (action.type === "POST_REFERAL_LOYALTY_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, buttonText: "Submit" };
  }

  // fetch advertisement
  if (action.type === "FETCH_ADVERTISEMENT_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_ADVERTISEMENT_SUCCESS") {
    return {
      ...state,
      advertisementList: action.payload,
      buttonText: "Submit",
      updateButtonText: "Update",
      isLoadingOnLogin: false,
      isEnabledUsers: "",
    };
  }
  if (action.type === "FETCH_ADVERTISEMENT_FAILURE") {
    toast.error("Sorry!!.Network Error.");
    return { ...state, isLoadingOnLogin: false };
  }
  // fetch internal advertisement links
  if (action.type === "FETCH_INTERNAL_ADS_LINK_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_INTERNAL_ADS_LINK_SUCCESS") {
    return { ...state, internalAdsLinks: action.payload };
  }
  if (action.type === "FETCH_INTERNAL_ADS_LINK_FAILURE") {
    toast.error("Couldn't fetch Internal Ads Links.");
    return { ...state, isLoadingOnLogin: false };
  }
  // Post Advertisement
  if (action.type === "POST_ADVERTISEMENT_REQUEST") {
    return { ...state, buttonText: "Submitting", isEnabledUsers: "a" };
  }
  if (action.type === "POST_ADVERTISEMENT_SUCCESS") {
    toast.success("The Advertisement added successfully!!!");
    return { ...state, buttonText: "Submitted", isEnabledUsers: "b" };
  }
  if (action.type === "POST_ADVERTISEMENT_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Internal Server Error !!!. Try Again Later");
    }

    return { ...state, buttonText: "Submit" };
  }
  // Updating Advertisement
  if (action.type === "UPDATE_ADVERTISEMENT_REQUEST") {
    return { ...state, updateButtonText: "Updating" };
  }
  if (action.type === "UPDATE_ADVERTISEMENT_SUCCESS") {
    toast.success("The Advertisement Updated successfully!!!");
    return { ...state, updateButtonText: "Update" };
  }
  if (action.type === "UPDATE_ADVERTISEMENT_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, updateButtonText: "Update" };
  }
  // Enable Promotion
  if (action.type === "ENABLE_PROMOTION_REQUEST") {
    return { ...state, isEnabledUsers: "a" };
  }
  if (action.type === "ENABLE_PROMOTION_SUCCESS") {
    toast.success("The Promotion Activated successfully!!!");
    return { ...state, isEnabledUsers: "b" };
  }
  if (action.type === "ENABLE_PROMOTION_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, isEnabledUsers: "" };
  }
  // Enable Promotion
  if (action.type === "DISABLE_PROMOTION_REQUEST") {
    return { ...state, isEnabledUsers: "a" };
  }
  if (action.type === "DISABLE_PROMOTION_SUCCESS") {
    toast.success("The Promotion De-Activated successfully!!!");
    return { ...state, isEnabledUsers: "b" };
  }
  if (action.type === "DISABLE_PROMOTION_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, isEnabledUsers: "" };
  }
  // Get wallet transaction data
  if (action.type === "FETCH_WALLET_DATA_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_WALLET_DATA_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      transactionType: "unverified",
      walletData: action.payload,
      isEnabledUsers: "",
    };
  }
  if (action.type === "FETCH_WALLET_DATA_FAILURE") {
    toast.error("Network Error. Try Again !!!");
    return { ...state, isLoadingOnLogin: false };
  }
  // Search wallet transaction data
  if (action.type === "SEARCH_WALLET_DATA_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "SEARCH_WALLET_DATA_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      transactionType: "unverified",
      walletData: action.payload,
      isEnabledUsers: "",
    };
  }
  if (action.type === "SEARCH_WALLET_DATA_FAILURE") {
    toast.error("Network Error. Try Again !!!");
    return { ...state, isLoadingOnLogin: false };
  }
  // Get verified wallet transaction data
  if (action.type === "FETCH_VERIFIED_WALLET_DATA_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_VERIFIED_WALLET_DATA_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      transactionType: "verified",
      walletData: action.payload,
      isEnabledUsers: "",
    };
  }
  if (action.type === "FETCH_VERIFIED_WALLET_DATA_FAILURE") {
    toast.error("Network Error. Try Again !!!");
    return { ...state, isLoadingOnLogin: false };
  }
  // search verified wallet transaction data
  if (action.type === "SEARCH_VERIFIED_WALLET_DATA_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "SEARCH_VERIFIED_WALLET_DATA_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      transactionType: "verified",
      walletData: action.payload,
      isEnabledUsers: "",
    };
  }
  if (action.type === "SEARCH_VERIFIED_WALLET_DATA_FAILURE") {
    toast.error("Network Error. Try Again !!!");
    return { ...state, isLoadingOnLogin: false };
  }
  // verify wallet transaction
  if (action.type === "VERIFY_TRANSACTION_REQUEST") {
    return { ...state, isEnabledUsers: "a" };
  }
  if (action.type === "VERIFY_TRANSACTION_SUCCESS") {
    toast.success("Transaction verified successfully");
    return { ...state, isEnabledUsers: "b" };
  }
  if (action.type === "VERIFY_TRANSACTION_FAILURE") {
    if (action.payload.data === undefined) {
      toast.error("Internal server error. Try again later !!!");
    } else {
      toast.error(action.payload.data.message);
    }
    return { ...state, isEnabledUsers: "" };
  }
  // Get Service Rate
  if (action.type === "FETCH_SERVICE_RATE_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_SERVICE_RATE_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      serviceRateData: action.payload,
      buttonText: "Submit",
      updateButtonText: "Update",
    };
  }
  if (action.type === "FETCH_SERVICE_RATE_FAILURE") {
    toast.error("Network Error. Try Again !!!");
    return { ...state, isLoadingOnLogin: false };
  }
  // Add update Service Rate
  if (action.type === "POST_SERVICE_RATE_REQUEST") {
    return { ...state, buttonText: "Submitting", updateButtonText: "Updating" };
  }
  if (action.type === "POST_SERVICE_RATE_SUCCESS") {
    toast.success("Successfully Updated Service Rate.");
    return { ...state, buttonText: "Submit", updateButtonText: "Updated" };
  }
  if (action.type === "POST_SERVICE_RATE_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, buttonText: "Submit", updateButtonText: "Update" };
  }
  // Get order List
  if (action.type === "SEARCH_ORDER_LIST_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "SEARCH_ORDER_LIST_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      orderList: action.payload,
      isEnabledUsers: "",
    };
  }
  if (action.type === "SEARCH_ORDER_LIST_FAILURE") {
    toast.error("Network Error !!!. Try Again.");
    return { ...state, isLoadingOnLogin: false };
  }
  // FETCH PHONE ORDER LIST
  if (action.type === "FETCH_PHONE_ORDER_LIST_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_PHONE_ORDER_LIST_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      phoneOrderList: action.payload,
      isEnabledUsers: "",
      addCartButton: "Add To Cart",
      cartItemArray: [],
      myMenuCategoryId: null,
      myMenuItemId: null,
    };
  }
  if (action.type === "FETCH_PHONE_ORDER_LIST_FAILURE") {
    toast.error("Network Error !!!. Try Again.");
    return { ...state, isLoadingOnLogin: false };
  }

  // fetch campaign order list
  if (action.type === "FETCH_CAMPAIGN_ORDER_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_CAMPAIGN_ORDER_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      campaignOrderList: action.payload,
      isEnabledUsers: "",
    };
  }
  if (action.type === "FETCH_CAMPAIGN_ORDER_FAILURE") {
    toast.error("Network Error !!!. Try Again.");
    return { ...state, isLoadingOnLogin: false };
  }
  // search order list
  if (action.type === "SEARCH_ORDER_LIST_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "SEARCH_ORDER_LIST_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      orderList: action.payload,
      isEnabledUsers: "",
    };
  }
  if (action.type === "SEARCH_ORDER_LIST_FAILURE") {
    toast.error("Network Error !!!. Try Again.");
    return { ...state, isLoadingOnLogin: false };
  }
  // Get order List
  if (action.type === "FETCH_ORDER_LIST_BY_STATUS_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_ORDER_LIST_BY_STATUS_SUCCESS") {
    return {
      ...state,
      orderList: action.payload,
      isLoadingOnLogin: false,
      isEnabledUsers: "",
      addCartButton: "Add To Cart",
      cartItemArray: [],
      myMenuCategoryId: null,
      myMenuItemId: null,
    };
  }
  if (action.type === "FETCH_ORDER_LIST_BY_STATUS_FAILURE") {
    toast.error("Network Error !!!. Try Again.");
    return { ...state, isLoadingOnLogin: false };
  }
  // Get order details by id
  if (action.type === "ORDER_DETAILS_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "ORDER_DETAILS_SUCCESS") {
    return { ...state, isLoadingOnLogin: false, orderDetails: action.payload };
  }
  if (action.type === "ORDER_DETAILS_FAILURE") {
    toast.error("Network Error !!!. Try Again.");
    return { ...state, isLoadingOnLogin: false };
  }
  // Accept order
  if (action.type === "ACCEPT_ORDER_REQUEST") {
    return { ...state, isEnabledUsers: "a" };
  }
  if (action.type === "ACCEPT_ORDER_SUCCESS") {
    toast.success("Great !!! Successfully Accepted Order.");
    return { ...state, isEnabledUsers: "b" };
  }
  if (action.type === "ACCEPT_ORDER_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, isEnabledUsers: "" };
  }
  // Accept campaing order
  if (action.type === "APPROVE_CAMPAIGN_ORDER_REQUEST") {
    return { ...state, isEnabledUsers: "a" };
  }
  if (action.type === "APPROVE_CAMPAIGN_ORDER_SUCCESS") {
    toast.success("Great !!! Successfully approved campaign Order.");
    return { ...state, isEnabledUsers: "b" };
  }
  if (action.type === "APPROVE_CAMPAIGN_ORDER_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, isEnabledUsers: "" };
  }
  // complete campaing order
  if (action.type === "COMPLETE_CAMPAIGN_ORDER_REQUEST") {
    return { ...state, isEnabledUsers: "a" };
  }
  if (action.type === "COMPLETE_CAMPAIGN_ORDER_SUCCESS") {
    toast.success("Great !!! Successfully completed campaign Order.");
    return { ...state, isEnabledUsers: "b" };
  }
  if (action.type === "COMPLETE_CAMPAIGN_ORDER_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, isEnabledUsers: "" };
  }
  // cancel campaing order
  if (action.type === "CANCEL_CAMPAIGN_ORDER_REQUEST") {
    return { ...state, isEnabledUsers: "a" };
  }
  if (action.type === "CANCEL_CAMPAIGN_ORDER_SUCCESS") {
    toast.success("Great !!! Successfully canceled campaign Order.");
    setTimeout(() => {
      window.location.reload();
    }, [1000]);
    return { ...state, isEnabledUsers: "b" };
  }
  if (action.type === "CANCEL_CAMPAIGN_ORDER_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, isEnabledUsers: "" };
  }
  // Cancel order
  if (action.type === "CANCEL_ORDER_REQUEST") {
    return { ...state, isEnabledUsers: "a" };
  }
  if (action.type === "CANCEL_ORDER_SUCCESS") {
    toast.success("Great !!! Successfully Canceled Order.");
    return { ...state, isEnabledUsers: "b" };
  }
  if (action.type === "CANCEL_ORDER_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, isEnabledUsers: "" };
  }
  // fetch active delivery userslist
  if (action.type === "FETCH_ACTIVE_DELIVERY_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_ACTIVE_DELIVERY_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      activeDeliveryList: action.payload,
    };
  }
  if (action.type === "FETCH_ACTIVE_DELIVERY_FAILURE") {
    toast.error("Network Error. Try Again");
    return { ...state, isLoadingOnLogin: false };
  }
  // fetch delivery data
  if (action.type === "FETCH_DELIVERY_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_DELIVERY_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      deliveryUsersList: action.payload,
      isEnabledUsers: "",
      buttonText: "Submit",
      updateButtonText: "Update",
    };
  }
  if (action.type === "FETCH_DELIVERY_FAILURE") {
    toast.error("Network Error !!!. Try Again.");
    return { ...state, isLoadingOnLogin: false };
  }
  // fetch delivery draft data
  if (action.type === "FETCH_DELIVERY_DRAFT_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_DELIVERY_DRAFT_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      deliveryUsersList: action.payload,
      isEnabledUsers: "",
      buttonText: "Submit",
      updateButtonText: "Update",
    };
  }
  if (action.type === "FETCH_DELIVERY_DRAFT_FAILURE") {
    toast.error("Network Error !!!. Try Again.");
    return { ...state, isLoadingOnLogin: false };
  }
  // accept delivery draft data
  if (action.type === "ACCEPT_DELIVER_DRAFT_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isEnabledUsers: "a" };
  }
  if (action.type === "ACCEPT_DELIVER_DRAFT_SUCCESS") {
    toast.success("Delivery draft accepted successfully.");
    return { ...state, isEnabledUsers: "b", isLoadingOnLogin: false };
  }
  if (action.type === "ACCEPT_DELIVER_DRAFT_FAILURE") {
    toast.error("Network Error !!!. Try Again.");
    return { ...state, isEnabledUsers: "", isLoadingOnLogin: false };
  }
  // Add delivery data
  if (action.type === "ADD_DELIVERY_REQUEST") {
    return { ...state, buttonText: "Submitting", isEnabledUsers: "a" };
  }
  if (action.type === "ADD_DELIVERY_SUCCESS") {
    return {
      ...state,
      buttonText: "Submit",
      isEnabledUsers: "b",
      isDeliveryModal: false,
      isAddDeliverySuccess: true,
      userId: action.payload.id,
    };
  }
  if (action.type === "ADD_DELIVERY_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, buttonText: "Submit", isEnabledUsers: "" };
  }
  if (action.type === "ADD_DELIVERY_MORE_INFO_REQUEST") {
    return { ...state, buttonText: "Submitting", isEnabledUsers: "a" };
  }
  if (action.type === "ADD_DELIVERY_MORE_INFO_SUCCESS") {
    toast.success("Successully added Delivery Person !!!");
    return {
      ...state,
      buttonText: "Submit",
      isEnabledUsers: "c",
      isAddDeliverySuccess: false,
    };
  }
  if (action.type === "ADD_DELIVERY_MORE_INFO_FAILURE") {
    toast.error("Network Error !!!. Try Again.");
    return { ...state, buttonText: "Submit", isEnabledUsers: "" };
  }
  // Update delivery data
  if (action.type === "UPDATE_DELIVERY_REQUEST") {
    return { ...state, updateButtonText: "Updating" };
  }
  if (action.type === "UPDATE_DELIVERY_SUCCESS") {
    toast.success("Successully Updated Delivery Person !!!");
    return { ...state, updateButtonText: "Updated" };
  }
  if (action.type === "UPDATE_DELIVERY_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, updateButtonText: "Update" };
  }
  // Activate delivery user
  if (action.type === "ACTIVATE_DELIVERY_REQUEST") {
    return { ...state, isEnabledUsers: "a" };
  }
  if (action.type === "ACTIVATE_DELIVERY_SUCCESS") {
    toast.success("Successully Activated Delivery Person !!!");
    return { ...state, isEnabledUsers: "b" };
  }
  if (action.type === "ACTIVATE_DELIVERY_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, isEnabledUsers: "" };
  }
  // deactivate delivery user
  if (action.type === "DE_ACTIVATE_DELIVERY_REQUEST") {
    return { ...state, isEnabledUsers: "a" };
  }
  if (action.type === "DE_ACTIVATE_DELIVERY_SUCCESS") {
    toast.success("Successully De-activated Delivery Person !!!");
    return { ...state, isEnabledUsers: "b" };
  }
  if (action.type === "DE_ACTIVATE_DELIVERY_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, isEnabledUsers: "" };
  }
  // Assign order to delivery
  if (action.type === "ASSIGN_ORDER_T_DELIVERY_REQUEST") {
    return { ...state, isEnabledUsers: "a" };
  }
  if (action.type === "ASSIGN_ORDER_T_DELIVERY_SUCCESS") {
    toast.success("Successully Assigned Order To Delivery Person !!!");
    return { ...state, isEnabledUsers: "b" };
  }
  if (action.type === "ASSIGN_ORDER_T_DELIVERY_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, isEnabledUsers: "" };
  }
  // Assign order to multiple delivery
  if (action.type === "ASSIGN_ORDER_T_MULTIPLE_DELIVERY_REQUEST") {
    return { ...state, isEnabledUsers: "a" };
  }
  if (action.type === "ASSIGN_ORDER_T_MULTIPLE_DELIVERY_SUCCESS") {
    toast.success("Successully Assigned Order To Delivery Person !!!");
    return { ...state, isEnabledUsers: "b" };
  }
  if (action.type === "ASSIGN_ORDER_T_MULTIPLE_DELIVERY_FAILURE") {
    toast.error(action.payload?.data?.message);
    return { ...state, isEnabledUsers: "" };
  }
  // Delete delivery boy
  if (action.type === "DELETE_DELIVERY_REQUEST") {
    return { ...state, isEnabledUsers: "a" };
  }
  if (action.type === "DELETE_DELIVERY_SUCCESS") {
    toast.success("Successully Deleted Delivery Person !!!");
    return { ...state, isEnabledUsers: "b" };
  }
  if (action.type === "DELETE_DELIVERY_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, isEnabledUsers: "" };
  }
  // Fetch delivery details by id
  if (action.type === "FETCH_DELIVERY_DETAILS_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_DELIVERY_DETAILS_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      deliveryDetails: action.payload,
    };
  }
  if (action.type === "FETCH_DELIVERY_DETAILS_FAILURE") {
    toast.error("Network Error, Try again later!!!");
    return { ...state, isLoadingOnLogin: false };
  }
  // Fetch kyc revoke history log by id
  if (action.type === "FETCH_KYC_REVOKE_HISTORY_LOG_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_KYC_REVOKE_HISTORY_LOG_REQUEST_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      kycRevokeLog: action.payload,
    };
  }
  if (action.type === "FETCH_KYC_REVOKE_HISTORY_LOG_REQUEST_FAILURE") {
    toast.error("Network Error, Try again later!!!");
    return { ...state, isLoadingOnLogin: false };
  }
  // Receiver order by delivery
  if (action.type === "RECEIVE_ORDER_REQUEST") {
    return { ...state, isEnabledUsers: "a" };
  }
  if (action.type === "RECEIVE_ORDER_SUCCESS") {
    toast.success("Successfully Received Order !!!");
    return { ...state, isEnabledUsers: "b" };
  }
  if (action.type === "RECEIVE_ORDER_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, isEnabledUsers: "" };
  }
  // Reached order to the destination
  if (action.type === "REACHED_DELIVERY_DESTINATION_REQUEST") {
    return { ...state, isEnabledUsers: "a" };
  }
  if (action.type === "REACHED_DELIVERY_DESTINATION_SUCCESS") {
    toast.success("Delivery reached destination successfully !!!");
    return { ...state, isEnabledUsers: "b" };
  }
  if (action.type === "REACHED_DELIVERY_DESTINATION_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, isEnabledUsers: "" };
  }
  // delivery completed status
  if (action.type === "DELIVERY_COMPLETED_REQUEST") {
    return { ...state, isEnabledUsers: "a" };
  }
  if (action.type === "DELIVERY_COMPLETED_SUCCESS") {
    toast.success("Delivery completed successfully !!!");
    return { ...state, isEnabledUsers: "b" };
  }
  if (action.type === "DELIVERY_COMPLETED_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, isEnabledUsers: "" };
  }
  // Accept payment of order
  if (action.type === "ACCEPT_PAYMENT_OF_ORDER_REQUEST") {
    return { ...state, isEnabledUsers: "a" };
  }
  if (action.type === "ACCEPT_PAYMENT_OF_ORDER_SUCCESS") {
    toast.success("Successfully accepted Payment of this order.");
    return { ...state, isEnabledUsers: "b" };
  }
  if (action.type === "ACCEPT_PAYMENT_OF_ORDER_FAILURE") {
    toast.error("Couldn't Accept payment of this order. !!!");
    return { ...state, isEnabledUsers: "" };
  }
  // reject payment of order
  if (action.type === "REJECT_PAYMENT_OF_ORDER_REQUEST") {
    return { ...state, isEnabledUsers: "a" };
  }
  if (action.type === "REJECT_PAYMENT_OF_ORDER_SUCCESS") {
    toast.success("Successfully Rejected Payment of this order.");
    return { ...state, isEnabledUsers: "b" };
  }
  if (action.type === "REJECT_PAYMENT_OF_ORDER_FAILURE") {
    toast.error("Couldn't Accept payment of this order. !!!");
    return { ...state, isEnabledUsers: "" };
  }
  // Approve order from admin
  if (action.type === "APPROVE_ORDER_REQUEST") {
    return { ...state, isEnabledUsers: "a" };
  }
  if (action.type === "APPROVE_ORDER_SUCCESS") {
    toast.success("Order Approved From Admin Successfully !!!");
    return { ...state, isEnabledUsers: "b" };
  }
  if (action.type === "APPROVE_ORDER_FAILURE") {
    toast.error(action?.payload?.data?.message);
    return { ...state, isEnabledUsers: "" };
  }
  // set order ready
  if (action.type === "SET_ORDER_READY_REQUEST") {
    return { ...state, isEnabledUsers: "a" };
  }
  if (action.type === "SET_ORDER_READY_SUCCESS") {
    toast.success("Order Status Changed to Ready !!!");
    return { ...state, isEnabledUsers: "b" };
  }
  if (action.type === "SET_ORDER_READY_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, isEnabledUsers: "" };
  }
  // Fetch notification list
  if (action.type === "FETCH_NOTIFICATION_LIST_REQUEST") {
    return { ...state, isLoadingOnLogin: false };
  }
  if (action.type === "FETCH_NOTIFICATION_LIST_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      myNotificationList: action.payload,
    };
  }
  if (action.type === "FETCH_NOTIFICATION_LIST_FAILURE") {
    toast.error("Network Error . Try Again");
    return { ...state, isLoadingOnLogin: false };
  }
  // Fetch referal and loyality exchange point list
  if (action.type === "FETCH_EXCHANGE_REFERAL_REQUEST") {
    return { ...state, isLoadingOnLogin: false };
  }
  if (action.type === "FETCH_EXCHANGE_REFERAL_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      exChangeReferalData: action.payload,
      buttonText: "Submit",
      updateButtonText: "Update",
    };
  }
  if (action.type === "FETCH_EXCHANGE_REFERAL_FAILURE") {
    toast.error("Network Error . Try Again");
    return { ...state, isLoadingOnLogin: false };
  }
  // post referal and loyality exchange point list
  if (action.type === "POST_EXCHANGE_REFERAL_REQUEST") {
    return { ...state, buttonText: "Submitting", updateButtonText: "Updating" };
  }
  if (action.type === "POST_EXCHANGE_REFERAL_SUCCESS") {
    toast.success("Successfully Updated Exchange Loyalty Scheme !!!!");
    return { ...state, buttonText: "Submitted", updateButtonText: "Updated" };
  }
  if (action.type === "POST_EXCHANGE_REFERAL_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, buttonText: "Submit", updateButtonText: "Update" };
  }
  // Fetch user announcemnt list
  if (action.type === "FETCH_USER_ANNOUNCEMENT_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_USER_ANNOUNCEMENT_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      userAnnouncementList: action.payload,
      buttonText: "Submit",
      updateButtonText: "Update",
      isEnabledUsers: "",
    };
  }
  if (action.type === "FETCH_USER_ANNOUNCEMENT_FAILURE") {
    toast.error("Network Error . Try Again");
    return { ...state, isLoadingOnLogin: false };
  }

  // post user announcement
  if (action.type === "POST_USER_ANNOUNCEMENT_REQUEST") {
    return { ...state, buttonText: "Submitting" };
  }
  if (action.type === "POST_USER_ANNOUNCEMENT_SUCCESS") {
    toast.success("Successfully Added Delivery Announcement !!!!");
    return { ...state, buttonText: "Submitted" };
  }
  if (action.type === "POST_USER_ANNOUNCEMENT_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, buttonText: "Submit" };
  }
  // post user announcement
  if (action.type === "UPDATE_USER_ANNOUNCEMENT_REQUEST") {
    return { ...state, updateButtonText: "Updating" };
  }
  if (action.type === "UPDATE_USER_ANNOUNCEMENT_SUCCESS") {
    toast.success("Successfully Updated Delivery Announcement !!!!");
    return { ...state, updateButtonText: "Updated" };
  }
  if (action.type === "UPDATE_USER_ANNOUNCEMENT_FAILURE") {
    toast.error(action.payload.data.message);
    return { ...state, updateButtonText: "Update" };
  }
  // activate announcement
  if (action.type === "ACTIVATE_ANNOUNCEMENT_REQUEST") {
    return { ...state, isEnabledUsers: "a" };
  }
  if (action.type === "ACTIVATE_ANNOUNCEMENT_SUCCESS") {
    toast.success("Successfully Activated Announcement !!!!");
    return { ...state, isEnabledUsers: "b" };
  }
  if (action.type === "ACTIVATE_ANNOUNCEMENT_FAILURE") {
    toast.error("Something gone wrong. Try Again Later.");
    return { ...state, isEnabledUsers: "" };
  }
  // de-activate announcement
  if (action.type === "DE_ACTIVATE_ANNOUNCEMENT_REQUEST") {
    return { ...state, isEnabledUsers: "a" };
  }
  if (action.type === "DE_ACTIVATE_ANNOUNCEMENT_SUCCESS") {
    toast.success("Successfully De-Activated Announcement !!!!");
    return { ...state, isEnabledUsers: "b" };
  }
  if (action.type === "DE_ACTIVATE_ANNOUNCEMENT_FAILURE") {
    toast.error("Something gone wrong. Try Again Later.");
    return { ...state, isEnabledUsers: "" };
  }

  // Fetch Delivery fee
  if (action.type === "FETCH_DELIVERY_FEE_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_DELIVERY_FEE_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      deliveryFeeData: action.payload,
      buttonText: "Submit",
      updateButtonText: "Update",
    };
  }
  if (action.type === "FETCH_DELIVERY_FEE_FAILURE") {
    toast.error("Network Error . Try Again");
    return { ...state, isLoadingOnLogin: false };
  }
  // set Delivery fee
  if (action.type === "SET_DELIVERY_FEE_REQUEST") {
    return {
      ...state,
      buttonText: "Submitting",
      updateButtonText: "Updating",
      isEnabledUser: "a",
    };
  }
  if (action.type === "SET_DELIVERY_FEE_SUCCESS") {
    toast.success("Successfully Added Delivery Fee !!!!");
    return {
      ...state,
      buttonText: "Submitted",
      updateButtonText: "Updated",
      isEnabledUser: "b",
    };
  }
  if (action.type === "SET_DELIVERY_FEE_FAILURE") {
    toast.error(action.payload.data.message);
    return {
      ...state,
      buttonText: "Submit",
      updateButtonText: "Update",
      isEnabledUser: "",
    };
  }
  // Fetch Delivery commission fee
  if (action.type === "FETCH_DELIVERY_COMMISSION_FEE_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_DELIVERY_COMMISSION_FEE_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      deliveryCommData: action.payload,
      buttonText: "Submit",
      updateButtonText: "Update",
    };
  }
  if (action.type === "FETCH_DELIVERY_COMMISSION_FEE_FAILURE") {
    toast.error("Network Error . Try Again");
    return { ...state, isLoadingOnLogin: false };
  }
  // set Delivery commission fee
  if (action.type === "SET_DELIVERY_COMMISSION_FEE_REQUEST") {
    return {
      ...state,
      buttonText: "Submitting",
      updateButtonText: "Updating",
      isEnabledUser: "a",
    };
  }
  if (action.type === "SET_DELIVERY_COMMISSION_FEE_SUCCESS") {
    toast.success("Successfully Added Delivery Commission Fee !!!!");
    return {
      ...state,
      buttonText: "Submitted",
      updateButtonText: "Updated",
      isEnabledUser: "b",
    };
  }
  if (action.type === "SET_DELIVERY_COMMISSION_FEE_FAILURE") {
    toast.error(action.payload.data.message);
    return {
      ...state,
      buttonText: "Submit",
      updateButtonText: "Update",
      isEnabledUser: "",
    };
  }
  // settlement module
  if (action.type === "SETTLEMENT_DATA_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "SETTLEMENT_DATA_SUCCESS") {
    return {
      ...state,
      settlementData: action.payload,
      isLoadingOnLogin: false,
    };
  }
  if (action.type === "SETTLEMENT_DATA_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Something Went Wrong. Try Again Later !!!");
    }

    return { ...state, isLoadingOnLogin: false };
  }
  if (action.type === "CHANGE_SETTLEMENT_STATUS_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isOperationSuccessful: false };
  }
  if (action.type === "CHANGE_SETTLEMENT_STATUS_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      isSettlementSuccess: true,
      isOperationSuccessful: true,
    };
  }
  if (action.type === "CHANGE_SETTLEMENT_STATUS_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Something Went Wrong. Try Again Later !!!");
    }

    return { ...state, isLoadingOnLogin: false, isOperationSuccessful: false };
  }
  if (action.type === "CLEAR_SETTLEMENT_SUCCESS") {
    return { ...state, isSettlementSuccess: false };
  }
  // export to excell settlement module
  if (action.type === "EXPORT_TO_EXCELL_REQUEST") {
    return { ...state, exportButton: "Exporting To Excell" };
  }
  if (action.type === "EXPORT_TO_EXCELL_SUCCESS") {
    toast.success("Successfully Export to Excell !!!");
    return { ...state, exportButton: "Export To Excell" };
  }
  if (action.type === "EXPORT_TO_EXCELL_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Couldn't Export To Excell. Try Again Later !!!");
    }

    return { ...state, exportButton: "Export To Excell" };
  }
  // search customer by phone number
  if (action.type === "SEARCH_CUSTOMER_BY_PHN_REQUEST") {
    return { ...state, isCustomerData: true };
  }
  if (action.type === "SEARCH_CUSTOMER_BY_PHN_SUCCESS") {
    return {
      ...state,
      searchCustomerResult: action.payload,
      isCustomerData: false,
    };
  }
  if (action.type === "SEARCH_CUSTOMER_BY_PHN_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }

    return { ...state, isCustomerData: false };
  }
  if (action.type === "MAKE_EMPTY_PHONESEARCH_CUSTOMER") {
    return { ...state, searchCustomerResult: {} };
  }
  // Add new customer
  if (action.type === "ADD_NEW_CUSTOMER_REQUEST") {
    return { ...state, isEnabledUser: "a" };
  }
  if (action.type === "ADD_NEW_CUSTOMER_SUCCESS") {
    toast.success("New Customer Added Successfully");
    return { ...state, isEnabledUser: "b" };
  }
  if (action.type === "ADD_NEW_CUSTOMER_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }

    return { ...state, isEnabledUser: "" };
  }
  // fetch restaurnat list
  if (action.type === "FETCH_RESTAURANT_LIST_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_RESTAURANT_LIST_SUCCESS") {
    return {
      ...state,
      searchRestaurantList: action.payload,
      isLoadingOnLogin: false,
      isEnabledUser: "",
    };
  }
  if (action.type === "FETCH_RESTAURANT_LIST_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isLoadingOnLogin: false };
  }
  // fetch menu items acc restaurant id
  if (action.type === "FETCH_MENU_ITEMS_WITHOUT_RES_ID_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_MENU_ITEMS_WITHOUT_RES_ID_SUCCESS") {
    return {
      ...state,
      searchRestaurantList: action.payload,
      isLoadingOnLogin: false,
    };
  }
  if (action.type === "FETCH_MENU_ITEMS_WITHOUT_RES_ID_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isLoadingOnLogin: false };
  }
  // search menu items acc to restaurant
  if (action.type === "FETCH_MENU_ITEMS_BY_RES_ID_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_MENU_ITEMS_BY_RES_ID_SUCCESS") {
    return {
      ...state,
      searchMenuItemsList: action.payload,
      isLoadingOnLogin: false,
    };
  }
  if (action.type === "FETCH_MENU_ITEMS_BY_RES_ID_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isLoadingOnLogin: false };
  }
  // add items to cart
  if (action.type === "ADD_ITEMS_TO_CART_REQUEST") {
    return { ...state, addCartButton: "Processing" };
  }
  if (action.type === "ADD_ITEMS_TO_CART_SUCCESS") {
    toast.success("Successfully added to the cart");
    return {
      ...state,
      addCartButton: "Cart Item Added",
      myCartId: action.payload.cartId,
    };
  }
  if (action.type === "ADD_ITEMS_TO_CART_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, addCartButton: "Add To Cart" };
  }
  if (action.type === "SET_CART_BUTTON_TO_DEFAULT") {
    return { ...state, addCartButton: "Add To Cart" };
  }
  // add delivery information befor add to CArt
  if (action.type === "ADD_DELIVERY_INFO_BEFOR_ADD_TO_CART_REQUEST") {
    return { ...state, buttonText: "Submitting", isEnabledUser: "A" };
  }
  if (action.type === "ADD_DELIVERY_INFO_BEFOR_ADD_TO_CART_SUCCESS") {
    return {
      ...state,
      buttonText: "Submit",
      deliveryInfoBAToCartData: action.payload,
      isEnabledUser: "B",
    };
  }
  if (action.type === "ADD_DELIVERY_INFO_BEFOR_ADD_TO_CART_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, buttonText: "Submit", isEnabledUser: "" };
  }
  // place order from phone order
  if (action.type === "PLACE_ORDER_FROM_PHONEORDER_REQUEST") {
    return {
      ...state,
      placeButtonText: "Placeing Order",
      isEnabledUser: "ordering",
    };
  }
  if (action.type === "PLACE_ORDER_FROM_PHONEORDER_SUCCESS") {
    toast.success("Congrats!!! Sucessfully placed phone order");
    return {
      ...state,
      placeButtonText: "Place An Order",
      isEnabledUser: "ordered",
      searchCustomerResult: {},
    };
  }
  if (action.type === "PLACE_ORDER_FROM_PHONEORDER_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, buttonText: "Submit", isEnabledUser: "" };
  }
  // fetch operating locations
  if (action.type === "FETCH_OPERATING_LOCATION_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_OPERATING_LOCATION_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      operatingLocationList: action.payload,
    };
  }
  if (action.type === "FETCH_OPERATING_LOCATION_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isLoadingOnLogin: false };
  }
  // fetch active operating locations
  if (action.type === "FETCH_ACTIVE_OPERATING_LOCATION_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_ACTIVE_OPERATING_LOCATION_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      activeOperatingLocationList: action.payload,
    };
  }
  if (action.type === "FETCH_ACTIVE_OPERATING_LOCATION_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isLoadingOnLogin: false };
  }
  // add operating locations
  if (action.type === "ADD_OPERATING_LOCATION_REQUEST") {
    return {
      ...state,
      isLoadingOnLogin: true,
      buttonText: "Submitting",
      isEnabledUser: "a",
    };
  }
  if (action.type === "ADD_OPERATING_LOCATION_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      operatingLocationList: action.payload,
      buttonText: "Submit",
      isEnabledUser: "operatingLoAdded",
    };
  }
  if (action.type === "ADD_OPERATING_LOCATION_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return {
      ...state,
      isLoadingOnLogin: false,
      buttonText: "Submit",
      isEnabledUser: "",
    };
  }
  // EDIT operating locations
  if (action.type === "EDIT_OPERATING_LOCATION_REQUEST") {
    return {
      ...state,
      isLoadingOnLogin: true,
      updateButtonText: "Updating",
      isEnabledUser: "x",
    };
  }
  if (action.type === "EDIT_OPERATING_LOCATION_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      operatingLocationList: action.payload,
      updateButtonText: "Update",
      isEnabledUser: "y",
    };
  }
  if (action.type === "EDIT_OPERATING_LOCATION_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return {
      ...state,
      isLoadingOnLogin: false,
      updateButtonText: "Update",
      isEnabledUser: "",
    };
  }
  // mark restaurant popular
  if (action.type === "MARK_RESTAURANT_POPULAR_REQUEST") {
    return {
      ...state,
      isLoadingOnLogin: true,
      updateButtonText: "Updating",
      isEnabledUsers: "a",
    };
  }
  if (action.type === "MARK_RESTAURANT_POPULAR_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      updateButtonText: "Update",
      isEnabledUsers: "b",
    };
  }
  if (action.type === "MARK_RESTAURANT_POPULAR_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return {
      ...state,
      isLoadingOnLogin: false,
      updateButtonText: "Update",
      isEnabledUsers: "",
    };
  }
  // unmark restaurant popular
  if (action.type === "UN_MARK_RESTAURANT_AS_POPULAR_REQUEST") {
    return {
      ...state,
      isLoadingOnLogin: true,
      updateButtonText: "Updating",
      isEnabledUsers: "a",
    };
  }
  if (action.type === "UN_MARK_RESTAURANT_AS_POPULAR_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      updateButtonText: "Update",
      isEnabledUsers: "b",
    };
  }
  if (action.type === "UN_MARK_RESTAURANT_AS_POPULAR_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return {
      ...state,
      isLoadingOnLogin: false,
      updateButtonText: "Update",
      isEnabledUsers: "",
    };
  }
  // update order cart item from order module
  if (action.type === "UPDATE_ORDER_CART_ITEM_REQUEST") {
    return { ...state, updateButtonText: "Updating", isEnabledUsers: "a" };
  }
  if (action.type === "UPDATE_ORDER_CART_ITEM_SUCCESS") {
    return { ...state, updateButtonText: "Update", isEnabledUsers: "b" };
  }
  if (action.type === "UPDATE_ORDER_CART_ITEM_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, updateButtonText: "Update", isEnabledUsers: "" };
  }
  // set customer id from phone order
  if (action.type === "SET_CUTOMER_ID_PHONE_ODER") {
    return { ...state, myCustomerId: action.payload.id };
  }

  // get all pre order list
  if (action.type === "GET_ALL_PREORDER_LIST_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "GET_ALL_PREORDER_LIST_SUCCESS") {
    return {
      ...state,
      allPreOrderList: action.payload,
      isLoadingOnLogin: false,
    };
  }
  if (action.type === "GET_ALL_PREORDER_LIST_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isLoadingOnLogin: false };
  }
  // get requestred pre order list
  if (action.type === "GET_REQUESTED_PREORDER_LIST_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "GET_REQUESTED_PREORDER_LIST_SUCCESS") {
    return {
      ...state,
      allPreOrderRequestedList: action.payload,
      isLoadingOnLogin: false,
      isEnabledUsers: "",
    };
  }
  if (action.type === "GET_REQUESTED_PREORDER_LIST_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isLoadingOnLogin: false };
  }
  // Search requestred pre order list
  if (action.type === "SEARCH_PRE_ORDER_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "SEARCH_PRE_ORDER_SUCCESS") {
    return {
      ...state,
      allPreOrderRequestedList: action.payload,
      isLoadingOnLogin: false,
    };
  }
  if (action.type === "SEARCH_PRE_ORDER_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isLoadingOnLogin: false };
  }
  // add pre order
  if (action.type === "ADD_PRE_ORDER_REQUEST") {
    return { ...state, isEnabledUser: "a", buttonText: "Submitting" };
  }
  if (action.type === "ADD_PRE_ORDER_SUCCESS") {
    toast.success("Pre order added successfully.");
    return { ...state, isEnabledUser: "b", buttonText: "Submit" };
  }
  if (action.type === "ADD_PRE_ORDER_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isEnabledUser: "", buttonText: "Submit" };
  }
  // edit pre order
  if (action.type === "EDIT_PRE_ORDER_REQUEST") {
    return { ...state, isEnabledUser: "a", buttonText: "Submitting" };
  }
  if (action.type === "EDIT_PRE_ORDER_SUCCESS") {
    toast.success("Pre order edited successfully.");
    return { ...state, isEnabledUser: "b", buttonText: "Submit" };
  }
  if (action.type === "EDIT_PRE_ORDER_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isEnabledUser: "", buttonText: "Submit" };
  }
  // activate pre order
  if (action.type === "ACTIVATE_PRE_ORDER_REQUEST") {
    return { ...state, isEnabledUser: "a" };
  }
  if (action.type === "ACTIVATE_PRE_ORDER_SUCCESS") {
    toast.success("Pre order activated successfully.");
    return { ...state, isEnabledUser: "b" };
  }
  if (action.type === "ACTIVATE_PRE_ORDER_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isEnabledUser: "" };
  }
  // update restaurant email
  if (action.type === "EDIT_RESTAURANT_EMAIL_REQUEST") {
    return { ...state, isEnabledUsers: "a" };
  }
  if (action.type === "EDIT_RESTAURANT_EMAIL_SUCCESS") {
    toast.success("Restaurant email updated successfully.");
    return { ...state, isEnabledUsers: "b" };
  }
  if (action.type === "EDIT_RESTAURANT_EMAIL_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isEnabledUsers: "" };
  }
  // de-activate pre order
  if (action.type === "DE_ACTIVATE_PRE_ORDER_REQUEST") {
    return { ...state, isEnabledUser: "a" };
  }
  if (action.type === "DE_ACTIVATE_PRE_ORDER_SUCCESS") {
    toast.success("Pre order de-activated successfully.");
    return { ...state, isEnabledUser: "b" };
  }
  if (action.type === "DE_ACTIVATE_PRE_ORDER_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isEnabledUser: "" };
  }
  // check previous cart item
  if (action.type === "CHECK_PREV_CART_ITEM__REQUEST") {
    return { ...state };
  }
  if (action.type === "CHECK_PREV_CART_ITEM__SUCCESS") {
    return { ...state, isCartItemPresent: action.payload?.isCartPresent };
  }
  if (action.type === "CHECK_PREV_CART_ITEM__FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isEnabledUser: "" };
  }
  // search restaurant
  if (action.type === "SEARCH_RESNAME_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "SEARCH_RESNAME_SUCCESS") {
    return {
      ...state,
      restaurantData: action.payload,
      isLoadingOnLogin: false,
    };
  }
  if (action.type === "SEARCH_RESNAME_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isEnabledUser: "", isLoadingOnLogin: false };
  }
  // menu list
  if (action.type === "FETCH_MENU_LIST_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_MENU_LIST_SUCCESS") {
    return { ...state, menuList: action.payload, isLoadingOnLogin: false };
  }
  if (action.type === "FETCH_MENU_LIST_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isEnabledUser: "", isLoadingOnLogin: false };
  }
  // category list
  if (action.type === "FETCH_CATEGORY_LIST_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_CATEGORY_LIST_SUCCESS") {
    return { ...state, categoryList: action.payload, isLoadingOnLogin: false };
  }
  if (action.type === "FETCH_CATEGORY_LIST_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isEnabledUser: "", isLoadingOnLogin: false };
  }
  // champaign list
  if (action.type === "FETCH_CAMPAIGN_LIST_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isEnabledUsers: "" };
  }
  if (action.type === "FETCH_CAMPAIGN_LIST_SUCCESS") {
    return { ...state, campaignList: action.payload, isLoadingOnLogin: false };
  }
  if (action.type === "FETCH_CAMPAIGN_LIST_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isEnabledUser: "", isLoadingOnLogin: false };
  }
  // ADD campaign
  if (action.type === "ADD_CAMPAIGN_REQUEST") {
    return { ...state, isLoadingOnLogin: true, buttonText: "Submitting" };
  }
  if (action.type === "ADD_CAMPAIGN_SUCCESS") {
    // toast.success("Campaign added successfully")
    setTimeout(() => {
      window.location.reload();
    }, 1000);
    return {
      ...state,
      isLoadingOnLogin: false,
      buttonText: "Submit",
      isEnabledUsers: "b",
    };
  }
  if (action.type === "ADD_CAMPAIGN_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return {
      ...state,
      isEnabledUser: "",
      isLoadingOnLogin: false,
      buttonText: "Submit",
    };
  }
  // ADD campaign PHONE ORDER
  if (action.type === "CAMPAIGN_PHONE_ORDER_REQUEST") {
    return { ...state, isLoadingOnLogin: true, buttonText: "Submitting" };
  }
  if (action.type === "CAMPAIGN_PHONE_ORDER_SUCCESS") {
    toast.success("Campaign Phone order placed successfully");
    return {
      ...state,
      isLoadingOnLogin: false,
      buttonText: "Submit",
      isEnabledUsers: "b",
    };
  }
  if (action.type === "CAMPAIGN_PHONE_ORDER_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return {
      ...state,
      isEnabledUser: "",
      isLoadingOnLogin: false,
      buttonText: "Submit",
    };
  }
  // edit campaign
  if (action.type === "EDIT_CAMPAIGN_REQUEST") {
    return { ...state, isLoadingOnLogin: true, updateButtonText: "Updating" };
  }
  if (action.type === "EDIT_CAMPAIGN_SUCCESS") {
    toast.success("Campaign updated successfully");
    return {
      ...state,
      isLoadingOnLogin: false,
      updateButtonText: "Update",
      isEnabledUsers: "b",
    };
  }
  if (action.type === "EDIT_CAMPAIGN_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return {
      ...state,
      isEnabledUser: "",
      isLoadingOnLogin: false,
      updateButtonText: "Update",
    };
  }
  // enable campaign
  if (action.type === "ENABLE_CAMPAIGN_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isEnabledUsers: "a" };
  }
  if (action.type === "ENABLE_CAMPAIGN_SUCCESS") {
    toast.success("Campaign enabled successfully");
    return { ...state, isLoadingOnLogin: false, isEnabledUsers: "b" };
  }
  if (action.type === "ENABLE_CAMPAIGN_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return {
      ...state,
      isEnabledUser: "",
      isLoadingOnLogin: false,
      isEnabledUsers: "",
    };
  }
  // DISABLE campaign
  if (action.type === "DISABLE_CAMPAIGN_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isEnabledUsers: "a" };
  }
  if (action.type === "DISABLE_CAMPAIGN_SUCCESS") {
    toast.success("Campaign disabled successfully");
    return { ...state, isLoadingOnLogin: false, isEnabledUsers: "b" };
  }
  if (action.type === "DISABLE_CAMPAIGN_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return {
      ...state,
      isEnabledUser: "",
      isLoadingOnLogin: false,
      isEnabledUsers: "",
    };
  }
  // enable campaign item
  if (action.type === "ENABLE_CAMPAIGN_ITEM_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isEnabledUsers: "a" };
  }
  if (action.type === "ENABLE_CAMPAIGN_ITEM_SUCCESS") {
    toast.success("Campaign item enabled successfully");
    return { ...state, isLoadingOnLogin: false, isEnabledUsers: "b" };
  }
  if (action.type === "ENABLE_CAMPAIGN_ITEM_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return {
      ...state,
      isEnabledUser: "",
      isLoadingOnLogin: false,
      isEnabledUsers: "",
    };
  }
  // DISABLE campaign item
  if (action.type === "DISABLE_CAMPAIGN_ITEM_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isEnabledUsers: "a" };
  }
  if (action.type === "DISABLE_CAMPAIGN_ITEM_SUCCESS") {
    toast.success("Campaign item disabled successfully");
    return { ...state, isLoadingOnLogin: false, isEnabledUsers: "b" };
  }
  if (action.type === "DISABLE_CAMPAIGN_ITEM_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return {
      ...state,
      isEnabledUser: "",
      isLoadingOnLogin: false,
      isEnabledUsers: "",
    };
  }
  // approve campaign order
  if (action.type === "APPROVE_CAMPAIGN_ORDER_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isEnabledUsers: "a" };
  }
  if (action.type === "APPROVE_CAMPAIGN_ORDER_SUCCESS") {
    toast.success("Campaign order approved successfully");
    return { ...state, isLoadingOnLogin: false, isEnabledUsers: "b" };
  }
  if (action.type === "APPROVE_CAMPAIGN_ORDER_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return {
      ...state,
      isEnabledUser: "",
      isLoadingOnLogin: false,
      isEnabledUsers: "",
    };
  }
  // CHANGE PREorder status
  if (action.type === "CHANGE_PREORDER_STATUS_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isEnabledUsers: "a" };
  }
  if (action.type === "CHANGE_PREORDER_STATUS_SUCCESS") {
    toast.success("Status Changed successfully");

    return { ...state, isLoadingOnLogin: false, isEnabledUsers: "b" };
  }
  if (action.type === "CHANGE_PREORDER_STATUS_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return {
      ...state,
      isEnabledUser: "",
      isLoadingOnLogin: false,
      isEnabledUsers: "",
    };
  }

  // create new batch
  if (action.type === "CREATE_NEW_BATCH_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isEnabledUsers: "a" };
  }
  if (action.type === "CREATE_NEW_BATCH_SUCCESS") {
    toast.success("New batch created successfully");
    return { ...state, isLoadingOnLogin: false, isBatchCreated: true };
  }
  if (action.type === "CREATE_NEW_BATCH_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return {
      ...state,
      isEnabledUser: "",
      isLoadingOnLogin: false,
      isEnabledUsers: "",
    };
  }

  // fetch submitted kyc
  if (action.type === "FETCH_KYC_LIST_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_KYC_LIST_REQUEST_SUCCESS") {
    // toast.success("New batch created successfully")
    return {
      ...state,
      isLoadingOnLogin: false,
      // isBatchCreated: false,
      kycList: action.payload,
    };
  }
  if (action.type === "FETCH_KYC_LIST_REQUEST_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isEnabledUser: "", isLoadingOnLogin: false };
  }
  // fetch verified kyc
  if (action.type === "FETCH_VERIFIED_KYC_LIST_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_VERIFIED_KYC_LIST_REQUEST_SUCCESS") {
    // toast.success("New batch created successfully")
    return {
      ...state,
      isLoadingOnLogin: false,
      // isBatchCreated: false,
      kycList: action.payload,
    };
  }
  if (action.type === "FETCH_VERIFIED_KYC_LIST_REQUEST_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isEnabledUser: "", isLoadingOnLogin: false };
  }
  // fetch unverified kyc
  if (action.type === "FETCH_UNVERIFIED_KYC_LIST_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_UNVERIFIED_KYC_LIST_REQUEST_SUCCESS") {
    // toast.success("New batch created successfully")
    return {
      ...state,
      isLoadingOnLogin: false,
      // isBatchCreated: false,
      kycList: action.payload,
    };
  }
  if (action.type === "FETCH_UNVERIFIED_KYC_LIST_REQUEST_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isEnabledUser: "", isLoadingOnLogin: false };
  }
  //verify kyc
  if (action.type === "VERIFY_KYC_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "VERIFY_KYC_REQUEST_SUCCESS") {
    toast.success("KYC verified successfully !");
    return {
      ...state,
      isLoadingOnLogin: false,
      isVerifyChanged: state.isVerifyChanged ? false : true,
    };
  }
  if (action.type === "VERIFY_KYC_REQUEST_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isLoadingOnLogin: false };
  }
  //revoke kyc verification
  if (action.type === "REVOKE_KYC_VERIFICATION_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "REVOKE_KYC_VERIFICATION_REQUEST_SUCCESS") {
    toast.success("KYC revoked successfully !");
    return {
      ...state,
      isLoadingOnLogin: false,
      isVerifyChanged: state.isVerifyChanged ? false : true,
    };
  }
  if (action.type === "REVOKE_KYC_VERIFICATION_REQUEST_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isLoadingOnLogin: false };
  }
  //push into revoke log kyc verification
  if (action.type === "PUSH_INTO_KYC_REVOKE_LOG_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "PUSH_INTO_KYC_REVOKE_LOG_REQUEST_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      // isVerifyChanged: state.isVerifyChanged ? false : true,
    };
  }
  if (action.type === "PUSH_INTO_KYC_REVOKE_LOG_REQUEST_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isLoadingOnLogin: false };
  }
  // create new batch
  if (action.type === "FETCH_NEW_BATCH_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_NEW_BATCH_SUCCESS") {
    // toast.success("New batch created successfully")
    return {
      ...state,
      isLoadingOnLogin: false,
      isBatchCreated: false,
      bachedList: action.payload,
    };
  }
  if (action.type === "FETCH_NEW_BATCH_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isEnabledUser: "", isLoadingOnLogin: false };
  }
  // assign batch pakage to driver
  if (action.type === "ASSIGN_BATCH_PAKAGE_TO_D_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isEnabledUsers: "b" };
  }
  if (action.type === "ASSIGN_BATCH_PAKAGE_TO_D_SUCCESS") {
    toast.success("Batch pakage assigned successfully");
    return { ...state, isLoadingOnLogin: false, isEnabledUsers: "b" };
  }
  if (action.type === "ASSIGN_BATCH_PAKAGE_TO_D_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isEnabledUsers: "", isLoadingOnLogin: false };
  }
  // fetch pre-order time slot
  if (action.type === "FETCH_PRE_ORDER_TIME_SLOT_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_PRE_ORDER_TIME_SLOT_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      preOrderTimeSlotList: action.payload,
    };
  }
  if (action.type === "FETCH_PRE_ORDER_TIME_SLOT_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isEnabledUsers: "", isLoadingOnLogin: false };
  }
  // ADD pre-order
  if (action.type === "POST_PRE_ORDER_TIME_SLOT_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isEnabledUsers: "a" };
  }
  if (action.type === "POST_PRE_ORDER_TIME_SLOT_SUCCESS") {
    toast.success("Pre-order time slot added successfully !!!");
    return { ...state, isLoadingOnLogin: false, isEnabledUsers: "b" };
  }
  if (action.type === "POST_PRE_ORDER_TIME_SLOT_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return {
      ...state,
      isEnabledUsers: "",
      isLoadingOnLogin: false,
      isEnabledUsers: "",
    };
  }
  // edit pre-order
  if (action.type === "EDIT_PRE_ORDER_TIME_SLOT_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isEnabledUsers: "a" };
  }
  if (action.type === "EDIT_PRE_ORDER_TIME_SLOT_SUCCESS") {
    toast.success("Pre-order time slot updated successfully !!!");
    return { ...state, isLoadingOnLogin: false, isEnabledUsers: "b" };
  }
  if (action.type === "EDIT_PRE_ORDER_TIME_SLOT_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return {
      ...state,
      isEnabledUsers: "",
      isLoadingOnLogin: false,
      isEnabledUsers: "",
    };
  }

  // handle modals
  if (action.type === "ADD_COUPON_MODAL") {
    return { ...state, isAddCouponModal: !state.isAddCouponModal };
  }
  if (action.type === "ADD_RESTAURANT_MODAL") {
    return { ...state, isAddReataurantModal: !state.isAddReataurantModal };
  }
  if (action.type === "ADD_ADMIN_REGISTER_MODAL") {
    return {
      ...state,
      isAddAdminRegisterModal: !state.isAddAdminRegisterModal,
    };
  }
  if (action.type === "ADD_REFERRAL_LOYALITY_MODAL") {
    return { ...state, isReferralLoyaltyModal: !state.isReferralLoyaltyModal };
  }
  if (action.type === "OPEN_ADVERTISEMENT_MODAL") {
    return {
      ...state,
      isAddAdvertisementModal: !state.isAddAdvertisementModal,
    };
  }
  if (action.type === "OPEN_RESELLAR_MODAL") {
    return {
      ...state,
      isResellarModal: !state.isResellarModal,
    };
  }
  if (action.type === "OPEN_RESELLAR_EDIT_MODAL") {
    return {
      ...state,
      isResellarEditModal: !state.isResellarEditModal,
    };
  }
  if (action.type === "OPEN_PREORDER_MERCHANT_EDIT_MODAL") {
    return {
      ...state,
      isEditPreOrderMerchant: !state.isEditPreOrderMerchant,
    };
  }
  if (action.type === "OPEN_PREORDER_MERCHANT_MODAL") {
    return {
      ...state,
      isAddPreOrderMerchantList: !state.isAddPreOrderMerchantList,
    };
  }
  if (action.type === "OPEN_SERVICE_RATE_MODAL") {
    return { ...state, isServiceRateModal: !state.isServiceRateModal };
  }
  if (action.type === "ADD_DELIVERY_MODAL") {
    return { ...state, isDeliveryModal: !state.isDeliveryModal };
  }
  if (action.type === "ADD_DELIVERY_MORE_INFO_MODAL") {
    return { ...state, isAddDeliverySuccess: false };
  }

  if (action.type === "ADD_EXCHANGE_LOYALITY_MODAL") {
    return {
      ...state,
      isExchangeLoaylityModal: !state.isExchangeLoaylityModal,
    };
  }
  if (action.type === "ADD_ANNOUNCEMENT_MODAL") {
    return { ...state, isAnnouncementModal: !state.isAnnouncementModal };
  }
  if (action.type === "ADD_DELIVERY_CHARGE_MODAL") {
    return {
      ...state,
      isSetDeliveryChagerModal: !state.isSetDeliveryChagerModal,
    };
  }
  if (action.type === "ADD_DELIVERY_COMMISSION_MODAL") {
    return { ...state, IsSetDeliveryCommModal: !state.IsSetDeliveryCommModal };
  }
  if (action.type === "ADD_NEW_CUSTOMER_MODAL") {
    return { ...state, isAddNewCustomerModal: !state.isAddNewCustomerModal };
  }
  if (action.type === "ADD_DELIVERY_ADD_FORM_MODAL") {
    return { ...state, isDeliveryFormModal: !state.isDeliveryFormModal };
  }
  if (action.type === "ADD_OPERATING_LOCATION_MODAL") {
    return {
      ...state,
      isAddOperatingLocationModal: !state.isAddOperatingLocationModal,
    };
  }
  if (action.type === "ADD_RES_WITHOUT_CSV_MODAL") {
    return {
      ...state,
      isAddResWithoutCsvModal: !state.isAddResWithoutCsvModal,
    };
  }
  if (action.type === "ADD_CAMPAIGN_MODAL") {
    return { ...state, isAddCampaignModal: !state.isAddCampaignModal };
  }
  if (action.type === "ADD_CART_ITEM_TO_ARRAY") {
    let myPrevCartItems = [];
    if (state.cartItemArray.length < 1) {
      myPrevCartItems = [...state.cartItemArray, action.payload];
    } else {
      let index = state.cartItemArray.findIndex(
        (object) => object.itemId === action.payload.itemId
      );
      if (index === -1) {
        myPrevCartItems = [...state.cartItemArray, action.payload];
      } else {
        myPrevCartItems = state.cartItemArray.map((item) => {
          if (item.itemId === action.payload.itemId) {
            item = action.payload;
          }
          return item;
        });
      }
    }

    return { ...state, cartItemArray: myPrevCartItems };
  }
  if (action.type === "REMOVE_CARTITEM_FROM_ARRAY") {
    return { ...state, cartItemArray: [] };
  }
  if (action.type === "REMOVE_CARTITEM_FROM_CARTLIST") {
    let newCartItemArray = state.cartItemArray.filter(
      (item) => item.itemId !== action.payload
    );
    return { ...state, cartItemArray: newCartItemArray };
  }
  if (action.type === "CLEAR_RESTAURANT_SEARCH_LIST") {
    return { ...state, searchRestaurantList: {} };
  }
  if (action.type === "CLEAR_USER_SEARCH_LIST") {
    return { ...state, searchUsersList: [] };
  }
  if (action.type === "CLEAR_PREORDER_SEARCH_LIST") {
    return { ...state, allPreOrderList: {} };
  }
  if (action.type === "CLEAR_CAMPAIGN_SEARCH_LIST") {
    return { ...state, campaignList: {} };
  }
  if (action.type === "SET_MENU_CATEGORY_ID") {
    return { ...state, myMenuCategoryId: action.payload };
  }
  if (action.type === "SET_MENU_ITEM_ID") {
    return { ...state, myMenuItemId: action.payload };
  }
  if (action.type === "FETCH_BATCH_PACKAGE_SUCCESS") {
    return { ...state, batchPackage: action.payload };
  }
  // VERIFY DRIVER
  if (action.type === "VERIFY_DRIVER_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "VERIFY_DRIVER_SUCCESS") {
    toast.success("Driver verified successfully !");
    return {
      ...state,
      isLoadingOnLogin: false,
      isVerifyChanged: state.isVerifyChanged ? false : true,
    };
  }
  if (action.type === "VERIFY_DRIVER_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isLoadingOnLogin: false };
  }
  //UPDATE Slip Modal

  if (action.type === "UPDATE_EMPTY_BILL_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "UPDATE_EMPTY_BILL_SUCCESS") {
    toast.success("Slip No Updated successfully !");
    return {
      ...state,
      isLoadingOnLogin: false,
      isVerifyChanged: state.isVerifyChanged ? false : true,
    };
  }
  if (action.type === "UPDATE_EMPTY_BILL_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isLoadingOnLogin: false };
  }
  if (action.type === "MARK_AS_POPUP_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isOperationSuccessful: false };
  }
  if (action.type === "MARK_AS_POPUP_SUCCESS") {
    toast.success("Marked as popup successfully !");
    return {
      ...state,
      isLoadingOnLogin: false,
      isVerifyChanged: state.isVerifyChanged ? false : true,
      isOperationSuccessful: true,
    };
  }
  if (action.type === "MARK_AS_POPUP_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return {
      ...state,
      isLoadingOnLogin: false,
      isVerifyChanged: state.isVerifyChanged ? false : true,
      isOperationSuccessful: false,
    };
  }
  if (action.type === "REMOVE_FROM_POPUP_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isOperationSuccessful: false };
  }
  if (action.type === "REMOVE_FROM_POPUP_SUCCESS") {
    toast.success("Marked as popup successfully !");
    return {
      ...state,
      isLoadingOnLogin: false,
      isVerifyChanged: state.isVerifyChanged ? false : true,
      isOperationSuccessful: true,
    };
  }
  if (action.type === "REMOVE_FROM_POPUP_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return {
      ...state,
      isLoadingOnLogin: false,
      isVerifyChanged: state.isVerifyChanged ? false : true,
      isOperationSuccessful: false,
    };
  }
  if (action.type === "SETTLE_PREORDER_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isPreOrderSettled: false };
  }
  if (action.type === "SETTLE_PREORDER_SUCCESS") {
    toast.success("Preorder Settled successfully !");
    return {
      ...state,
      isLoadingOnLogin: false,
      isVerifyChanged: state.isVerifyChanged ? false : true,
      isPreOrderSettled: true,
    };
  }
  if (action.type === "SETTLE_PREORDER_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return {
      ...state,
      isLoadingOnLogin: false,
      isVerifyChanged: state.isVerifyChanged ? false : true,
      isPreOrderSettled: false,
    };
  }
  if (action.type === "FETCH_RESELLER_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_RESELLER_SUCCESS") {
    return { ...state, isLoadingOnLogin: false, resellerList: action.payload };
  }
  if (action.type === "FETCH_RESELLER_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isLoadingOnLogin: false };
  }
  if (action.type === "ADD_RESELLER_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isOperationSuccessful: false };
  }
  if (action.type === "ADD_RESELLER_SUCCESS") {
    toast.success(action.payload.message);

    return {
      ...state,
      isLoadingOnLogin: false,
      isResellarModal: false,
      isOperationSuccessful: true,
    };
  }
  if (action.type === "ADD_RESELLER_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isLoadingOnLogin: false, isOperationSuccessful: false };
  }
  if (action.type === "UPDATE_RESELLER_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isOperationSuccessful: false };
  }
  if (action.type === "UPDATE_RESELLER_SUCCESS") {
    toast.success(action.payload.message);

    return {
      ...state,
      isLoadingOnLogin: false,
      isResellarEditModal: false,
      isOperationSuccessful: true,
    };
  }
  if (action.type === "UPDATE_RESELLER_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isLoadingOnLogin: false, isOperationSuccessful: false };
  }
  if (action.type === "ACTIVATE_RESELLER_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isOperationSuccessful: false };
  }
  if (action.type === "ACTIVATE_RESELLER_SUCCESS") {
    toast.success(action.payload.message);
    return { ...state, isLoadingOnLogin: false, isOperationSuccessful: true };
  }
  if (action.type === "ACTIVATE_RESELLER_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isLoadingOnLogin: false, isOperationSuccessful: false };
  }
  if (action.type === "DEACTIVATE_RESELLER_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isOperationSuccessful: false };
  }
  if (action.type === "DEACTIVATE_RESELLER_SUCCESS") {
    toast.success(action.payload.message);
    return { ...state, isLoadingOnLogin: false, isOperationSuccessful: true };
  }
  if (action.type === "DEACTIVATE_RESELLER_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isLoadingOnLogin: false, isOperationSuccessful: false };
  }
  if (action.type === "ACTIVATE_MENU_CATEGORY_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isOperationSuccessful: false };
  }
  if (action.type === "ACTIVATE_MENU_CATEGORY_SUCCESS") {
    toast.success(action.payload.message);
    return { ...state, isLoadingOnLogin: false, isOperationSuccessful: true };
  }
  if (action.type === "ACTIVATE_MENU_CATEGORY_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isLoadingOnLogin: false, isOperationSuccessful: false };
  }
  if (action.type === "DEACTIVATE_MENU_CATEGORY_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isOperationSuccessful: false };
  }
  if (action.type === "DEACTIVATE_MENU_CATEGORY_SUCCESS") {
    toast.success(action.payload.message);
    return { ...state, isLoadingOnLogin: false, isOperationSuccessful: true };
  }
  if (action.type === "DEACTIVATE_MENU_CATEGORY_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isLoadingOnLogin: false, isOperationSuccessful: false };
  }
  if (action.type === "ACTIVATE_MENU_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isOperationSuccessful: false };
  }
  if (action.type === "ACTIVATE_MENU_SUCCESS") {
    toast.success(action.payload.message);
    return { ...state, isLoadingOnLogin: false, isOperationSuccessful: true };
  }
  if (action.type === "ACTIVATE_MENU_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isLoadingOnLogin: false, isOperationSuccessful: false };
  }
  if (action.type === "DEACTIVATE_MENU_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isOperationSuccessful: false };
  }
  if (action.type === "DEACTIVATE_MENU_SUCCESS") {
    toast.success(action.payload.message);
    return { ...state, isLoadingOnLogin: false, isOperationSuccessful: true };
  }
  if (action.type === "DEACTIVATE_MENU_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isLoadingOnLogin: false, isOperationSuccessful: false };
  }
  if (action.type === "FETCH_PREORDER_MERCHANT_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_PREORDER_MERCHANT_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      preOrderMerchantList: action.payload,
    };
  }
  if (action.type === "FETCH_PREORDER_MERCHANT_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isLoadingOnLogin: false };
  }
  if (action.type === "ADD_PREORDER_MERCHANT_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isOperationSuccessful: false };
  }
  if (action.type === "ADD_PREORDER_MERCHANT_SUCCESS") {
    toast.success(action.payload.message);

    return {
      ...state,
      isLoadingOnLogin: false,
      isAddPreOrderMerchantList: false,
      isOperationSuccessful: true,
    };
  }
  if (action.type === "ADD_PREORDER_MERCHANT_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isLoadingOnLogin: false, isOperationSuccessful: false };
  }
  if (action.type === "ADD_CATEGORY_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isOperationSuccessful: false };
  }
  if (action.type === "ADD_CATEGORY_SUCCESS") {
    toast.success(action.payload.message);

    return {
      ...state,
      isLoadingOnLogin: false,
      isOperationSuccessful: true,
    };
  }
  if (action.type === "ADD_CATEGORY_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isLoadingOnLogin: false, isOperationSuccessful: false };
  }
  if (action.type === "ADD_MENU_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isOperationSuccessful: false };
  }
  if (action.type === "ADD_MENU_SUCCESS") {
    toast.success(action.payload.message);

    return {
      ...state,
      isLoadingOnLogin: false,
      isOperationSuccessful: true,
    };
  }
  if (action.type === "ADD_MENU_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isLoadingOnLogin: false, isOperationSuccessful: false };
  }
  if (action.type === "UPDATE_CATEGORY_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isOperationSuccessful: false };
  }
  if (action.type === "UPDATE_CATEGORY_SUCCESS") {
    toast.success(action.payload.message);

    return {
      ...state,
      isLoadingOnLogin: false,
      isOperationSuccessful: true,
    };
  }
  if (action.type === "UPDATE_CATEGORY_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isLoadingOnLogin: false, isOperationSuccessful: false };
  }
  if (action.type === "UPDATE_MENU_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isOperationSuccessful: false };
  }
  if (action.type === "UPDATE_MENU_SUCCESS") {
    toast.success(action.payload.message);

    return {
      ...state,
      isLoadingOnLogin: false,
      isOperationSuccessful: true,
    };
  }
  if (action.type === "UPDATE_MENU_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isLoadingOnLogin: false, isOperationSuccessful: false };
  }

  if (action.type === "UPDATE_PREORDER_MERCHANT_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isOperationSuccessful: false };
  }
  if (action.type === "UPDATE_PREORDER_MERCHANT_SUCCESS") {
    toast.success(action.payload.message);

    return {
      ...state,
      isLoadingOnLogin: false,
      isEditPreOrderMerchant: false,
      isOperationSuccessful: true,
    };
  }
  if (action.type === "UPDATE_PREORDER_MERCHANT_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isLoadingOnLogin: false, isOperationSuccessful: false };
  }

  if (action.type === "FETCH_PREORDER_MERCHANT_LIST_REQUEST") {
    return { ...state, isLoadingOnLogin: true };
  }
  if (action.type === "FETCH_PREORDER_MERCHANT_LIST_SUCCESS") {
    return {
      ...state,
      allMerchantPreOrderRequestedList: action.payload,
      isLoadingOnLogin: false,
    };
  }
  if (action.type === "FETCH_PREORDER_MERCHANT_LIST_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isLoadingOnLogin: false };
  }
  if (action.type === "APPROVE_PREORDER_MERCHANT_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isOperationSuccessful: false };
  }
  if (action.type === "APPROVE_PREORDER_MERCHANT_SUCCESS") {
    toast.success(action.payload.message);

    return {
      ...state,
      isLoadingOnLogin: false,
      isEditPreOrderMerchant: false,
      isOperationSuccessful: true,
    };
  }
  if (action.type === "APPROVE_PREORDER_MERCHANT_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isLoadingOnLogin: false, isOperationSuccessful: false };
  }
  if (action.type === "DISPATCH_PREORDER_MERCHANT_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isOperationSuccessful: false };
  }
  if (action.type === "DISPATCH_PREORDER_MERCHANT_SUCCESS") {
    toast.success(action.payload.message);

    return {
      ...state,
      isLoadingOnLogin: false,
      isEditPreOrderMerchant: false,
      isOperationSuccessful: true,
    };
  }
  if (action.type === "DISPATCH_PREORDER_MERCHANT_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isLoadingOnLogin: false, isOperationSuccessful: false };
  }
  if (action.type === "ASSIGN_DELIVERY_PREORDER_MERCHANT_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isOperationSuccessful: false };
  }
  if (action.type === "ASSIGN_DELIVERY_PREORDER_MERCHANT_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      isEditPreOrderMerchant: false,
      isOperationSuccessful: true,
    };
  }
  if (action.type === "ASSIGN_DELIVERY_PREORDER_MERCHANT_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isLoadingOnLogin: false, isOperationSuccessful: false };
  }
  if (action.type === "FETCH_RESELLER_DETAIL_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isOperationSuccessful: false };
  }
  if (action.type === "FETCH_RESELLER_DETAIL_SUCCESS") {
    toast.success(action.payload.message);

    return {
      ...state,
      isLoadingOnLogin: false,
      resellerDetail: action.payload,
    };
  }
  if (action.type === "FETCH_RESELLER_DETAIL_FAILURE") {
    return { ...state, isLoadingOnLogin: false, isOperationSuccessful: false };
  }
  if (action.type === "FETCH_ORDER_FROM_ALL_CAMPAIGN_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isOperationSuccessful: false };
  }
  if (action.type === "FETCH_ORDER_FROM_ALL_CAMPAIGN_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      ordersFromAllCampaign: action.payload,
    };
  }
  if (action.type === "FETCH_ORDER_FROM_ALL_CAMPAIGN_FAILURE") {
    return { ...state, isLoadingOnLogin: false, isOperationSuccessful: false };
  }
  if (action.type === "FETCH_COD_VERIFICATION_PENDING") {
    return { ...state, isLoadingOnLogin: true, isOperationSuccessful: false };
  }
  if (action.type === "FETCH_COD_VERIFICATION_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      CODVerificationPendingList: action.payload,
    };
  }
  if (action.type === "FETCH_COD_VERIFICATION_FAILURE") {
    return { ...state, isLoadingOnLogin: false, isOperationSuccessful: false };
  }
  if (action.type === "ACTIVATE_PREORDER_MERCHANT_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isOperationSuccessful: false };
  }
  if (action.type === "ACTIVATE_PREORDER_MERCHANT_SUCCESS") {
    toast.success(action.payload.message);
    return { ...state, isLoadingOnLogin: false, isOperationSuccessful: true };
  }
  if (action.type === "ACTIVATE_PREORDER_MERCHANT_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isLoadingOnLogin: false, isOperationSuccessful: false };
  }
  if (action.type === "DEACTIVATE_PREORDER_MERCHANT_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isOperationSuccessful: false };
  }
  if (action.type === "DEACTIVATE_PREORDER_MERCHANT_SUCCESS") {
    toast.success(action.payload.message);
    return { ...state, isLoadingOnLogin: false, isOperationSuccessful: true };
  }
  if (action.type === "DEACTIVATE_PREORDER_MERCHANT_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isLoadingOnLogin: false, isOperationSuccessful: false };
  }
  if (action.type === "FETCH_RECONCILEMENT_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isOperationSuccessful: false };
  }
  if (action.type === "FETCH_RECONCILEMENT_SUCCESS") {
    return {
      ...state,
      isLoadingOnLogin: false,
      reconcilementList: action.payload,
    };
  }
  if (action.type === "FETCH_RECONCILEMENT_FAILURE") {
    return { ...state, isLoadingOnLogin: false, isOperationSuccessful: false };
  }
  if (action.type === "APPROVE_RECONCILEMENT_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isApproveSuccess: false };
  }
  if (action.type === "APPROVE_RECONCILEMENT_SUCCESS") {
    toast.success(action.payload.message);
    return { ...state, isLoadingOnLogin: false, isApproveSuccess: true };
  }
  if (action.type === "APPROVE_RECONCILEMENT_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isLoadingOnLogin: false, isApproveSuccess: false };
  }
  if (action.type === "FETCH_ARCHIVES_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isOperationSuccessful: false };
  }
  if (action.type === "FETCH_ARCHIVES_SUCCESS") {
    return { ...state, isLoadingOnLogin: false, archiveList: action.payload };
  }
  if (action.type === "FETCH_ARCHIVES_FAILURE") {
    return { ...state, xisLoadingOnLogin: false, isOperationSuccessful: false };
  }
  if (action.type === "REAPPROVE_RECONCILEMENT_REQUEST") {
    return { ...state, isLoadingOnLogin: true, isApproveSuccess: false };
  }
  if (action.type === "REAPPROVE_RECONCILEMENT_SUCCESS") {
    toast.success("Reapproved Successfully !!");
    return { ...state, isLoadingOnLogin: false, isApproveSuccess: true };
  }
  if (action.type === "REAPPROVE_RECONCILEMENT_FAILURE") {
    if (action.payload !== undefined) {
      toast.error(action.payload.data.message);
    } else {
      toast.error("Network Error. Try Again Later !!!");
    }
    return { ...state, isLoadingOnLogin: false, isApproveSuccess: false };
  }
  return state;
};

export default orkReducer;
