import React, { useEffect, useState } from 'react'
import Layout from '../../../Layout/Layout'
import { AiFillEdit } from 'react-icons/ai'
// import {RiAddBoxFill} from 'react-icons/ri'
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi'
import { BsX } from "react-icons/bs";
import { connect, useDispatch } from 'react-redux'
import LoadingComponent from '../../LoadingComponent'
import Footer from '../../Z_Footer/Footer'
// import AddServiceRate from './AddServiceRate'
import moment from 'moment';

const mapStateToProps = store => {
    const { isLoadingOnLogin, myNotificationList, isToogleSidebar } = store.mainState;
    return { isLoadingOnLogin, myNotificationList, isToogleSidebar }
}

function CategoryList({ isLoadingOnLogin, myNotificationList, isToogleSidebar }) {
    const dispatch = useDispatch();

    // Pagination
    const [currentPage, setCurrentPage] = useState(1)
    const [postsPerPage, setPostsPerPage] = useState(10);
    const [currentPosts, setCurrentPosts] = useState([])

    let pageNumbers = []

    // get current posts
    const indexOfLastPost = currentPage * postsPerPage
    const indexOfFirstPost = indexOfLastPost - postsPerPage

    for (let i = 1; i <= myNotificationList.totalPage; i++) {
        pageNumbers.push(i)
    }
    useEffect(() => {
        dispatch({
            type: "FETCH_NOTIFICATION_LIST_REQUEST",
            payload: {postsPerPage,currentPage}
        })
    }, [postsPerPage,currentPage])
    const getLocalDateTime = (mydate) => {
        var stillUtc = moment.utc(mydate).toDate();
        var local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
        return local
    }
    return (
        <Layout>
            <div>
                <div className="group_list">
                    <h4>Menu Category List</h4>
                </div>
                <div className="user_groups">
                    <div className="user_groups--button_n_search">
                        <div className="user_groups--button_n_search--button">
                            <label htmlFor="pageSize" style={{ marginLeft: "10px", fontSize: '18px', fontFamily: "bold", color: "#3e445e", marginRight: "-10px" }}>Per Page</label>
                            <select name="pageSize" onChange={(e) => setPostsPerPage(e.target.value)}>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                            </select>
                        </div>
                        <div className="user_groups--button_n_search--search">
                            <input type="text" placeholder="Search" />
                        </div>
                    </div>
                    <p className="privacy-loading--onFetching">{isLoadingOnLogin && <LoadingComponent />}</p>
                    <div className="table-container">
                        {/* <table className="table_x">
                            <thead>
                                <tr>
                                    <th className="loyality--id">ID</th>
                                    <th className="loyality--schemeName" style={{ width: "15vw" }}>Title</th>
                                    <th className="loyality--schemeName" style={{ width: "30vw" }}>Body</th>
                                    <th className="loyality--addedBy">Added By</th>
                                    <th className="loyality--addedBy">Sent Date&Time</th>
                                    <th className="loyality--addedBy">Notification Type</th>
                                </tr></thead>
                            <tbody>
                                {myNotificationList.data !== undefined && myNotificationList.data.map((item, index) => <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.title}</td>
                                    <td>{item.body}</td>
                                    <td>{item.addedBy}</td>
                                    <td>{getLocalDateTime(item.sentDateTime)}</td>
                                    <td>{item.notificationType}</td>

                                </tr>)

                                }
                            </tbody>
                        </table> */}
                        {/* <div className="pagination">
                            <p>Showing {indexOfFirstPost} to {indexOfLastPost > myNotificationList.totalData ? myNotificationList.totalData : indexOfLastPost} of {myNotificationList.totalData} entries</p>
                            <nav aria-label="...">
                                <ul className="pagination">
                                    {currentPage === 1 ? <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronLeft /></a>
                                    </li> : <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronLeft onClick={() => setCurrentPage(currentPage - 1)} /></a>
                                    </li>}
                                    {pageNumbers.map((x) =>
                                        <li key={x} className="page-item"><a className="page-link" href="#" onClick={() => setCurrentPage(x)} >{x}</a></li>
                                    )}
                                    {currentPage == pageNumbers.length ? <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronRight /></a>
                                    </li> : <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronRight onClick={() => setCurrentPage(currentPage + 1)} /></a>
                                    </li>}
                                </ul>
                            </nav>

                        </div> */}
                    </div>
                </div>
                <Footer />
            </div>

        </Layout>
    )
}

export default connect(mapStateToProps, null)(CategoryList)
