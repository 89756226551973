import React, { useEffect, useState } from 'react'
import Layout from '../../Layout/Layout'
import { AiFillEdit } from 'react-icons/ai'
// import {RiAddBoxFill} from 'react-icons/ri'
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi'
import { BsX, BsCheckCircle } from "react-icons/bs";
import { connect, useDispatch } from 'react-redux'
import LoadingComponent from '../LoadingComponent'
import Footer from '../Z_Footer/Footer'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { GiSkullCrossedBones } from 'react-icons/gi'
import { TiTick } from 'react-icons/ti'
import EditAnnouncement from './EditAnnouncement';


const mapStateToProps = store => {
    const { buttonText, isLoadingOnLogin, userAnnouncementList, isToogleSidebar, isEnabledUsers } = store.mainState;
    return { buttonText, isLoadingOnLogin, userAnnouncementList, isToogleSidebar, isEnabledUsers }
}

function Announcement({ buttonText, isLoadingOnLogin, userAnnouncementList, isToogleSidebar, isEnabledUsers }) {
    const [showSmModal, setShowSmModal] = useState(false);
    const [enableDisableItem, setEnableDisableItem] = useState({});
    const [showEditModal, setShowEditModal] = useState(false)
    const [editingId, setEditingId] = useState(null)
    const [announcementBody, setAnnouncementBody] = useState("")

    // Pagination
    const [currentPage, setCurrentPage] = useState(1)
    const [postsPerPage, setPostsPerPage] = useState(10);
    const [currentPosts, setCurrentPosts] = useState([])

    let pageNumbers = []

    // get current posts
    const indexOfLastPost = currentPage * postsPerPage
    const indexOfFirstPost = indexOfLastPost - postsPerPage

    for (let i = 1; i <= userAnnouncementList.totalPage; i++) {
        pageNumbers.push(i)
    }

    const [rate, setRate] = useState(null)
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({
            type: "FETCH_USER_ANNOUNCEMENT_REQUEST",
            payload: { currentPage, postsPerPage }
        })
    }, [currentPage,postsPerPage])
    useEffect(() => {
        setTimeout(() => {
            if (isEnabledUsers === "b") {
                setTimeout(() => {
                    setShowSmModal(false);
                    dispatch({
                        type: "FETCH_USER_ANNOUNCEMENT_REQUEST",
                        payload: { currentPage, postsPerPage }
                    })
                }, 1000);

            }

        })

    }, [isEnabledUsers])

    return (
        <Layout>
            <div>
                <div className="group_list">
                    <h4>Manage Announcement</h4>
                </div>
                <div className="user_groups">
                    <div className="user_groups--button_n_search">
                        <div className="user_groups--button_n_search--button">
                            <h4>Announcement</h4>
                            <button onClick={() => dispatch({ type: "ADD_ANNOUNCEMENT_MODAL" })}>
                                Add <i className="bi bi-plus c-plus" />
                            </button>
                        </div>
                        <div className="user_groups--button_n_search--search">
                            <input type="text" placeholder="Search" />
                        </div>
                    </div>
                    <p className="privacy-loading--onFetching">{isLoadingOnLogin && <LoadingComponent />}</p>
                    <div className="table-container">
                        <table className="table_x">
                            <thead>
                                <tr>
                                    <th className="announcement--id">S.N</th>
                                    <th className="announcement--body">Announcement Body</th>
                                    <th className="announcement--addedBy">Announcement For</th>
                                    <th className="announcement--addedBy">Added By</th>
                                    <th className="announcement--addedBy">Active Status</th>
                                    <th className="announcement--actions">Actions</th>
                                </tr></thead>
                            <tbody>
                                {userAnnouncementList.data !== undefined && userAnnouncementList.data.map((item, index) => <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.announcement}</td>
                                    <td>{item.announcementFor}</td>
                                    <td>{item.addedBy}</td>
                                    <td><span style={{ backgroundColor: "green", borderRadius: "4px", color: "white" }}>{item.isActive ? "Active" : "Not Active"}</span></td>
                                    <td>
                                        <Tippy content={<span>{`${item.isActive ? "De-activate" : "Activate"}`}</span>}>
                                            <span>
                                                <BsCheckCircle className="action--eye__admin" onClick={() => {
                                                    setShowSmModal(true);
                                                    setEnableDisableItem(item)
                                                }} /></span>
                                        </Tippy>
                                        <Tippy content="Edit" placement="top">
                                            <span>
                                                <AiFillEdit className="edit--icon" onClick={() => {
                                                    setShowEditModal(true);
                                                    setEditingId(item.id)
                                                    setAnnouncementBody(item.announcement)
                                                }} /> </span>
                                        </Tippy>
                                    </td>
                                </tr>)}
                            </tbody>
                        </table>
                        {/* for active de-activation of announcement */}
                        <form>

                            <div className={`${showSmModal ? "delete--admin" : "delete--admin__hidden"}`} >
                                <div className="delete--admin--container" >
                                    <div className="delete--admin--container__heading">{enableDisableItem.isActive ? <h1>Are you sure to De-Activate this ??</h1> : <h1>Are you sure to Activate this ??</h1>}</div>
                                    <>
                                        <div className="delete--admin--container__body">

                                            <div>{isEnabledUsers === "a" && <div className="deleting--something">  <p>Processing...</p></div>}</div>
                                            <div> {isEnabledUsers === "b" && <div className="deleting--something"> <TiTick className="success--icon" /> <p>Successful</p></div>}</div>
                                            <div> {isEnabledUsers === "c" && <div className="deleting--something"> <GiSkullCrossedBones className="success--icon" style={{ fontSize: "30px" }} /> <p>Try Again</p>

                                            </div>}</div>
                                        </div>
                                        <div className="delete--admin--container__footer">
                                            <button onClick={(e) => {
                                                e.preventDefault()
                                                setShowSmModal(!showSmModal)
                                            }}>Cancel</button>
                                            {enableDisableItem.isActive ? <button type="submit" onClick={(e) => {
                                                e.preventDefault()
                                                let enableDisableId = enableDisableItem.id
                                                dispatch({
                                                    type: "DE_ACTIVATE_ANNOUNCEMENT_REQUEST",
                                                    payload: {
                                                        enableDisableId,
                                                    }
                                                })
                                            }}>Ok</button> :
                                                <button type="submit" onClick={(e) => {
                                                    e.preventDefault()
                                                    let enableDisableId = enableDisableItem.id
                                                    dispatch({
                                                        type: "ACTIVATE_ANNOUNCEMENT_REQUEST",
                                                        payload: {
                                                            enableDisableId,
                                                        }
                                                    })
                                                }}>Ok</button>}
                                        </div>
                                    </>

                                </div>
                            </div>
                        </form>
                        {/* edit announcement modal*/}
                        <div className={`${showEditModal ? "mymodal modal_activated" : "mymodal"}`}>
                            <div className={`${!isToogleSidebar ? "manage_modal--container " : "manage_modal--container sidebarOpend_F_modal"}`}>
                                {editingId !== null && <EditAnnouncement id={editingId} myBody={announcementBody} />}
                            </div>
                            <div className={`${!isToogleSidebar ? "task--rectangle" : "task--rectangle withsidebarOpend"}`}>
                                <div className="task--rectangle__circle">
                                    <div className='circle'> <span onClick={() => {
                                        setShowEditModal(false)
                                        dispatch({
                                            type: "FETCH_USER_ANNOUNCEMENT_REQUEST",
                                            payload: { currentPage, postsPerPage }
                                        })
                                    }}><BsX /></span><p>Edit</p></div>
                                </div>
                            </div>
                        </div>
                        <div className="pagination">
                            <p>Showing {indexOfFirstPost} to {indexOfLastPost > userAnnouncementList.totalData ? userAnnouncementList.totalData : indexOfLastPost} of {userAnnouncementList.totalData} entries</p>
                            <nav aria-label="...">
                                <ul className="pagination">
                                    {currentPage === 1 ? <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronLeft /></a>
                                    </li> : <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronLeft onClick={() => setCurrentPage(currentPage - 1)} /></a>
                                    </li>}
                                    {pageNumbers.map((x) =>
                                        <li key={x} className="page-item"><a className="page-link" href="#" onClick={() => setCurrentPage(x)} >{x}</a></li>
                                    )}
                                    {currentPage == pageNumbers.length ? <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronRight /></a>
                                    </li> : <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronRight onClick={() => setCurrentPage(currentPage + 1)} /></a>
                                    </li>}
                                </ul>
                            </nav>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        </Layout>
    )
}

export default connect(mapStateToProps, null)(Announcement)
