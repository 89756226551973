import React, { useEffect } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Link,
  Font,
} from "@react-pdf/renderer";
import Html from "react-pdf-html";
import ReactDOMServer from "react-dom/server";

import moment from "moment";

export function PdfDocument(props) {
  const styles = StyleSheet.create({
    page: {
      backgroundColor: "#ffffff",
      padding: "20px",
    },
  });
  const getLocalDateTime = (mydate) => {
    var stillUtc = moment.utc(mydate).toDate();
    var local = moment(stillUtc).local().format("YYYY-MM-DD HH:mm:ss");
    return local;
  };
  const element = (
    <html>
      <body>
        <style>
          {`
          .heading4 {
            background: darkgreen;
            color: white;
          }
          .thead{
            margin-bottom:1rem;
          }
          pre {
            background-color: #eee;
            padding: 10px;
          }`}
        </style>
        <table className="table_x">
          <thead className="thead">
            <tr
              style={{ fontSize: "17px", color: "red", marginBottom: "1rem" }}
            >
              <th style={{textAlign:"center"}} className="preOrder--des">Plan Name</th>
              <th style={{textAlign:"center"}} className="preOrder--startDate">PreOrder Date</th>
              <th style={{textAlign:"center"}} className="preOrder--startDate">Delivery Date</th>
              <th style={{textAlign:"center"}} className="preOrder--contactname">Ordered By</th>
              <th style={{textAlign:"center"}} className="preOrder--contactNo">Contact</th>
              <th style={{textAlign:"center"}} className="preOrder--startDate">Location</th>
              <th style={{textAlign:"center"}} className="preOrder--startDate">Quantity</th>
              <th style={{textAlign:"center"}} className="preOrder--startDate">Amount</th>
              <th style={{textAlign:"center"}} className="preOrder--startDate">Is Takeway</th>
              <th style={{textAlign:"center"}} className="preOrder--startDate">Status</th>
            </tr>
          </thead>
          <div
            style={{
              width: "100%",
              height: "0.5px",
              background: "black",
              marginBottom: "16px",
              marginTop: "16px",
            }}
          ></div>
          <tbody>
            {props.allPreOrderRequestedList !== undefined &&
              props.allPreOrderRequestedList.data?.map((item, index) => (
                <>
                  <tr key={index} style={{ marginTop: "1rem",fontSize:"15px" }}>
                    <td style={{textAlign:"center"}}>{item.preOrderPlanName}</td>
                    <td style={{textAlign:"center"}}>
                      {getLocalDateTime(item.orderPlacedDateTime)}
                      
                    </td>
                    <td style={{textAlign:"center"}}> {getLocalDateTime(item.deliveryTime)}</td>
                    <td style={{textAlign:"center"}}>{item.fullName}</td>
                    <td style={{textAlign:"center"}}>{item.contactNumber}</td>
                    <td
                    style={{textAlign:"center"}}
                      onClick={() =>
                        window.open(
                          `https://www.google.com/maps/search/?api=1&query=${item.deliveryLatitude},${item.deliveryLongitude}`
                        )
                      }
                      className="requestedPreorder--location"
                    >
                      {item.deliveryLocation}
                    </td>
                    <td style={{textAlign:"center"}}>{item.quantity}</td>
                    <td style={{textAlign:"center"}}>{item.paidAmount}</td>
                    <td style={{textAlign:"center"}}>
                      {" "}
                      {item.isTakeAway ? (
                        <span
                          style={{
                            backgroundColor: "green",
                            padding: "3px 5px",
                            borderRadius: "4px",
                            color: "white",
                          }}
                        >
                          Pick up
                        </span>
                      ) : (
                        <span
                          style={{
                            backgroundColor: "green",
                            padding: "3px 5px",
                            borderRadius: "4px",
                            color: "white",
                          }}
                        >
                          Delivery
                        </span>
                      )}
                    </td>
                    <td style={{textAlign:"center"}}>
                      <span
                        style={{
                          backgroundColor: "green",
                          padding: "3px 5px",
                          borderRadius: "4px",
                          color: "white",
                        }}
                      >
                        {item.orderState}
                      </span>
                    </td>
                  </tr>
                  <div
                    style={{
                      width: "100%",
                      height: "0.5px",
                      background: "black",
                      marginBottom: "16px",
                      marginTop: "16px",
                    }}
                  ></div>
                </>
              ))}
          </tbody>
        </table>
      </body>
    </html>
  );

  const html = ReactDOMServer.renderToStaticMarkup(element);

  return (
    <Document>
      <Page style={styles.page} size="A2">
        <View style={{ display: "flex", justifyContent: "space-between" }}>
          <Text>
            Date : {moment(props.finalPreorderDate).format("YYYY-MM-DD")}
          </Text>
          <Text>
            Time Slot : {moment(props.finalPreorderDate).format("YYYY-MM-DD")}
          </Text>
          <View style={{ display: "flex", gap: "1rem" }}>
            <Html>{html}</Html>
          </View>
        </View>
      </Page>
    </Document>
  );
}
