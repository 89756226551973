import React, { useState, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";
import { DatePicker } from "antd";
import moment from "moment";
const mapStateToProps = (store) => {
  const { buttonText } = store.mainState;
  return { buttonText };
};

function AddAnnouncement({ buttonText }) {
  const dispatch = useDispatch();
  const [announcement, setAnnouncement] = useState("");
  const [announcementFor, setAnnoouncementFor] = useState("ALL");
  const [startFrom, setStartFrom] = useState("");
  const [endDate, setEndDate] = useState("");
  const handleLoyalitySubmit = (e) => {
    e.preventDefault();
    dispatch({
      type: "POST_USER_ANNOUNCEMENT_REQUEST",
      payload: {
        announcement: announcement,
        announcementFor: announcementFor,
        startDate: moment(startFrom)
          .utc()
          .local()
          .format("YYYY-MM-DD HH:mm:ss"),
        endDate: moment(endDate).utc().local().format("YYYY-MM-DD HH:mm:ss"),
      },
    });
    setAnnouncement("");
    setStartFrom("");
    setEndDate("");
  };
  const onChange = (value, dateString) => {
    setStartFrom(value);
  };
  const onChange1 = (value, dateString) => {
    setEndDate(value);
  };

  return (
    <div>
      <ToastContainer />
      <div className="Modal--heading">Add Announcement</div>
      <div className="Modal--form">
        <form>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Announcement Body: </label>
              <textarea
                style={{ minHeight: "100px", padding: "20px" }}
                value={announcement}
                onChange={(e) => setAnnouncement(e.target.value)}
                placeholder="Announcement Body"
              ></textarea>
            </p>
          </div>
          <div className="Modal--form--title s">
            <p>
              <label htmlFor="title">Start Date: </label>
              <DatePicker
                showTime
                value={startFrom}
                onChange={onChange}
                style={{ marginLeft: "1.8rem", height: "50px" }}
              />
            </p>
          </div>
          <div className="Modal--form--title s">
            <p>
              <label htmlFor="title">End Date: </label>
              <DatePicker
                showTime
                value={endDate}
                onChange={onChange1}
                style={{
                  marginLeft: "1.8rem",
                  height: "50px",
                  paddingTop: "-1rem",
                }}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title" style={{ marginLeft: "30px" }}>
                All:{" "}
              </label>
              <input
                type="radio"
                defaultChecked="true"
                value="ALL"
                style={{ width: "20px", height: "20px" }}
                name="groundfloordropdown"
                onChange={(e) => setAnnoouncementFor(e.target.value)}
              />
              <label htmlFor="title">Customer: </label>
              <input
                type="radio"
                value="CUSTOMER"
                name="groundfloordropdown"
                onChange={(e) => setAnnoouncementFor(e.target.value)}
                style={{ width: "20px", height: "20px" }}
              />
              <label htmlFor="title">Driver: </label>
              <input
                type="radio"
                value="DRIVER"
                name="groundfloordropdown"
                onChange={(e) => setAnnoouncementFor(e.target.value)}
                style={{ width: "20px", height: "20px" }}
              />
            </p>
          </div>

          <div className="modal--container__group--buttons">
            <button
              style={{ marginBottom: "50px" }}
              type="submit"
              className="btn btn-primary"
              onClick={handleLoyalitySubmit}
            >
              {buttonText}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(AddAnnouncement);
