import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import AdminController from "../Components/Admin Controller/AdminController";
import AdminProfile from "../Components/Admin Controller/AdminProfile";
import Announcement from "../Components/Announcement/Announcement";
import ChangePassword from "../Components/auth/ChangePassword";
import LogIn from "../Components/auth/SignIn/LogIn";
import Coupon from "../Components/Coupon/Coupon";
import ManageUsers from "../Components/Customers/ManageUsers";
import Dashboard from "../Components/Dashboard/Dashboard";
import CommissionFee from "../Components/Delivery/CommissionFee";
import Delivery from "../Components/Delivery/Delivery";
import GetDeliveryFee from "../Components/Delivery/GetDeliveryFee";
import Notification from "../Components/Nofification/Notification";
import NotificationList from "../Components/Nofification/NotificationList";
import OperatingLocList from "../Components/Operating Location/OperatingLocList";
import OrderManagement from "../Components/Orders/OrderManagement";
// import PhoneOrderList from '../Components/Orders/PhoneOrders/PhoneOrderList'
import DeliveryDetailInputForm from "../Components/Phone Order/DeliveryDetailInputForm";
import PhoneOrderList from "../Components/Orders/PhoneOrderList";
import Ph_OrderRestaurant from "../Components/Phone Order/Ph_OrderRestaurant";
import ShowMenuItem from "../Components/Phone Order/ShowMenuItem";
import ShowRestaurantList from "../Components/Phone Order/ShowRestaurantList";
import GetPreOrderList from "../Components/PreOrder/GetPreOrderList";
import Privacy from "../Components/Privacy/Privacy";
import PrivateRoute from "../Components/PrivateRoute/PrivateRoute";
import Advertisement from "../Components/Promotions/Advertisement";
import ExchangePoint from "../Components/Referral And Loyalty/ExchangePoint";
import ReferalAndLoyalty from "../Components/Referral And Loyalty/ReferalAndLoyalty";
import Restaurant from "../Components/Restaurant/Restaurant";
import ServiceRate from "../Components/Service Rate/ServiceRate";
import Settlement from "../Components/Settlement/Settlement";
import Terms_nCondition from "../Components/Terms And Condition/Terms_nCondition";
import Wallet from "../Components/Wallet/Wallet";
import RequestedList from "../Components/PreOrder/RequestedList";
import MenuList from "../Components/Restaurant/Menu/MenuList";
import CategoryList from "../Components/Restaurant/Category/CategoryList";
import Campaign from "../Components/Campaign/Campaign";
import Reseller from "../Components/Reseller/Reseller";
import CampaignOrder from "../Components/Orders/Campaign/CampaignOrder";

import CampaignOrderByIdList from "../Components/Campaign/CampaignOrderById/CampaignOrderByIdList";
import PreOrderByIdList from "../Components/PreOrder/PreOrderById/PreOrderByIdList";
import BatchPakageList from "../Components/BatchPakage/BatchPakageList";
import AddPreOrderBatch from "../Components/BatchPakage/AddPreOrderBatch";
import AddNormalOrderBatch from "../Components/BatchPakage/AddNormalOrderBatch";
import AddCampaignOrderBatch from "../Components/BatchPakage/AddCampaignOrderBatch";
import PreOrderTimeSlotList from "../Components/TimeSlot/PreOrderTimeSlot/PreOrderTimeSlotList";
import FindCustomer from "../Components/Campaign/CampaignPhoneOrder/FindCustomer";
import DeliveryLocationForm from "../Components/Campaign/CampaignPhoneOrder/DeliveryLocationForm";
import PreOrderMerchant from "../Components/PreOrderMerchant/PreOrderMerchant";
import RequestMerchantPreorder from "../Components/PreOrderMerchant/RequestMerchantPreorder";
import Report from "../Components/Report/Report";
import Reconcilement from "../Components/Reconcilement/Reconcilement";
import KYClist from "../Components/KYC/KYClist";

function MainRoutes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={LogIn} />
        {/* <Route path="/dashboard" exact component={Dashboard} /> */}
        <PrivateRoute path="/dashboard" exact component={Dashboard} />
        <PrivateRoute path="/admin-room" exact component={AdminController} />
        <PrivateRoute path="/restaurant" exact component={Restaurant} />
        <PrivateRoute
          path="/referral-loyalty"
          exact
          component={ReferalAndLoyalty}
        />
        <PrivateRoute
          path="/exchange-loyalty"
          exact
          component={ExchangePoint}
        />
        <PrivateRoute path="/privacy-policy" exact component={Privacy} />
        <PrivateRoute
          path="/terms-condition"
          exact
          component={Terms_nCondition}
        />
        <PrivateRoute path="/notification" exact component={Notification} />
        <PrivateRoute
          path="/notification-list"
          exact
          component={NotificationList}
        />
        <PrivateRoute path="/manage-customer" exact component={ManageUsers} />
        <PrivateRoute path="/coupon" exact component={Coupon} />
        <PrivateRoute path="/campaign" exact component={Campaign} />
        <PrivateRoute
          path="/campaign-phone-order/:campaignId"
          exact
          component={FindCustomer}
        />
        <PrivateRoute
          path="/campaign-phone-order/delivery-info/:customerId"
          exact
          component={DeliveryLocationForm}
        />
        <PrivateRoute path="/service-rate" exact component={ServiceRate} />
        <PrivateRoute
          path="/order-management"
          exact
          component={OrderManagement}
        />
        <PrivateRoute
          path="/manage-phone-order"
          exact
          component={PhoneOrderList}
        />
        <PrivateRoute
          path="/manage-campaign-order"
          exact
          component={CampaignOrder}
        />
        <PrivateRoute
          path="/manage-campaign-order-by-id/:campaignId"
          exact
          component={CampaignOrder}
        />
        <PrivateRoute path="/delivery" exact component={Delivery} />
        <PrivateRoute
          path="/delivery-charge"
          exact
          component={GetDeliveryFee}
        />
        <PrivateRoute
          path="/delivery-commission"
          exact
          component={CommissionFee}
        />
        <PrivateRoute path="/announcement" exact component={Announcement} />
        <PrivateRoute path="/settlement" exact component={Settlement} />
        <PrivateRoute path="/reseller" exact component={Reseller} />
        <PrivateRoute
          path="/preorder-merchant"
          exact
          component={PreOrderMerchant}
        />
        <PrivateRoute path="/admin-profile" exact component={AdminProfile} />
        <PrivateRoute path="/promotion" exact component={Advertisement} />
        <PrivateRoute
          path="/get-unverified-trasactions"
          exact
          component={Wallet}
        />
        <PrivateRoute
          path="/phone-order"
          exact
          component={Ph_OrderRestaurant}
        />
        <PrivateRoute path="/pre-order" exact component={GetPreOrderList} />
        <PrivateRoute
          path="/requested-pre-order"
          exact
          component={RequestedList}
        />
        <PrivateRoute
          path="/requested-pre-order-by-id/:preOrderId"
          exact
          component={PreOrderByIdList}
        />
        <PrivateRoute
          path="/requested-pre-order-merchant-by-id/:preOrderId"
          exact
          component={RequestMerchantPreorder}
        />

        <PrivateRoute
          path="/active-restaurants"
          exact
          component={ShowRestaurantList}
        />
        <PrivateRoute
          path="/active-menu-list/:restaurantId"
          exact
          component={ShowMenuItem}
        />
        <PrivateRoute
          path="/delivery-address-form/:cartId"
          exact
          component={DeliveryDetailInputForm}
        />
        <PrivateRoute
          path="/get-operating-locations"
          exact
          component={OperatingLocList}
        />

        <PrivateRoute
          path="/restaurant-menu/:resId"
          exact
          component={MenuList}
        />
        <PrivateRoute
          path="/restaurant-category/:resId"
          exact
          component={CategoryList}
        />
        <Route path="/change-password" exact component={ChangePassword} />

        {/* <PrivateRoute path = '/campaign-order-list' exact component = {CampaignOrder} /> */}
        <PrivateRoute path="/batch-package" exact component={BatchPakageList} />
        <PrivateRoute path="/manage-kyc" exact component={KYClist} />
        <PrivateRoute
          path="/add-preorder-batch-package"
          exact
          component={AddPreOrderBatch}
        />
        <PrivateRoute
          path="/add-normalorder-batch-package"
          exact
          component={AddNormalOrderBatch}
        />
        <PrivateRoute
          path="/add-campaignorder-batch-package"
          exact
          component={AddCampaignOrderBatch}
        />

        <PrivateRoute
          path="/preorder-time-slot/:preOrderId"
          exact
          component={PreOrderTimeSlotList}
        />
        <PrivateRoute path="/report" exact component={Report} />
        <PrivateRoute path="/reconcilement" exact component={Reconcilement} />
      </Switch>
    </BrowserRouter>
  );
}

export default MainRoutes;
