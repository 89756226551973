import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { AiOutlineHome, AiOutlineControl } from "react-icons/ai";
import { FcAdvertising, FcPrivacy, FcMenu } from "react-icons/fc";
import { VscListOrdered } from "react-icons/vsc";
import { IoRestaurantOutline, IoGameControllerOutline } from "react-icons/io5";
import { IoIosAdd, IoIosRemove } from "react-icons/io";
import { HiOutlinePlusCircle } from "react-icons/hi";
import { FiUsers, FiPercent } from "react-icons/fi";
import { FiTruck } from "react-icons/fi";
import { FaAdversal, FaRegObjectGroup, FaLuggageCart } from "react-icons/fa";
import { IoMdNotificationsOutline } from "react-icons/io";
import {
  BsQuestionSquare,
  BsPerson,
  BsColumns,
  BsCommand,
  BsHandbag,
} from "react-icons/bs";
import { FiPhoneCall } from "react-icons/fi";
import { GoLocation } from "react-icons/go";
import { RiShoppingBagLine } from "react-icons/ri";

import { toogleSidebar } from "../../Redux/actions";
import { BsX } from "react-icons/bs";
import { RiCoupon2Line } from "react-icons/ri";
import { BiWallet } from "react-icons/bi";
import AddCoupon from "../Coupon/AddCoupon";
import AddRestaurant from "../Restaurant/AddRestaurant";
import AddAdmin from "../Admin Controller/AddAdmin";
import AddReferralLoyalty from "../Referral And Loyalty/AddReferralLoyalty";
import AddAdvertisement from "../Promotions/AddAdvertisement";
import AddServiceRate from "../Service Rate/AddServiceRate";
import AddDelivery from "../Delivery/AddDelivery";
import AddExchangeRate from "../Referral And Loyalty/AddExchangeRate";
import AddAnnouncement from "../Announcement/AddAnnouncement";
import SetDeliveryCharge from "../Delivery/SetDeliveryCharge";
import SetCommissionFee from "../Delivery/SetCommissionFee";
import AddOperatingLocation from "../Operating Location/AddOperatingLocation";
import AddResWithoutCsv from "../Restaurant/AddResWithoutCsv";
import AddCampaign from "../Campaign/AddCampaign";
import AddDelivery2 from "../Delivery/AddDelivery2";
import AddResellar from "../Reseller/AddResellar";
import { MdReport } from "react-icons/md";
import { GrDocumentVerified } from "react-icons/gr";

const mapStateToProps = (store) => {
  const {
    isToogleSidebar,
    isResellarModal,
    isAddCouponModal,
    isAddReataurantModal,
    isAddAdminRegisterModal,
    isReferralLoyaltyModal,
    isAddAdvertisementModal,
    isServiceRateModal,
    isDeliveryModal,
    isExchangeLoaylityModal,
    isAnnouncementModal,
    isSetDeliveryChagerModal,
    IsSetDeliveryCommModal,
    isAddOperatingLocationModal,
    isAddResWithoutCsvModal,
    isAddCampaignModal,
    isAddDeliverySuccess,
  } = store.mainState;
  return {
    isToogleSidebar,
    isResellarModal,
    isAddCouponModal,
    isAddReataurantModal,
    isAddAdminRegisterModal,
    isReferralLoyaltyModal,
    isAddAdvertisementModal,
    isServiceRateModal,
    isDeliveryModal,
    isExchangeLoaylityModal,
    isAnnouncementModal,
    isSetDeliveryChagerModal,
    IsSetDeliveryCommModal,
    isAddOperatingLocationModal,
    isAddResWithoutCsvModal,
    isAddCampaignModal,
    isAddDeliverySuccess,
  };
};

function Sidebar({
  isToogleSidebar,
  isResellarModal,
  isAddCouponModal,
  isAddReataurantModal,
  isAddAdminRegisterModal,
  isReferralLoyaltyModal,
  isAddAdvertisementModal,
  isServiceRateModal,
  isDeliveryModal,
  isExchangeLoaylityModal,
  isAnnouncementModal,
  isSetDeliveryChagerModal,
  IsSetDeliveryCommModal,
  isAddOperatingLocationModal,
  isAddResWithoutCsvModal,
  isAddCampaignModal,
  isAddDeliverySuccess,
}) {
  const [user, setUser] = useState(false);
  const [restaurantMng, setRetaurantMng] = useState(false);
  const [menuMng, setMenuMng] = useState(false);
  const [orders, setOrders] = useState(false);
  const [delivery, setDelivery] = useState(false);
  const [coupon, setCoupon] = useState(false);
  const [advertisement, setAdvertisement] = useState(false);
  const [settlement, setSettlement] = useState(false);
  const [Reseller, setReseller] = useState(false);
  const [PreOrderMerchant, setPreOrderMerchant] = useState(false);
  const [reconcilement, setReconcilement] = useState(false);
  const [serviceRage, setServiceRate] = useState(false);
  const [loyality, setLoyality] = useState(false);
  const [privacy_n_terms, setPrivacy_n_terms] = useState(false);
  const [announcement, setAnnouncement] = useState(false);
  const [report, setReport] = useState(false);

  const [wallet, setWallet] = useState(false);
  const [operatingLocation, setOperatingLocation] = useState(false);
  const [showPreOrder, setShowPreOrder] = useState(false);
  const [campaign, setCampaigh] = useState(false);
  const [batch, setBatch] = useState(false);
  const [KYC, setKYC] = useState(false);

  const [notification, setNotification] = useState(false);
  const dispatch = useDispatch();
  return (
    <>
      {" "}
      <section id={`${isToogleSidebar ? "sidebar_sm" : "sidebar"}`}>
        <div className={`${isToogleSidebar ? "_sidebar_sm" : "_sidebar"}`}>
          <div className="_sidebar--main__para">Main</div>
          <div className="_sidebar--main__dashboard">
            <Link to="/dashboard" style={{ textDecoration: "none" }}>
              <ul>
                <span>
                  <AiOutlineHome />
                </span>{" "}
                Dashboard
              </ul>
            </Link>
          </div>
          <div className="_sidebar--main__dashboard">
            <Link to="/phone-order" style={{ textDecoration: "none" }}>
              <ul style={{ color: "black" }}>
                <span>
                  <FiPhoneCall />
                </span>{" "}
                Phone Order
              </ul>
            </Link>
          </div>
          <div
            className={`${
              showPreOrder
                ? "_sidebar--main__dashboard user_activated"
                : "_sidebar--main__dashboard"
            }`}
            onClick={() => setShowPreOrder(!showPreOrder)}
          >
            <ul>
              <span>
                <VscListOrdered />
              </span>{" "}
              Pre order{" "}
              {showPreOrder ? (
                <IoIosRemove className="add_icon" />
              ) : (
                <IoIosAdd className="add_icon" />
              )}
            </ul>
          </div>
          <div
            className={`${
              showPreOrder ? "advertisement_subclass" : "user_subclass__collabs"
            }`}
          >
            <li>
              <Link to="/pre-order" style={{ textDecoration: "none" }}>
                Manage Pre Order
              </Link>
            </li>
            {/* <li><Link to="/requested-pre-order" style={{ textDecoration: "none" }}>Request List</Link></li> */}
          </div>
          <div className="_sidebar--main__para">Components</div>
          <div
            className={`${
              user
                ? "_sidebar--main__dashboard user_activated"
                : "_sidebar--main__dashboard"
            }`}
            onClick={() => setUser(!user)}
          >
            <ul>
              <span>
                <FiUsers />
              </span>{" "}
              Customer{" "}
              {user ? (
                <IoIosRemove className="add_icon" />
              ) : (
                <IoIosAdd className="add_icon" />
              )}{" "}
            </ul>
          </div>
          <div
            className={`${
              user ? "advertisement_subclass" : "user_subclass__collabs"
            }`}
          >
            <li>
              <Link to="/manage-customer" style={{ textDecoration: "none" }}>
                Manage
              </Link>
            </li>
          </div>
          <div
            className={`${
              restaurantMng
                ? "_sidebar--main__dashboard user_activated"
                : "_sidebar--main__dashboard"
            }`}
            onClick={() => setRetaurantMng(!restaurantMng)}
          >
            <ul>
              <span>
                <IoRestaurantOutline />
              </span>{" "}
              Restaurant{" "}
              {restaurantMng ? (
                <IoIosRemove className="add_icon" />
              ) : (
                <IoIosAdd className="add_icon" />
              )}
            </ul>
          </div>
          <div
            className={`${
              restaurantMng
                ? "advertisement_subclass"
                : "user_subclass__collabs"
            }`}
          >
            <li>
              {" "}
              <Link to="/restaurant" style={{ textDecoration: "none" }}>
                {" "}
                Manage{" "}
              </Link>{" "}
              <span>
                <HiOutlinePlusCircle
                  className="add_task--icon"
                  onClick={() =>
                    dispatch({ type: "ADD_RES_WITHOUT_CSV_MODAL" })
                  }
                />
              </span>
            </li>
          </div>
          <div
            className={`${
              orders
                ? "_sidebar--main__dashboard user_activated"
                : "_sidebar--main__dashboard"
            }`}
            onClick={() => setOrders(!orders)}
          >
            <ul>
              <span>
                <VscListOrdered />
              </span>{" "}
              Orders{" "}
              {orders ? (
                <IoIosRemove className="add_icon" />
              ) : (
                <IoIosAdd className="add_icon" />
              )}
            </ul>
          </div>
          <div
            className={`${
              orders ? "order_subclass" : "user_subclass__collabs"
            }`}
          >
            <li>
              <Link to="/order-management" style={{ textDecoration: "none" }}>
                Normal Orders
              </Link>
            </li>
            <li>
              <Link to="/manage-phone-order" style={{ textDecoration: "none" }}>
                Phone Orders
              </Link>
            </li>
            {/* <li><Link to="/manage-campaign-order" style={{ textDecoration: "none" }}>Campaign Orders</Link></li> */}
          </div>
          <div
            className={`${
              delivery
                ? "_sidebar--main__dashboard user_activated"
                : "_sidebar--main__dashboard"
            }`}
            onClick={() => setDelivery(!delivery)}
          >
            <ul>
              <span>
                <FiTruck />
              </span>{" "}
              Delivery{" "}
              {delivery ? (
                <IoIosRemove className="add_icon" />
              ) : (
                <IoIosAdd className="add_icon" />
              )}
            </ul>
          </div>
          <div
            className={`${
              delivery ? "delivery_subclass" : "user_subclass__collabs"
            }`}
          >
            <li>
              <Link to="delivery" style={{ textDecoration: "none" }}>
                Manage Delivery
              </Link>
              <span>
                <HiOutlinePlusCircle
                  className="add_task--icon"
                  onClick={() => dispatch({ type: "ADD_DELIVERY_MODAL" })}
                />
              </span>
            </li>
            <li>
              <Link to="delivery-charge" style={{ textDecoration: "none" }}>
                Set Delivery Fee
              </Link>
              <span>
                <HiOutlinePlusCircle
                  className="add_task--icon"
                  onClick={() =>
                    dispatch({ type: "ADD_DELIVERY_CHARGE_MODAL" })
                  }
                />
              </span>
            </li>
            <li>
              <Link to="delivery-commission" style={{ textDecoration: "none" }}>
                Set Commission Fee
              </Link>
              <span>
                <HiOutlinePlusCircle
                  className="add_task--icon"
                  onClick={() =>
                    dispatch({ type: "ADD_DELIVERY_COMMISSION_MODAL" })
                  }
                />
              </span>
            </li>
          </div>
          <div
            className={`${
              KYC
                ? "_sidebar--main__dashboard user_activated"
                : "_sidebar--main__dashboard"
            }`}
            onClick={() => setKYC(!KYC)}
          >
            <ul>
              <span>
                <GrDocumentVerified />
              </span>{" "}
              KYC{" "}
            </ul>
          </div>
          <div
            className={`${
              KYC ? "advertisement_subclass" : "user_subclass__collabs"
            }`}
          >
            <li>
              <Link to="/manage-kyc" style={{ textDecoration: "none" }}>
                Manage KYC
              </Link>{" "}
              <span>
                <HiOutlinePlusCircle className="add_task--icon" />
              </span>
            </li>
          </div>
          <div
            className={`${
              batch
                ? "_sidebar--main__dashboard user_activated"
                : "_sidebar--main__dashboard"
            }`}
            onClick={() => setBatch(!batch)}
          >
            <ul>
              <span>
                <FaLuggageCart />
              </span>{" "}
              Batch Pakage{" "}
              {batch ? (
                <IoIosRemove className="add_icon" />
              ) : (
                <IoIosAdd className="add_icon" />
              )}
            </ul>
          </div>
          <div
            className={`${
              batch ? "advertisement_subclass" : "user_subclass__collabs"
            }`}
          >
            <li>
              <Link to="/batch-package" style={{ textDecoration: "none" }}>
                Manage Batch
              </Link>{" "}
              <span>
                <HiOutlinePlusCircle className="add_task--icon" />
              </span>
            </li>
          </div>
          <div
            className={`${
              campaign
                ? "_sidebar--main__dashboard user_activated"
                : "_sidebar--main__dashboard"
            }`}
            onClick={() => setCampaigh(!campaign)}
          >
            <ul>
              <span>
                <FaRegObjectGroup />
              </span>{" "}
              Campaign{" "}
              {campaign ? (
                <IoIosRemove className="add_icon" />
              ) : (
                <IoIosAdd className="add_icon" />
              )}
            </ul>
          </div>
          <div
            className={`${
              campaign ? "advertisement_subclass" : "user_subclass__collabs"
            }`}
          >
            <li>
              <Link to="/campaign" style={{ textDecoration: "none" }}>
                Manage Campaign
              </Link>{" "}
              <span>
                <HiOutlinePlusCircle
                  className="add_task--icon"
                  onClick={() => dispatch({ type: "ADD_CAMPAIGN_MODAL" })}
                />
              </span>
            </li>
          </div>
          <div
            className={`${
              coupon
                ? "_sidebar--main__dashboard user_activated"
                : "_sidebar--main__dashboard"
            }`}
            onClick={() => setCoupon(!coupon)}
          >
            <ul>
              <span>
                <RiCoupon2Line />
              </span>{" "}
              Coupon{" "}
              {coupon ? (
                <IoIosRemove className="add_icon" />
              ) : (
                <IoIosAdd className="add_icon" />
              )}
            </ul>
          </div>
          <div
            className={`${
              coupon ? "advertisement_subclass" : "user_subclass__collabs"
            }`}
          >
            <li>
              <Link to="/coupon" style={{ textDecoration: "none" }}>
                Manage Coupon
              </Link>{" "}
              <span>
                <HiOutlinePlusCircle
                  className="add_task--icon"
                  onClick={() => dispatch({ type: "ADD_COUPON_MODAL" })}
                />
              </span>
            </li>
          </div>
          <div
            className={`${
              advertisement
                ? "_sidebar--main__dashboard user_activated"
                : "_sidebar--main__dashboard"
            }`}
            onClick={() => setAdvertisement(!advertisement)}
          >
            <ul>
              <span>
                <FaAdversal />
              </span>{" "}
              Promotions{" "}
              {advertisement ? (
                <IoIosRemove className="add_icon" />
              ) : (
                <IoIosAdd className="add_icon" />
              )}
            </ul>
          </div>
          <div
            className={`${
              advertisement
                ? "advertisement_subclass"
                : "user_subclass__collabs"
            }`}
          >
            <li>
              <Link to="promotion" style={{ textDecoration: "none" }}>
                Manage{" "}
              </Link>
              <span>
                <HiOutlinePlusCircle
                  className="add_task--icon"
                  onClick={() =>
                    dispatch({
                      type: "OPEN_ADVERTISEMENT_MODAL",
                    })
                  }
                />
              </span>
            </li>
          </div>
          <div
            className={`${
              wallet
                ? "_sidebar--main__dashboard user_activated"
                : "_sidebar--main__dashboard"
            }`}
            onClick={() => setWallet(!wallet)}
          >
            <ul>
              <span>
                <BiWallet />
              </span>{" "}
              Wallet{" "}
              {advertisement ? (
                <IoIosRemove className="add_icon" />
              ) : (
                <IoIosAdd className="add_icon" />
              )}
            </ul>
          </div>
          <div
            className={`${
              wallet ? "advertisement_subclass" : "user_subclass__collabs"
            }`}
          >
            <li>
              <Link
                to="get-unverified-trasactions"
                style={{ textDecoration: "none" }}
              >
                Manage{" "}
              </Link>
            </li>
          </div>

          {/* 
                <div className={`${servicePakage ? "_sidebar--main__dashboard user_activated" : "_sidebar--main__dashboard"}`} onClick={() => setServicePakage(!servicePakage)}>
                    <ul><span><BsXDiamond /></span> Service Pakage {servicePakage ? <IoIosRemove className="add_icon" /> : <IoIosAdd className="add_icon" />}</ul>
                </div>
                <div className={`${servicePakage ? "advertisement_subclass" : "user_subclass__collabs"}`}>
                    <li><Link to="#" style={{ textDecoration: "none" }}>Manage</Link><span><HiOutlinePlusCircle className='add_task--icon' onClick={() => dispatch({
                        type: "OPEN_BADGE_MODAL"
                    })} /></span></li>
                </div> */}

          <div
            className={`${
              operatingLocation
                ? "_sidebar--main__dashboard user_activated"
                : "_sidebar--main__dashboard"
            }`}
            onClick={() => setOperatingLocation(!operatingLocation)}
          >
            <ul>
              <span>
                <GoLocation />
              </span>{" "}
              Operating Location{" "}
              {operatingLocation ? (
                <IoIosRemove className="add_icon" />
              ) : (
                <IoIosAdd className="add_icon" />
              )}
            </ul>
          </div>
          <div
            className={`${
              operatingLocation
                ? "advertisement_subclass"
                : "user_subclass__collabs"
            }`}
          >
            <li>
              <Link
                to="/get-operating-locations"
                style={{ textDecoration: "none" }}
              >
                Manage
              </Link>
              <span>
                <HiOutlinePlusCircle
                  className="add_task--icon"
                  onClick={() =>
                    dispatch({ type: "ADD_OPERATING_LOCATION_MODAL" })
                  }
                />
              </span>
            </li>
          </div>
          <div
            className={`${
              serviceRage
                ? "_sidebar--main__dashboard user_activated"
                : "_sidebar--main__dashboard"
            }`}
            onClick={() => setServiceRate(!serviceRage)}
          >
            <ul>
              <span>
                <FiPercent />
              </span>{" "}
              Service Rate{" "}
              {serviceRage ? (
                <IoIosRemove className="add_icon" />
              ) : (
                <IoIosAdd className="add_icon" />
              )}
            </ul>
          </div>
          <div
            className={`${
              serviceRage ? "advertisement_subclass" : "user_subclass__collabs"
            }`}
          >
            <li>
              <Link to="/service-rate" style={{ textDecoration: "none" }}>
                Manage
              </Link>
              <span>
                <HiOutlinePlusCircle
                  className="add_task--icon"
                  onClick={() => dispatch({ type: "OPEN_SERVICE_RATE_MODAL" })}
                />
              </span>
            </li>
          </div>
          {/* <div className={`${privilegePlan ? "_sidebar--main__dashboard user_activated" : "_sidebar--main__dashboard"}`} onClick={() => setPrivilegePlan(!privilegePlan)}>
                    <ul><span><BsCardChecklist /></span>Privilege Plan {privilegePlan ? <IoIosRemove className="add_icon" /> : <IoIosAdd className="add_icon" />}</ul>
                </div>
                <div className={`${privilegePlan ? "advertisement_subclass" : "user_subclass__collabs"}`}>
                    <li><Link to="#" style={{ textDecoration: "none" }}>Manage Permission</Link></li>
                </div>
                <div className={`${review ? "_sidebar--main__dashboard user_activated" : "_sidebar--main__dashboard"}`} onClick={() => setReview(!review)}>
                    <ul><span><MdRateReview /></span> Reviews {review ? <IoIosRemove className="add_icon" /> : <IoIosAdd className="add_icon" />}</ul>
                </div>
                <div className={`${review ? "category_subclass" : "user_subclass__collabs"}`}>
                    <li><Link to="#" style={{ textDecoration: "none" }}>Role & Permissions</Link></li>
                    <li> <Link to="#" style={{ textDecoration: "none" }}> Manage R&P</Link> </li>
                </div>
                <div className={`${games ? "_sidebar--main__dashboard user_activated" : "_sidebar--main__dashboard"}`} onClick={() => setGames(!games)}>
                    <ul><span><IoGameControllerOutline /></span> Games {games ? <IoIosRemove className="add_icon" /> : <IoIosAdd className="add_icon" />}</ul>
                </div>
                <div className={`${games ? "advertisement_subclass" : "user_subclass__collabs"}`}>
                    <li><Link to="latidude" style={{ textDecoration: "none" }}>Manage Games</Link><span><HiOutlinePlusCircle className='add_task--icon' onClick={() => dispatch({
                        type: "OPEN_SECURITY_QTN_MODAL"
                    })} /></span></li>
                </div> */}

          <div
            className={`${
              loyality
                ? "_sidebar--main__dashboard user_activated"
                : "_sidebar--main__dashboard"
            }`}
            onClick={() => setLoyality(!loyality)}
          >
            <ul>
              <span>
                <BsCommand />
              </span>{" "}
              Referral & Loyalty{" "}
              {loyality ? (
                <IoIosRemove className="add_icon" />
              ) : (
                <IoIosAdd className="add_icon" />
              )}
            </ul>
          </div>
          <div
            className={`${
              loyality ? "category_subclass" : "user_subclass__collabs"
            }`}
          >
            <li>
              <Link to="referral-loyalty" style={{ textDecoration: "none" }}>
                Earn Point
              </Link>
              <span>
                <HiOutlinePlusCircle
                  className="add_task--icon"
                  onClick={() =>
                    dispatch({ type: "ADD_REFERRAL_LOYALITY_MODAL" })
                  }
                />
              </span>
            </li>
            <li>
              <Link to="exchange-loyalty" style={{ textDecoration: "none" }}>
                Exchange Point
              </Link>
              <span>
                <HiOutlinePlusCircle
                  className="add_task--icon"
                  onClick={() =>
                    dispatch({ type: "ADD_REFERRAL_LOYALITY_MODAL" })
                  }
                />
              </span>
            </li>
          </div>
          <div
            className={`${
              notification
                ? "_sidebar--main__dashboard user_activated"
                : "_sidebar--main__dashboard"
            }`}
            onClick={() => setNotification(!notification)}
          >
            <ul>
              <span>
                <IoMdNotificationsOutline />
              </span>{" "}
              Notification{" "}
              {notification ? (
                <IoIosRemove className="add_icon" />
              ) : (
                <IoIosAdd className="add_icon" />
              )}
            </ul>
          </div>
          <div
            className={`${
              notification ? "category_subclass" : "user_subclass__collabs"
            }`}
          >
            <li>
              <Link to="notification" style={{ textDecoration: "none" }}>
                Add Notification
              </Link>
            </li>
            <li>
              <Link to="notification-list" style={{ textDecoration: "none" }}>
                Notification List
              </Link>
            </li>
          </div>
          <div
            className={`${
              announcement
                ? "_sidebar--main__dashboard user_activated"
                : "_sidebar--main__dashboard"
            }`}
            onClick={() => setAnnouncement(!announcement)}
          >
            <ul>
              <span>
                <FcAdvertising />
              </span>{" "}
              Announcement{" "}
              {announcement ? (
                <IoIosRemove className="add_icon" />
              ) : (
                <IoIosAdd className="add_icon" />
              )}
            </ul>
          </div>
          <div
            className={`${
              announcement ? "advertisement_subclass" : "user_subclass__collabs"
            }`}
          >
            <li>
              <Link to="/announcement" style={{ textDecoration: "none" }}>
                Manage
              </Link>
              <span>
                <HiOutlinePlusCircle
                  className="add_task--icon"
                  onClick={() => dispatch({ type: "ADD_ANNOUNCEMENT_MODAL" })}
                />
              </span>
            </li>
          </div>
          <div
            className={`${
              report
                ? "_sidebar--main__dashboard user_activated"
                : "_sidebar--main__dashboard"
            }`}
            onClick={() => setReport(!report)}
          >
            <ul>
              <span>
                <MdReport />
              </span>{" "}
              Reports{" "}
              {report ? (
                <IoIosRemove className="add_icon" />
              ) : (
                <IoIosAdd className="add_icon" />
              )}
            </ul>
          </div>
          <div
            className={`${
              report ? "advertisement_subclass" : "user_subclass__collabs"
            }`}
          >
            <li>
              <Link to="/report" style={{ textDecoration: "none" }}>
                Manage
              </Link>
              <span>
                <HiOutlinePlusCircle
                  className="add_task--icon"
                  onClick={() => dispatch({ type: "ADD_ANNOUNCEMENT_MODAL" })}
                />
              </span>
            </li>
          </div>
          <div
            className={`${
              settlement
                ? "_sidebar--main__dashboard user_activated"
                : "_sidebar--main__dashboard"
            }`}
            onClick={() => setSettlement(!settlement)}
          >
            <ul>
              <span>
                <BsColumns />
              </span>{" "}
              Settlement{" "}
              {settlement ? (
                <IoIosRemove className="add_icon" />
              ) : (
                <IoIosAdd className="add_icon" />
              )}
            </ul>
          </div>
          <div
            className={`${
              settlement ? "advertisement_subclass" : "user_subclass__collabs"
            }`}
          >
            <li>
              <Link to="/settlement" style={{ textDecoration: "none" }}>
                Manage
              </Link>
            </li>
          </div>
          <div
            className={`${
              Reseller
                ? "_sidebar--main__dashboard user_activated"
                : "_sidebar--main__dashboard"
            }`}
            onClick={() => setReseller(!Reseller)}
          >
            <ul>
              <span>
                <FiUsers />
              </span>{" "}
              Reseller{" "}
              {settlement ? (
                <IoIosRemove className="add_icon" />
              ) : (
                <IoIosAdd className="add_icon" />
              )}
            </ul>
          </div>
          <div
            className={`${
              Reseller ? "advertisement_subclass" : "user_subclass__collabs"
            }`}
          >
            <li>
              <Link to="/reseller" style={{ textDecoration: "none" }}>
                Manage
              </Link>
            </li>
          </div>
          <div
            className={`${
              PreOrderMerchant
                ? "_sidebar--main__dashboard user_activated"
                : "_sidebar--main__dashboard"
            }`}
            onClick={() => setPreOrderMerchant(!PreOrderMerchant)}
          >
            <ul>
              <span>
                <VscListOrdered />
              </span>{" "}
              Preorder Merchant{" "}
              {PreOrderMerchant ? (
                <IoIosRemove className="add_icon" />
              ) : (
                <IoIosAdd className="add_icon" />
              )}
            </ul>
          </div>
          <div
            className={`${
              PreOrderMerchant
                ? "advertisement_subclass"
                : "user_subclass__collabs"
            }`}
          >
            <li>
              <Link to="/preorder-merchant" style={{ textDecoration: "none" }}>
                Manage
              </Link>
            </li>
          </div>
          <div
            className={`${
              reconcilement
                ? "_sidebar--main__dashboard user_activated"
                : "_sidebar--main__dashboard"
            }`}
            onClick={() => setReconcilement(!reconcilement)}
          >
            <ul>
              <span>
                <VscListOrdered />
              </span>{" "}
              Reconcilement{" "}
              {reconcilement ? (
                <IoIosRemove className="add_icon" />
              ) : (
                <IoIosAdd className="add_icon" />
              )}
            </ul>
          </div>
          <div
            className={`${
              reconcilement
                ? "advertisement_subclass"
                : "user_subclass__collabs"
            }`}
          >
            <li>
              <Link to="/reconcilement" style={{ textDecoration: "none" }}>
                Manage
              </Link>
            </li>
          </div>
          {/* <div className={`${report ? "_sidebar--main__dashboard user_activated" : "_sidebar--main__dashboard"}`} onClick={() => setReport(!report)}>
                    <ul><span><VscReport /></span> Reports {report ? <IoIosRemove className="add_icon" /> : <IoIosAdd className="add_icon" />}</ul>
                </div>
                <div className={`${report ? "category_subclass" : "user_subclass__collabs"}`}>
                    <li><Link to="manage-bid" style={{ textDecoration: "none" }}>Bids Reports</Link></li>
                    <li><Link to="reports" style={{ textDecoration: "none" }}>Report Reasons</Link><span><HiOutlinePlusCircle className='add_task--icon' onClick={() => dispatch({ type: "OPEN_REPORT_MODAL" })} /></span></li>
                </div> */}
          {/* <div className={`${help ? "_sidebar--main__dashboard user_activated" : "_sidebar--main__dashboard"}`} onClick={() => setHelp(!help)}>
                    <ul><span><BiHelpCircle /></span> Help {help ? <IoIosRemove className="add_icon" /> : <IoIosAdd className="add_icon" />}</ul>

                </div>
                <div className={`${help ? "category_subclass" : "user_subclass__collabs"}`}>
                    <li><Link to="#" style={{ textDecoration: "none" }}>FAQ?</Link><span><HiOutlinePlusCircle className='add_task--icon' onClick={() => dispatch({
                        type: "OPEN_FAQ_MODAL"
                    })} /></span></li>
                    <li><Link to="#" style={{ textDecoration: "none" }}>Supports</Link></li>
                </div> */}
          <div
            className={`${
              privacy_n_terms
                ? "_sidebar--main__dashboard user_activated"
                : "_sidebar--main__dashboard"
            }`}
            onClick={() => setPrivacy_n_terms(!privacy_n_terms)}
          >
            <ul>
              <span>
                <BsQuestionSquare />
              </span>{" "}
              Terms And Conditions{" "}
              {privacy_n_terms ? (
                <IoIosRemove className="add_icon" />
              ) : (
                <IoIosAdd className="add_icon" />
              )}
            </ul>
          </div>
          <div
            className={`${
              privacy_n_terms
                ? "advertisement_subclass"
                : "user_subclass__collabs"
            }`}
          >
            <li>
              <Link to="terms-condition" style={{ textDecoration: "none" }}>
                Manage
              </Link>
            </li>
          </div>
          {/* <div className={`${rules_n_guidelines ? "_sidebar--main__dashboard user_activated" : "_sidebar--main__dashboard"}`} onClick={() => setRules_n_guidelines(!rules_n_guidelines)}>
                    <ul><span><BsLayoutTextSidebar /></span> Rule/Guidance {rules_n_guidelines ? <IoIosRemove className="add_icon" /> : <IoIosAdd className="add_icon" />}</ul>
                </div>
                <div className={`${rules_n_guidelines ? "category_subclass" : "user_subclass__collabs"}`}>
                    <li><Link to="#" style={{ textDecoration: "none" }}>Rules</Link></li>
                    <li><Link to="#" style={{ textDecoration: "none" }}>Guidelines</Link></li>
                </div> */}
          <div className="_sidebar--main__dashboard-last">
            <Link to="privacy-policy" style={{ textDecoration: "none" }}>
              <ul>
                <span>
                  <FcPrivacy />
                </span>{" "}
                Privacy And Policy <IoIosAdd className="add_icon" />
              </ul>
            </Link>
          </div>
        </div>
      </section>
      {/* add coupon modal */}
      <div
        className={`${
          isAddCouponModal ? "mymodal modal_activated" : "mymodal"
        }`}
      >
        <div
          className={`${
            !isToogleSidebar
              ? "manage_modal--container "
              : "manage_modal--container sidebarOpend_F_modal"
          }`}
        >
          <AddCoupon />
        </div>
        <div
          className={`${
            !isToogleSidebar
              ? "task--rectangle"
              : "task--rectangle withsidebarOpend"
          }`}
        >
          <div className="task--rectangle__circle">
            <div className="circle">
              {" "}
              <span
                onClick={() => {
                  let sortBy = "ascending";
                  let sortingParam = "id";
                  let currentPage = 1;
                  let postsPerPage = 10;
                  dispatch({ type: "ADD_COUPON_MODAL" });
                  dispatch({
                    type: "FETCH_COUPON_REQUEST",
                    payload: {
                      sortBy,
                      sortingParam,
                      currentPage,
                      postsPerPage,
                    },
                  });
                }}
              >
                <BsX />
              </span>
              <p>Add</p>
            </div>
          </div>
        </div>
      </div>
      {/* add restaurant modal */}
      <div
        className={`${
          isAddReataurantModal ? "mymodal modal_activated" : "mymodal"
        }`}
      >
        <div
          className={`${
            !isToogleSidebar
              ? "manage_modal--container "
              : "manage_modal--container sidebarOpend_F_modal"
          }`}
        >
          <AddRestaurant />
        </div>
        <div
          className={`${
            !isToogleSidebar
              ? "task--rectangle"
              : "task--rectangle withsidebarOpend"
          }`}
        >
          <div className="task--rectangle__circle">
            <div className="circle">
              {" "}
              <span
                onClick={() => {
                  let sortBy = "ascending";
                  let sortingParam = "id";
                  let currentPage = 1;
                  let postsPerPage = 10;
                  dispatch({ type: "ADD_RESTAURANT_MODAL" });
                  dispatch({
                    type: "FETCH_RESTAURANTS_REQUEST",
                    payload: {
                      sortBy,
                      sortingParam,
                      currentPage,
                      postsPerPage,
                    },
                  });
                }}
              >
                <BsX />
              </span>
              <p>Add</p>
            </div>
          </div>
        </div>
      </div>
      {/* add admin register modal */}
      <div
        className={`${
          isAddAdminRegisterModal ? "mymodal modal_activated" : "mymodal"
        }`}
      >
        <div
          className={`${
            !isToogleSidebar
              ? "manage_modal--container "
              : "manage_modal--container sidebarOpend_F_modal"
          }`}
        >
          <AddAdmin />
        </div>
        <div
          className={`${
            !isToogleSidebar
              ? "task--rectangle"
              : "task--rectangle withsidebarOpend"
          }`}
        >
          <div className="task--rectangle__circle">
            <div className="circle">
              {" "}
              <span
                onClick={() => {
                  let myKeyword = "";
                  let sortingParam = "NOT_VERIFIED";
                  let currentPage = 1;
                  let postsPerPage = 10;
                  dispatch({ type: "ADD_ADMIN_REGISTER_MODAL" });
                  dispatch({
                    type: "FETCH_ADMIN_USERS_REQUEST",
                    payload: {
                      currentPage,
                      postsPerPage,
                      myKeyword,
                      sortingParam,
                    },
                  });
                }}
              >
                <BsX />
              </span>
              <p>Add</p>
            </div>
          </div>
        </div>
      </div>
      {/* add referral And loyalty modal */}
      <div
        className={`${
          isReferralLoyaltyModal ? "mymodal modal_activated" : "mymodal"
        }`}
      >
        <div
          className={`${
            !isToogleSidebar
              ? "manage_modal--container "
              : "manage_modal--container sidebarOpend_F_modal"
          }`}
        >
          <AddReferralLoyalty />
        </div>
        <div
          className={`${
            !isToogleSidebar
              ? "task--rectangle"
              : "task--rectangle withsidebarOpend"
          }`}
        >
          <div className="task--rectangle__circle">
            <div className="circle">
              {" "}
              <span
                onClick={() => {
                  dispatch({ type: "ADD_REFERRAL_LOYALITY_MODAL" });
                  dispatch({
                    type: "FETCH_REFERAL_LOYALTY_REQUEST",
                  });
                }}
              >
                <BsX />
              </span>
              <p>Add</p>
            </div>
          </div>
        </div>
      </div>
      {/* add Advertisement Modal */}
      <div
        className={`${isResellarModal ? "mymodal modal_activated" : "mymodal"}`}
      >
        <div
          className={`${
            !isToogleSidebar
              ? "manage_modal--container "
              : "manage_modal--container sidebarOpend_F_modal"
          }`}
        >
          <AddResellar />
        </div>
        <div
          className={`${
            !isToogleSidebar
              ? "task--rectangle"
              : "task--rectangle withsidebarOpend"
          }`}
        >
          <div className="task--rectangle__circle">
            <div className="circle">
              {" "}
              <span
                onClick={() => {
                  dispatch({ type: "OPEN_RESELLAR_MODAL" });
                  dispatch({
                    type: "FETCH_RESELLER_REQUEST",
                    payload: {
                      postsPerPage: 10,
                      currentPage: 1,
                    },
                  });
                }}
              >
                <BsX />
              </span>
              <p>Add</p>
            </div>
          </div>
        </div>
      </div>
      {/* add Resellar Modal */}
      <div
        className={`${
          isAddAdvertisementModal ? "mymodal modal_activated" : "mymodal"
        }`}
      >
        <div
          className={`${
            !isToogleSidebar
              ? "manage_modal--container "
              : "manage_modal--container sidebarOpend_F_modal"
          }`}
        >
          <AddAdvertisement />
        </div>
        <div
          className={`${
            !isToogleSidebar
              ? "task--rectangle"
              : "task--rectangle withsidebarOpend"
          }`}
        >
          <div className="task--rectangle__circle">
            <div className="circle">
              {" "}
              <span
                onClick={() => {
                  dispatch({ type: "OPEN_ADVERTISEMENT_MODAL" });
                  dispatch({
                    type: "FETCH_ADVERTISEMENT_REQUEST",
                  });
                }}
              >
                <BsX />
              </span>
              <p>Add</p>
            </div>
          </div>
        </div>
      </div>
      {/* add Service rate Modal */}
      <div
        className={`${
          isServiceRateModal ? "mymodal modal_activated" : "mymodal"
        }`}
      >
        <div
          className={`${
            !isToogleSidebar
              ? "manage_modal--container "
              : "manage_modal--container sidebarOpend_F_modal"
          }`}
        >
          <AddServiceRate />
        </div>
        <div
          className={`${
            !isToogleSidebar
              ? "task--rectangle"
              : "task--rectangle withsidebarOpend"
          }`}
        >
          <div className="task--rectangle__circle">
            <div className="circle">
              {" "}
              <span
                onClick={() => {
                  dispatch({ type: "OPEN_SERVICE_RATE_MODAL" });
                  dispatch({
                    type: "FETCH_SERVICE_RATE_REQUEST",
                  });
                }}
              >
                <BsX />
              </span>
              <p>Add</p>
            </div>
          </div>
        </div>
      </div>
      {/* add Delivery Modal */}
      <div
        className={`${isDeliveryModal ? "mymodal modal_activated" : "mymodal"}`}
      >
        <div
          className={`${
            !isToogleSidebar
              ? "manage_modal--container "
              : "manage_modal--container sidebarOpend_F_modal"
          }`}
        >
          <AddDelivery />
        </div>
        <div
          className={`${
            !isToogleSidebar
              ? "task--rectangle"
              : "task--rectangle withsidebarOpend"
          }`}
        >
          <div className="task--rectangle__circle">
            <div className="circle">
              {" "}
              <span
                onClick={() => {
                  dispatch({ type: "ADD_DELIVERY_MODAL" });
                }}
              >
                <BsX />
              </span>
              <p>Add</p>
            </div>
          </div>
        </div>
      </div>
      {/* Add Delivery More Info Section */}
      <div
        className={`${
          isAddDeliverySuccess ? "mymodal modal_activated" : "mymodal"
        }`}
      >
        <div
          className={`${
            !isToogleSidebar
              ? "manage_modal--container "
              : "manage_modal--container sidebarOpend_F_modal"
          }`}
        >
          <AddDelivery2 />
        </div>
        <div
          className={`${
            !isToogleSidebar
              ? "task--rectangle"
              : "task--rectangle withsidebarOpend"
          }`}
        >
          <div className="task--rectangle__circle">
            <div className="circle">
              {" "}
              <span
                onClick={() => {
                  dispatch({ type: "ADD_DELIVERY_MORE_INFO_MODAL" });
                }}
              >
                <BsX />
              </span>
              <p>Add</p>
            </div>
          </div>
        </div>
      </div>
      {/* add Exchange loyality Modal */}
      <div
        className={`${
          isExchangeLoaylityModal ? "mymodal modal_activated" : "mymodal"
        }`}
      >
        <div
          className={`${
            !isToogleSidebar
              ? "manage_modal--container "
              : "manage_modal--container sidebarOpend_F_modal"
          }`}
        >
          <AddExchangeRate />
        </div>
        <div
          className={`${
            !isToogleSidebar
              ? "task--rectangle"
              : "task--rectangle withsidebarOpend"
          }`}
        >
          <div className="task--rectangle__circle">
            <div className="circle">
              {" "}
              <span
                onClick={() => {
                  dispatch({ type: "ADD_EXCHANGE_LOYALITY_MODAL" });
                  dispatch({
                    type: "FETCH_EXCHANGE_REFERAL_REQUEST",
                  });
                }}
              >
                <BsX />
              </span>
              <p>Add</p>
            </div>
          </div>
        </div>
      </div>
      {/* add Announcement Modal */}
      <div
        className={`${
          isAnnouncementModal ? "mymodal modal_activated" : "mymodal"
        }`}
      >
        <div
          className={`${
            !isToogleSidebar
              ? "manage_modal--container "
              : "manage_modal--container sidebarOpend_F_modal"
          }`}
        >
          <AddAnnouncement />
        </div>
        <div
          className={`${
            !isToogleSidebar
              ? "task--rectangle"
              : "task--rectangle withsidebarOpend"
          }`}
        >
          <div className="task--rectangle__circle">
            <div className="circle">
              {" "}
              <span
                onClick={() => {
                  let currentPage = 1;
                  let postsPerPage = 10;
                  dispatch({ type: "ADD_ANNOUNCEMENT_MODAL" });
                  dispatch({
                    type: "FETCH_USER_ANNOUNCEMENT_REQUEST",
                    payload: { currentPage, postsPerPage },
                  });
                }}
              >
                <BsX />
              </span>
              <p>Add</p>
            </div>
          </div>
        </div>
      </div>
      {/* add delivery charge modal */}
      <div
        className={`${
          isSetDeliveryChagerModal ? "mymodal modal_activated" : "mymodal"
        }`}
      >
        <div
          className={`${
            !isToogleSidebar
              ? "manage_modal--container "
              : "manage_modal--container sidebarOpend_F_modal"
          }`}
        >
          <SetDeliveryCharge />
        </div>
        <div
          className={`${
            !isToogleSidebar
              ? "task--rectangle"
              : "task--rectangle withsidebarOpend"
          }`}
        >
          <div className="task--rectangle__circle">
            <div className="circle">
              {" "}
              <span
                onClick={() => {
                  dispatch({ type: "ADD_DELIVERY_CHARGE_MODAL" });
                  dispatch({
                    type: "FETCH_DELIVERY_FEE_REQUEST",
                  });
                }}
              >
                <BsX />
              </span>
              <p>Add</p>
            </div>
          </div>
        </div>
      </div>
      {/* add delivery commission charge modal */}
      <div
        className={`${
          IsSetDeliveryCommModal ? "mymodal modal_activated" : "mymodal"
        }`}
      >
        <div
          className={`${
            !isToogleSidebar
              ? "manage_modal--container "
              : "manage_modal--container sidebarOpend_F_modal"
          }`}
        >
          <SetCommissionFee />
        </div>
        <div
          className={`${
            !isToogleSidebar
              ? "task--rectangle"
              : "task--rectangle withsidebarOpend"
          }`}
        >
          <div className="task--rectangle__circle">
            <div className="circle">
              {" "}
              <span
                onClick={() => {
                  dispatch({ type: "ADD_DELIVERY_COMMISSION_MODAL" });
                }}
              >
                <BsX />
              </span>
              <p>Add</p>
            </div>
          </div>
        </div>
      </div>
      {/* add operating location modal */}
      <div
        className={`${
          isAddOperatingLocationModal ? "mymodal modal_activated" : "mymodal"
        }`}
      >
        <div
          className={`${
            !isToogleSidebar
              ? "manage_modal--container "
              : "manage_modal--container sidebarOpend_F_modal"
          }`}
        >
          <AddOperatingLocation />
        </div>
        <div
          className={`${
            !isToogleSidebar
              ? "task--rectangle"
              : "task--rectangle withsidebarOpend"
          }`}
        >
          <div className="task--rectangle__circle">
            <div className="circle">
              {" "}
              <span
                onClick={() => {
                  dispatch({ type: "ADD_OPERATING_LOCATION_MODAL" });
                }}
              >
                <BsX />
              </span>
              <p>Add</p>
            </div>
          </div>
        </div>
      </div>
      {/* add restaurant without csv */}
      <div
        className={`${
          isAddResWithoutCsvModal ? "mymodal modal_activated" : "mymodal"
        }`}
      >
        <div
          className={`${
            !isToogleSidebar
              ? "manage_modal--container "
              : "manage_modal--container sidebarOpend_F_modal"
          }`}
        >
          <AddResWithoutCsv />
        </div>
        <div
          className={`${
            !isToogleSidebar
              ? "task--rectangle"
              : "task--rectangle withsidebarOpend"
          }`}
        >
          <div className="task--rectangle__circle">
            <div className="circle">
              {" "}
              <span
                onClick={() => {
                  dispatch({ type: "ADD_RES_WITHOUT_CSV_MODAL" });
                }}
              >
                <BsX />
              </span>
              <p>Add</p>
            </div>
          </div>
        </div>
      </div>
      {/* add campaign */}
      <div
        className={`${
          isAddCampaignModal ? "mymodal modal_activated" : "mymodal"
        }`}
      >
        <div
          className={`${
            !isToogleSidebar
              ? "manage_modal--container "
              : "manage_modal--container sidebarOpend_F_modal"
          }`}
        >
          <AddCampaign />
        </div>
        <div
          className={`${
            !isToogleSidebar
              ? "task--rectangle"
              : "task--rectangle withsidebarOpend"
          }`}
        >
          <div className="task--rectangle__circle">
            <div className="circle">
              {" "}
              <span
                onClick={() => {
                  dispatch({ type: "ADD_CAMPAIGN_MODAL" });
                }}
              >
                <BsX />
              </span>
              <p>Add</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    toogleSidebar: () => dispatch(toogleSidebar()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
