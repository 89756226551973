import cookie from 'js-cookie'

// set in cookie
export const setCookie = (key,value)=> {
    if(window !== "undefined") {
         cookie.set(key,value,{
            expires: 1
        })
    }
       
}
// remove from cookie
export const removeCookie = key=> {
    if(window !== "undefined") {
        cookie.remove(key,{
            expires: 1
        })
    }
}
// get from cookie such as stored token
export const getCookie = key=> {
    if(window !== "undefined") {
        return cookie.get(key)
    }
}
// will be useful when we need to make request to server
// set in localstorage
export const setLocalStorage = (key,value)=> {
    if(window !== 'undefined') {
        localStorage.setItem(key,JSON.stringify(value))
    }
}
// remove from localStoreage
export const removeLocalStorage = key=> {
    if(window !== 'undefined'){
        localStorage.removeItem(key)
    }
}
// authenticate user by passing data to cookie and localStorage
export const authenticateAdmin = (response,next)=> {
    // console.log("the authenticate helper on signin response",response)
    setCookie('token',response.data.accessToken);
    setLocalStorage('adminProfile',response.data.adminProfile.fullName);
    next();
};
// 
// access user info from localStorage
export const isAuth = ()=> {
    if(window !== 'undefined') {
        const cookieChecked = getCookie("token")
        if(cookieChecked) {
            if(localStorage.getItem('adminProfile')) {
                return JSON.parse(localStorage.getItem("adminProfile"))
            } else {
                return false
            }
        }
    }
}
// access admin Id from localStorage
export const getAdminId = ()=> {
    if(window !== "undefined") {
        return JSON.parse(localStorage.getItem("adminId"))
    }
}