import React,{useState,useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../../Layout/Layout'
import LoadingComponent from '../../LoadingComponent';
import Footer from '../../Z_Footer/Footer'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { BsX } from "react-icons/bs";
import {BsEyeFill} from 'react-icons/bs'
import {BiChevronLeft,BiChevronRight} from 'react-icons/bi'
import CampaignOrderDetails from './CampaignOrderDetails';
import moment from 'moment';
import dayjs from 'dayjs';
import DatePicker from "react-datepicker";
import { useParams } from 'react-router';

function CampaignOrder() {
    const {campaignId} = useParams()

    const dispatch = useDispatch()

    const {isLoadingOnLogin ,isEnabledUsers,campaignOrderList,isToogleSidebar} = useSelector((state)=> state.mainState)

    const [orderStatus, setOrderStatus] = useState("PENDING");
    const [sortingParam,setSortingParams] = useState("descending")
    const [filterPreorderDate,setFilterPreorderDate] = useState("")
    const [finalPreorderDate,setFinalPreorderDate] = useState("")
    const [campaignStatus,setCampaignStatus] = useState("PENDING")

    useEffect(()=> {
        let a = dayjs(filterPreorderDate).format("YYYY-MM-DD").split(" ")[0]
        if(filterPreorderDate) {
            setFinalPreorderDate(a)
        } else {
            setFinalPreorderDate("")
        }
        
      },[filterPreorderDate])


        // for showing details 
        const [openEye, setOpenEye] = useState(false);
        const [userDetailsId, setUserDetailsId] = useState(null);

        // Pagination
        const [currentPage, setCurrentPage] = useState(1)
        const [postsPerPage, setPostsPerPage] = useState(10);
        const [currentPosts, setCurrentPosts] = useState([])
    
        let pageNumbers = []
        for (let i = 1; i <= campaignOrderList.totalPage; i++) {
            pageNumbers.push(i)
        }
        // get current posts
        const indexOfLastPost = currentPage * postsPerPage
        const indexOfFirstPost = indexOfLastPost - postsPerPage

        useEffect(()=> {
            dispatch({
                type: "FETCH_CAMPAIGN_ORDER_REQUEST",
                payload: {
                    campaignId,
                    currentPage,postsPerPage,orderStatus : campaignStatus,sortingParam,finalPreorderDate
                }
            })
        }, [postsPerPage,campaignStatus,currentPage,sortingParam,finalPreorderDate,isEnabledUsers])

        const campaignOrder = () => (
            campaignOrderList.data !== undefined && campaignOrderList.data.reverse().map((item) =>
                <tr key={item.id}>
                    <td>{item.code}</td>
                    <td>{item.campaignName}</td>
                    <td>Rs. {item.campaignPrice}</td>
                    <td>{item.userFullName}</td>
                    <td>{moment.utc(item.createdOn).local().format("YYYY-MM-DD HH:mm:ss")}</td>
                    <td>{moment.utc(item?.deliveryData?.scheduledTime).local().format("YYYY-MM-DD HH:mm:ss") || "Not Scheduled"}</td>
                    <td>{item.deliveryData.deliveryContactNo}</td>
                    <td>{item.completionStatus}</td>
                    <td><span style={{ backgroundColor: "purple", color: "white", padding: "3px 5px", borderRadius: "4px" }}>{item.paymentMethod}</span></td>
                    <td>
                        <Tippy content="View" placement="bottom">
                            <span>
                                <BsEyeFill className="action--edit__admin" onClick={() => {
                                setUserDetailsId(item);
                                setOpenEye(true)
                            }}  /> </span>
                        </Tippy>
    
                    </td>
                </tr>
            )
        )

  return (
    <Layout>
            <div>
                <div className="group_list">
                    <h4>Manage Campaign Order</h4>
                </div>
                <div className="user_groups">
                <div className="user_groups--button_n_search" style={{display:"flex",justifyContent: "flex-start"}}>
                        <div className="user_groups--button_n_search--button">
                            <label htmlFor="pageSize" style={{ marginLeft: "10px", fontSize: '18px', fontFamily: "bold", color: "#3e445e", marginRight: "-10px" }}>Per Page</label>
                            <select name="pageSize" onChange={(e) => setPostsPerPage(e.target.value)} style={{width: "60px"}}>
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                            <label htmlFor="" style={{ marginLeft: "10px", fontSize: '18px', fontFamily: "bold", color: "#3e445e", marginRight: "-10px" }}>Campaign Status : </label>
                            <select name="status" onChange = {(e) => setCampaignStatus(e.target.value)}>
                            <option value="PENDING">PENDING</option>
                                <option value="COMPLETED">COMPLETED</option>
                                <option value="APPROVED">APPROVED</option>
                                <option value="FAILED">FAILED</option>
                                <option value="REJECTED">REJECTED</option>
                            </select>
                        </div>
                      
                    </div>
                    <p className="privacy-loading--onFetching">{isLoadingOnLogin && <LoadingComponent />}</p>
                    <div className="table-container">
                    <hr />
                        <div className='filter-by__date'>
                            <p><label htmlFor="">Filter By Date :  <DatePicker placeholderText='Enter your date' className='filter-by__Date--input' dateFormat="yyyy-MM-dd" selected={filterPreorderDate}
                                onChange={(date) => setFilterPreorderDate(date)} /></label> <input type="radio" name='order-date' style={{marginLeft: "20px"}} onChange={()=> {
                                    let a = new Date()
                                    setFilterPreorderDate(a)
                                } } /> <label htmlFor="order-date">Today</label> <input style={{marginLeft: "20px"}} name='order-date' type="radio" onChange={()=> {
                                    const today = new Date()
                                    const yesterday = new Date(today)

                                    yesterday.setDate(yesterday.getDate() - 1)

                                    setFilterPreorderDate(yesterday)
                                } } /> <label htmlFor="order-date">Yesterday</label></p>
                        </div>
                        <table className="table_x">
                            <thead>
                                <tr>
                                    <th className="camporder--id">Order ID</th>
                                    <th className="camporder--campaignName">Campaign Name</th>
                                    <th className="camporder--price">Campaign Price</th>
                                    <th className="camporder--name">Customer Name</th>
                                    <th className="camporder--status">Order Date</th>
                                    <th className="camporder--status">Delivery Date</th>
                                    <th className="camporder--status">Contact Number</th>
                                    <th className="camporder--status">Status</th>
                                    <th className="camporder--paymentStatus">Payment Status</th>
                                    <th className="camporder--actions" style={{ width: "5vw" }}>Actions</th>
                                </tr></thead>
                            <tbody>
                                {campaignOrder()}
                            </tbody>
                        </table>
                        <div className="pagination">
                            <p>Showing {indexOfFirstPost} to {indexOfLastPost > campaignOrderList.totalData ? campaignOrderList.totalData : indexOfLastPost} of {campaignOrderList.totalData} entries</p>
                            <nav aria-label="...">
                                <ul className="pagination" style={{ maxWidth: "400px", overflowX: "auto" }}>
                                    {currentPage === 1 ? <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronLeft /></a>
                                    </li> : <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronLeft onClick={() => setCurrentPage(currentPage - 1)} /></a>
                                    </li>}
                                    {pageNumbers.map((x) =>
                                        <li key={x} className="page-item"><a className="page-link" href="#" onClick={() => setCurrentPage(x)} >{x}</a></li>
                                    )}
                                    {currentPage == pageNumbers.length ? <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronRight /></a>
                                    </li> : <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronRight onClick={() => setCurrentPage(currentPage + 1)} /></a>
                                    </li>}
                                </ul>
                            </nav>

                        </div>
                    </div>
                </div>

                <div className={`${openEye ? "mymodal modal_activated" : "mymodal"}`}>
                            <div className={`${!isToogleSidebar ? "manage_modal--container " : "manage_modal--container sidebarOpend_F_modal"}`}>
                                {userDetailsId !== null && <CampaignOrderDetails item={userDetailsId}  />}
                            </div>
                            <div className={`${!isToogleSidebar ? "task--rectangle" : "task--rectangle withsidebarOpend"}`}>
                                <div className="task--rectangle__circle">
                                    <div className='circle'> <span onClick={() => {
                                        setOpenEye(!openEye)
                                        setUserDetailsId(null)
                                    }
 
                                    }><BsX /></span><p>Close</p></div>
                                </div>
                            </div>
                        </div>

                <Footer />
            </div>

        </Layout>
  )
}

export default CampaignOrder