import { mergeMap, catchError } from "rxjs/operators";
import { ofType } from "redux-observable";
import { API } from "../BaseUrlProvider";
import { ADMIN_AUTH_REQUEST } from "./actionType";
import { from, of } from "rxjs";
import { loginFailure, loginSuccess } from "./actions";

// Fetch Dashboard Data
export const fetchDashboardDataEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_DASHBOARD_DATA_REQUEST"),
    mergeMap((action) =>
      from(API.get("/admin/dashboard")).pipe(
        mergeMap((response) =>
          of({
            type: "FETCH_DASHBOARD_DATA_SUCCESS",
            payload: response.data.userData,
          })
        ),
        catchError((error) =>
          of({ type: "FETCH_DASHBOARD_DATA_FAILURE", payload: error.response })
        )
      )
    )
  );
// fetch Admin data
export const fetchAdminDataEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_ADMIN_USERS_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/admin/get-list?page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}&searchValue=${action.payload.myKeyword}&sortParam=${action.payload.sortingParam}`
        )
      ).pipe(
        mergeMap((response) =>
          of({ type: "FETCH_ADMIN_USERS_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "FETCH_ADMIN_USERS_FAILURE", payload: error.response })
        )
      )
    )
  );
// fetch Admin Profile
export const fetchAdminByIdEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_ADMIN_BY_ID_REQUEST"),
    mergeMap((action) =>
      from(API.get("/admin/profile")).pipe(
        mergeMap((response) =>
          of({ type: "FETCH_ADMIN_BY_ID_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "FETCH_ADMIN_BY_ID_FAILURE", payload: error.response })
        )
      )
    )
  );
// Add admin
export const postAdminDataEpic = (action$) =>
  action$.pipe(
    ofType("POST_ADMIN_USERS_REQUEST"),
    mergeMap((action) =>
      from(API.post("/admin/register", action.payload)).pipe(
        // tap(response=> console.log("The response after posting Reports",response)),
        mergeMap((response) =>
          of({ type: "POST_ADMIN_USERS_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "POST_ADMIN_USERS_FAILURE", payload: error.response })
        )
      )
    )
  );
// Update Admin
export const updateAdminDataEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_ADMIN_USERS_REQUEST"),
    mergeMap((action) =>
      from(API.put("/admin/update-profile", action.payload)).pipe(
        // tap(response=> console.log("The response after posting Reports",response)),
        mergeMap((response) =>
          of({ type: "UPDATE_ADMIN_USERS_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "UPDATE_ADMIN_USERS_FAILURE", payload: error.response })
        )
      )
    )
  );
// Enable and disable Admin
export const enableDisableAdminEpic = (action$) =>
  action$.pipe(
    ofType("ENABLE_DISABLE_ADMIN_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/admin/enable-disable-admin/${action.payload.id}`,
          action.payload.values
        )
      ).pipe(
        // tap(response=> console.log("The response after posting Reports",response)),
        mergeMap((response) =>
          of({ type: "ENABLE_DISABLE_ADMIN_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "ENABLE_DISABLE_ADMIN_FAILURE", payload: error.response })
        )
      )
    )
  );

//authenticate admin epic
export const authenticateAdminEpic = (action$) =>
  action$.pipe(
    ofType(ADMIN_AUTH_REQUEST),
    mergeMap((action) =>
      from(API.post("/admin/login", action.payload.adminCredencial)).pipe(
        // tap((response) => console.log("the response data from epic",response.data)),
        mergeMap((response) => of(loginSuccess(response.data))),
        catchError((error) => of(loginFailure(error.response)))
      )
    )
  );
// // fetch Privacy
export const fetchPrivacyEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_PRIVACY_REQUEST"),
    mergeMap((action) =>
      from(API.get("/terms-privacy")).pipe(
        // tap(response=> console.log("The response after posting Reports",response)),
        mergeMap((response) =>
          of({
            type: "FETCH_PRIVACY_SUCCESS",
            payload: response.data.privacyPolicy,
          })
        ),
        catchError((error) =>
          of({ type: "FETCH_PRIVACY_FAILURE", payload: error.response })
        )
      )
    )
  );
// // fetch terms and condition
export const fetchTermsAndConditionEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_TERMS_CONDITION_REQUEST"),
    mergeMap((action) =>
      from(API.get("/terms-privacy")).pipe(
        // tap(response=> console.log("The response after posting Reports",response)),
        mergeMap((response) =>
          of({
            type: "FETCH_TERMS_CONDITION_SUCCESS",
            payload: response.data.termsAndCondition,
          })
        ),
        catchError((error) =>
          of({ type: "FETCH_TERMS_CONDITION_FAILURE", payload: error.response })
        )
      )
    )
  );
// // updating privacy and policy
export const postPrivacy_Epic = (action$) =>
  action$.pipe(
    ofType("POST_PRIVACY_REQUEST"),
    mergeMap((action) =>
      from(
        API.post(
          "/privacy-terms/admin/add-privacy-policy",
          action.payload.myPolicy
        )
      ).pipe(
        // tap(response=> console.log("The response for privacy post",response)),
        mergeMap((response) =>
          of({ type: "POST_PRIVACY_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "POST_PRIVACY_FAILURE", payload: error.response })
        )
      )
    )
  );
// // updating terms and conditions
export const postTerms_n_condition_Epic = (action$) =>
  action$.pipe(
    ofType("POST_TERMS_N_CONDITION_REQUEST"),
    mergeMap((action) =>
      from(
        API.post(
          "/privacy-terms/admin/add-terms-condition",
          action.payload.myPolicy
        )
      ).pipe(
        // tap(response=> console.log("The response for privacy post",response)),
        mergeMap((response) =>
          of({ type: "POST_TERMS_N_CONDITION_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({
            type: "POST_TERMS_N_CONDITION_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// // Add Mass Notification
export const addNotificationEpic = (action$) =>
  action$.pipe(
    ofType("ADD_MASS_NOTIFICATION_REQUEST"),
    mergeMap((action) =>
      from(API.post("/admin/send/mass-notification", action.payload)).pipe(
        mergeMap((response) =>
          of({ type: "ADD_MASS_NOTIFICATION_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "ADD_MASS_NOTIFICATION_FAILURE", payload: error.response })
        )
      )
    )
  );
// // fetch user list epic

export const fetchUsersListEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_USERS_LIST_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/admin/get-users?sortParam=VERIFIED&page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}&searchValue=${action.payload.myKeyword}`
        )
      ).pipe(
        mergeMap((res) =>
          of({ type: "FETCH_USERS_LIST_SUCCESS", payload: res.data })
        ),
        catchError((error) =>
          of({ type: "FETCH_USERS_LIST_FAILURE", payload: error.response })
        )
      )
    )
  );
// // fetch users by Id for description

export const fetchUserByIdEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_USER_BY_ID_REQUEST"),
    mergeMap((action) =>
      from(API.get(`/admin/get-user-by-id/${action.payload}`)).pipe(
        // tap(res=> console.log("The fetched category response",res.data)),
        mergeMap((res) =>
          of({ type: "FETCH_USER_BY_ID_SUCCESS", payload: res.data })
        ),
        catchError((error) =>
          of({ type: "FETCH_USER_BY_ID_FAILURE", payload: error.response })
        )
      )
    )
  );

// // // Enable User
export const enableUsersEpic = (action$) =>
  action$.pipe(
    ofType("ENABLE_USERS_REQUEST"),
    mergeMap((action) =>
      from(API.put("/admin/enable-users", action.payload)).pipe(
        mergeMap((response) =>
          of({ type: "ENABLE_USERS_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "ENABLE_USERS_FAILURE", payload: error.response })
        )
      )
    )
  );
// // // Disable user
export const disableUsersEpic = (action$) =>
  action$.pipe(
    ofType("DISABLE_USERS_REQUEST"),
    mergeMap((action) =>
      from(API.put("/admin/disable-users", action.payload)).pipe(
        mergeMap((response) =>
          of({ type: "DISABLE_USERS_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "DISABLE_USERS_FAILURE", payload: error.response })
        )
      )
    )
  );
// // fetch coupon code
export const fetchCouponEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_COUPON_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/coupon-code/admin/list-all?page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}&sortParam=${action.payload.sortingParam}`
        )
      ).pipe(
        // tap(res=> console.log("The fetched category response",res.data)),
        mergeMap((res) =>
          of({ type: "FETCH_COUPON_SUCCESS", payload: res.data })
        ),
        catchError((error) =>
          of({ type: "FETCH_COUPON_FAILURE", payload: error.response })
        )
      )
    )
  );
// // Add Coupon
export const addCouponEpic = (action$) =>
  action$.pipe(
    ofType("POST_COUPON_REQUEST"),
    mergeMap((action) =>
      from(API.post("/coupon-code/admin/add", action.payload)).pipe(
        mergeMap((res) =>
          of({ type: "POST_COUPON_SUCCESS", payload: res.data })
        ),
        catchError((error) =>
          of({ type: "POST_COUPON_FAILURE", payload: error.response })
        )
      )
    )
  );
// Update Coupon
export const updateCouponEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_COUPON_REQUEST"),
    mergeMap((action) =>
      from(API.put("/coupon-code/admin/update", action.payload)).pipe(
        mergeMap((res) =>
          of({ type: "UPDATE_COUPON_SUCCESS", payload: res.data })
        ),
        catchError((error) =>
          of({ type: "UPDATE_COUPON_FAILURE", payload: error.response })
        )
      )
    )
  );
// Activate Coupon
export const activivateCouponEpic = (action$) =>
  action$.pipe(
    ofType("ACTIVATE_COUPON_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(`/coupon-code/admin/activate/${action.payload.enableDisableId}`)
      ).pipe(
        // tap(res=> console.log("The fetched category response",res.data)),
        mergeMap((res) =>
          of({ type: "ACTIVATE_COUPON_SUCCESS", payload: res.data })
        ),
        catchError((error) =>
          of({ type: "ACTIVATE_COUPON_FAILURE", payload: error.response })
        )
      )
    )
  );
// Deactivate Coupon
export const deActivivateCouponEpic = (action$) =>
  action$.pipe(
    ofType("DEACTIVATE_COUPON_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/coupon-code/admin/deactivate/${action.payload.enableDisableId}`
        )
      ).pipe(
        mergeMap((res) =>
          of({ type: "DEACTIVATE_COUPON_SUCCESS", payload: res.data })
        ),
        catchError((error) =>
          of({ type: "DEACTIVATE_COUPON_FAILURE", payload: error.response })
        )
      )
    )
  );
// Fetch Restaurant Data
export const fetchRestaurantsEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_RESTAURANTS_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/admin/restaurant/get-all?page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}&sortParameter=${action.payload.sortingParam}`
        )
      ).pipe(
        // tap(res=> console.log("The fetched category response",res.data)),
        mergeMap((res) =>
          of({ type: "FETCH_RESTAURANTS_SUCCESS", payload: res.data })
        ),
        catchError((error) =>
          of({ type: "FETCH_RESTAURANTS_FAILURE", payload: error.response })
        )
      )
    )
  );
// Add Restaurant Data
export const addRestaurantEpic = (action$) =>
  action$.pipe(
    ofType("ADD_RESTAURANTS_REQUEST"),
    mergeMap((action) =>
      from(API.post("/admin/restaurant/add", action.payload)).pipe(
        // tap(res=> console.log("The fetched category response",res.data)),
        mergeMap((res) =>
          of({ type: "ADD_RESTAURANTS_SUCCESS", payload: res.data })
        ),
        catchError((error) =>
          of({ type: "ADD_RESTAURANTS_FAILURE", payload: error.response })
        )
      )
    )
  );
// // Add Restaurant Data without csv file
// export const addRestaurantWoutCsvEpic = action$ => action$.pipe(
//     ofType("ADD_RESTAURANTS_WIOUT_CSV_REQUEST_REQUEST"),
//     mergeMap(action =>
//     from(API.post('/admin/restaurant/add',action.payload)).pipe(
//         // tap(res=> console.log("The fetched category response",res.data)),
//         mergeMap(res=> of({type: "ADD_RESTAURANTS_WIOUT_CSV_REQUEST_SUCCESS",
//         payload: res.data})),
//         catchError(error=> of({type: "ADD_RESTAURANTS_WIOUT_CSV_REQUEST_FAILURE",
//         payload: error.response
//         }))
//     ))
// )
// Update Restuarent
export const updateRestaurantEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_RESTAURANTS_REQUEST"),
    mergeMap((action) =>
      from(API.post("/admin/restaurant/edit", action.payload)).pipe(
        mergeMap((res) =>
          of({ type: "UPDATE_RESTAURANTS_SUCCESS", payload: res.data })
        ),
        catchError((error) =>
          of({ type: "UPDATE_RESTAURANTS_FAILURE", payload: error.response })
        )
      )
    )
  );
// Activate Restaurant
export const activivateRestaurantEpic = (action$) =>
  action$.pipe(
    ofType("ACTIVATE_RESTAURANT_REQUEST"),
    mergeMap((action) =>
      from(API.put("/admin/restaurant/activate", action.payload)).pipe(
        // tap(res=> console.log("The fetched category response",res.data)),
        mergeMap((res) =>
          of({ type: "ACTIVATE_RESTAURANT_SUCCESS", payload: res.data })
        ),
        catchError((error) =>
          of({ type: "ACTIVATE_RESTAURANT_FAILURE", payload: error.response })
        )
      )
    )
  );
// Deactivate Restaurant
export const deActivivateRestaurantEpic = (action$) =>
  action$.pipe(
    ofType("DEACTIVATE_RESTAURANT_REQUEST"),
    mergeMap((action) =>
      from(API.put("/admin/restaurant/deactivate", action.payload)).pipe(
        mergeMap((res) =>
          of({ type: "DEACTIVATE_RESTAURANT_SUCCESS", payload: res.data })
        ),
        catchError((error) =>
          of({ type: "DEACTIVATE_RESTAURANT_FAILURE", payload: error.response })
        )
      )
    )
  );
// Delete Restaurant
export const deleteRestaurantEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_RESTAURANT_REQUEST"),
    mergeMap((action) =>
      from(API.delete("/admin/restaurant/delete", action.payload)).pipe(
        mergeMap((res) =>
          of({ type: "DELETE_RESTAURANT_SUCCESS", payload: res.data })
        ),
        catchError((error) =>
          of({ type: "DELETE_RESTAURANT_FAILURE", payload: error.response })
        )
      )
    )
  );
// Fetch Referral and Loyalty
export const fetchReferalEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_REFERAL_LOYALTY_REQUEST"),
    mergeMap((action) =>
      from(API.get("/admin/get-info/referal-loyalty")).pipe(
        mergeMap((res) =>
          of({ type: "FETCH_REFERAL_LOYALTY_SUCCESS", payload: res.data })
        ),
        catchError((error) =>
          of({ type: "FETCH_REFERAL_LOYALTY_FAILURE", payload: error.response })
        )
      )
    )
  );
// Add Referral and Loyalty
export const postReferalEpic = (action$) =>
  action$.pipe(
    ofType("POST_REFERAL_LOYALTY_REQUEST"),
    mergeMap((action) =>
      from(API.post("/admin/set-key-value", action.payload)).pipe(
        mergeMap((res) =>
          of({ type: "POST_REFERAL_LOYALTY_SUCCESS", payload: res.data })
        ),
        catchError((error) =>
          of({ type: "POST_REFERAL_LOYALTY_FAILURE", payload: error.response })
        )
      )
    )
  );

// fetch advertisement data
export const fetchAdvertisementEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_ADVERTISEMENT_REQUEST"),
    mergeMap((action) =>
      from(API.get("/admin/advertisement/get-all")).pipe(
        // tap(response=> console.log("The response after fetching advertisement",response)),
        mergeMap((response) =>
          of({ type: "FETCH_ADVERTISEMENT_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "FETCH_ADVERTISEMENT_FAILURE", payload: error.response })
        )
      )
    )
  );
// fetch internal type advertisement
export const fetchInternalAdsLinkEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_INTERNAL_ADS_LINK_REQUEST"),
    mergeMap((action) =>
      from(API.get("/admin/advertisement/get-available-links")).pipe(
        // tap(response=> console.log("The response after fetching advertisement",response)),
        mergeMap((response) =>
          of({
            type: "FETCH_INTERNAL_ADS_LINK_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "FETCH_INTERNAL_ADS_LINK_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// Posting advertisement data
export const postAdvertisementEpic = (action$) =>
  action$.pipe(
    ofType("POST_ADVERTISEMENT_REQUEST"),
    mergeMap((action) =>
      from(API.post("/admin/advertisement/add", action.payload)).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "POST_ADVERTISEMENT_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "POST_ADVERTISEMENT_FAILURE", payload: error.response })
        )
      )
    )
  );
// Enable adds
export const enablePromotionEpic = (action$) =>
  action$.pipe(
    ofType("ENABLE_PROMOTION_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/admin/advertisement/activate/${action.payload.enableDisableId}`
        )
      ).pipe(
        mergeMap((response) =>
          of({ type: "ENABLE_PROMOTION_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "ENABLE_PROMOTION_FAILURE", payload: error.response })
        )
      )
    )
  );
// // // Disable adds
export const disablePromotionEpic = (action$) =>
  action$.pipe(
    ofType("DISABLE_PROMOTION_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/admin/advertisement/deactivate/${action.payload.enableDisableId}`
        )
      ).pipe(
        mergeMap((response) =>
          of({ type: "DISABLE_PROMOTION_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "DISABLE_PROMOTION_FAILURE", payload: error.response })
        )
      )
    )
  );
// Updating advertisement data
export const updateAdvertisementEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_ADVERTISEMENT_REQUEST"),
    mergeMap((action) =>
      from(API.put("/admin/advertisement/update", action.payload)).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "UPDATE_ADVERTISEMENT_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "UPDATE_ADVERTISEMENT_FAILURE", payload: error.response })
        )
      )
    )
  );

// Fetch unverified wallet transactions
export const fetchWalletDataEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_WALLET_DATA_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/wallet/get-unverified-transactions?page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}`
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "FETCH_WALLET_DATA_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "FETCH_WALLET_DATA_FAILURE", payload: error.response })
        )
      )
    )
  );
// search unverified wallet transactions
export const searchWalletDataEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_WALLET_DATA_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/wallet/get-unverified-transactions?page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}&searchParam=${action.payload.searchParam}`
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "SEARCH_WALLET_DATA_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "SEARCH_WALLET_DATA_FAILURE", payload: error.response })
        )
      )
    )
  );
// Fetch verified wallet transactions
export const fetchVerifiedWalletDataEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_VERIFIED_WALLET_DATA_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/wallet/get-verified-transactions?page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}`
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({
            type: "FETCH_VERIFIED_WALLET_DATA_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "FETCH_VERIFIED_WALLET_DATA_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// search verified wallet transactions
export const searchVerifiedWalletDataEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_VERIFIED_WALLET_DATA_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/wallet/get-verified-transactions?page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}&searchParam=${action.payload.searchParam}`
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({
            type: "SEARCH_VERIFIED_WALLET_DATA_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "SEARCH_VERIFIED_WALLET_DATA_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// verify wallet transaction
export const verifyTransactionEpic = (action$) =>
  action$.pipe(
    ofType("VERIFY_TRANSACTION_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/wallet/verify/unverified-transactions/${action.payload.transactionId}`
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "VERIFY_TRANSACTION_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "VERIFY_TRANSACTION_FAILURE", payload: error.response })
        )
      )
    )
  );
// Fetch Service Rate
export const fetchServiceRateEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_SERVICE_RATE_REQUEST"),
    mergeMap((action) =>
      from(API.get("/admin/get-service-charge")).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "FETCH_SERVICE_RATE_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "FETCH_SERVICE_RATE_FAILURE", payload: error.response })
        )
      )
    )
  );
// add update Service Rate
export const addServiceRateEpic = (action$) =>
  action$.pipe(
    ofType("POST_SERVICE_RATE_REQUEST"),
    mergeMap((action) =>
      from(API.post("/admin/add-service-charge", action.payload)).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "POST_SERVICE_RATE_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "POST_SERVICE_RATE_FAILURE", payload: error.response })
        )
      )
    )
  );
// get order list acc. to order status
export const fetchOrderListByStatusEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_ORDER_LIST_BY_STATUS_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/admin/get-order-list?page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}&orderStatus=${action.payload.orderStatus}&displayPhoneOrderOnly=false&operatingLocation=${action.payload.opLocation}`
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({
            type: "FETCH_ORDER_LIST_BY_STATUS_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "FETCH_ORDER_LIST_BY_STATUS_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// // get order list acc. to order status
// export const fetchOrderListByStatusEpic = action$ => action$.pipe(
//     ofType("FETCH_ORDER_LIST_BY_STATUS_REQUEST"),
//     mergeMap(action=>
//     from(API.get(`/admin/get-order-list?page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}&orderStatus=${action.payload.orderStatus}`)).pipe(
//         // tap(response=> console.log("The response after posting advertisement",response)),
//         mergeMap((response)=> of({type: "FETCH_ORDER_LIST_BY_STATUS_SUCCESS",
//         payload: response.data})),
//         catchError(error=> of({type: "FETCH_ORDER_LIST_BY_STATUS_FAILURE",
//         payload: error.response}))
//     ))
// )
// fetch order list from order
export const fetchOrderListEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_ORDER_LIST_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/admin/get-order-list?page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}&orderStatus=${action.payload.orderStatus}&searchWord=${action.payload.searchKeyWord}&operatingLocation=${action.payload.opLocation}`
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "FETCH_ORDER_LIST_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "FETCH_ORDER_LIST_FAILURE", payload: error.response })
        )
      )
    )
  );
// fetch PHONE ORDER LIST
export const fetchPhoneOrderListEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_PHONE_ORDER_LIST_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/admin/get-order-list?page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}&orderStatus=${action.payload.orderStatus}&displayPhoneOrderOnly=true&searchWord=${action.payload.searchKeyWord}&operatingLocation=${action.payload.opLocation}`
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "FETCH_PHONE_ORDER_LIST_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({
            type: "FETCH_PHONE_ORDER_LIST_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

// search fetch campaign order
export const fetchCampaignOrderEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_CAMPAIGN_ORDER_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/admin/campaign/list-orders?campaignId=${action.payload.campaignId}&page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}&orderStatus=${action.payload.orderStatus}&sort=${action.payload.sortingParam}&createdOn=${action.payload.finalPreorderDate}`
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "FETCH_CAMPAIGN_ORDER_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "FETCH_CAMPAIGN_ORDER_FAILURE", payload: error.response })
        )
      )
    )
  );
// search order list by order Id
export const searchOderListEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_ORDER_LIST_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/admin/get-order-list?page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}&orderStatus=${action.payload.orderStatus}&searchWord=${action.payload.searchKeyWord}`
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "SEARCH_ORDER_LIST_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "SEARCH_ORDER_LIST_FAILURE", payload: error.response })
        )
      )
    )
  );
// get order details by Id
export const orderDetailsEpic = (action$) =>
  action$.pipe(
    ofType("ORDER_DETAILS_REQUEST"),
    mergeMap((action) =>
      from(API.get(`/admin/order-by-id/${action.payload}`)).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "ORDER_DETAILS_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "ORDER_DETAILS_FAILURE", payload: error.response })
        )
      )
    )
  );
// accept order
export const acceptOrderEpic = (action$) =>
  action$.pipe(
    ofType("ACCEPT_ORDER_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(`/admin/accept-order/${action.payload.id}`, action.payload.data)
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "ACCEPT_ORDER_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "ACCEPT_ORDER_FAILURE", payload: error.response })
        )
      )
    )
  );
// Cancel order
export const cancelOrderEpic = (action$) =>
  action$.pipe(
    ofType("CANCEL_ORDER_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/admin/cancel-order/${action.payload.id}`,
          action.payload.values
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "CANCEL_ORDER_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "CANCEL_ORDER_FAILURE", payload: error.response })
        )
      )
    )
  );
// fetch active delivery boys list
export const fetchActiveDeliveryEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_ACTIVE_DELIVERY_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/admin/delivery-users/active-list?page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}`
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "FETCH_ACTIVE_DELIVERY_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "FETCH_ACTIVE_DELIVERY_FAILURE", payload: error.response })
        )
      )
    )
  );
// fetch delivery peops information
export const fetchDeliveryEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_DELIVERY_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/admin/delivery-users/list?page=${
            action.payload.currentPage
          }&pageSize=${action.payload.postsPerPage}&searchParam=${
            action.payload.normalSearchParam
              ? action.payload.normalSearchParam
              : ""
          }`
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "FETCH_DELIVERY_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "FETCH_DELIVERY_FAILURE", payload: error.response })
        )
      )
    )
  );
// fetch delivery draft data
export const fetchDeliveryDrafEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_DELIVERY_DRAFT_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/delivery-user/get-driver-draft?page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}&searchParam=${action.payload.normalSearchParam}`
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "FETCH_DELIVERY_DRAFT_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "FETCH_DELIVERY_DRAFT_FAILURE", payload: error.response })
        )
      )
    )
  );
// accept delivery draft
export const acceptDeliveryDraftEpic = (action$) =>
  action$.pipe(
    ofType("ACCEPT_DELIVER_DRAFT_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/delivery-user/mark-driver-draft-as-delivery-user/${action.payload.id}`,
          { confirmationText: "Ok" }
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "ACCEPT_DELIVER_DRAFT_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "ACCEPT_DELIVER_DRAFT_FAILURE", payload: error.response })
        )
      )
    )
  );
// Add Delivery person
export const addDeliveryEpic = (action$) =>
  action$.pipe(
    ofType("ADD_DELIVERY_REQUEST"),
    mergeMap((action) =>
      from(API.post("/admin/add-delivery-user", action.payload)).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "ADD_DELIVERY_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "ADD_DELIVERY_FAILURE", payload: error.response })
        )
      )
    )
  );
export const addDeliveryMoreInfoEpic = (action$) =>
  action$.pipe(
    ofType("ADD_DELIVERY_MORE_INFO_REQUEST"),
    mergeMap((action) =>
      from(API.put("/delivery-user/driver-info-by-admin", action.payload)).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "ADD_DELIVERY_MORE_INFO_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({
            type: "ADD_DELIVERY_MORE_INFO_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// Update Delivery
export const updateDeliveryEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_DELIVERY_REQUEST"),
    mergeMap((action) =>
      from(API.post("/admin/update-delivery-profile", action.payload)).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "UPDATE_DELIVERY_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "UPDATE_DELIVERY_FAILURE", payload: error.response })
        )
      )
    )
  );
// Active delivery User
export const activateDeliveryEpic = (action$) =>
  action$.pipe(
    ofType("ACTIVATE_DELIVERY_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/admin/delivery-users/activate/${action.payload.enableDisableId}`,
          action.payload.reasonForEnableDisable
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "ACTIVATE_DELIVERY_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "ACTIVATE_DELIVERY_FAILURE", payload: error.response })
        )
      )
    )
  );
// De activate delivery user
export const deactivateDeliveryEpic = (action$) =>
  action$.pipe(
    ofType("DE_ACTIVATE_DELIVERY_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/admin/delivery-users/deactivate/${action.payload.enableDisableId}`,
          action.payload.reasonForEnableDisable
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "DE_ACTIVATE_DELIVERY_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "DE_ACTIVATE_DELIVERY_FAILURE", payload: error.response })
        )
      )
    )
  );
// Assign order to delivery boy
export const assignOrderToDEpic = (action$) =>
  action$.pipe(
    ofType("ASSIGN_ORDER_T_DELIVERY_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/admin/assign-delivery-order/${action.payload.id}`,
          action.payload.values
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({
            type: "ASSIGN_ORDER_T_DELIVERY_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "ASSIGN_ORDER_T_DELIVERY_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// Assign order to delivery boy
export const assignOrderToMultipleDEpic = (action$) =>
  action$.pipe(
    ofType("ASSIGN_ORDER_T_MULTIPLE_DELIVERY_REQUEST"),
    mergeMap((action) =>
      from(
        API.post(`/admin/send-mass-notification`, action.payload.values)
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({
            type: "ASSIGN_ORDER_T_MULTIPLE_DELIVERY_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "ASSIGN_ORDER_T_MULTIPLE_DELIVERY_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// Delete Delivery Boy
export const deleteDeliveryBoyEpic = (action$) =>
  action$.pipe(
    ofType("DELETE_DELIVERY_REQUEST"),
    mergeMap((action) =>
      from(
        API.delete(
          `/admin/delete-delivery-users/${action.payload.id}`,
          action.payload.values
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "DELETE_DELIVERY_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "DELETE_DELIVERY_FAILURE", payload: error.response })
        )
      )
    )
  );
// get delivery details by id
export const fetchDeliveryDetailsByIdEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_DELIVERY_DETAILS_REQUEST"),
    mergeMap((action) =>
      from(API.get(`/admin/get-delivery-users/${action.payload.id}`)).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "FETCH_DELIVERY_DETAILS_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({
            type: "FETCH_DELIVERY_DETAILS_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// receive order from delivery
export const receiveOrderEpic = (action$) =>
  action$.pipe(
    ofType("RECEIVE_ORDER_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/admin/receive-delivery-order/${action.payload.id}`,
          action.payload.values
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "RECEIVE_ORDER_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "RECEIVE_ORDER_FAILURE", payload: error.response })
        )
      )
    )
  );
// reached delivery order destination
export const reachedDeliveryDestinationEpic = (action$) =>
  action$.pipe(
    ofType("REACHED_DELIVERY_DESTINATION_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/admin/delivery-order-reached/${action.payload.id}`,
          action.payload.values
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({
            type: "REACHED_DELIVERY_DESTINATION_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "REACHED_DELIVERY_DESTINATION_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// Deliver Completed
export const deliveryCompletedEpic = (action$) =>
  action$.pipe(
    ofType("DELIVERY_COMPLETED_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/admin/delivery-order-completed/${action.payload.id}`,
          action.payload.values
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "DELIVERY_COMPLETED_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "DELIVERY_COMPLETED_FAILURE", payload: error.response })
        )
      )
    )
  );

// Accept Payment of order

export const acceptPaymentOfOrderEpic = (action$) =>
  action$.pipe(
    ofType("ACCEPT_PAYMENT_OF_ORDER_REQUEST"),
    mergeMap((action) =>
      from(API.post("/admin/accept-payment", action.payload)).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({
            type: "ACCEPT_PAYMENT_OF_ORDER_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "ACCEPT_PAYMENT_OF_ORDER_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// Reject Payment of order

export const rejectPaymentOfOrderEpic = (action$) =>
  action$.pipe(
    ofType("REJECT_PAYMENT_OF_ORDER_REQUEST"),
    mergeMap((action) =>
      from(API.post("/admin/reject-payment", action.payload)).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({
            type: "REJECT_PAYMENT_OF_ORDER_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "REJECT_PAYMENT_OF_ORDER_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// Notification list

export const fetchNotificationListEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_NOTIFICATION_LIST_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/admin/get-mass-notification-list?pageNumber=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}`
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({
            type: "FETCH_NOTIFICATION_LIST_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "FETCH_NOTIFICATION_LIST_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// Approve order from admin
export const approveOrderEpic = (action$) =>
  action$.pipe(
    ofType("APPROVE_ORDER_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/admin/approve-order-by-admin/${action.payload.id}`,
          action.payload.values
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "APPROVE_ORDER_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "APPROVE_ORDER_FAILURE", payload: error.response })
        )
      )
    )
  );
// set Order Ready
export const setOrderReadyEpic = (action$) =>
  action$.pipe(
    ofType("SET_ORDER_READY_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/admin/mark-order-as-ready/${action.payload.id}`,
          action.payload.values
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "SET_ORDER_READY_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "SET_ORDER_READY_FAILURE", payload: error.response })
        )
      )
    )
  );
// fetch Exchange Referal and loyality point
export const fetchExchangePointEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_EXCHANGE_REFERAL_REQUEST"),
    mergeMap((action) =>
      from(API.get("/admin/loyalty-points/get")).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "FETCH_EXCHANGE_REFERAL_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({
            type: "FETCH_EXCHANGE_REFERAL_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// post Exchange Referal and loyality point
export const postExchangePointEpic = (action$) =>
  action$.pipe(
    ofType("POST_EXCHANGE_REFERAL_REQUEST"),
    mergeMap((action) =>
      from(API.post("/admin/loyalty-points/set", action.payload)).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "POST_EXCHANGE_REFERAL_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "POST_EXCHANGE_REFERAL_FAILURE", payload: error.response })
        )
      )
    )
  );
// get user announcement
export const fetchUserAnnouncemnetEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_USER_ANNOUNCEMENT_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/admin/list-announcement?page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}`
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({
            type: "FETCH_USER_ANNOUNCEMENT_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "FETCH_USER_ANNOUNCEMENT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// post announcement
export const postUserAnnouncemnetEpic = (action$) =>
  action$.pipe(
    ofType("POST_USER_ANNOUNCEMENT_REQUEST"),
    mergeMap((action) =>
      from(
        API.post("/admin/add-delivery-user-announcement", action.payload)
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "POST_USER_ANNOUNCEMENT_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({
            type: "POST_USER_ANNOUNCEMENT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// update announcement
export const updateUserAnnouncemnetEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_USER_ANNOUNCEMENT_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/admin/update-delivery-user-announcement/${action.payload.id}`,
          action.payload.value
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({
            type: "UPDATE_USER_ANNOUNCEMENT_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "UPDATE_USER_ANNOUNCEMENT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// activate announcement
export const activateAnnouncemnetEpic = (action$) =>
  action$.pipe(
    ofType("ACTIVATE_ANNOUNCEMENT_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(`/admin/announcment/activate/${action.payload.enableDisableId}`)
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "ACTIVATE_ANNOUNCEMENT_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "ACTIVATE_ANNOUNCEMENT_FAILURE", payload: error.response })
        )
      )
    )
  );
// de-activate announcement
export const de_activateAnnouncemnetEpic = (action$) =>
  action$.pipe(
    ofType("DE_ACTIVATE_ANNOUNCEMENT_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/admin/announcment/deactivate/${action.payload.enableDisableId}`
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({
            type: "DE_ACTIVATE_ANNOUNCEMENT_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "DE_ACTIVATE_ANNOUNCEMENT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// get delivery fee
export const fetchDeliveryFeeEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_DELIVERY_FEE_REQUEST"),
    mergeMap((action) =>
      from(API.get("/admin/get-delivery-charge")).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "FETCH_DELIVERY_FEE_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "FETCH_DELIVERY_FEE_FAILURE", payload: error.response })
        )
      )
    )
  );
// set Delivery Fee
export const postDeliveryFeeEpic = (action$) =>
  action$.pipe(
    ofType("SET_DELIVERY_FEE_REQUEST"),
    mergeMap((action) =>
      from(API.post("/admin/set-delivery-charge", action.payload)).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "SET_DELIVERY_FEE_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "SET_DELIVERY_FEE_FAILURE", payload: error.response })
        )
      )
    )
  );
// get delivery commission fee
export const fetchDeliveryCommissionFeeEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_DELIVERY_COMMISSION_FEE_REQUEST"),
    mergeMap((action) =>
      from(API.get("/admin/get-delivery-commission")).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({
            type: "FETCH_DELIVERY_COMMISSION_FEE_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "FETCH_DELIVERY_COMMISSION_FEE_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// set Delivery commission Fee
export const postDeliveryCommissionFeeEpic = (action$) =>
  action$.pipe(
    ofType("SET_DELIVERY_COMMISSION_FEE_REQUEST"),
    mergeMap((action) =>
      from(API.post("/admin/set-delivery-commission", action.payload)).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({
            type: "SET_DELIVERY_COMMISSION_FEE_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "SET_DELIVERY_COMMISSION_FEE_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// search restaurant by name
export const searchRestaurantEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_RESTAURANT_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/admin/restaurant/search-restaurant?searchParam=${action.payload.resturantName}&page=1&pageSize=20`
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "SEARCH_RESTAURANT_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "SEARCH_RESTAURANT_FAILURE", payload: error.response })
        )
      )
    )
  );
// search restaurant
export const searchRestaurantNameEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_RESNAME_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/admin/restaurant/search-restaurant?searchParam=${action.payload.searchWord}&page=1&pageSize=20`
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "SEARCH_RESNAME_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "SEARCH_RESNAME_FAILURE", payload: error.response })
        )
      )
    )
  );
// setttlement module
export const settlementEpic = (action$) =>
  action$.pipe(
    ofType("SETTLEMENT_DATA_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/admin/get-settlement-list?startDate=${action.payload.startDate}&endDate=${action.payload.endDate}&page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}&settlementState=${action.payload.settlementState}`
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "SETTLEMENT_DATA_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "SETTLEMENT_DATA_FAILURE", payload: error.response })
        )
      )
    )
  );
// change settlement status
export const chnageSettlementStatusEpic = (action$) =>
  action$.pipe(
    ofType("CHANGE_SETTLEMENT_STATUS_REQUEST"),
    mergeMap((action) =>
      from(API.put(`/admin/change-settlement-state`, action.payload)).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({
            type: "CHANGE_SETTLEMENT_STATUS_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "CHANGE_SETTLEMENT_STATUS_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// export to excell
export const exportToExcellEpic = (action$) =>
  action$.pipe(
    ofType("EXPORT_TO_EXCELL_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/admin/export/xlxs-settlement?startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "EXPORT_TO_EXCELL_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "EXPORT_TO_EXCELL_FAILURE", payload: error.response })
        )
      )
    )
  );
// Phone order Section
export const searchCustomerByPhN = (action$) =>
  action$.pipe(
    ofType("SEARCH_CUSTOMER_BY_PHN_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/admin/restaurant/phone-order/search-user-by-phone-number?phoneNumber=${action.payload.phoneNumber}`
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "SEARCH_CUSTOMER_BY_PHN_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({
            type: "SEARCH_CUSTOMER_BY_PHN_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// Add new customer
export const addNewCutomerEpic = (action$) =>
  action$.pipe(
    ofType("ADD_NEW_CUSTOMER_REQUEST"),
    mergeMap((action) =>
      from(
        API.post(
          `/admin/restaurant/phone-order/create-new-user`,
          action.payload
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "ADD_NEW_CUSTOMER_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "ADD_NEW_CUSTOMER_FAILURE", payload: error.response })
        )
      )
    )
  );
// fetch activ-restaurants
export const fetchActivRestaurantListEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_RESTAURANT_LIST_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/admin/restaurant/search-active-restaurant?searchParam=${action.payload.keyWord}&page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}&operatingLocation=${action.payload.operatingLoc}`
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "FETCH_RESTAURANT_LIST_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "FETCH_RESTAURANT_LIST_FAILURE", payload: error.response })
        )
      )
    )
  );
// fetch actve menu list
export const fetchActivMenuListEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_MENU_ITEMS_BY_RES_ID_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/admin/restaurant/get-active-menu-of-restaurant/${action.payload.restaurantId}?searchParam=${action.payload.searchKeyWord}&page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}`
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({
            type: "FETCH_MENU_ITEMS_BY_RES_ID_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "FETCH_MENU_ITEMS_BY_RES_ID_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// fetch menu items without restaurant id
export const fetchMenuItemWithoutResIdEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_MENU_ITEMS_WITHOUT_RES_ID_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/admin/restaurant/phone-order/get-or-search-menu/?searchParam=${action.payload.menuKeyword}&operatingLocation=${action.payload.operatingLoc}`
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({
            type: "FETCH_MENU_ITEMS_WITHOUT_RES_ID_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "FETCH_MENU_ITEMS_WITHOUT_RES_ID_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// add items to cart
export const addItemToCartEpic = (action$) =>
  action$.pipe(
    ofType("ADD_ITEMS_TO_CART_REQUEST"),
    mergeMap((action) =>
      from(
        API.post("/admin/restaurant/phone-order/add-cart", action.payload)
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "ADD_ITEMS_TO_CART_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "ADD_ITEMS_TO_CART_FAILURE", payload: error.response })
        )
      )
    )
  );
// add delivery information
export const addDeliveryInfoEpic = (action$) =>
  action$.pipe(
    ofType("ADD_DELIVERY_INFO_BEFOR_ADD_TO_CART_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/user/cart/set-delivery-address-for-cart/${action.payload.cartId}`,
          action.payload.deliveryInfo
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({
            type: "ADD_DELIVERY_INFO_BEFOR_ADD_TO_CART_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "ADD_DELIVERY_INFO_BEFOR_ADD_TO_CART_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// place order from phone order
export const placeOrderFromPhoneOrderEpic = (action$) =>
  action$.pipe(
    ofType("PLACE_ORDER_FROM_PHONEORDER_REQUEST"),
    mergeMap((action) =>
      from(
        API.post("/admin/restaurant/phone-order/checkout-cart", action.payload)
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({
            type: "PLACE_ORDER_FROM_PHONEORDER_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "PLACE_ORDER_FROM_PHONEORDER_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// get operating location
export const getOperatingLocationEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_OPERATING_LOCATION_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/admin/operating-location/get-all?page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}`
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({
            type: "FETCH_OPERATING_LOCATION_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "FETCH_OPERATING_LOCATION_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// get active operating location
export const getActiveOperatingLocationEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_ACTIVE_OPERATING_LOCATION_REQUEST"),
    mergeMap((action) =>
      from(API.get(`/admin/operating-location/get-active`)).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({
            type: "FETCH_ACTIVE_OPERATING_LOCATION_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "FETCH_ACTIVE_OPERATING_LOCATION_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// add operating location
export const addOperatingLocationEpic = (action$) =>
  action$.pipe(
    ofType("ADD_OPERATING_LOCATION_REQUEST"),
    mergeMap((action) =>
      from(API.post(`/admin/operating-location/add`, action.payload)).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "ADD_OPERATING_LOCATION_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({
            type: "ADD_OPERATING_LOCATION_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// edit operating location
export const editOperatingLocationEpic = (action$) =>
  action$.pipe(
    ofType("EDIT_OPERATING_LOCATION_REQUEST"),
    mergeMap((action) =>
      from(API.post(`/admin/operating-location/edit`, action.payload)).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({
            type: "EDIT_OPERATING_LOCATION_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "EDIT_OPERATING_LOCATION_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// mark restaurant popular
export const markRestaurnatPopularEpic = (action$) =>
  action$.pipe(
    ofType("MARK_RESTAURANT_POPULAR_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/admin/restaurant/mark-restaurant-as-popular/${action.payload.id}`
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({
            type: "MARK_RESTAURANT_POPULAR_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "MARK_RESTAURANT_POPULAR_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// mark restaurant not-popular
export const markRestaurantNotPopularEpic = (action$) =>
  action$.pipe(
    ofType("UN_MARK_RESTAURANT_AS_POPULAR_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/admin/restaurant/remove-restaurant-from-popular/${action.payload.id}`
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({
            type: "UN_MARK_RESTAURANT_AS_POPULAR_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "UN_MARK_RESTAURANT_AS_POPULAR_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// update order cart item from order module
export const updateOrderCartItemEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_ORDER_CART_ITEM_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/admin/update-order/${action.payload.orderId}`,
          action.payload.itemList
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "UPDATE_ORDER_CART_ITEM_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({
            type: "UPDATE_ORDER_CART_ITEM_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// get all pre-order list
export const getAllPreOrderListEpic = (action$) =>
  action$.pipe(
    ofType("GET_ALL_PREORDER_LIST_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/admin/pre-order/pre-order/list?page=${
            action.payload.currentPage
          }&pageSize=${action.payload.postsPerPage}&searchParam=${
            action.payload.keyWord ? action.payload.keyWord : ""
          }`
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "GET_ALL_PREORDER_LIST_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "GET_ALL_PREORDER_LIST_FAILURE", payload: error.response })
        )
      )
    )
  );
// get requested pre-order list
export const getRequestedPreOrderListEpic = (action$) =>
  action$.pipe(
    ofType("GET_REQUESTED_PREORDER_LIST_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/admin/pre-order/get-list-by-id?preOrderDataId=${action.payload.preOrderId}&page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}&sort=${action.payload.sortingParam}&deliveryTime=${action.payload.deliveryTime}&timeSlotId=${action.payload.selectedPreOrderTimeSlot}&orderState=${action.payload.orderState}`
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({
            type: "GET_REQUESTED_PREORDER_LIST_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_REQUESTED_PREORDER_LIST_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const getRequestedPreOrderListEpic1 = (action$) =>
  action$.pipe(
    ofType("GET_REQUESTED_PREORDER_LIST_REQUEST1"),
    mergeMap((action) =>
      from(
        API.get(
          `/admin/pre-order/get-list-by-id?preOrderDataId=${action.payload.preOrderId}&page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}&sort=${action.payload.sortingParam}&searchParam=${action.payload.myKeyword}`
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({
            type: "GET_REQUESTED_PREORDER_LIST_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_REQUESTED_PREORDER_LIST_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

export const getRequestedPreOrderListInBatchEpic = (action$) =>
  action$.pipe(
    ofType("GET_REQUESTED_PREORDER_LIST_BATCH_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/admin/batch-package/fetch-unassigned-batch/${action.payload.preOrderId}?listType=PRE_ORDER&page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}&sort=${action.payload.sortingParam}&deliveryTime=${action.payload.deliveryTime}&timeSlotId=${action.payload.selectedPreOrderTimeSlot}`
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({
            type: "GET_REQUESTED_PREORDER_LIST_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "GET_REQUESTED_PREORDER_LIST_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// // get requested pre-order list
// export const getOrderPreorderListEpic = action$ => action$.pipe(
//     ofType("GET_REQUESTED_PREORDER_BATCH_REQUEST"),
//     mergeMap(action=>
//     from(API.get(`/admin/pre-order/get-list-by-id?page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}&sort=${action.payload.sortingParam}`)).pipe(
//         // tap(response=> console.log("The response after posting advertisement",response)),
//         mergeMap((response)=> of({type: "GET_REQUESTED_PREORDER_BATCH_SUCCESS",
//         payload: response.data})),
//         catchError(error=> of({type: "GET_REQUESTED_PREORDER_BATCH_FAILURE",
//         payload: error.response}))
//     ))
// )
// add pre order
export const addPreOrderEpic = (action$) =>
  action$.pipe(
    ofType("ADD_PRE_ORDER_REQUEST"),
    mergeMap((action) =>
      from(API.post(`/admin/pre-order/pre-order/add`, action.payload)).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "ADD_PRE_ORDER_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "ADD_PRE_ORDER_FAILURE", payload: error.response })
        )
      )
    )
  );
// edit pre order
export const editPreOrderEpic = (action$) =>
  action$.pipe(
    ofType("EDIT_PRE_ORDER_REQUEST"),
    mergeMap((action) =>
      from(API.post(`/admin/pre-order/pre-order/edit`, action.payload)).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "EDIT_PRE_ORDER_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "EDIT_PRE_ORDER_FAILURE", payload: error.response })
        )
      )
    )
  );
// activate pre order
export const activatePreOrderEpic = (action$) =>
  action$.pipe(
    ofType("ACTIVATE_PRE_ORDER_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/admin/pre-order/pre-order/activate/${action.payload.id}`,
          action.payload.confirmText
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "ACTIVATE_PRE_ORDER_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "ACTIVATE_PRE_ORDER_FAILURE", payload: error.response })
        )
      )
    )
  );
// de-ativate pre order
export const de_activatePreOrderEpic = (action$) =>
  action$.pipe(
    ofType("DE_ACTIVATE_PRE_ORDER_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/admin/pre-order/pre-order/deactivate/${action.payload.id}`,
          action.payload.confirmText
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "DE_ACTIVATE_PRE_ORDER_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "DE_ACTIVATE_PRE_ORDER_FAILURE", payload: error.response })
        )
      )
    )
  );
// determine wheather have prevCart item or not
export const checkPreCartItemEpic = (action$) =>
  action$.pipe(
    ofType("CHECK_PREV_CART_ITEM__REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/admin/restaurant/phone-order/verify-cart-present?restaurantId=${action.payload.restaurantId}&userId=${action.payload.customerId}`
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "CHECK_PREV_CART_ITEM__SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "CHECK_PREV_CART_ITEM__FAILURE", payload: error.response })
        )
      )
    )
  );
// search pre-order request list
export const searchPreOrderRequestEpic = (action$) =>
  action$.pipe(
    ofType("SEARCH_PRE_ORDER_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/admin/pre-order-request/list/search?searchParam=${action.payload.myKeyword}&page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}&sort=${action.payload.sortingParam}&createdOn=${action.payload.finalPreorderDate}`
        )
      ).pipe(
        // tap(response=> console.log("The response after posting advertisement",response)),
        mergeMap((response) =>
          of({ type: "SEARCH_PRE_ORDER_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "SEARCH_PRE_ORDER_FAILURE", payload: error.response })
        )
      )
    )
  );
// edit restaurant email
export const editRestarantEmailEpic = (action$) =>
  action$.pipe(
    ofType("EDIT_RESTAURANT_EMAIL_REQUEST"),
    mergeMap((action) =>
      from(API.post(`/admin/restaurant/reset-email`, action.payload)).pipe(
        mergeMap((response) =>
          of({ type: "EDIT_RESTAURANT_EMAIL_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "EDIT_RESTAURANT_EMAIL_FAILURE", payload: error.response })
        )
      )
    )
  );
// menu category
export const fetchCategoryListEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_CATEGORY_LIST_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/admin/restaurant/get-category/${action.payload.resId}?page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}`
        )
      ).pipe(
        mergeMap((response) =>
          of({ type: "FETCH_CATEGORY_LIST_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "FETCH_CATEGORY_LIST_FAILURE", payload: error.response })
        )
      )
    )
  );
// menu list
export const fetchMenuListEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_MENU_LIST_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(`/admin/restaurant/get-menu-by-cat-id/${action.payload?.id}`)
      ).pipe(
        mergeMap((response) =>
          of({ type: "FETCH_MENU_LIST_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "FETCH_MENU_LIST_FAILURE", payload: error.response })
        )
      )
    )
  );
// FETch campaign lsiet
export const fetchCampaignListEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_CAMPAIGN_LIST_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/admin/campaign/get-all?page=${
            action.payload.currentPage
          }&pageSize=${action.payload.postsPerPage}&searchParam=${
            action.payload.keyWord ? action.payload.keyWord : ""
          }`
        )
      ).pipe(
        mergeMap((response) =>
          of({ type: "FETCH_CAMPAIGN_LIST_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "FETCH_CAMPAIGN_LIST_FAILURE", payload: error.response })
        )
      )
    )
  );
// add campaign lsiet
export const addCampaignListEpic = (action$) =>
  action$.pipe(
    ofType("ADD_CAMPAIGN_REQUEST"),
    mergeMap((action) =>
      from(API.post(`/admin/add-campaign`, action.payload)).pipe(
        mergeMap((response) =>
          of({ type: "ADD_CAMPAIGN_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "ADD_CAMPAIGN_FAILURE", payload: error.response })
        )
      )
    )
  );
// edit campaign
export const editCampaignListEpic = (action$) =>
  action$.pipe(
    ofType("EDIT_CAMPAIGN_REQUEST"),
    mergeMap((action) =>
      from(API.put(`/admin/update-campaign`, action.payload)).pipe(
        mergeMap((response) =>
          of({ type: "EDIT_CAMPAIGN_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "EDIT_CAMPAIGN_FAILURE", payload: error.response })
        )
      )
    )
  );
// enable campaign
export const enableCampaignEpic = (action$) =>
  action$.pipe(
    ofType("ENABLE_CAMPAIGN_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/admin/campaign/activate/${action.payload.id}?confirmationText=${action.payload.confirmText}`,
          action.payload
        )
      ).pipe(
        mergeMap((response) =>
          of({ type: "ENABLE_CAMPAIGN_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "ENABLE_CAMPAIGN_FAILURE", payload: error.response })
        )
      )
    )
  );
// edit campaign
export const disableCampaignLEpic = (action$) =>
  action$.pipe(
    ofType("DISABLE_CAMPAIGN_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/admin/campaign/deactivate/${action.payload.id}?confirmationText=${action.payload.confirmText}`,
          action.payload
        )
      ).pipe(
        mergeMap((response) =>
          of({ type: "DISABLE_CAMPAIGN_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "DISABLE_CAMPAIGN_FAILURE", payload: error.response })
        )
      )
    )
  );
// enable campaign item
export const enableCampaignItemEpic = (action$) =>
  action$.pipe(
    ofType("ENABLE_CAMPAIGN_ITEM_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/admin/campaign/activate-item/${action.payload.id}?confirmationText=${action.payload.confirmText}`,
          action.payload
        )
      ).pipe(
        mergeMap((response) =>
          of({ type: "ENABLE_CAMPAIGN_ITEM_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "ENABLE_CAMPAIGN_ITEM_FAILURE", payload: error.response })
        )
      )
    )
  );
// edit campaign
export const disableCampaignItemEpic = (action$) =>
  action$.pipe(
    ofType("DISABLE_CAMPAIGN_ITEM_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/admin/campaign/deactivate-item/${action.payload.id}?confirmationText=${action.payload.confirmText}`,
          action.payload
        )
      ).pipe(
        mergeMap((response) =>
          of({ type: "DISABLE_CAMPAIGN_ITEM_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "DISABLE_CAMPAIGN_ITEM_FAILURE", payload: error.response })
        )
      )
    )
  );

// change pre-order status
export const changePreOrderStatusEpic = (action$) =>
  action$.pipe(
    ofType("CHANGE_PREORDER_STATUS_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/admin/pre-order/update-state/${action.payload.id}`,
          action.payload.values
        )
      ).pipe(
        mergeMap((response) =>
          of({ type: "CHANGE_PREORDER_STATUS_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({
            type: "CHANGE_PREORDER_STATUS_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

// approve campaign order
export const approveCampaignEpic = (action$) =>
  action$.pipe(
    ofType("APPROVE_CAMPAIGN_ORDER_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(`/admin/campaign/approve-order/${action.payload.orderId}`, {
          confirmationText: "ok",
        })
      ).pipe(
        mergeMap((response) =>
          of({ type: "APPROVE_CAMPAIGN_ORDER_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({
            type: "APPROVE_CAMPAIGN_ORDER_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

// complete campaign order
export const completeCampaignEpic = (action$) =>
  action$.pipe(
    ofType("COMPLETE_CAMPAIGN_ORDER_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(`/admin/campaign/complete-order/${action.payload.orderId}`, {
          confirmationText: "ok",
        })
      ).pipe(
        mergeMap((response) =>
          of({
            type: "COMPLETE_CAMPAIGN_ORDER_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "COMPLETE_CAMPAIGN_ORDER_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// cancel campaign order
export const cancelCampaignOrderEpic = (action$) =>
  action$.pipe(
    ofType("CANCEL_CAMPAIGN_ORDER_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(`/admin/campaign/cancel-order/${action.payload.orderId}`, {
          confirmationText: "ok",
        })
      ).pipe(
        mergeMap((response) =>
          of({ type: "CANCEL_CAMPAIGN_ORDER_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "CANCEL_CAMPAIGN_ORDER_FAILURE", payload: error.response })
        )
      )
    )
  );
// create new batch
export const createNewBatchEpic = (action$) =>
  action$.pipe(
    ofType("CREATE_NEW_BATCH_REQUEST"),
    mergeMap((action) =>
      from(API.post(`/admin/batch-package/create-new`, action.payload)).pipe(
        mergeMap((response) =>
          of({ type: "CREATE_NEW_BATCH_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "CREATE_NEW_BATCH_FAILURE", payload: error.response })
        )
      )
    )
  );
// fetch new batch
export const fetchNewBatchEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_NEW_BATCH_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/admin/batch-package/get-all?page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}`
        )
      ).pipe(
        mergeMap((response) =>
          of({ type: "FETCH_NEW_BATCH_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "FETCH_NEW_BATCH_FAILURE", payload: error.response })
        )
      )
    )
  );
// assign batch to driver
export const assignBatchPakageToDEpic = (action$) =>
  action$.pipe(
    ofType("ASSIGN_BATCH_PAKAGE_TO_D_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/admin/batch-package/assign-package/${action.payload.driverId}`,
          action.payload.values
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "ASSIGN_BATCH_PAKAGE_TO_D_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "ASSIGN_BATCH_PAKAGE_TO_D_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// FETCH PREORDER TIME SLOT
export const fetchPreOTimeslotEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_PRE_ORDER_TIME_SLOT_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/admin/pre-order/time-slot?preOrderId=${action.payload.preOrderId}&page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}`,
          action.payload.values
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "FETCH_PRE_ORDER_TIME_SLOT_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "FETCH_PRE_ORDER_TIME_SLOT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// post PREORDER TIME SLOT
export const addPreOTimeslotEpic = (action$) =>
  action$.pipe(
    ofType("POST_PRE_ORDER_TIME_SLOT_REQUEST"),
    mergeMap((action) =>
      from(API.post(`/admin/pre-order/time-slot`, action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "POST_PRE_ORDER_TIME_SLOT_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "POST_PRE_ORDER_TIME_SLOT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// post PREORDER TIME SLOT
export const editPreOTimeslotEpic = (action$) =>
  action$.pipe(
    ofType("EDIT_PRE_ORDER_TIME_SLOT_REQUEST"),
    mergeMap((action) =>
      from(API.put(`/admin/pre-order/time-slot`, action.payload)).pipe(
        mergeMap((response) =>
          of({
            type: "EDIT_PRE_ORDER_TIME_SLOT_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "EDIT_PRE_ORDER_TIME_SLOT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// post campaign phone order
export const campaignPhoneOrderEpic = (action$) =>
  action$.pipe(
    ofType("CAMPAIGN_PHONE_ORDER_REQUEST"),
    mergeMap((action) =>
      from(
        API.post(
          `/admin/campaign/make-phone-order/${action.payload.userId}`,
          action.payload.values
        )
      ).pipe(
        mergeMap((response) =>
          of({ type: "CAMPAIGN_PHONE_ORDER_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "CAMPAIGN_PHONE_ORDER_FAILURE", payload: error.response })
        )
      )
    )
  );

//FETCH BATCH PACKAGE

export const fetchBadgePackage = (action$) =>
  action$.pipe(
    ofType("FETCH_BATCH_PACKAGE_REQUEST"),
    mergeMap((action) =>
      from(API.get(`/admin/batch-package/get-items/${action.payload}`)).pipe(
        mergeMap((response) =>
          of({ type: "FETCH_BATCH_PACKAGE_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "FETCH_BATCH_PACKAGE_FAILURE", payload: error.response })
        )
      )
    )
  );

//VERIFY DRIVER

export const verifyDriver = (action$) =>
  action$.pipe(
    ofType("VERIFY_DRIVER_REQUEST"),
    mergeMap((action) =>
      from(
        API.patch(
          `/delivery-user/driver-verify/${action.payload.id}?confirmationText=${action.payload.confirmationText}&isSendSMSForLogin=${action.payload.isSendSMSForLogin}`
        )
      ).pipe(
        mergeMap((response) =>
          of({ type: "VERIFY_DRIVER_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "VERIFY_DRIVER_FAILURE", payload: error.response })
        )
      )
    )
  );
//Update empty bill no

export const updateBillNoEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_EMPTY_BILL_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/admin/order/update-empty-bill/${action.payload.id}`,
          action.payload.data
        )
      ).pipe(
        mergeMap((response) =>
          of({ type: "UPDATE_EMPTY_BILL_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "UPDATE_EMPTY_BILL_FAILURE", payload: error.response })
        )
      )
    )
  );
// Mark As Popup in advertisement
export const markAsPopupEpic = (action$) =>
  action$.pipe(
    ofType("MARK_AS_POPUP_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/admin/advertisement/mark-as-popup/${action.payload.id}`,
          action.payload
        )
      ).pipe(
        mergeMap((response) =>
          of({ type: "MARK_AS_POPUP_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "MARK_AS_POPUP_FAILURE", payload: error.response })
        )
      )
    )
  );

// Remove from popup in advertisement

export const removeFromPopupEpic = (action$) =>
  action$.pipe(
    ofType("REMOVE_FROM_POPUP_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(`/admin/advertisement/remove-from-popup/${action.payload.id}`, {
          id: action.payload.id,
        })
      ).pipe(
        mergeMap((response) =>
          of({ type: "REMOVE_FROM_POPUP_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "REMOVE_FROM_POPUP_FAILURE", payload: error.response })
        )
      )
    )
  );

// settle preorder

export const settlePreOrderEpic = (action$) =>
  action$.pipe(
    ofType("SETTLE_PREORDER_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/admin/pre-order/settle/${action.payload.id}`,
          action.payload.values
        )
      ).pipe(
        mergeMap((response) =>
          of({ type: "SETTLE_PREORDER_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "SETTLE_PREORDER_FAILURE", payload: error.response })
        )
      )
    )
  );
//fetch reseller

export const fetchResellerEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_RESELLER_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/admin/reseller/get-list?page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}`
        )
      ).pipe(
        mergeMap((response) =>
          of({ type: "FETCH_RESELLER_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "FETCH_RESELLER_FAILURE", payload: error.response })
        )
      )
    )
  );
// add reseller

export const addResellerEpic = (action$) =>
  action$.pipe(
    ofType("ADD_RESELLER_REQUEST"),
    mergeMap((action) =>
      from(API.post(`/admin/reseller/add`, action.payload)).pipe(
        mergeMap((response) =>
          of({ type: "ADD_RESELLER_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "ADD_RESELLER_FAILURE", payload: error.response })
        )
      )
    )
  );
// Update Reseller

export const updateResellerEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_RESELLER_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/admin/reseller/update/${action.payload.id}`,
          action.payload.values
        )
      ).pipe(
        // tap(response=> console.log("The response after posting Reports",response)),
        mergeMap((response) =>
          of({ type: "UPDATE_RESELLER_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "UPDATE_RESELLER_FAILURE", payload: error.response })
        )
      )
    )
  );

// activate reseller

export const activateResellerEpic = (action$) =>
  action$.pipe(
    ofType("ACTIVATE_RESELLER_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/admin/reseller/activate/${action.payload.id}`,
          action.payload.values
        )
      ).pipe(
        mergeMap((response) =>
          of({ type: "ACTIVATE_RESELLER_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "ACTIVATE_RESELLER_FAILURE", payload: error.response })
        )
      )
    )
  );
// deactivate reseller

export const deActivateResellerEpic = (action$) =>
  action$.pipe(
    ofType("DEACTIVATE_RESELLER_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/admin/reseller/deactivate/${action.payload.id}`,
          action.payload.values
        )
      ).pipe(
        mergeMap((response) =>
          of({ type: "DEACTIVATE_RESELLER_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "DEACTIVATE_RESELLER_FAILURE", payload: error.response })
        )
      )
    )
  );
// activate category

export const activateMenuCategoryEpic = (action$) =>
  action$.pipe(
    ofType("ACTIVATE_MENU_CATEGORY_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/admin/restaurant/activate-category/${action.payload.id}`,
          action.payload.values
        )
      ).pipe(
        mergeMap((response) =>
          of({ type: "ACTIVATE_MENU_CATEGORY_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({
            type: "ACTIVATE_MENU_CATEGORY_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// deactivate reseller

export const deActivateMenuCategoryEpic = (action$) =>
  action$.pipe(
    ofType("DEACTIVATE_MENU_CATEGORY_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/admin/restaurant/deactivate-category/${action.payload.id}`,
          action.payload.values
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "DEACTIVATE_MENU_CATEGORY_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "DEACTIVATE_MENU_CATEGORY_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// activate menu

export const activateMenuEpic = (action$) =>
  action$.pipe(
    ofType("ACTIVATE_MENU_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/admin/restaurant/activate-menu/${action.payload.id}`,
          action.payload.values
        )
      ).pipe(
        mergeMap((response) =>
          of({ type: "ACTIVATE_MENU_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({
            type: "ACTIVATE_MENU_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// deactivate reseller

export const deActivateMenuEpic = (action$) =>
  action$.pipe(
    ofType("DEACTIVATE_MENU_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/admin/restaurant/deactivate-menu/${action.payload.id}`,
          action.payload.values
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "DEACTIVATE_MENU_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "DEACTIVATE_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

export const fetchPreOrderMerchantEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_PREORDER_MERCHANT_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/employee/pre-order/get-all?page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}`
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "FETCH_PREORDER_MERCHANT_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "FETCH_PREORDER_MERCHANT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

// add Menu category

export const addMenuCategoryEpic = (action$) =>
  action$.pipe(
    ofType("ADD_CATEGORY_REQUEST"),
    mergeMap((action) =>
      from(API.post(`/admin/restaurant/add-category`, action.payload)).pipe(
        mergeMap((response) =>
          of({ type: "ADD_CATEGORY_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "ADD_CATEGORY_FAILURE", payload: error.response })
        )
      )
    )
  );

// Add Menu

export const addMenuEpic = (action$) =>
  action$.pipe(
    ofType("ADD_MENU_REQUEST"),
    mergeMap((action) =>
      from(API.post(`/admin/restaurant/add-menu`, action.payload)).pipe(
        mergeMap((response) =>
          of({ type: "ADD_MENU_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "ADD_MENU_FAILURE", payload: error.response })
        )
      )
    )
  );

// Update Menu

export const updateMenuEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_MENU_REQUEST"),
    mergeMap((action) =>
      from(API.put(`/admin/restaurant/update-menu`, action.payload)).pipe(
        // tap(response=> console.log("The response after posting Reports",response)),
        mergeMap((response) =>
          of({
            type: "UPDATE_MENU_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "UPDATE_MENU_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// Update Category

export const updateCategoryEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_CATEGORY_REQUEST"),
    mergeMap((action) =>
      from(API.put(`/admin/restaurant/update-category`, action.payload)).pipe(
        // tap(response=> console.log("The response after posting Reports",response)),
        mergeMap((response) =>
          of({
            type: "UPDATE_CATEGORY_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "UPDATE_CATEGORY_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

// add preorder merchant

export const addPreOrderMerchantEpic = (action$) =>
  action$.pipe(
    ofType("ADD_PREORDER_MERCHANT_REQUEST"),
    mergeMap((action) =>
      from(API.post(`/employee/pre-order/add`, action.payload)).pipe(
        mergeMap((response) =>
          of({ type: "ADD_PREORDER_MERCHANT_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "ADD_PREORDER_MERCHANT_FAILURE", payload: error.response })
        )
      )
    )
  );
// Update Reseller

export const updatePreOrderMerchantEpic = (action$) =>
  action$.pipe(
    ofType("UPDATE_PREORDER_MERCHANT_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/employee/pre-order/update/${action.payload.id}`,
          action.payload.values
        )
      ).pipe(
        // tap(response=> console.log("The response after posting Reports",response)),
        mergeMap((response) =>
          of({
            type: "UPDATE_PREORDER_MERCHANT_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "UPDATE_PREORDER_MERCHANT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

export const fetchPreOrderMerchantListEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_PREORDER_MERCHANT_LIST_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/employee/pre-order/purchase-list/${
            action.payload.preOrderId
          }?page=${action.payload.currentPage}&pageSize=${
            action.payload.postsPerPage
          }&deliveryStartTime=${
            action.payload.deliveryStartTime
              ? action.payload.deliveryStartTime
              : ""
          }&deliveryEndTime=${
            action.payload.deliveryEndTime ? action.payload.deliveryEndTime : ""
          }&searchParam=${
            action.payload.searchParam ? action.payload.searchParam : ""
          }`
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "FETCH_PREORDER_MERCHANT_LIST_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "FETCH_PREORDER_MERCHANT_LIST_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// Approve Preorder merchant

export const approvePreOrderMerchantEpic = (action$) =>
  action$.pipe(
    ofType("APPROVE_PREORDER_MERCHANT_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `employee/pre-order/admin/approve/${action.payload.id}`,
          action.payload.values
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "APPROVE_PREORDER_MERCHANT_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "APPROVE_PREORDER_MERCHANT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

// Dispatch Preorder Merchant

export const dispatchPreOrderMerchantEpic = (action$) =>
  action$.pipe(
    ofType("DISPATCH_PREORDER_MERCHANT_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `employee/pre-order/admin/dispatch-order/${action.payload.id}`,
          action.payload.values
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "DISPATCH_PREORDER_MERCHANT_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "DISPATCH_PREORDER_MERCHANT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

// Assign devlivery preorder merchant

export const assignPreOrderMerchantEpic = (action$) =>
  action$.pipe(
    ofType("ASSIGN_DELIVERY_PREORDER_MERCHANT_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `employee/pre-order/admin/assign-order/${action.payload.values.orderId}/${action.payload.id}`
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "ASSIGN_DELIVERY_PREORDER_MERCHANT_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "ASSIGN_DELIVERY_PREORDER_MERCHANT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

// View Reseller Detail

export const getResellerByIdEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_RESELLER_DETAIL_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/admin/reseller/get-by-id/${action.payload.resellerId}?page=${
            action.payload.currentPage
          }&pageSize=${action.payload.postsPerPage}&deliveryStartTime=${
            action.payload.deliveryStartTime
              ? action.payload.deliveryStartTime
              : ""
          }&deliveryEndTime=${
            action.payload.deliveryEndTime ? action.payload.deliveryEndTime : ""
          }&searchParam=${
            action.payload.searchParam ? action.payload.searchParam : ""
          }`
        )
      ).pipe(
        mergeMap((response) =>
          of({ type: "FETCH_RESELLER_DETAIL_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "FETCH_RESELLER_DETAIL_FAILURE", payload: error.response })
        )
      )
    )
  );
// Fetch All Campaign List
export const getOrdersFromAllCampaignEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_ORDER_FROM_ALL_CAMPAIGN_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/admin/campaign/list-all-orders?page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}&orderStatus=${action.payload.orderStatus}`
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "FETCH_ORDER_FROM_ALL_CAMPAIGN_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "FETCH_ORDER_FROM_ALL_CAMPAIGN_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// Fetch COD Verification Pending
export const getCODVerificationPendingEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_COD_VERIFICATION_PENDING"),
    mergeMap((action) =>
      from(
        API.get(
          `/admin/order/get-cod-pending?page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}`
        )
      ).pipe(
        mergeMap((response) =>
          of({ type: "FETCH_COD_VERIFICATION_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({
            type: "FETCH_COD_VERIFICATION_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// activate preorder merchant

export const activatepreordermerchantEpic = (action$) =>
  action$.pipe(
    ofType("ACTIVATE_PREORDER_MERCHANT_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/employee/pre-order/activate/${action.payload.id}`,
          action.payload.values
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "ACTIVATE_PREORDER_MERCHANT_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "ACTIVATE_PREORDER_MERCHANT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
// deactivate preorder merchant

export const deActivatepreordermerchantEpic = (action$) =>
  action$.pipe(
    ofType("DEACTIVATE_PREORDER_MERCHANT_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/employee/pre-order/deactivate/${action.payload.id}`,
          action.payload.values
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "DEACTIVATE_PREORDER_MERCHANT_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "DEACTIVATE_PREORDER_MERCHANT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

// Fetch Reconcilement

export const getReconcilementEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_RECONCILEMENT_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/admin/reconcilement/list/COD?reconcilementDate=${
            action.payload.reconcilementDate
          }&operatingLocationId=${
            action.payload.operatingLocationId
              ? action.payload.operatingLocationId
              : ""
          }`
        )
      ).pipe(
        mergeMap((response) =>
          of({ type: "FETCH_RECONCILEMENT_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "FETCH_RECONCILEMENT_FAILURE", payload: error.response })
        )
      )
    )
  );

// Approve reconcilement orders

export const approveReconcilementOrdersEpic = (action$) =>
  action$.pipe(
    ofType("APPROVE_RECONCILEMENT_REQUEST"),
    mergeMap((action) =>
      from(
        API.post(
          `/admin/reconcilement/approve-day-reconcilement`,
          action.payload
        )
      ).pipe(
        mergeMap((response) =>
          of({ type: "APPROVE_RECONCILEMENT_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "APPROVE_RECONCILEMENT_FAILURE", payload: error.response })
        )
      )
    )
  );

// Fetch Archives

export const getArchivesEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_ARCHIVES_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/admin/reconcilement/get-archives?page=${
            action.payload.currentPage
          }&pageSize=${action.payload.postsPerPage}&reconcilementDate=${
            action.payload.reconcilementDate
              ? action.payload.reconcilementDate
              : ""
          }&operatingLocationId=${action.payload.operatingLocationId}`
        )
      ).pipe(
        mergeMap((response) =>
          of({ type: "FETCH_ARCHIVES_SUCCESS", payload: response.data })
        ),
        catchError((error) =>
          of({ type: "FETCH_ARCHIVES_FAILURE", payload: error.response })
        )
      )
    )
  );

// Reapprove Reconcilement

export const reapproveReconcilementOrdersEpic = (action$) =>
  action$.pipe(
    ofType("REAPPROVE_RECONCILEMENT_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/admin/reconcilement/update-reconcilement/${action.payload.reconcilementId}`,
          action.payload
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "REAPPROVE_RECONCILEMENT_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "REAPPROVE_RECONCILEMENT_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

//know your customer (kyc)
//submitted
export const fetchKYClistEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_KYC_LIST_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `delivery-user/get-kyc-submitted-list?page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}`
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "FETCH_KYC_LIST_REQUEST_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "FETCH_KYC_LIST_REQUEST_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

//verified
export const fetchVerifiedKYClistEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_VERIFIED_KYC_LIST_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/delivery-user/get-kyc-verified-list?page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}`
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "FETCH_VERIFIED_KYC_LIST_REQUEST_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "FETCH_VERIFIED_KYC_LIST_REQUEST_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
//unverified
export const fetchUnVerifiedKYClistEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_UNVERIFIED_KYC_LIST_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(
          `/delivery-user/get-kyc-un-verified-list?page=${action.payload.currentPage}&pageSize=${action.payload.postsPerPage}`
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "FETCH_UNVERIFIED_KYC_LIST_REQUEST_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "FETCH_UNVERIFIED_KYC_LIST_REQUEST_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );

//verify kyc

export const verifyKycEpic = (action$) =>
  action$.pipe(
    ofType("VERIFY_KYC_REQUEST"),
    mergeMap((action) =>
      from(
        API.get(`/admin/verify-delivery-user-kyc/${action.payload.id}`)
      ).pipe(
        mergeMap((response) => {
          action.payload?.isReloadFn && action.payload?.reloadFn(response);
          return of({
            type: "VERIFY_KYC_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((error) =>
          of({
            type: "VERIFY_KYC_REQUEST_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const revokeKycVerificationEpic = (action$) =>
  action$.pipe(
    ofType("REVOKE_KYC_VERIFICATION_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/admin/revoke-user-kyc-verification/${action.payload.id}?message=${action.payload.confirmationText}`,
          action.payload
        )
      ).pipe(
        mergeMap((response) => {
          action.payload?.isReloadFn && action.payload?.reloadFn(response);

          return of({
            type: "REVOKE_KYC_VERIFICATION_REQUEST_SUCCESS",
            payload: response.data,
          });
        }),
        catchError((error) =>
          of({
            type: "REVOKE_KYC_VERIFICATION_REQUEST_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const pushIntoKycRevokeLogEpic = (action$) =>
  action$.pipe(
    ofType("PUSH_INTO_KYC_REVOKE_LOG_REQUEST"),
    mergeMap((action) =>
      from(
        API.put(
          `/revoke-log/add/${action.payload.id}?message=${action.payload.message}`,
          action.payload
        )
      ).pipe(
        mergeMap((response) =>
          of({
            type: "PUSH_INTO_KYC_REVOKE_LOG_REQUEST_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "PUSH_INTO_KYC_REVOKE_LOG_REQUEST_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
export const fetchKycRevokeHistoryLogEpic = (action$) =>
  action$.pipe(
    ofType("FETCH_KYC_REVOKE_HISTORY_LOG_REQUEST"),
    mergeMap((action) =>
      from(API.get(`/admin/revoke-log/get/${action.payload.id}`)).pipe(
        mergeMap((response) =>
          of({
            type: "FETCH_KYC_REVOKE_HISTORY_LOG_REQUEST_SUCCESS",
            payload: response.data,
          })
        ),
        catchError((error) =>
          of({
            type: "FETCH_KYC_REVOKE_HISTORY_LOG_REQUEST_FAILURE",
            payload: error.response,
          })
        )
      )
    )
  );
