import React from 'react'
import {ImSpinner3} from 'react-icons/im'

function LoadingComponent() {
    return (
        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    )
}

export default LoadingComponent
