import React, { useState, useRef, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import guruGLogo from "../../Images/logo-sm.png";
import { GiFlowerPot } from "react-icons/gi";

function RestroDetails({ id, item }) {
  return (
    <div>
      <div className="Modal--heading">
        <span style={{ color: "#3e445e" }}>Restaurant Details</span>
      </div>
      <div className="delivery--profile">
        <div className="delivery--profile__background">
          <div className="delivery--profile__background--color"></div>
        </div>
        <div className="delivery--profile__details">
          <div className="profile--header">
            <div className="profile--header__image">
              <img src={item.imageUrl} alt="Profile Image" />
            </div>
            <div className="profile--header__fullName">
              <p>{item.restaurantName}</p>
              <p>{item.slogan}</p>
              <p>{item.email}</p>
            </div>
          </div>
          <div className="profile--body">
            <div className="profile--body--children">
              <p>Contact Person Phone No. : {item.contactPersonPhone}</p>
              <div className="profile--body--children__text">
                <p>Contact Person Name : {item.contactPersonName}</p>
              </div>
            </div>
            <div className="profile--body--children">
              <p>Comission Rate : {item.commissionRate}</p>
              <div className="profile--body--children__text">
                <p>Location : {item.location}</p>
              </div>
            </div>
            <div className="profile--body--children">
              <p>Latitude : {item.latitude}</p>
              <div className="profile--body--children__text">
                <p>Longitude : {item.longitude}</p>
              </div>
            </div>

            <div className="profile--body--children">
              <p>
                Deleted Status :{" "}
                <span>{item.deleted ? "Deleted" : "Not Deleted"}</span>
              </p>
              <div className="profile--body--children__text">
                <p>
                  Active Status :{" "}
                  <span>{item.active ? "Active" : "False"}</span>
                </p>
              </div>
            </div>
            <div className="profile--body--children">
              <p>Opening Time : {item.openingTime}</p>
              <div className="profile--body--children__text">
                <p>Closing Time : {item.closingTime}</p>
              </div>
            </div>
            <div className="profile--body--children">
              <p>
                Open Status : <span>{item.isOpen ? "Yes" : "No"}</span>
              </p>
              <div className="profile--body--children__text">
                <p>Operating Location : {item.operatingLocation}</p>
              </div>
            </div>
            <div className="profile--body--description">
              <p>Description : </p>
              <p>{item.description}</p>
            </div>
          </div>
        </div>
        <div
          className="delivery--profile__footer"
          style={{ marginTop: "-40px" }}
        ></div>
      </div>
    </div>
  );
}

export default RestroDetails;
