import React, { useState, useEffect } from 'react'
import { useDispatch, connect } from 'react-redux'

const mapStateToProps = store => {
    const { buttonText } = store.mainState
    return { buttonText }
}

function AddExchangeRate({ buttonText }) {
    const dispatch = useDispatch();
    const [loyaltyPoint, setLoyaltyPoint] = useState("")
    const [equivalentAmount, setEquivalentAmount] = useState("");
    const handleLoyalitySubmit = (e) => {
        e.preventDefault();
        dispatch({
            type: "POST_EXCHANGE_REFERAL_REQUEST",
            payload: {
                "redeemedValue": Number(equivalentAmount),
                "loyaltyPoints": Number(loyaltyPoint)
            }
        })

        setLoyaltyPoint("");
        setEquivalentAmount("")

    }

    return (
        <div>
            <div className="Modal--heading">
                Add/Update Referral And Loyalty
            </div>
            <div className="Modal--form">
                <form>
                    <div className="Modal--form--title">
                        <p><label htmlFor="added_by">Loyalty Point: </label>
                        <input type ="number" value={loyaltyPoint} onChange={(e) => setLoyaltyPoint(e.target.value)} placeholder="Enter Loyalty Point" />
                        </p>
                    </div>
                    <div className="Modal--form--title">
                        <p><label htmlFor="title">Equivalent Amount: </label><input type="number" value={equivalentAmount} onChange={(e) => setEquivalentAmount(e.target.value)} placeholder="Equivalent Amount" /></p>
                    </div>

                    <div className="modal--container__group--buttons">
                        <button style={{ marginBottom: "50px" }} type="submit" className="btn btn-primary" onClick={handleLoyalitySubmit} >
                            {buttonText}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, null)(AddExchangeRate)