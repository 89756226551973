import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import { AiFillEdit } from "react-icons/ai";
// import {RiAddBoxFill} from 'react-icons/ri'
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { BsX } from "react-icons/bs";
import { connect, useDispatch } from "react-redux";
import LoadingComponent from "../LoadingComponent";
import Footer from "../Z_Footer/Footer";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
// import EditServiceRate from './EditServiceRate';
import moment from "moment";
import EditOperatingLocation from "./EditOperatingLocation";

const mapStateToProps = (store) => {
  const {
    buttonText,
    isLoadingOnLogin,
    operatingLocationList,
    isToogleSidebar,
    isEnabledUser,
  } = store.mainState;
  return {
    buttonText,
    isLoadingOnLogin,
    operatingLocationList,
    isToogleSidebar,
    isEnabledUser,
  };
};

function OperatingLocList({
  buttonText,
  isLoadingOnLogin,
  operatingLocationList,
  isToogleSidebar,
  isEnabledUser,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  let pageNumbers = [];

  const [showEditModal, setShowEditModal] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [operatingLocation, setOperatingLocation] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [imgUrl, setImgUrl] = useState(null);
  const dispatch = useDispatch();

  // get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  for (let i = 1; i <= operatingLocationList.totalPage; i++) {
    pageNumbers.push(i);
  }
  useEffect(() => {
    dispatch({
      type: "FETCH_OPERATING_LOCATION_REQUEST",
      payload: { postsPerPage, currentPage },
    });
  }, [postsPerPage, currentPage]);
  const getLocalDateTime = (mydate) => {
    var stillUtc = moment.utc(mydate).toDate();
    var local = moment(stillUtc).local().format("YYYY-MM-DD HH:mm:ss");
    return local;
  };

  useEffect(() => {
    if (isEnabledUser === "y") {
      setShowEditModal(false);
      dispatch({
        type: "FETCH_OPERATING_LOCATION_REQUEST",
        payload: { postsPerPage, currentPage },
      });
    }
  }, [isEnabledUser]);

  return (
    <Layout>
      <div>
        <div className="group_list">
          <h4>Manage Operating Location</h4>
        </div>
        <div className="user_groups">
          <div className="user_groups--button_n_search">
            <div className="user_groups--button_n_search--button">
              <h4>Operating Location</h4>
              <button
                onClick={() =>
                  dispatch({ type: "ADD_OPERATING_LOCATION_MODAL" })
                }
              >
                Add <i className="bi bi-plus c-plus" />
              </button>
            </div>
            <div className="user_groups--button_n_search--search">
              {/* <input type="text" placeholder="Search" /> */}
            </div>
          </div>
          <p className="privacy-loading--onFetching">
            {isLoadingOnLogin && <LoadingComponent />}
          </p>
          <div className="table-container">
            <table className="table_x">
              <thead>
                <tr>
                  <th className="loyality--id">Image</th>
                  <th
                    className="loyality--schemeName"
                    style={{ width: "20vw" }}
                  >
                    Operating Location
                  </th>
                  <th className="loyality--addedBy" style={{ width: "20vw" }}>
                    Contact Person{" "}
                  </th>
                  <th className="loyality--addedBy" style={{ width: "20vw" }}>
                    Contact Number
                  </th>
                  <th className="loyality--addedBy">Service Rate</th>
                  <th className="loyality--addedBy">Base Delivery</th>
                  <th className="loyality--addedBy">Delivery Charge/KM</th>
                  <th className="loyality--loyalityPoint">Active Status</th>
                  <th className="loyality--actions">Actions</th>
                </tr>
              </thead>
              <tbody>
                {operatingLocationList !== undefined &&
                  operatingLocationList.data?.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <img
                          className="myLargeImage"
                          src={item.operatingLocationImage}
                          alt="image"
                        />
                      </td>
                      <td>{item.locationName}</td>
                      <td>{item.operatingLocationContactPersonName}</td>
                      <td>{item.operatingLocationContactPersonNumber}</td>
                      <td>
                        {" "}
                        {item.serviceCharge}
                        {item.serviceType === "PERCENTAGE" ? "%" : "(Rs.)"}
                      </td>
                      <td>Rs. {item.baseDeliveryCharge}</td>
                      <td>Rs. {item.deliveryChargePerKm}</td>
                      <td>
                        {item.isActive ? (
                          <span
                            style={{
                              backgroundColor: "green",
                              borderRadius: "4px",
                              color: "white",
                            }}
                          >
                            Active
                          </span>
                        ) : (
                          <span
                            style={{
                              backgroundColor: "green",
                              borderRadius: "4px",
                              color: "white",
                            }}
                          >
                            Not Active
                          </span>
                        )}
                      </td>
                      <td>
                        <Tippy content="Edit" placement="top">
                          <span>
                            <AiFillEdit
                              className="edit--icon"
                              onClick={() => {
                                setEditingItem(item);
                                setShowEditModal(true);
                                setOperatingLocation(item.locationName);
                                setIsActive(item.isActive);
                                setImgUrl(item.operatingLocationImage);
                              }}
                            />{" "}
                          </span>
                        </Tippy>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {/* edit operating location modal */}
            <div
              className={`${
                showEditModal ? "mymodal modal_activated" : "mymodal"
              }`}
            >
              <div
                className={`${
                  !isToogleSidebar
                    ? "manage_modal--container "
                    : "manage_modal--container sidebarOpend_F_modal"
                }`}
              >
                {editingItem !== null && (
                  <EditOperatingLocation
                    editingItem={editingItem}
                    imgUrl={imgUrl}
                    opLocation={operatingLocation}
                    acticStatus={isActive}
                  />
                )}
              </div>
              <div
                className={`${
                  !isToogleSidebar
                    ? "task--rectangle"
                    : "task--rectangle withsidebarOpend"
                }`}
              >
                <div className="task--rectangle__circle">
                  <div className="circle">
                    {" "}
                    <span
                      onClick={() => {
                        setShowEditModal(false);
                      }}
                    >
                      <BsX />
                    </span>
                    <p>Edit</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="pagination">
              <p>
                Showing {indexOfFirstPost} to{" "}
                {indexOfLastPost > operatingLocationList.totalData
                  ? operatingLocationList.totalData
                  : indexOfLastPost}{" "}
                of {operatingLocationList.totalData} entries
              </p>

              <nav aria-label="...">
                <ul className="pagination">
                  {currentPage === 1 ? (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronLeft />
                      </a>
                    </li>
                  ) : (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronLeft
                          onClick={() => setCurrentPage(currentPage - 1)}
                        />
                      </a>
                    </li>
                  )}
                  {pageNumbers.map((x) => (
                    <li key={x} className="page-item">
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => setCurrentPage(x)}
                      >
                        {x}
                      </a>
                    </li>
                  ))}
                  {currentPage == pageNumbers.length ? (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronRight />
                      </a>
                    </li>
                  ) : (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronRight
                          onClick={() => setCurrentPage(currentPage + 1)}
                        />
                      </a>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default connect(mapStateToProps, null)(OperatingLocList);
