import React, { useState, useEffect, useRef } from "react";
import { useDispatch, connect } from "react-redux";
import axios from "axios";
import { getCookie } from "../../FrontendHelper";
import { Checkbox, Col, Row, Radio, DatePicker } from "antd";
import moment from "moment";
import LoadingComponent from "../LoadingComponent";
import { toast } from "react-toastify";

const mapStateToProps = (store) => {
  const { buttonText, isEnabledUsers, searchUsersList,isLoadingOnLogin,isOperationSuccessful } = store.mainState;
  return { buttonText, isEnabledUsers, searchUsersList,isLoadingOnLogin,isOperationSuccessful };
};

function AddResellar({ buttonText, searchUsersList,isLoadingOnLogin,isOperationSuccessful }) {
  const [fullName, setFullName] = useState("");
  const [resellarCode, setResellarCode] = useState("");
  const [referralAmount, setReferralAmount] = useState("");
  const [referreAmount, setRefereeAmount] = useState("");
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [isOneTimeReferee, setIsOneTimeReferee] = useState("");
  const [isOneTimeReferral, setIsOneTimeReferral] = useState("");
  const [expiryTime, setExpiryTime] = useState("");
  const [businessName, setBuinessName] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState("");
  const [discountEnd, setDiscountEnd] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const dispatch = useDispatch();

  const handleAddResellar = (e) => {
    e.preventDefault();
    if (!selectedCustomer || !resellarCode || !referralAmount || !referreAmount || !paymentMethod.length > 0 || !expiryTime || !businessName || !discountEnd || !discountPercentage){
      return toast.error("Please fill all the fields !!")
    }
    dispatch({
      type: "ADD_RESELLER_REQUEST",
      payload: {
        userId: selectedCustomer.id,
        resellerCode: resellarCode,
        referralAmount: referralAmount,
        referrerAmount: referreAmount,
        applicablePaymentMethods: paymentMethod.length > 0 && paymentMethod?.map((item) => {
          return {
            paymentMethod: item,
          };
        }),
        isOneTimeReferral: isOneTimeReferral,
        isOneTimeReferrer: isOneTimeReferee,
        expiryTime: moment(expiryTime).format("YYYY-MM-DD HH:mm:ss"),
        businessName: businessName,
        refereeDiscountEnd: moment(discountEnd).format("YYYY-MM-DD HH:mm:ss"),
        discountPercentage: discountPercentage,
      },
    });

 
  };
    useEffect(() => {
      if (isOperationSuccessful) {
        setFullName("")
        setSelectedCustomer("");
        setResellarCode("");
        setReferralAmount("");
        setRefereeAmount("");
        setPaymentMethod([]);
        setIsOneTimeReferee("");
        setIsOneTimeReferral("");
        setExpiryTime("");
        setBuinessName("");
        setDiscountEnd("");
        setDiscountPercentage("");
      }
    }, [isOperationSuccessful]);
  const onChange = (checkedValues) => {
    setPaymentMethod(checkedValues);
  };
  const onChange1 = (e) => {
    setIsOneTimeReferral(e.target.value);
  };
  const onChange2 = (e) => {
    setIsOneTimeReferee(e.target.value);
  };
  const onExpiryTime = (value, dateString) => {
    setExpiryTime(value);
  };
  const onDiscountEndChange = (value, dateString) => {
    setDiscountEnd(value);
  };


  const onNameChangeHandler = (fullName) => {
    let currentPage = 1;
    let postsPerPage = 50;
    let keyWord = fullName;
    dispatch({
      type: "FETCH_USERS_LIST_REQUEST",
      payload: { myKeyword:keyWord, currentPage, postsPerPage },
    });
  }


  return (
    <div>
      <div className="Modal--heading">Add Resellar</div>
      <div className="Modal--form">
        <form>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Full Name / Email / Phone : </label>
              <input
                type="text"
                value={fullName}
                placeholder="Search For Customer"
                onChange={(e) =>{ onNameChangeHandler(e.target.value);setFullName(e.target.value)}}
              />
            </p>
          </div>
          {isLoadingOnLogin && <div style={{margin:"auto",display:"flex",justifyContent:"center"}}><LoadingComponent /></div> }
          {selectedCustomer != "" && !searchUsersList?.data?.length && (
            <div className="searchMenu--box--restaurant">
              <p>Selected Customer Name : {selectedCustomer?.fullName}</p>
    
            </div>
          )}
          <div className="addAdvertisement--restaurant--container">
              {searchUsersList?.data?.length > 0 
                ? searchUsersList.data?.map((rest) => (
                    <>
                      <div
                        className="searchByRestaurant--body__item"
                        onClick={() => {
                          setSelectedCustomer(rest);
                          dispatch({
                            type: "CLEAR_USER_SEARCH_LIST",
                          });
                        }}
                      >
                        <div   className="restaurant--details--container"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                            width: "100%",
                          }}>


                          <p>{rest.fullName}</p>
                      
                        </div>
                        <div className="restaurant--image--container">
                          <img src={rest.userImage} alt="Reseller Image" />
                        </div>
    
                      </div>
                    </>
                  ))
                : null}
            </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Resellar Code: </label>
              <input
                type="text"
                value={resellarCode}
                placeholder="Resellar Code"
                onChange={(e) => setResellarCode(e.target.value)}
              />
            </p>
          </div>

          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Referral Amount / User : </label>
              <input
                type="number"
                value={referralAmount}
                placeholder="Referral Amount"
                onChange={(e) => setReferralAmount(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Referre Amount / Reseller : </label>
              <input
                type="number"
                placeholder="Referre Amount"
                value={referreAmount}
                onChange={(e) => setRefereeAmount(e.target.value)}
              />
            </p>
          </div>

          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Businees Name : </label>
              <input
                type="text"
                placeholder="Business Name"
                value={businessName}
                onChange={(e) => setBuinessName(e.target.value)}
              />
            </p>
          </div>

          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Discount Percentage : </label>
              <input
                type="number"
                value={discountPercentage}
                placeholder="Discount Percentage"
                onChange={(e) => setDiscountPercentage(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Expiry Time: </label>
              <DatePicker
              value={expiryTime}
                style={{
                  marginLeft: "2rem",
                  marginTop: "1rem",
                  borderRadius: "5px",
                  width: "53.5%",
                }}
                showTime
                onChange={onExpiryTime}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Discount End: </label>
              <DatePicker
              value={discountEnd}
                style={{
                  marginLeft: "2rem",
                  marginTop: "1rem",
                  borderRadius: "5px",
                  width: "53.5%",
                }}
                showTime
                onChange={onDiscountEndChange}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Payment Methods : </label>
              <Checkbox.Group
              value={paymentMethod}
                onChange={onChange}
                style={{ marginLeft: "2rem", width: "25%", marginTop: "1rem" }}
              >
                <Row>
                  <Col span={8}>
                    <Checkbox value="KHALTI">Khalti</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="STRIPE">Stripe</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox
                      value="CASH_ON_DELIVERY"
                      style={{ width: "200px" }}
                    >
                      Cash On Delivery
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Is One Time Referral / User: </label>
              <Radio.Group
                style={{ marginLeft: "2rem", marginTop: "1rem" }}
                onChange={onChange1}
                value={isOneTimeReferral}
              >
                <Radio value={true}>Yes</Radio>
                <Radio style={{ marginLeft: "2rem" }} value={false}>
                  No
                </Radio>
              </Radio.Group>
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Is One Time Referee / Reseller: </label>
              <Radio.Group
                style={{ marginLeft: "2rem", marginTop: "1rem" }}
                onChange={onChange2}
                value={isOneTimeReferee}
              >
                <Radio value={true}>Yes</Radio>
                <Radio style={{ marginLeft: "2rem" }} value={false}>
                  No
                </Radio>
              </Radio.Group>
            </p>
          </div>

          <div className="modal--container__group--buttons">
            <button
              style={{ marginBottom: "50px" }}
              type="submit"
              className="btn btn-primary"
              onClick={handleAddResellar}
            >
              {buttonText}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(AddResellar);
