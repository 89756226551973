import React, { useState, useEffect, useRef } from "react";
import { useDispatch, connect } from "react-redux";
import axios from "axios";
import { getCookie } from "../../FrontendHelper";
// import { LatLangGenerator } from '../Coupon/LatLangGenerator';
import { Radio, Checkbox, Col, Row } from "antd";
const mapStateToProps = (store) => {
  const { buttonText, isEnabledUsers } = store.mainState;
  return { buttonText, isEnabledUsers };
};

function AddDelivery({ buttonText, isEnabledUsers }) {
  const [fullName, setFullName] = useState("");
  const [userName, setUserName] = useState("");

  const [bikeType, setBikeType] = useState("");
  const [gender, setGender] = useState("");
  const [restEmail, setRestEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [image, setImage] = useState("");
  const [dob, setDob] = useState("");
  const [identificationType, setIdentificationType] = useState("");
  const [identificationNo, setIdentificationNo] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSuccessImgUrl, setIsSuccessImgUrl] = useState(false);
  const clearFileInputRef = useRef(null);
  const [bikeNumber, setBikeNumber] = useState("");
  const [bikeModel, setBikeModel] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [location, setLocation] = useState("");
  const [radiusOS, setRadiusOS] = useState(10);
  const [bypassDriver, setBypassDriver] = useState(false);
  const [workShift, setWorkShift] = useState([]);
  const [isNightShiftAvailable, setIsNightShiftAvailable] = useState(false);
  const [dayTimeAvailability, setDayTimeAvailability] = useState(false);

  const dispatch = useDispatch();

  const handlelatLong = (myvalue) => {
    setLatitude(myvalue.lat);
    setLongitude(myvalue.lng);
  };
  const handleAddress = (address) => {
    setLocation(address);
  };

  const onChange1 = (e) => {
    setBypassDriver(e.target.value);
  };
  const onNightShiftChange = (e) => {
    setIsNightShiftAvailable(e.target.value);
  };
  const onDayShiftChange = (e) => {
    setDayTimeAvailability(e.target.value);
  };
  const onChange = (checkedValues) => {
    setWorkShift(checkedValues);
  };
  useEffect(() => {
    const Token = getCookie("token");
    let myDataform = new FormData();
    myDataform.append("attachment", image);
    setLoading(true);
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_FAKE}/restaurant/v1/api/admin/add-delivery-user/image`,
      // url: "https://app.gurugfood.com:8443/restaurant/v1/api/admin/add-delivery-user/image",
      data: myDataform,
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => {
        setImageUrl(response.data.imageUrl);
        setLoading(false);
        setIsSuccessImgUrl(!isSuccessImgUrl);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [image]);
  function getRandomString(length) {
    var randomChars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var result = "";
    for (var i = 0; i < length; i++) {
      result += randomChars.charAt(
        Math.floor(Math.random() * randomChars.length)
      );
    }
    return result;
  }

  const handleDeliverySubmit = (e) => {
    e.preventDefault();
    dispatch({
      type: "ADD_DELIVERY_REQUEST",
      payload: {
        imageUrl: imageUrl,
        email: restEmail,
        phone: phoneNo,
        fullName: fullName,
        userName: userName,
        gender: gender,
        dob: dob,
        location: location,
        bikeNo: bikeNumber,
        bikeType: bikeType,
        bikeModel: bikeModel,
        identificationType: identificationType,
        identificationNo: identificationNo,
        longitude: longitude,
        latitude: latitude,
        deliveryRadius: 10,
        country: "Nepal",
        workShift: workShift,
        nightTimeAvailability: isNightShiftAvailable,
        dayTimeAvailability: dayTimeAvailability,
      },
    });
  };
  useEffect(() => {
    if (isEnabledUsers === "b") {
      clearFileInputRef.current.value = "";
      setRestEmail("");
      setPhoneNo("");
      setFullName("");
      setUserName("");
      setGender("");
      setDob("");
      setLocation("");
      setBikeNumber("");
      setBikeType("");
      setBikeModel("");
      setIdentificationType("");
      setIdentificationNo("");
      setLatitude("");
      setLongitude("");
      setRadiusOS("");
      setIsNightShiftAvailable("");
      setWorkShift("");
      setDayTimeAvailability("");
    }
  }, [isEnabledUsers]);
  return (
    <div>
      <div className="Modal--heading">Add Delivery</div>
      <div className="Modal--form">
        <form>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Image : </label>
              <input
                type="file"
                ref={clearFileInputRef}
                name="image"
                accept="image/*"
                onChange={(e) => setImage(e.target.files[0])}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Full Name: </label>
              <input
                type="text"
                value={fullName}
                placeholder="Full Name"
                onChange={(e) => setFullName(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">User Name: </label>
              <input
                type="text"
                value={userName}
                placeholder="User Name"
                onChange={(e) => setUserName(e.target.value)}
              />
            </p>
          </div>

          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Gender: </label>
              <select
                value={gender}
                onChange={(e) => setGender(e.target.value)}
              >
                <option>Select</option>
                <option value="MALE">Male</option>
                <option value="FEMALE">Female</option>
                <option value="OTHERS">Others</option>
              </select>
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Date Of Birth : </label>
              <input
                type="date"
                value={dob}
                placeholder="Date of Birth"
                onChange={(e) => setDob(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Phone No : </label>
              <input
                type="text"
                value={phoneNo}
                placeholder="Phone Number"
                onChange={(e) => setPhoneNo(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Email : </label>
              <input
                type="email"
                placeholder="Email"
                value={restEmail}
                onChange={(e) => setRestEmail(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Identification Type: </label>
              <select
                value={identificationType}
                onChange={(e) => setIdentificationType(e.target.value)}
              >
                <option>Select</option>
                <option value="CITIZENSHIP">Citizenship</option>
                <option value="LICENCE">Licence</option>
                <option value="OTHER">Other</option>
              </select>
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Identification No. : </label>
              <input
                type="text"
                placeholder="Identification Number"
                value={identificationNo}
                onChange={(e) => setIdentificationNo(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Bike Type: </label>
              <select
                value={bikeType}
                onChange={(e) => setBikeType(e.target.value)}
              >
                <option>Select</option>
                <option value="BIKE">Bike</option>
                <option value="CYCLE">Cycle</option>
              </select>
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Bike Model : </label>
              <input
                type="text"
                value={bikeModel}
                placeholder="Bike Model"
                onChange={(e) => setBikeModel(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Bike Number : </label>
              <input
                type="text"
                value={bikeNumber}
                placeholder="Bike Number"
                onChange={(e) => setBikeNumber(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Work Shift : </label>
              <Checkbox.Group
                value={workShift}
                onChange={onChange}
                style={{ marginLeft: "2rem", width: "25%", marginTop: "1rem" }}
              >
                <Row>
                  <Col span={8}>
                    <Checkbox style={{ width: "200px" }} value="PART_TIME">
                      Part Time
                    </Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox style={{ width: "200px" }} value="FULL_TIME">
                      Full Time
                    </Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="CASUAL" style={{ width: "200px" }}>
                      Casual
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Is Night Shift Available : </label>
              <Radio.Group
                defaultValue={false}
                style={{ marginLeft: "2rem", marginTop: "1rem" }}
                onChange={onNightShiftChange}
                value={isNightShiftAvailable}
              >
                <Radio value={true}>Yes</Radio>
                <Radio style={{ marginLeft: "2rem" }} value={false}>
                  No
                </Radio>
              </Radio.Group>
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Is Day Shift Available : </label>
              <Radio.Group
                defaultValue={false}
                style={{ marginLeft: "2rem", marginTop: "1rem" }}
                onChange={onDayShiftChange}
                value={dayTimeAvailability}
              >
                <Radio value={true}>Yes</Radio>
                <Radio style={{ marginLeft: "2rem" }} value={false}>
                  No
                </Radio>
              </Radio.Group>
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Bypassed Driver Except Flow: </label>
              <Radio.Group
                defaultValue={false}
                style={{ marginLeft: "2rem", marginTop: "1rem" }}
                onChange={onChange1}
                value={bypassDriver}
              >
                <Radio value={true}>Yes</Radio>
                <Radio style={{ marginLeft: "2rem" }} value={false}>
                  No
                </Radio>
              </Radio.Group>
            </p>
          </div>

          <div className="modal--container__group--buttons">
            <button
              style={{ marginBottom: "50px" }}
              type="submit"
              className="btn btn-primary"
              onClick={handleDeliverySubmit}
            >
              {buttonText}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(AddDelivery);
