import React, { useState, useEffect, useRef } from "react";
import { useDispatch, connect } from "react-redux";
import axios from "axios";
import { getCookie } from "../../../FrontendHelper";
import { Checkbox, Col, Row, Radio, DatePicker } from "antd";
import moment from "moment";
import LoadingComponent from "../../LoadingComponent";
import { toast } from "react-toastify";

const mapStateToProps = (store) => {
  const {
    buttonText,
    isEnabledUsers,
    searchUsersList,
    isLoadingOnLogin,
    isOperationSuccessful,
  } = store.mainState;
  return {
    buttonText,
    isEnabledUsers,
    searchUsersList,
    isLoadingOnLogin,
    isOperationSuccessful,
  };
};

function AddCategory({
  buttonText,
  searchUsersList,
  isLoadingOnLogin,
  isOperationSuccessful,
  restaurantId,
}) {
  const [categoryName, setCategoryName] = useState("");
  const [categoryImage, setCategoryImage] = useState("");
  const [description, setDescription] = useState("");

  const dispatch = useDispatch();

  const handleAddCategory = (e) => {
    e.preventDefault();
    if (!categoryImage || !categoryName || !description) {
      return toast.error("Please fill all the fields !!");
    }
    dispatch({
      type: "ADD_CATEGORY_REQUEST",
      payload: {
        categoryName: categoryName,
        imageUrl: categoryImage,
        description: description,
        restaurantId: restaurantId,
      },
    });
  };
  useEffect(() => {
    if (isOperationSuccessful) {
      setCategoryImage("");
      setCategoryImage("");
      setDescription("");
    }
  }, [isOperationSuccessful]);

  const imageChangeHandler = (e) => {
    const Token = getCookie("token");
    let myDataform = new FormData();
    myDataform.append("attachment", e.target.files[0]);
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_FAKE}/restaurant/v1/api/admin/add-delivery-user/image`,
      // url: "https://app.gurugfood.com:8443/restaurant/v1/api/admin/add-delivery-user/image",
      data: myDataform,
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => {
        setCategoryImage(response.data.imageUrl);
      })
      .catch((error) => {});
  };
  return (
    <div>
      <div className="Modal--heading">Add Category</div>
      <div className="Modal--form">
        <form>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Category Name: </label>
              <input
                type="text"
                value={categoryName}
                placeholder="Category Name"
                onChange={(e) => setCategoryName(e.target.value)}
              />
            </p>
          </div>

          <div className="Modal--form--title">
            <p>
              <label htmlFor="title"> Category Image: </label>
              <input
                type="file"
                placeholder="category Image"
                onChange={(e) => imageChangeHandler(e)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Description : </label>
              <input
                type="text"
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </p>
          </div>

          <div className="modal--container__group--buttons">
            <button
              style={{ marginBottom: "50px" }}
              type="submit"
              className="btn btn-primary"
              onClick={handleAddCategory}
            >
              {buttonText}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(AddCategory);
