import React, { useState, useEffect } from "react";
import { TiShoppingCart } from "react-icons/ti";
import { BsPlus, BsDash } from "react-icons/bs";
import { GoNote } from "react-icons/go";
import {useDispatch} from 'react-redux'
import { toast } from "react-toastify";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

function AdjustItemsCount({ item,prevCount }) {
  const [itemCount, setItemCount] = useState(0);
  const [note,setNote] = useState("")
  const [showAddNoteModal,setShowAddNoteModal] = useState(false)
  const [showNoteIcon,setShowNoteIcon ] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    if (prevCount==="yes") {
      setItemCount(x=> x + 1)
    }
  }, [prevCount]);

  return (
    <div className="adjustItemCount">
      <p className="adjustItemCount--item__name">{item.itemName}</p>
      <p>Rs. {item.price}</p>
      <div className="plus--minus">
        <p
          onClick={() => {
            setItemCount((prev) => prev + 1);
          }}>
          <BsPlus />
        </p>
        <p>{itemCount}</p>
        <p
          onClick={() => {
            if (itemCount > 0) {
              setItemCount((prev) => prev - 1);
            }
          }}>
          <BsDash />
        </p>
      </div>
      <p>
          <TiShoppingCart className="addTo-cart__icon" onClick={()=> {
            if(itemCount<=0) {
              toast.error("Add item at least One to the cart.")
            }
            else {
              dispatch({
                type: "ADD_CART_ITEM_TO_ARRAY",
                payload: {  
                  "itemId":item.id,
                  "itemName": item.itemName,
                  "quantity":itemCount,
                  "price":item.price,
                  "note": note
              }
              })
              setShowNoteIcon(true)
            }
            
            
          }} />
        </p>
        {showNoteIcon ?<Tippy content="Add Note" placement="top"><p onClick={()=> setShowAddNoteModal(true)}><GoNote className="addTo-cart__icon" /></p></Tippy>:null}
        {showAddNoteModal ? <div className="operatingLocation">
                <div className="operatingLocation--container">
                    <p>Add your note here.</p>
                    {/* <hr /> */}
                    <div className="Modal--form" style={{marginTop: "-10px"}}>
                    <div className="Modal--form--title" >
                      <hr />
                        <p><label htmlFor="title">Note : </label>
                            <input type="text" placeholder="Add Note" style={{width: "20vw"}} value={note} onChange={(e)=> setNote(e.target.value)} />
                        </p>
                    </div>
                    <div className="button_container">
                        <button onClick={()=> {
                            setShowAddNoteModal(false)
                        }}>Cancel</button>
                        <button onClick={()=> {
                          dispatch({
                            type: "ADD_CART_ITEM_TO_ARRAY",
                            payload: {  
                              "itemId":item.id,
                              "itemName": item.itemName,
                              "quantity":itemCount,
                              "price":item.price,
                              "note": note
                          }
                          
                          })
                          setShowAddNoteModal(false)
                          setShowAddNoteModal(false)
                        }}>Submit</button>
                    </div>
                    </div>
                    
                </div>
            </div>: null
}
        </div>
  );
}

export default AdjustItemsCount;
