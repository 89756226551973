import React, { useState, useEffect } from "react";
import Layout from "../../Layout/Layout";
import { AiFillEdit, AiFillEye } from "react-icons/ai";
// import {RiAddBoxFill} from 'react-icons/ri'
import { BiChevronLeft, BiChevronRight, BiCut } from "react-icons/bi";
import { BsCheckCircle } from "react-icons/bs";
import { connect, useDispatch } from "react-redux";
import LoadingComponent from "../LoadingComponent";
import Footer from "../Z_Footer/Footer";
import { BsX } from "react-icons/bs";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import moment from "moment";
import EditReseller from "./EditReseller";
import ResellerDetail from "./ResellerDetail";

const mapStateToProps = (store) => {
  const {
    isToogleSidebar,
    advertisementList,
    isLoadingOnLogin,
    isEnabledUsers,
    isVerifyChanged,
    isOperationSuccessful,
    resellerList,
    isResellarEditModal,
    
  } = store.mainState;
  return {
    isToogleSidebar,
    advertisementList,
    isLoadingOnLogin,
    isEnabledUsers,
    isVerifyChanged,
    isOperationSuccessful,
    resellerList,
    isResellarEditModal,
  };
};

function Reseller({

  isToogleSidebar,
  isLoadingOnLogin,
  isVerifyChanged,
  isOperationSuccessful,
  resellerList,
  isResellarEditModal,
}) {
  const [edtingItem, setEditingItem] = useState(null);
  // for Enable/Disable adds
  const [enableDisableItem, setEnableDisableItem] = useState({});


  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  let pageNumbers = [];

  // get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts =
    resellerList !== undefined &&
    resellerList?.data?.slice(indexOfFirstPost, indexOfLastPost);
  const [activateConfirmationText, setactivateConfirmationText] = useState("");
  const [deactivateConfirmationText, setdeactivateConfirmationText] =
    useState("");
  const [activateModal, setActivateModal] = useState("");
  const [deactivateModal, setDeactivateModal] = useState("");
  const [showResellerDetailModal, setShowResellerDetailModal] = useState(false);
  const [resellerId,setResellerId] = useState("")


  for (let i = 1; i <= resellerList.totalPage; i++) {
    pageNumbers.push(i);
  }

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "FETCH_RESELLER_REQUEST",
      payload: { postsPerPage, currentPage },
    });
  }, [isVerifyChanged, postsPerPage, currentPage, isOperationSuccessful]);


  useEffect(() => {
   
    if(isOperationSuccessful){
      setActivateModal(false)
      setDeactivateModal(false)
      setactivateConfirmationText("")
      setdeactivateConfirmationText("")
    }
   
  }, [isOperationSuccessful]);
  return (
    <Layout>
      <div>
      
        <div className="user_groups">
          <div className="user_groups--button_n_search">
            <div className="user_groups--button_n_search--button">
              <h4>Resellers</h4>
              <button onClick={() => dispatch({ type: "OPEN_RESELLAR_MODAL" })}>
                Add <i className="bi bi-plus c-plus" />
              </button>
            </div>
            <div className="user_groups--button_n_search--search">
              <input type="text" placeholder="Search" />
            </div>
          </div>
          <p className="privacy-loading--onFetching">
            {isLoadingOnLogin && <LoadingComponent />}
          </p>
          <div className="table-container">
            <table className="table_x">
              <thead>
                <tr>
                  <th className="securityQtn--image">Name</th>
                  <th className="securityQtn--qtn">Reseller Code</th>
                  <th className="securityQtn--state">Referral Amount</th>
                  <th className="securityQtn--updatedBy">Referrer Amount</th>
                  <th className="securityQtn--updatedBy">Payment Method</th>
                  <th className="securityQtn--actions">One Time Referral</th>
                  <th className="securityQtn--actions">One Time Referrer</th>
                  <th className="securityQtn--state">Expiry Time</th>
                  <th className="securityQtn--state">Business Name</th>
                  <th className="securityQtn--state">Referee Discount End</th>
                  <th className="securityQtn--state">Discount Percentage</th>
                  <th className="securityQtn--state">Active</th>
                  <th className="securityQtn--actions">Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentPosts?.length !== 0 &&
                  currentPosts?.map((item) => (
                    <tr key={item.id}>
                      <td>{item.name}</td>
                      <td>{item.resellerCode}</td>
                      <td>{item.referralAmount}</td>
                      <td>{item.referrerAmount}</td>
                      <td>
                        {item.applicablePaymentMethods?.map((item) => (
                          <p>{item.paymentMethod}</p>
                        ))}
                      </td>
                      <td>
                        <span
                          style={{
                            backgroundColor: "purple",
                            padding: "2px 5px",
                            borderRadius: "4px",
                            color: "white",
                          }}
                        >
                          {item.isOneTimeReferral ? "Yes" : "No"}
                        </span>
                      </td>
                      <td>
                        <span
                          style={{
                            backgroundColor: "purple",
                            padding: "2px 5px",
                            borderRadius: "4px",
                            color: "white",
                          }}
                        >
                          {item.isOneTimeReferrer ? "Yes" : "No"}
                        </span>
                      </td>
                      <td>
                        {" "}
                        {moment(item.expiryTime).format("YYYY-MM-DD HH:mm:ss")}
                      </td>
                      <td>{item.businessName}</td>
                      <td>
                        {moment(item.refereeDiscountEnd).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )}
                      </td>
                      <td>{item.discountPercentage}</td>
                      <td>
                        {" "}
                        <span
                          style={{
                            backgroundColor: item.isActive ? "green" : "red",
                            padding: "2px 5px",
                            borderRadius: "4px",
                            color: "white",
                          }}
                        >
                          {item.isActive ? "Yes" : "No"}
                        </span>
                      </td>
                      <td>
                        <Tippy content="Edit" placement="bottom">
                          <span>
                            <AiFillEdit
                              className="edit--icon"
                              onClick={() => {
                                dispatch({ type: "OPEN_RESELLAR_EDIT_MODAL" });
                                setEditingItem("")
                                setEditingItem(item);
                              }}
                            />{" "}
                          </span>
                        </Tippy>
                        <Tippy
                          content={
                            <span>
                              {item.isActive ? "De-activate" : "Activate"}
                            </span>
                          }
                          placement="top"
                        >
                          <span>
                            <BsCheckCircle
                              className="action--eye__admin"
                              onClick={() => {
                                if (item.isActive) {
                                  setDeactivateModal(true);
                                } else {
                                  setActivateModal(true);
                                }
                                setEnableDisableItem(item);
                              }}
                            />{" "}
                          </span>
                        </Tippy>
                        <Tippy
                          content={
                            "View"
                          }
                          placement="top"
                        >
                          <span>
                            <AiFillEye
                              className="action--eye__admin"
                              onClick={() => {
                                setResellerId(item.resellerId)
                                setShowResellerDetailModal(true)
                              }}
                            />{" "}
                          </span>
                        </Tippy>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {/* Update delivery user details */}
            <div
              className={`${
                isResellarEditModal ? "mymodal modal_activated" : "mymodal"
              }`}
            >
              <div
                className={`${
                  !isToogleSidebar
                    ? "manage_modal--container "
                    : "manage_modal--container sidebarOpend_F_modal"
                }`}
              >
                {edtingItem !== null && <EditReseller item={edtingItem} />}
              </div>
              <div
                className={`${
                  !isToogleSidebar
                    ? "task--rectangle"
                    : "task--rectangle withsidebarOpend"
                }`}
              >
                <div className="task--rectangle__circle">
                  <div className="circle">
                    {" "}
                    <span
                      onClick={() => {
                        setEditingItem(" ")
                        dispatch({
                          type: "OPEN_RESELLAR_EDIT_MODAL",
                        });
                        dispatch({
                          type: "FETCH_RESELLER_REQUEST",
                          payload: {
                            postsPerPage,
                            currentPage,
                          },
                        });
                      }}
                    >
                      <BsX />
                    </span>
                    <p>Close</p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`${
                activateModal ? "delete--admin" : "delete--admin__hidden"
              }`}
            >
              <div className="delete--admin--container">
                <div
                  className="delete--admin--container__heading"
                  style={{ color: "black" }}
                >
                  Are you sure want to activate this Reseller ?
                </div>
                <>
                  <div className="delete--admin--container__body">
                    <div className="reason--enableDisable">
                      <label>Confirmation Text : </label>
                      <textarea
                        type="text"
                        style={{border:"1px solid #d7dbd8",borderRadius:"5px"}}

                        placeholder="Confirmation Text"
                        value={activateConfirmationText}
                        onChange={(e) =>
                          setactivateConfirmationText(e.target.value)
                        }
                      ></textarea>
                    </div>
                    {isLoadingOnLogin && <LoadingComponent />}
                  </div>
                  <div className="delete--admin--container__footer">
                    <button onClick={() => setActivateModal(!activateModal)}>
                      Cancel
                    </button>

                    <button
                      onClick={() => {
                        dispatch({
                          type: "ACTIVATE_RESELLER_REQUEST",
                          payload: {
                            id: enableDisableItem.resellerId,
                            values : {
                              confirmationText: activateConfirmationText
                            }
                          },
                        });
                      }}
                    >
                      Ok
                    </button>
                  </div>
                </>
              </div>
            </div>
            <div
              className={`${
                deactivateModal ? "delete--admin" : "delete--admin__hidden"
              }`}
            >
              <div className="delete--admin--container">
                <div
                  className="delete--admin--container__heading"
                  style={{ color: "black" }}
                >
                  Are you sure want to deactivate this Reseller ?
                </div>
                <>
                  <div className="delete--admin--container__body">
                    <div className="reason--enableDisable">
                      <label>Confirmation Text : </label>
                      <textarea
                        type="text"
                        style={{border:"1px solid #d7dbd8",borderRadius:"5px"}}
                        placeholder="Confirmation Text"
                        value={deactivateConfirmationText}
                        onChange={(e) =>
                          setdeactivateConfirmationText(e.target.value)
                        }
                      ></textarea>
                    </div>
                    {isLoadingOnLogin && <LoadingComponent />}
                  </div>
                  <div className="delete--admin--container__footer">
                    <button
                      onClick={() => setDeactivateModal(!deactivateModal)}
                    >
                      Cancel
                    </button>

                    <button
                      onClick={() => {
                        dispatch({
                          type: "DEACTIVATE_RESELLER_REQUEST",
                          payload: {
                            id: enableDisableItem.resellerId,
                            values: {
                              confirmationText: deactivateConfirmationText
                            }
                          },
                        });
                      }}
                    >
                      Ok
                    </button>
                  </div>
                </>
              </div>
            </div>

            <div
            className={`${
              showResellerDetailModal ? "mymodal modal_activated" : "mymodal"
            }`}
          >
            <div
              className={`${
                !isToogleSidebar
                  ? "manage_modal--container "
                  : "manage_modal--container sidebarOpend_F_modal"
              }`}
            >
              {resellerId !== null && <ResellerDetail id={resellerId} />}
            </div>
            <div
              className={`${
                !isToogleSidebar
                  ? "task--rectangle"
                  : "task--rectangle withsidebarOpend"
              }`}
            >
              <div className="task--rectangle__circle">
                <div className="circle">
                  {" "}
                  <span
                    onClick={() => {
                      setShowResellerDetailModal(!showResellerDetailModal);
                    }}
                  >
                    <BsX />
                  </span>
                  <p>Close</p>
                </div>
              </div>
            </div>
          </div>

            {/* update advertisement */}

            <div className="pagination">
              <p>
                Showing {indexOfFirstPost} to{" "}
                {indexOfLastPost > resellerList.length
                  ? resellerList.length
                  : indexOfLastPost}{" "}
                of {resellerList.totalData} entries
              </p>
              <nav aria-label="...">
                <ul className="pagination">
                  {currentPage === 1 ? (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronLeft />
                      </a>
                    </li>
                  ) : (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronLeft
                          onClick={() => setCurrentPage(currentPage - 1)}
                        />
                      </a>
                    </li>
                  )}
                  {pageNumbers.map((x) => (
                    <li key={x} className="page-item">
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => setCurrentPage(x)}
                      >
                        {x}
                      </a>
                    </li>
                  ))}
                  {currentPage == pageNumbers.length ? (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronRight />
                      </a>
                    </li>
                  ) : (
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <BiChevronRight
                          onClick={() => setCurrentPage(currentPage + 1)}
                        />
                      </a>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default connect(mapStateToProps, null)(Reseller);
