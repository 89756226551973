import React, { useState, useEffect, useRef } from "react";
import { useDispatch, connect } from "react-redux";
import axios from "axios";
import GoogleMap from "./GoogleMap";
import { getCookie } from "../../FrontendHelper";
import TimePicker from "react-time-picker";
import { MdDelete } from "react-icons/md";
import { GrDocumentCsv } from "react-icons/gr";
import { toast } from "react-toastify";

const mapStateToProps = (store) => {
  const { buttonText, operatingLocationList } = store.mainState;
  return { buttonText, operatingLocationList };
};

function AddRestaurant({ buttonText, operatingLocationList }) {
  const [isEnabledUser, setIsEnabledUser] = useState("");
  const [restaurantName, setRestaurantName] = useState("");
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [slogan, setSlogan] = useState("");
  const [restEmail, setRestEmail] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [image, setImage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSuccessImgUrl, setIsSuccessImgUrl] = useState(false);
  const clearFileInputRef = useRef(null);
  const [contactPersonNo, setContactNo] = useState("");
  const [ContactPersonName, setContactPersonName] = useState("");
  const [commissionRate, setCommissionRate] = useState("");
  const [openingTime, setOpeningTime] = useState("");
  const [closingTime, setClosingTime] = useState("");
  const [operatingLocation, setOperatingLocation] = useState(null);

  const [files, setFiles] = useState([]);
  const [restaurantImg, setRestaurantImg] = useState(null);
  const [csvFile, setCsvFile] = useState(null);

  // const [myImgObj,setMyImgObj] = useState({})
  // const [deletingImg,setDeletingImg] = useState(null)

  const dispatch = useDispatch();

  useEffect(() => {
    let postsPerPage = 50;
    let currentPage = 1;
    dispatch({
      type: "FETCH_OPERATING_LOCATION_REQUEST",
      payload: { postsPerPage, currentPage },
    });
  }, []);

  const handlelatLong = (myvalue) => {
    setLatitude(myvalue.lat);
    setLongitude(myvalue.lng);
  };
  const handleAddress = (address) => {
    setLocation(address);
  };
  const handleLoyalitySubmit = (e) => {
    e.preventDefault();
    if (!contactPersonNo) {
      return toast.error("Please enter Contact Person Number !");
    }

    const Token = getCookie("token");
    let openingTimeX = `2021-01-15 ${openingTime}`;
    let closingTimeX = `2021-01-15 ${closingTime}`;
    let m = new Date(openingTimeX).toUTCString().split(" ");
    let n = new Date(closingTimeX).toUTCString().split(" ");
    let myDataform = new FormData();
    myDataform.append("csvFile", csvFile);
    myDataform.append("restaurantImage", restaurantImg);
    files.map((item) => myDataform.append("menuImages", item));

    myDataform.append("restaurantName", restaurantName);
    myDataform.append("location", location);
    myDataform.append("latitude", Number(latitude));
    myDataform.append("longitude", Number(longitude));
    myDataform.append("phoneNumber", phoneNo);
    myDataform.append("slogan", slogan);
    myDataform.append("description", description);
    myDataform.append("email", restEmail);
    myDataform.append("imageUrl", imageUrl);
    myDataform.append("commissionRate", Number(commissionRate));
    myDataform.append("contactPersonName", ContactPersonName);
    myDataform.append("contactPersonPhone", contactPersonNo);
    myDataform.append("openingTime", m[4]);
    myDataform.append("closingTime", n[4]);
    myDataform.append("operatingLocation", operatingLocation);

    setLoading(true);
    // http://{{url}}:8080/restaurant/menu-upload
    if (csvFile === null) {
      toast.error("Please upload csv file first");
    } else if (restaurantImg === null) {
      toast.error("Please upload restaurant image first");
    } else {
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_FAKE}/restaurant/menu-upload`,
        // url: "https://app.gurugfood.com:8443/restaurant/v1/api/admin/restaurant/add-image",
        data: myDataform,
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      })
        .then((response) => {
          setIsEnabledUser("b");
          setLoading(false);
          setIsSuccessImgUrl(!isSuccessImgUrl);
        })
        .catch((error) => {
          setLoading(false);
          setIsEnabledUser("");
          toast.error("Please fill the form properly or something gone wrong");
        });
    }
  };

  useEffect(() => {
    if (isEnabledUser === "b") {
      setRestaurantName("");
      setLocation("");
      setLatitude("");
      setLongitude("");
      setPhoneNo("");
      setSlogan("");
      setDescription("");
      setRestEmail("");
      setCommissionRate("");
      setContactPersonName("");
      setContactNo("");
      clearFileInputRef.current.value = "";
      setOpeningTime("");
      setClosingTime("");
      setOperatingLocation(null);
      setIsEnabledUser("");
    }
  }, [isEnabledUser]);
  const fileSelectedHandler = (e) => {
    setFiles([...files, ...e.target.files]);
  };
  // useEffect(()=> {
  //     let newFiles = files.filter((item)=> item.name !== deletingImg)
  //     setFiles(newFiles)
  // },[deletingImg])

  return (
    <div>
      <div className="Modal--heading">Add Restaurant</div>
      <div className="Modal--form">
        <div className="csv--file_uploader">
          <h1>Upload CSV File</h1>
          <p>
            {csvFile?.name ? (
              <span>{csvFile.name}</span>
            ) : (
              <label style={{ fontSize: "40px" }}>
                <GrDocumentCsv />
              </label>
            )}

            <input
              type="file"
              placeholder="Email"
              ref={clearFileInputRef}
              name="image"
              accept=".csv"
              onChange={(e) => setCsvFile(e.target.files[0])}
            />
          </p>
        </div>
        <div className="Modal--form--title">
          <p>
            <label htmlFor="title">Menu Images : </label>
            <input type="file" multiple onChange={fileSelectedHandler} />
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div>
            {files?.length > 0 && (
              <p>
                <span style={{ fontWeight: "bold", fontSize: "18px" }}>
                  {files.length}
                </span>{" "}
                menu images are uploaded.
              </p>
            )}
          </div>
        </div>
        <div className="Modal--form--title">
          <p>
            <label htmlFor="title">Restaurant Image : </label>
            <input
              type="file"
              onChange={(e) => setRestaurantImg(e.target.files[0])}
            />
          </p>
        </div>
        <div className="Modal--form--title">
          <p>
            <label htmlFor="title">Restaurant Name: </label>
            <input
              type="text"
              value={restaurantName}
              placeholder="Restaurant Name"
              onChange={(e) => setRestaurantName(e.target.value)}
            />
          </p>
        </div>
        <div className="Modal--form--title">
          <p>
            <label htmlFor="title">Restaurant Phone No : </label>
            <input
              type="text"
              value={phoneNo}
              placeholder="Phone Number"
              onChange={(e) => setPhoneNo(e.target.value)}
            />
          </p>
        </div>
        <div className="Modal--form--title">
          <p>
            <label htmlFor="title">Contact Person Name : </label>
            <input
              type="text"
              value={ContactPersonName}
              placeholder="Contact Person Name"
              onChange={(e) => setContactPersonName(e.target.value)}
            />
          </p>
        </div>
        <div className="Modal--form--title">
          <p>
            <label htmlFor="title">Contact Person Phone No : </label>
            <input
              type="text"
              value={contactPersonNo}
              placeholder="Contact Person Phone Number"
              onChange={(e) => setContactNo(e.target.value)}
            />
          </p>
        </div>

        <div className="Modal--form--title">
          <p>
            <label htmlFor="title">Description: </label>
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              style={{ padding: "10px", minHeight: "50px" }}
              value={description}
              placeholder="Description"
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
          </p>
        </div>
        <div className="Modal--form--title">
          <p>
            <label htmlFor="title">Commission Rate(%) : </label>
            <input
              type="text"
              value={commissionRate}
              placeholder="Comission Rate (%)"
              onChange={(e) => setCommissionRate(e.target.value)}
            />
          </p>
        </div>
        <div className="Modal--form--title">
          <p>
            <label htmlFor="title">Slogan : </label>
            <input
              type="text"
              value={slogan}
              placeholder="Slogan"
              onChange={(e) => setSlogan(e.target.value)}
            />
          </p>
        </div>
        <div className="Modal--form--title">
          <p>
            <label htmlFor="title">Email : </label>
            <input
              type="email"
              placeholder="Email"
              value={restEmail}
              onChange={(e) => setRestEmail(e.target.value)}
            />
          </p>
        </div>
        <div className="Modal--form--title">
          <div className="restaurant--Modal--form__time">
            <p>
              <label htmlFor="title">Opening Time : </label>
              <TimePicker
                format="hh:mm a"
                onChange={setOpeningTime}
                name="Opening_Time"
                value={openingTime}
                className="myTime--picker"
                style={{ marginLeft: "20px" }}
              />
            </p>
          </div>
        </div>
        <div className="Modal--form--title">
          <div className="restaurant--Modal--form__time">
            <p>
              <label htmlFor="title">Closing Time : </label>
              <TimePicker
                format="hh:mm a"
                onChange={setClosingTime}
                name="Opening_Time"
                value={closingTime}
                className="myTime--picker"
              />
            </p>
          </div>
        </div>
        <div>
          <GoogleMap
            handlelatLong={handlelatLong}
            handleAddress={handleAddress}
          />
        </div>
        <div className="Modal--form--title">
          <p>
            <label htmlFor="title">Location : </label>
            <input
              type="text"
              value={location}
              disabled
              placeholder="Location"
            />
          </p>
        </div>
        <div className="Modal--form--title">
          <p>
            <label htmlFor="title">Latitude : </label>
            <input
              type="text"
              placeholder="Latitude"
              value={latitude}
              disabled
            />
          </p>
        </div>
        <div className="Modal--form--title">
          <p>
            <label htmlFor="title">Longitude : </label>
            <input
              type="text"
              placeholder="Longitude"
              value={longitude}
              disabled
            />
          </p>
        </div>
        <div className="Modal--form--title">
          <p>
            <label htmlFor="title">Operating Location : </label>
            <select onChange={(e) => setOperatingLocation(e.target.value)}>
              <option value="">Select</option>
              {operatingLocationList.data?.map((location) =>
                location.isActive ? (
                  <option key={location.id} value={location.locationName}>
                    {location.locationName}
                  </option>
                ) : null
              )}
            </select>
          </p>
        </div>

        <div className="modal--container__group--buttons">
          <button
            style={{ marginBottom: "50px" }}
            type="submit"
            className="btn btn-primary"
            onClick={handleLoyalitySubmit}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(AddRestaurant);
