import React from 'react'
import {Route} from 'react-router-dom'
import { isAuth } from '../../FrontendHelper'
import LoadingToRedirect from './LoadingToRedirect'


function PrivateRoute({children, ...rest}) {
    return isAuth() ? (
        <Route {...rest} render={()=> children} />
    ) : (
        <LoadingToRedirect />
    )
}

export default PrivateRoute
