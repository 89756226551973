import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import "tippy.js/dist/tippy.css";
import LoadingComponent from "../LoadingComponent";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import moment from "moment";
import { Descriptions } from "antd";

const mapStateToProps = (store) => {
  const { isLoadingOnLogin, resellerDetail } = store.mainState;
  return { isLoadingOnLogin, resellerDetail };
};

function PreOrderMerchantDetail({ preOrderMerchantData, isLoadingOnLogin,description }) {
  const [preOrderMerchantDatas, setPreOrderMerchantDatas] = useState({});
  useEffect(() => {
    setPreOrderMerchantDatas(preOrderMerchantData);
  }, [preOrderMerchantData]);

  console.log("Pre OrderMerchant Data", preOrderMerchantDatas);

  return (
    <div>
      <div className="Modal--heading">
        <span style={{ color: "#3e445e" }}>Preorder Merchant Details</span>
      </div>
      <p className="privacy-loading--onFetching">
        {isLoadingOnLogin && <LoadingComponent />}
        <Descriptions style={{padding:"1vw 2vw"}}>
          <Descriptions.Item label="Total Approved Order">
            {preOrderMerchantDatas?.salesDto?.totalApprovedOrder}
          </Descriptions.Item>
          <Descriptions.Item label="Total Dispatched Order">{preOrderMerchantDatas?.salesDto?.totalDispatchedOrder}</Descriptions.Item>
          <Descriptions.Item label="Total Order">{preOrderMerchantDatas?.salesDto?.totalOrder}</Descriptions.Item>
          <Descriptions.Item label="Total Order Amount">Rs {preOrderMerchantDatas?.salesDto?.totalOrderAmount}</Descriptions.Item>
          <Descriptions.Item label="Total Order Quantity">{preOrderMerchantDatas?.salesDto?.totalOrderQuantity}</Descriptions.Item>
          <Descriptions.Item label="Total Post Payment pending Order">{preOrderMerchantDatas?.salesDto?.totalPostPaymentPendingOrder}</Descriptions.Item>
          <Descriptions.Item label="Total Pre Paid Amount">Rs {preOrderMerchantDatas?.salesDto?.totalPrePaidAmount}</Descriptions.Item>
          <Descriptions.Item label="Total Rejected Order">{preOrderMerchantDatas?.salesDto?.totalRejectedOrder}</Descriptions.Item>
          <Descriptions.Item label="Total Requested Order">{preOrderMerchantDatas?.salesDto?.totalRequestedOrder}</Descriptions.Item>
          <Descriptions.Item label="Total Unpaid Amount">Rs {preOrderMerchantDatas?.salesDto?.totalUnpaidAmount}</Descriptions.Item>
        </Descriptions>
    
      </p>
      <div style={{padding:"1vw 2vw"}}>
          <h3 style={{fontSize:"23px"}}>Description:</h3>
          {description}
        </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(PreOrderMerchantDetail);
