import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, connect } from 'react-redux'
import 'react-toastify/dist/ReactToastify.min.css'
import { toast, ToastContainer } from 'react-toastify';
import { BsPhone } from "react-icons/bs";
import { IoLocationOutline } from "react-icons/io5";

const mapStateToProps = store => {
    const { updateButtonText, internalAdsLinks, isEnabledUsers,searchRestaurantList } = store.mainState
    return { updateButtonText, internalAdsLinks, isEnabledUsers,searchRestaurantList }
}

function UpdateExternalType({ updateButtonText, internalAdsLinks, isEnabledUsers,searchRestaurantList,item }) {
    const [advertisementId,setAdvertisementId] = useState(null)
    const [typeOfAdd, setTypeOfAdd] = useState("External")
    const clearFileInputRef = useRef()
    const dispatch = useDispatch();
    const [addsName, setAddsName] = useState("");
    const [addsDetails, setAddsDetails] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [addsImage, setAddsImage] = useState("")
    const [companyUrl, setCompanyUrl] = useState("");
    const [internalLink, setInternalLink] = useState("")
    const [searchKeyWord,setSearchKeyWord] = useState("")

    const [restaurantItem,setRestaurnatId] = useState(null)


    useEffect(() => {
        dispatch({ type: "FETCH_INTERNAL_ADS_LINK_REQUEST" })

    }, [])
    console.log("The item",item)
    console.log("the image addImage",addsImage)

    

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        if(addsImage !== "") {
            if(companyUrl !==null) {
                formData.append("advertisementLinkType", "EXTERNAL");
                formData.append("advertisementId", advertisementId);
            formData.append("advertisementName", addsName);
            formData.append("advertisementDetails", addsDetails);
            formData.append("companyName", companyName);
            formData.append("content", addsImage)
            formData.append("companyUrl", companyUrl);
            dispatch({
                type: "UPDATE_ADVERTISEMENT_REQUEST",
                payload: formData
            })
            } else {
                formData.append("advertisementLinkType", "EXTERNAL");
                formData.append("advertisementId", advertisementId);
            formData.append("advertisementName", addsName);
            formData.append("advertisementDetails", addsDetails);
            formData.append("companyName", companyName);
            formData.append("content", addsImage)
            dispatch({
                type: "UPDATE_ADVERTISEMENT_REQUEST",
                payload: formData
            })
            }
            
        }else {
            if(companyUrl !== "") {
                formData.append("advertisementLinkType", "EXTERNAL");
                formData.append("advertisementId", advertisementId);
            formData.append("advertisementName", addsName);
            formData.append("advertisementDetails", addsDetails);
            formData.append("companyName", companyName);
            dispatch({
                type: "UPDATE_ADVERTISEMENT_REQUEST",
                payload: formData
            })
            } else {
                formData.append("advertisementLinkType", "EXTERNAL");
                formData.append("advertisementId", advertisementId);
            formData.append("advertisementName", addsName);
            formData.append("advertisementDetails", addsDetails);
            formData.append("companyName", companyName);
            formData.append("companyUrl", companyUrl);
            dispatch({
                type: "UPDATE_ADVERTISEMENT_REQUEST",
                payload: formData
            })
            }
        }       
    }
    useEffect(() => {
        setAdvertisementId(item?.id)
        setTypeOfAdd(item?.advertisementLinkType === "INTERNAL" ? "Internal": "External")
        setAddsName(item?.advertisementName)
        setAddsDetails(item?.advertisementDetails)
        setCompanyName(item?.companyName)
        setCompanyUrl(item?.companyURL)

        setInternalLink(item?.internalLink?.internalLink)
    }, [item])
    useEffect(()=> {
    let currentPage = 1;
    let postsPerPage = 50;
    let keyWord = searchKeyWord
    dispatch({
      type: "FETCH_RESTAURANT_LIST_REQUEST",
      payload: { keyWord, currentPage, postsPerPage },
    });
    },[searchKeyWord])

    return (
        <div>
            <ToastContainer />
            <div className="Modal--heading">
                Edit Advertisement
            </div>
            <div className="addSelector--button">
                <div className="addSelector--button__container">
                    {typeOfAdd === "External" ? <div className={typeOfAdd === "External" && "external--link"} >External Link</div>: <div className={typeOfAdd === "Internal" && "external--link"}>Internal Link</div>}
                </div>
            </div>
            {typeOfAdd === "External" && <div className="Modal--form">
                <form>
                    <div className="Modal--form--title">
                        <p><label htmlFor="added_by">Ads Image*: </label>
                            <input type="file" accept='image/*' ref={clearFileInputRef} onChange={(e) => setAddsImage(e.target.files[0])} /></p>
                    </div>
                    <div className="Modal--form--title">
                        <p><label htmlFor="added_by">Advertisement Name*: </label>
                            <input type="text" value={addsName} placeholder="Advertisement Name..." onChange={(e) => setAddsName(e.target.value)} /></p>
                    </div>
                    <div className="Modal--form--title">
                        <p><label htmlFor="added_by">Advertisement Details*: </label>
                            <input type="text" value={addsDetails} placeholder="Advertisement Details..." onChange={(e) => setAddsDetails(e.target.value)} /></p>
                    </div>
                    <div className="Modal--form--title">
                        <p><label htmlFor="added_by">Company Name*: </label>
                            <input type="text" placeholder="Company Name..." value={companyName} onChange={(e) => setCompanyName(e.target.value)} /></p>
                    </div>

                    <div className="Modal--form--title">
                        <p><label htmlFor="title">Company Url(Opt): </label><input type="url" required placeholder="Enter Url" value={companyUrl} onChange={(e) => setCompanyUrl(e.target.value)} /></p>
                    </div>

                    <div className="modal--container__group--buttons">
                        <button style={{ marginBottom: "50px" }} type="submit" className="btn btn-primary" onClick={handleSubmit}>
                            {updateButtonText}
                        </button>
                    </div>
                </form>
            </div>}
            {typeOfAdd === "Internal" && <div className="Modal--form">
                <form>
                    <div className="Modal--form--title">
                        <p><label htmlFor="added_by">Internal Link: </label>
                            <select name="" id="" onChange={(e) => setInternalLink(e.target.value)}>
                                {internalAdsLinks !== undefined && internalAdsLinks.map((item) => <option key={item} value={item}>{item}</option>)}
                            </select></p>
                    </div>
                
                    <div className="Modal--form--title">
                        <p><label htmlFor="added_by">Ads Image*: </label>
                            <input type="file" accept='image/*' ref={clearFileInputRef} onChange={(e) => setAddsImage(e.target.files[0])} /></p>
                    </div>
                    <div className="Modal--form--title">
                        <p><label htmlFor="added_by">Advertisement Name*: </label>
                            <input type="text" value={addsName} placeholder="Advertisement Name..." onChange={(e) => setAddsName(e.target.value)} /></p>
                    </div>
                    <div className="Modal--form--title">
                        <p><label htmlFor="added_by">Advertisement Details*: </label>
                            <input type="text" value={addsDetails} placeholder="Advertisement Details..." onChange={(e) => setAddsDetails(e.target.value)} /></p>
                    </div>
                    <div className="Modal--form--title">
                        <p><label htmlFor="added_by">Company Name*: </label>
                            <input type="text" placeholder="Company Name..." value={companyName} onChange={(e) => setCompanyName(e.target.value)} /></p>
                    </div>

                    <div className="Modal--form--title">
                        <p><label htmlFor="title">Company Url(Otp): </label><input type="url" required placeholder="Enter Url" value={companyUrl} onChange={(e) => setCompanyUrl(e.target.value)} /></p>
                    </div>

                    <div className="modal--container__group--buttons">
                        <button style={{ marginBottom: "50px" }} type="submit" className="btn btn-primary" onClick={handleSubmit}>
                            {updateButtonText}
                        </button>
                    </div>
                </form>
            </div>}
        </div>
    )
}

export default connect(mapStateToProps, null)(UpdateExternalType)

