import React, { useEffect, useState } from 'react'
import Layout from '../../Layout/Layout'
import { AiFillEdit } from 'react-icons/ai'
// import {RiAddBoxFill} from 'react-icons/ri'
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi'
import { BsX } from "react-icons/bs";
import { connect, useDispatch } from 'react-redux'
import LoadingComponent from '../LoadingComponent'
import Footer from '../Z_Footer/Footer'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import EditDeliveryFee from './EditDeliveryFee'
import EditCommissionFee from './EditCommissionFee';


const mapStateToProps = store => {
    const { buttonText, isLoadingOnLogin, deliveryCommData, isToogleSidebar ,isEnabledUser} = store.mainState;
    return { buttonText, isLoadingOnLogin, deliveryCommData, isToogleSidebar,isEnabledUser }
}

function CommissionFee({ buttonText, isLoadingOnLogin, deliveryCommData, isToogleSidebar,isEnabledUser }) {
    const [showEditModal, setShowEditModal] = useState(false)
    const [commissionFee,setCommissionfee] = useState(null)

    // Pagination
    const [currentPage, setCurrentPage] = useState(1)
    const [postsPerPage, setPostsPerPage] = useState(10);
    const [currentPosts, setCurrentPosts] = useState([])

    let pageNumbers = []

    // get current posts
    const indexOfLastPost = currentPage * postsPerPage
    const indexOfFirstPost = indexOfLastPost - postsPerPage

    for (let i = 1; i <= 1; i++) {
        pageNumbers.push(i)
    }

    const [rate, setRate] = useState(null)
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({
            type: "FETCH_DELIVERY_COMMISSION_FEE_REQUEST",
        })
    }, [])

    useEffect(()=> {
        if(isEnabledUser=== "b") {
            setShowEditModal(false)
            dispatch({
                type: "FETCH_DELIVERY_COMMISSION_FEE_REQUEST",
            })

        }
    },[isEnabledUser])
   

    return (
        <Layout>
            <div>
                <div className="group_list">
                    <h4>Manage Delivery Commission Fee</h4>
                </div>
                <div className="user_groups">
                    <div className="user_groups--button_n_search">
                        <div className="user_groups--button_n_search--button">
                            <h4>Delivery Commission Fee</h4>
                            <button onClick={() => dispatch({ type: "ADD_DELIVERY_COMMISSION_MODAL" })}>
                                Add <i className="bi bi-plus c-plus" />
                            </button>
                        </div>
                        <div className="user_groups--button_n_search--search">
                            {/* <input type="text" placeholder="Search" /> */}
                        </div>
                    </div>
                    <p className="privacy-loading--onFetching">{isLoadingOnLogin && <LoadingComponent />}</p>
                    <div className="table-container">
                        <table className="table_x">
                            <thead>
                                <tr>
                                    <th className="delivery--commission--title">Title</th>
                                    <th className="delivery--commission--amount">Amount</th>
                                    <th className="delivery--commission--action">Action</th>
                                </tr></thead>
                            <tbody>
                                {deliveryCommData !== undefined && <tr>
                                    <td>{deliveryCommData.configKey}</td>
                                    <td>{deliveryCommData.deliveryCommission}</td>
                                    <td>
                                        <Tippy content="Edit" placement="top">
                                            <span>
                                                <AiFillEdit className="edit--icon" onClick={()=> {
                                                    setShowEditModal(true);
                                                    setCommissionfee(deliveryCommData.deliveryCommission)
                                                }} /> </span>
                                        </Tippy>
                                    </td>
                                </tr>}
                                
                            </tbody>
                        </table>
                        {/* edit deliver-charge modal*/}
                        <div className={`${showEditModal ? "mymodal modal_activated" : "mymodal"}`}>
                            <div className={`${!isToogleSidebar ? "manage_modal--container " : "manage_modal--container sidebarOpend_F_modal"}`}>
                                {commissionFee !== null && <EditCommissionFee myCommission={commissionFee} />}
                            </div>
                            <div className={`${!isToogleSidebar ? "task--rectangle" : "task--rectangle withsidebarOpend"}`}>
                                <div className="task--rectangle__circle">
                                    <div className='circle'> <span onClick={() => {
                                        setShowEditModal(false)
                                        dispatch({
                                            type: "FETCH_DELIVERY_COMMISSION_FEE_REQUEST",
                                        })
                                    }}><BsX /></span><p>Edit</p></div>
                                </div>
                            </div>
                        </div>
                        <div className="pagination">
                            <p>Showing 0 to 1 of 1 entries</p>
                            <nav aria-label="...">
                                <ul className="pagination">
                                    {currentPage === 1 ? <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronLeft /></a>
                                    </li> : <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronLeft onClick={() => setCurrentPage(currentPage - 1)} /></a>
                                    </li>}
                                    {pageNumbers.map((x) =>
                                        <li key={x} className="page-item"><a className="page-link" href="#" onClick={() => setCurrentPage(x)} >{x}</a></li>
                                    )}
                                    {currentPage == pageNumbers.length ? <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronRight /></a>
                                    </li> : <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronRight onClick={() => setCurrentPage(currentPage + 1)} /></a>
                                    </li>}
                                </ul>
                            </nav>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        </Layout>
    )
}

export default connect(mapStateToProps, null)(CommissionFee)

