import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Layout from "../../Layout/Layout";
import Footer from "../Z_Footer/Footer";
import { connect, useDispatch } from "react-redux";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { BsPersonPlusFill } from "react-icons/bs";
import LoadingComponent from "../LoadingComponent";
import { getCookie } from "../../FrontendHelper";
import song from "../../Images/noti.wav";
import moment from "moment";
import Tippy from "@tippyjs/react";
import { BsEyeFill } from "react-icons/bs";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { BsX } from "react-icons/bs";
import OrderDetails from "./OrderDetails";
import CampaignOrderDetails from "./CampaignOrderDetails";
import { Select } from "antd";
import { Segmented } from "antd";
const { Option } = Select;

// FOR ADMIN LOGIN
const Token = getCookie("token");
const mapStateToProps = (store) => {
  const {
    dashboardData,
    isLoadingOnLogin,
    orderList,
    isToogleSidebar,
    ordersFromAllCampaign,
    CODVerificationPendingList,
    operatingLocationList,
  } = store.mainState;
  return {
    dashboardData,
    isLoadingOnLogin,
    orderList,
    isToogleSidebar,
    ordersFromAllCampaign,
    CODVerificationPendingList,
    operatingLocationList,
  };
};

function Dashboard({
  dashboardData,
  isLoadingOnLogin,
  orderList,
  isToogleSidebar,
  ordersFromAllCampaign,
  CODVerificationPendingList,
  operatingLocationList,
}) {
  const dispatch = useDispatch();
  const [myTime, setMyTime] = useState(0);
  const [myTotalUser, setMyTotalUser] = useState(0);
  const [myInactiveUsers, setInactiveUsers] = useState(0);
  const [myActiveUsers, setMyActuveUsers] = useState(0);
  const [myMaleUsers, setMyMaleUsers] = useState(0);
  const [myFamaleUsers, setMyFamaleUsers] = useState(0);
  const [myVerifiedUsers, setMyVerifiedUsers] = useState(0);
  const [myTodayVerifiedUsers, setMyTodayVerifiedUsers] = useState(0);
  const [myReferredUsers, setMyReferredUsers] = useState(0);
  const [myTodayAdded, setMyTodayAdded] = useState(0);
  const [audio, setAudio] = useState(new Audio(song));
  const [orderStatus, setOrderStatus] = useState("ALL");
  const [opLocation, setOpLocation] = useState("");
  const [opLocationCampaign, setOpLocationCampaign] = useState("");

  const [searchKeyWord, setSearchKeyword] = useState("");
  const [SegmentedValue, setSegmentedValue] = useState("ORDER_PLACED");
  const [SegmentedValueCampaign, setSegmentedValueCampaign] =
    useState("PENDING");

  const [openEye, setOpenEye] = useState(false);
  const [openEyeCampaign, setOpenEyeCampaign] = useState(false);

  const [userDetailsId, setUserDetailsId] = useState(null);
  const [campaignDetails, setCampaignDetails] = useState(null);
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [currentPage1, setCurrentPage1] = useState(1);
  const [postsPerPage1, setPostsPerPage1] = useState(10);
  const [currentPage2, setCurrentPage2] = useState(1);
  const [postsPerPage2, setPostsPerPage2] = useState(10);
  const [currentPosts, setCurrentPosts] = useState([]);
  const [newOperatingLocation, setNewOperatingLocation] = useState([]);

  let pageNumbers = [];
  let pageNumbers1 = [];
  let pageNumbers2 = [];

  // get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const indexOfLastPost1 = currentPage1 * postsPerPage1;
  const indexOfLastPost2 = currentPage2 * postsPerPage2;
  const indexOfFirstPost1 = indexOfLastPost1 - postsPerPage1;
  const indexOfFirstPost2 = indexOfLastPost2 - postsPerPage2;

  useEffect(() => {
    if (operatingLocationList?.data?.length > 0) {
      setNewOperatingLocation([
        { id: "", locationName: "All", isActive: true },
        ...operatingLocationList.data,
      ]);
    }
  }, [operatingLocationList]);

  if (myMaleUsers < dashboardData?.maleUsers) {
    setTimeout(() => {
      setMyMaleUsers(myMaleUsers + 1);
    }, 1);
  }
  if (myFamaleUsers < dashboardData?.femaleUsers) {
    setTimeout(() => {
      setMyFamaleUsers(myFamaleUsers + 1);
    }, 1);
  }

  if (myTodayVerifiedUsers < dashboardData?.totalVerifiedToday) {
    setTimeout(() => {
      setMyTodayVerifiedUsers(myTodayVerifiedUsers + 1);
    }, 1);
  }
  if (myReferredUsers < dashboardData?.totalReferredUsers) {
    setTimeout(() => {
      setMyReferredUsers(myReferredUsers + 1);
    }, 1);
  }
  if (myTodayAdded < dashboardData?.totalUsersAddedToday) {
    setTimeout(() => {
      setMyTodayAdded(myTodayAdded + 1);
    }, 1);
  }
  useEffect(() => {
    console.log("myTime", myTime);
    setTimeout(() => {
      setMyTime(() => myTime + 1);
    }, 120000);
    dispatch({
      type: "FETCH_DASHBOARD_DATA_REQUEST",
    });
  }, [myTime, opLocation]);
  useEffect(() => {
    dispatch({
      type: "FETCH_ORDER_FROM_ALL_CAMPAIGN_REQUEST",
      payload: {
        currentPage: currentPage1,
        postsPerPage: postsPerPage1,
        orderStatus: SegmentedValueCampaign,
      },
    });
    dispatch({
      type: "FETCH_COD_VERIFICATION_PENDING",
      payload: {
        currentPage: currentPage2,
        postsPerPage: postsPerPage2,
      },
    });
    dispatch({
      type: "FETCH_ORDER_LIST_BY_STATUS_REQUEST",
      payload: {
        orderStatus: SegmentedValue,
        currentPage,
        postsPerPage,
        opLocation,
        searchKeyWord: "",
      },
    });
  }, [
    orderStatus,
    currentPage,
    postsPerPage,
    opLocation,
    currentPage1,
    postsPerPage1,
    currentPage2,
    postsPerPage2,
    SegmentedValue,
    SegmentedValueCampaign,
  ]);
  for (let i = 1; i <= orderList.totalPage; i++) {
    pageNumbers.push(i);
  }
  for (let i = 1; i <= ordersFromAllCampaign.totalPage; i++) {
    pageNumbers1.push(i);
  }
  for (let i = 1; i <= CODVerificationPendingList.totalPage; i++) {
    pageNumbers2.push(i);
  }
  const getLocatDateTime = (mydate) => {
    var stillUtc = moment.utc(mydate).toDate();
    var local = moment(stillUtc).local().format("YYYY-MM-DD HH:mm:ss");
    return local;
  };
  const str = () =>
    orderList.data !== undefined &&
    orderList.data.map((item) => (
      <tr key={item.paymentId}>
        <td>{item.customerOrderId}</td>
        <td>{item.isPhoneOrder ? "Phone Order" : "Normal Order"}</td>
        <td>{item.deliveryInfoDto.deliveryFullName}</td>
        <td>
          {item.scheduledTime !== "null"
            ? getLocatDateTime(item.scheduledTime)
            : "Not Scheduled"}
        </td>
        <td>
          {item.checkOutDateTime !== "null"
            ? getLocatDateTime(item.checkOutDateTime)
            : "Not Available"}
        </td>
        <td>
          {item.deliveryAssignedTo !== null
            ? `${item.deliveryAssignedTo}`
            : "Not Assigned"}
        </td>
        <td>Rs. {item.actualPaymentData.grandTotal}</td>
        <td>
          <span
            style={{
              backgroundColor: "purple",
              color: "white",
              padding: "3px 5px",
              borderRadius: "4px",
            }}
          >
            {item.paymentStatus}
          </span>
        </td>
        <td>
          <span
            style={{
              backgroundColor: "purple",
              color: "white",
              padding: "3px 5px",
              borderRadius: "4px",
            }}
          >
            {item.orderStatus}
          </span>
        </td>
        <td>
          <Tippy content="View" placement="bottom">
            <span>
              <BsEyeFill
                className="action--edit__admin"
                onClick={() => {
                  setUserDetailsId(item.customerOrderId);
                  setOpenEye(true);
                }}
              />{" "}
            </span>
          </Tippy>
        </td>
      </tr>
    ));
  const CODVerificationPendingOrder = () =>
    CODVerificationPendingList.data !== undefined &&
    CODVerificationPendingList.data.map((item) => (
      <tr key={item.paymentId}>
        <td>{item.customerOrderId}</td>
        <td>{item.isPhoneOrder ? "Phone Order" : "Normal Order"}</td>
        <td>{item.deliveryInfoDto.deliveryFullName}</td>
        <td>
          {item.scheduledTime !== "null"
            ? getLocatDateTime(item.scheduledTime)
            : "Not Scheduled"}
        </td>
        <td>
          {item.checkOutDateTime !== "null"
            ? getLocatDateTime(item.checkOutDateTime)
            : "Not Available"}
        </td>
        <td>
          {item.deliveryAssignedTo !== null
            ? `${item.deliveryAssignedTo}`
            : "Not Assigned"}
        </td>
        <td>Rs. {item.actualPaymentData.grandTotal}</td>
        <td>
          <span
            style={{
              backgroundColor: "purple",
              color: "white",
              padding: "3px 5px",
              borderRadius: "4px",
            }}
          >
            {item.paymentStatus}
          </span>
        </td>
        <td>
          <span
            style={{
              backgroundColor: "purple",
              color: "white",
              padding: "3px 5px",
              borderRadius: "4px",
            }}
          >
            {item.orderStatus}
          </span>
        </td>
        <td>
          <Tippy content="View" placement="bottom">
            <span>
              <BsEyeFill
                className="action--edit__admin"
                onClick={() => {
                  setUserDetailsId(item.customerOrderId);
                  setOpenEye(true);
                }}
              />{" "}
            </span>
          </Tippy>
        </td>
      </tr>
    ));
  const campaignOrder = () =>
    ordersFromAllCampaign.data !== undefined &&
    ordersFromAllCampaign.data.reverse().map((item) => (
      <tr key={item.id}>
        <td>{item.code}</td>
        <td>{item.isPhoneOrder ? "Phone Order" : "Normal Order"}</td>
        <td>{item.campaignName}</td>
        <td>Rs. {item.campaignPrice}</td>
        <td>{item.userFullName}</td>
        <td>
          {moment.utc(item.createdOn).local().format("YYYY-MM-DD HH:mm:ss")}
        </td>
        <td>
          {moment
            .utc(item?.deliveryData?.scheduledTime)
            .local()
            .format("YYYY-MM-DD HH:mm:ss") || "Not Scheduled"}
        </td>
        <td>{item.deliveryData.deliveryContactNo}</td>
        <td>{item.completionStatus}</td>
        <td>
          <span
            style={{
              backgroundColor: "purple",
              color: "white",
              padding: "3px 5px",
              borderRadius: "4px",
            }}
          >
            {item.paymentMethod}
          </span>
        </td>
        <td>
          <Tippy content="View" placement="bottom">
            <span>
              <BsEyeFill
                className="action--edit__admin"
                onClick={() => {
                  setCampaignDetails(item);
                  setOpenEyeCampaign(true);
                }}
              />{" "}
            </span>
          </Tippy>
        </td>
      </tr>
    ));

  const orderPostition = document
    .querySelector(".table-container-order")
    ?.getBoundingClientRect();
  const campaignPostition = document
    .querySelector(".table_x_campaign")
    ?.getBoundingClientRect();
  const CODVerificationPendingPostition = document
    .querySelector(".table_x_COD_VERIFICATION")
    ?.getBoundingClientRect();

  // console.log("new operating location list", newOperatingLocation);
  return (
    <>
      <Layout>
        <p className="privacy-loading--onFetching">
          {isLoadingOnLogin && <LoadingComponent />}
        </p>
        <h3 style={{ fontSize: "24px", margin: "10px 0" }}>
          Welcome To Dashboard
        </h3>
        <div
          className="dashboard"
          style={{ marginBottom: "20px", minHeight: "60vh" }}
        >
          <div className="dashboard--headers">
            <Link to="/order-management" style={{ textDecoration: "none" }}>
              <div className="dashboard--headers__item">
                <div className="dashboard--item--description">
                  <div className="item--container">
                    <p>{dashboardData?.todayOrder}</p>
                    <p>Today's Online Orders</p>
                  </div>
                  <div className="icon--container">
                    <AiOutlineUsergroupAdd />
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/order-management" style={{ textDecoration: "none" }}>
              <div className="dashboard--headers__item">
                <div className="dashboard--item--description">
                  <div className="item--container">
                    <p>{dashboardData?.newOrder}</p>
                    <p>New Online Orders </p>
                  </div>
                  <div className="icon--container">
                    <AiOutlineUsergroupAdd />
                  </div>
                </div>
              </div>
            </Link>
            <div className="dashboard--headers__item">
              <div className="dashboard--item--description">
                <div className="item--container">
                  <p>{dashboardData?.todayPhoneOrder}</p>
                  <p>Today's Phone Orders</p>
                </div>
                <div className="icon--container">
                  <AiOutlineUsergroupAdd />
                </div>
              </div>
            </div>
            {/* Pending */}
            <div className="dashboard--headers__item">
              <div className="dashboard--item--description">
                <div className="item--container">
                  <p>{dashboardData?.todayPhoneOrder}</p>
                  <p>New Phone Orders</p>
                </div>
                <div className="icon--container">
                  <AiOutlineUsergroupAdd />
                </div>
              </div>
            </div>
            <div className="dashboard--headers__item">
              <div className="dashboard--item--description">
                <div className="item--container">
                  <p>{dashboardData?.pendingOrder}</p>
                  <p>Active Online Orders</p>
                </div>
                <div className="icon--container">
                  <AiOutlineUsergroupAdd />
                </div>
              </div>
            </div>
            <div className="dashboard--headers__item">
              <div className="dashboard--item--description">
                <div className="item--container">
                  <p>{dashboardData?.todayCampaignOrder}</p>
                  <p>Today's Campaign Orders</p>
                </div>
                <div className="icon--container">
                  <AiOutlineUsergroupAdd />
                </div>
              </div>
            </div>

            {/* Pending */}
            <div className="dashboard--headers__item">
              <div className="dashboard--item--description">
                <div className="item--container">
                  <p>{dashboardData?.newCampaignOrder}</p>
                  <p>New Campaign Orders</p>
                </div>
                <div className="icon--container">
                  <AiOutlineUsergroupAdd />
                </div>
              </div>
            </div>
            <div className="dashboard--headers__item">
              <div className="dashboard--item--description">
                <div className="item--container">
                  <p>{dashboardData?.preOrder}</p>
                  <p>PreOrders</p>
                </div>
                <div className="icon--container">
                  <AiOutlineUsergroupAdd />
                </div>
              </div>
            </div>
            <div className="dashboard--headers__item">
              <div className="dashboard--item--description">
                <div className="item--container">
                  <p>{dashboardData?.todayPreOrder}</p>
                  <p>Today's Preorders</p>
                </div>
                <div className="icon--container">
                  <AiOutlineUsergroupAdd />
                </div>
              </div>
            </div>

            {/* Pending */}
            <div className="dashboard--headers__item">
              <div className="dashboard--item--description">
                <div className="item--container">
                  <p>{dashboardData?.todayPreOrder}</p>
                  <p>New Preorders</p>
                </div>
                <div className="icon--container">
                  <AiOutlineUsergroupAdd />
                </div>
              </div>
            </div>
            <div className="dashboard--headers__item">
              <div className="dashboard--item--description">
                <div className="item--container">
                  <p>{dashboardData?.totalUsers}</p>
                  <p>Total Users</p>
                </div>
                <div className="icon--container">
                  <AiOutlineUsergroupAdd />
                </div>
              </div>
            </div>
            <div className="dashboard--headers__item">
              <div className="dashboard--item--description">
                <div className="item--container">
                  <p>{myTodayAdded}</p>
                  <p>Today's Users</p>
                </div>
                <div className="icon--container">
                  <BsPersonPlusFill />
                </div>
              </div>
              {/* <div className="dashboard--headers__item">
                <div className="dashboard--item--description">
                  <div className="item--container">
                    <p>{myTodayAdded}</p>
                    <p>Total Sales</p>
                  </div>
                  <div className="icon--container">
                    <BsPersonPlusFill />
                  </div>
                </div>
              </div> */}
            </div>

            <div className="dashboard--headers__item">
              <div className="dashboard--item--description">
                <div className="item--container">
                  <p>{myTodayAdded}</p>
                  <p>Total Sales</p>
                </div>
                <div className="icon--container">
                  <BsPersonPlusFill />
                </div>
              </div>
            </div>
            <Link to="/manage-kyc" style={{ textDecoration: "none" }}>
              <div className="dashboard--headers__item">
                <div className="dashboard--item--description">
                  <div className="item--container">
                    <p>{dashboardData?.totalKycSubmitted}</p>
                    <p>Total Submitted KYC</p>
                  </div>
                  <div className="icon--container">
                    <BsPersonPlusFill />
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/manage-kyc" style={{ textDecoration: "none" }}>
              <div className="dashboard--headers__item">
                <div className="dashboard--item--description">
                  <div className="item--container">
                    <p>{dashboardData?.totalKycUnVerified}</p>
                    <p>Total Unverified KYC</p>
                  </div>
                  <div className="icon--container">
                    <BsPersonPlusFill />
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>

        <h3
          style={{
            fontSize: "24px",
            margin: "10px 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
            <h3> Total New Online Orders</h3>
            <Segmented
              style={{ marginTop: "-8px" }}
              value={opLocation}
              onChange={(e) => setOpLocation(e)}
              options={newOperatingLocation
                ?.filter((item) => item.isActive)
                ?.map((item) => {
                  return { label: item.locationName, value: item.id };
                })}
            />
          </div>
        </h3>
        <Segmented
          style={{ marginBottom: "2rem", marginTop: "10px" }}
          value={SegmentedValue}
          onChange={(e) => setSegmentedValue(e)}
          options={[
            {
              label:
                SegmentedValue == "ORDER_PLACED"
                  ? `Order Placed (${orderList.totalData})`
                  : "Order Placed",
              value: "ORDER_PLACED",
            },
            {
              label:
                SegmentedValue == "IN_PROGRESS"
                  ? `In Progress (${orderList.totalData})`
                  : `In progress`,
              value: "IN_PROGRESS",
            },
            {
              label:
                SegmentedValue == "DELIVERY_COMPLETED"
                  ? `Completed (${orderList.totalData})`
                  : `Completed`,
              value: "DELIVERY_COMPLETED",
            },
          ]}
        />
        <div className="table-container-order">
          <table className="table_x">
            <thead>
              <tr>
                <th className="order--itemName">Order ID</th>
                <th className="order--itemName">Order Type</th>
                <th className="order--customerName">Customer Name</th>
                <th className="order--sheduledTime">Sheduled Time</th>
                <th className="order--status">Date Of Order</th>
                <th className="order--status">Assigned To</th>
                <th className="order--grandTotal">Grand Total</th>
                <th className="order--status">Payment Status</th>
                <th className="order--status">Order Status</th>

                <th className="order--actions" style={{ width: "15vw" }}>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>{str()}</tbody>
          </table>
          <div className={`${openEye ? "mymodal modal_activated" : "mymodal"}`}>
            <div
              className={`${
                !isToogleSidebar
                  ? "manage_modal--container "
                  : "manage_modal--container sidebarOpend_F_modal"
              }`}
            >
              {userDetailsId !== null && (
                <OrderDetails id={userDetailsId} opLocation={opLocation} />
              )}
            </div>
            <div
              className={`${
                !isToogleSidebar
                  ? "task--rectangle"
                  : "task--rectangle withsidebarOpend"
              }`}
            >
              <div className="task--rectangle__circle">
                <div className="circle">
                  {" "}
                  <span
                    onClick={() => {
                      setOpenEye(!openEye);
                      setUserDetailsId(null);
                    }}
                  >
                    <BsX />
                  </span>
                  <p>Close</p>
                </div>
              </div>
            </div>
          </div>

          <div className="pagination">
            <p>
              Showing {indexOfFirstPost} to{" "}
              {indexOfLastPost > orderList.totalData
                ? orderList.totalData
                : indexOfLastPost}{" "}
              of {orderList.totalData} entries
            </p>
            <nav aria-label="...">
              <ul
                className="pagination"
                style={{ maxWidth: "400px", overflowX: "auto" }}
              >
                {currentPage === 1 ? (
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <BiChevronLeft />
                    </a>
                  </li>
                ) : (
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <BiChevronLeft
                        onClick={() => setCurrentPage(currentPage - 1)}
                      />
                    </a>
                  </li>
                )}
                {pageNumbers.map((x) => (
                  <li key={x} className="page-item">
                    <a
                      className="page-link"
                      onClick={() => {
                        setCurrentPage(x);
                        window.scrollTo({
                          top: orderPostition?.top,
                          left: orderPostition?.left,

                          behavior: "smooth",
                        });
                      }}
                    >
                      {x}
                    </a>
                  </li>
                ))}
                {currentPage == pageNumbers.length ? (
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <BiChevronRight />
                    </a>
                  </li>
                ) : (
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <BiChevronRight
                        onClick={() => setCurrentPage(currentPage + 1)}
                      />
                    </a>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        </div>
        <h3
          style={{
            fontSize: "24px",
            margin: "10px 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
            <h3> Total New Campaign Orders</h3>
            <Segmented
              style={{ marginBottom: "2rem", marginTop: "10px" }}
              value={SegmentedValueCampaign}
              onChange={(e) => setSegmentedValueCampaign(e)}
              options={[
                {
                  label:
                    SegmentedValueCampaign == "PENDING"
                      ? `Pending (${ordersFromAllCampaign.totalData})`
                      : "Pending",
                  value: "PENDING",
                },
                {
                  label:
                    SegmentedValueCampaign == "APPROVED"
                      ? `In Progress (${ordersFromAllCampaign.totalData})`
                      : `In progress`,
                  value: "APPROVED",
                },
                {
                  label:
                    SegmentedValueCampaign == "COMPLETED"
                      ? `Completed (${ordersFromAllCampaign.totalData})`
                      : `Completed`,
                  value: "COMPLETED",
                },
              ]}
            />
            {/* <Segmented
              style={{ marginTop: "-8px" }}
              value={opLocationCampaign}
              onChange={(e) => setOpLocationCampaign(e)}
              options={newOperatingLocation
                ?.filter((item) => item.isActive)
                ?.map((item) => {
                  return { label: item.locationName, value: item.id };
                })}
            /> */}
          </div>
        </h3>

        <table className="table_x_campaign">
          <thead>
            <tr>
              <th className="camporder--id">Order ID</th>
              <th className="camporder--id">Order Type</th>
              <th className="camporder--campaignName">Campaign Name</th>
              <th className="camporder--price">Campaign Price</th>
              <th className="camporder--name">Customer Name</th>
              <th className="camporder--status">Order Date</th>
              <th className="camporder--status">Delivery Date</th>
              <th className="camporder--status">Contact Number</th>
              <th className="camporder--status">Status</th>
              <th className="camporder--paymentStatus">Payment Status</th>
              <th className="camporder--actions" style={{ width: "5vw" }}>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>{campaignOrder()}</tbody>
        </table>
        <div className="pagination">
          <p>
            Showing {indexOfFirstPost1} to{" "}
            {indexOfLastPost1 > ordersFromAllCampaign.totalData
              ? ordersFromAllCampaign.totalData
              : indexOfLastPost1}{" "}
            of {ordersFromAllCampaign.totalData} entries
          </p>
          <nav aria-label="...">
            <ul
              className="pagination"
              style={{ maxWidth: "400px", overflowX: "auto" }}
            >
              {currentPage1 === 1 ? (
                <li className="page-item">
                  <a className="page-link" href="#">
                    <BiChevronLeft />
                  </a>
                </li>
              ) : (
                <li className="page-item">
                  <a className="page-link" href="#">
                    <BiChevronLeft
                      onClick={() => setCurrentPage1(currentPage1 - 1)}
                    />
                  </a>
                </li>
              )}
              {pageNumbers1.map((x) => (
                <li key={x} className="page-item">
                  <a
                    className="page-link"
                    onClick={() => {
                      setCurrentPage1(x);
                      window.scrollTo({
                        top: campaignPostition?.top,
                        behavior: "smooth",
                      });
                    }}
                  >
                    {x}
                  </a>
                </li>
              ))}
              {currentPage1 == pageNumbers1.length ? (
                <li className="page-item">
                  <a className="page-link" href="#">
                    <BiChevronRight />
                  </a>
                </li>
              ) : (
                <li className="page-item">
                  <a className="page-link" href="#">
                    <BiChevronRight
                      onClick={() => setCurrentPage1(currentPage1 + 1)}
                    />
                  </a>
                </li>
              )}
            </ul>
          </nav>
        </div>

        <div
          className={`${
            openEyeCampaign ? "mymodal modal_activated" : "mymodal"
          }`}
        >
          <div
            className={`${
              !isToogleSidebar
                ? "manage_modal--container "
                : "manage_modal--container sidebarOpend_F_modal"
            }`}
          >
            {campaignDetails !== null && (
              <CampaignOrderDetails item={campaignDetails} />
            )}
          </div>
          <div
            className={`${
              !isToogleSidebar
                ? "task--rectangle"
                : "task--rectangle withsidebarOpend"
            }`}
          >
            <div className="task--rectangle__circle">
              <div className="circle">
                {" "}
                <span
                  onClick={() => {
                    setOpenEyeCampaign(!openEyeCampaign);
                    setCampaignDetails(null);
                  }}
                >
                  <BsX />
                </span>
                <p>Close</p>
              </div>
            </div>
          </div>
        </div>
        <h3 style={{ fontSize: "24px", margin: "10px 0" }}>
          COD Verification Pending
        </h3>
        <div className="table-container">
          <table className="table_x_COD_VERIFICATION">
            <thead>
              <tr>
                <th className="order--itemName">Order ID</th>
                <th className="order--itemName">Order Type</th>
                <th className="order--customerName">Customer Name</th>
                <th className="order--sheduledTime">Sheduled Time</th>
                <th className="order--status">Date Of Order</th>
                <th className="order--status">Assigned To</th>
                <th className="order--grandTotal">Grand Total</th>
                <th className="order--status">Payment Status</th>
                <th className="order--status">Order Status</th>

                <th className="order--actions" style={{ width: "15vw" }}>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>{CODVerificationPendingOrder()}</tbody>
          </table>
          <div className={`${openEye ? "mymodal modal_activated" : "mymodal"}`}>
            <div
              className={`${
                !isToogleSidebar
                  ? "manage_modal--container "
                  : "manage_modal--container sidebarOpend_F_modal"
              }`}
            >
              {userDetailsId !== null && (
                <OrderDetails id={userDetailsId} opLocation={opLocation} />
              )}
            </div>
            <div
              className={`${
                !isToogleSidebar
                  ? "task--rectangle"
                  : "task--rectangle withsidebarOpend"
              }`}
            >
              <div className="task--rectangle__circle">
                <div className="circle">
                  {" "}
                  <span
                    onClick={() => {
                      setOpenEye(!openEye);
                      setUserDetailsId(null);
                    }}
                  >
                    <BsX />
                  </span>
                  <p>Close</p>
                </div>
              </div>
            </div>
          </div>

          <div className="pagination">
            <p>
              Showing {indexOfFirstPost2} to{" "}
              {indexOfFirstPost2 > CODVerificationPendingList.totalData
                ? CODVerificationPendingList.totalData
                : indexOfLastPost2}{" "}
              of {CODVerificationPendingList.totalData} entries
            </p>
            <nav aria-label="...">
              <ul
                className="pagination"
                style={{ maxWidth: "400px", overflowX: "auto" }}
              >
                {currentPage2 === 1 ? (
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <BiChevronLeft />
                    </a>
                  </li>
                ) : (
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <BiChevronLeft
                        onClick={() => setCurrentPage2(currentPage2 - 1)}
                      />
                    </a>
                  </li>
                )}
                {pageNumbers2.map((x) => (
                  <li key={x} className="page-item">
                    <a
                      className="page-link"
                      onClick={() => {
                        setCurrentPage2(x);
                        window.scrollTo({
                          top: CODVerificationPendingPostition?.top,
                          behavior: "smooth",
                        });
                      }}
                    >
                      {x}
                    </a>
                  </li>
                ))}
                {currentPage2 == pageNumbers2.length ? (
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <BiChevronRight />
                    </a>
                  </li>
                ) : (
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <BiChevronRight
                        onClick={() => setCurrentPage2(currentPage2 + 1)}
                      />
                    </a>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        </div>
        <Footer />
      </Layout>
    </>
  );
}

export default connect(mapStateToProps, null)(Dashboard);
