import React, { useState, useEffect, useRef } from 'react'
import Layout from '../../Layout/Layout'
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi'
import { connect, useDispatch } from 'react-redux'
import LoadingComponent from '../LoadingComponent'
import Footer from '../Z_Footer/Footer'
import { BsEyeFill, BsCheckCircle } from 'react-icons/bs'
import { BsX } from "react-icons/bs";
import { GiSkullCrossedBones } from 'react-icons/gi'
import { TiTick } from 'react-icons/ti'
import UpdateCoupon from './UpdateCoupon'
import { AiFillEdit } from 'react-icons/ai'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import moment from 'moment'

const mapStateToProps = store => {
    const { isLoadingOnLogin, couponData, isToogleSidebar, isEnabledUsers } = store.mainState;
    return { isLoadingOnLogin, couponData, isToogleSidebar, isEnabledUsers }
}

function Coupon({ isLoadingOnLogin, couponData, isToogleSidebar, isEnabledUsers }) {
    const [showSmModal, setShowSmModal] = useState(false);
    const [enableDisableItem, setEnableDisableItem] = useState({});
    const [oepnUpdateCouponModal, setOpenUpdateCouponModal] = useState(false)
    const [editingItem, setEditingItem] = useState(null)

    // Pagination

    const [currentPage, setCurrentPage] = useState(1)
    const [postsPerPage, setPostsPerPage] = useState(10);
    const [sortingParam, setSortingParam] = useState("id")

    let pageNumbers = []

    // get current posts
    const indexOfLastPost = currentPage * postsPerPage
    const indexOfFirstPost = indexOfLastPost - postsPerPage

    const dispatch = useDispatch();


    useEffect(() => {
        dispatch({
            type: "FETCH_COUPON_REQUEST",
            payload: { sortingParam, currentPage, postsPerPage }
        })
    }, [currentPage, postsPerPage])

    for (let i = 1; i <= couponData.totalPage; i++) {
        pageNumbers.push(i)
    }
    useEffect(() => {
        if (isEnabledUsers === "b") {
            setTimeout(() => {
                setShowSmModal(false);
                dispatch({
                    type: "FETCH_COUPON_REQUEST",
                    payload: { sortingParam, currentPage, postsPerPage }
                })
            }, 1000);

        }
    }, [isEnabledUsers])
    // var createdDateTime = new Date('2021-10-05 13:00:00 UTC');
    // console.log("the createdDateTime",createdDateTime)

    const getLocatDateTime = (mydate) => {
        var stillUtc = moment.utc(mydate).toDate();
        var local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
        return local
    }


    const str = () => (

        couponData.data !== undefined && couponData.data.map((item) =>
            <tr key={item.id}>
                <td>{item.id}</td>
                <td className="mycouponcode">{item.couponCode}</td>
                <td>{item.codeName}</td>
                <td>{item.details}</td>
                <td>{getLocatDateTime(item.validStartDate)}</td>
                <td>{getLocatDateTime(item.expiryDate)}</td>

                <td>{item.addedBy}</td>
                <td>{item.discountAmount}</td>
                <td><span style={{ backgroundColor: "green", borderRadius: "4px", color: "white" }}>{item.isActive ? "Active" : "Not Active"}</span></td>

                <td>

                    <Tippy content={<span>{item.isActive ? "De-activate" : "Activate"}</span>} placement="top">
                        <span>
                            <BsCheckCircle className="action--eye__admin" onClick={() => {
                                setShowSmModal(true);
                                setEnableDisableItem(item)
                            }} /> </span>
                    </Tippy>
                    <Tippy content="Edit" placement="top">
                        <span>
                            <AiFillEdit className="edit--icon" onClick={() => {
                                setOpenUpdateCouponModal(true)
                                setEditingItem(item);

                            }} /> </span>
                    </Tippy>
                </td>
            </tr>
        )
    )



    return (
        <Layout>
            <div>
                <div className="group_list">
                    <h4>Manage Coupon</h4>
                </div>
                <div className="user_groups">
                    <div className="user_groups--button_n_search">
                        <div className="user_groups--button_n_search--button">
                            <h4>Coupon</h4>
                            <button onClick={() => dispatch({ type: "ADD_COUPON_MODAL" })}>
                                Add <i className="bi bi-plus c-plus" />
                            </button>
                            <label htmlFor="pageSize" style={{ marginLeft: "10px", fontSize: '18px', fontFamily: "bold", color: "#3e445e", marginRight: "-10px" }}>Per Page</label>
                            <select name="pageSize" onChange={(e) => setPostsPerPage(e.target.value)}>
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        <div className="user_groups--button_n_search--search">
                            <form>
                                <input type="text" placeholder="Search" />
                            </form>
                        </div>
                    </div>
                    <p className="privacy-loading--onFetching">{isLoadingOnLogin && <LoadingComponent />}</p>
                    <div className="table-container">
                        <table className="table_x">
                            <thead>
                                <tr>
                                    <th className="user--id">ID</th>
                                    <th className="user--name">Coupon Code</th>
                                    <th className="user--mobile">Code Name</th>
                                    <th className="user--email">Details</th>
                                    <th className="user--email">Valid Start Date</th>
                                    <th className="user--email">Expire Date</th>

                                    <th className="user--email">Added By</th>
                                    <th className="user--status">Discout Amount</th>
                                    <th className="user--deleted">Active Status</th>

                                    <th className="user--actions">Actions</th>
                                </tr></thead>
                            <tbody>
                                {str()}
                            </tbody>
                        </table>
                        <div className={`${oepnUpdateCouponModal ? "mymodal modal_activated" : "mymodal"}`}>
                            <div className={`${!isToogleSidebar ? "manage_modal--container " : "manage_modal--container sidebarOpend_F_modal"}`}>
                                {editingItem !== null && <UpdateCoupon item={editingItem} />}
                            </div>
                            <div className={`${!isToogleSidebar ? "task--rectangle" : "task--rectangle withsidebarOpend"}`}>
                                <div className="task--rectangle__circle">
                                    <div className='circle'> <span onClick={() => {
                                        setOpenUpdateCouponModal(!oepnUpdateCouponModal);
                                        dispatch({
                                            type: "FETCH_COUPON_REQUEST",
                                            payload: { sortingParam, currentPage, postsPerPage }
                                        })
                                    }}><BsX /></span><p>Edit</p></div>
                                </div>
                            </div>
                        </div>
                        {/* for Enable/Disable Security Question  */}

                        <div className={`${showSmModal ? "delete--admin" : "delete--admin__hidden"}`} >
                            <div className="delete--admin--container" >
                                <div className="delete--admin--container__heading"></div>
                                <><div className="delete--admin--container__body">
                                    {enableDisableItem.isActive ? <h1>Are you sure to Disable this ??</h1> : <h1>Are you sure to Enable this ??</h1>}
                                    <div>{isEnabledUsers === "a" && <div className="deleting--something">  <p>Processing...</p></div>}</div>
                                    <div> {isEnabledUsers === "b" && <div className="deleting--something"> <TiTick className="success--icon" /> <p>Successful</p></div>}</div>
                                    <div> {isEnabledUsers === "c" && <div className="deleting--something"> <GiSkullCrossedBones className="success--icon" style={{ fontSize: "30px" }} /> <p>Try Again</p>

                                    </div>}</div>
                                </div>
                                    <div className="delete--admin--container__footer">
                                        <button onClick={() => setShowSmModal(!showSmModal)}>Cancel</button>
                                        {enableDisableItem.isActive ? <button onClick={() => {
                                            let enableDisableId = enableDisableItem.id
                                            dispatch({
                                                type: "DEACTIVATE_COUPON_REQUEST",
                                                payload: {
                                                    enableDisableId,
                                                }
                                            })
                                        }}>Ok</button> :
                                            <button onClick={() => {
                                                let enableDisableId = enableDisableItem.id
                                                dispatch({
                                                    type: "ACTIVATE_COUPON_REQUEST",
                                                    payload: {
                                                        enableDisableId,
                                                    }
                                                })
                                            }}>Ok</button>}
                                    </div></>

                            </div>
                        </div>
                        <div className="pagination">
                            <p>Showing {indexOfFirstPost} to {indexOfLastPost > couponData.totalData ? couponData.totalData : indexOfLastPost} of {couponData.totalData} entries</p>
                            <nav aria-label="...">
                                <ul className="pagination">
                                    {currentPage === 1 ? <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronLeft /></a>
                                    </li> : <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronLeft onClick={() => setCurrentPage(currentPage - 1)} /></a>
                                    </li>}
                                    {pageNumbers.map((x) =>
                                        <li key={x} className="page-item"><a className="page-link" href="#" onClick={() => setCurrentPage(x)} >{x}</a></li>
                                    )}
                                    {currentPage == pageNumbers.length ? <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronRight /></a>
                                    </li> : <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronRight onClick={() => setCurrentPage(currentPage + 1)} /></a>
                                    </li>}
                                </ul>
                            </nav>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        </Layout>
    )
}

export default connect(mapStateToProps, null)(Coupon)

