import React, { useState, useEffect, useRef } from "react";
import { useDispatch, connect } from "react-redux";
import axios from "axios";
import { getCookie } from "../../FrontendHelper";
import { Checkbox, Col, Row, Radio, DatePicker } from "antd";
import moment from "moment";
import LoadingComponent from "../LoadingComponent";
import { toast } from "react-toastify";

// import { LatLangGenerator } from '../Coupon/LatLangGenerator';

const mapStateToProps = (store) => {
  const {
    buttonText,
    isEnabledUsers,
    searchUsersList,
    isLoadingOnLogin,
    isOperationSuccessful,
  } = store.mainState;
  return {
    buttonText,
    isEnabledUsers,
    searchUsersList,
    isLoadingOnLogin,
    isOperationSuccessful,
  };
};

function AddPreOrderMerchant({
  buttonText,
  isEnabledUsers,
  searchUsersList,
  isLoadingOnLogin,
  isOperationSuccessful,
}) {
  const [preorderName, setPreOrderName] = useState("");
  const [preOrderImage, setPreOrderImage] = useState("");
  const [validTill, setValidTill] = useState("");
  const [validFrom, setValidFrom] = useState("");
  const [deliveryStartFrom, setDeliveryStartFrom] = useState("");
  const [deliveryEndFrom, setDeliveryEndFrom] = useState("");
  const [description, setDescription] = useState("");
  const [planPrice, setPlanPrice] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [isSendNotification, setIsSendNotification] = useState("");
  const [customMessageNotification, setCustomMessageNotification] =
    useState("");

  const [minimumOrderQuantity, setMinimumOrderQuantity] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const dispatch = useDispatch();

  const handleAddResellar = (e) => {
    if (minimumOrderQuantity < 1) {
      return toast.error("Negative order quantity is not allowed !!");
    }
    e.preventDefault();
    if (
      !preorderName ||
      !preOrderImage ||
      !validTill ||
      !validFrom ||
      !deliveryStartFrom ||
      !deliveryEndFrom ||
      !description ||
      !planPrice ||
      !minimumOrderQuantity
    ) {
      return toast.error("Please fill all the fields !!");
    }
    dispatch({
      type: "ADD_PREORDER_MERCHANT_REQUEST",
      payload: {
        preOrderName: preorderName,
        preOrderImage: imageUrl,
        validTill: moment(validTill).format("YYYY-MM-DD HH:mm:ss"),
        validFrom: moment(validFrom).format("YYYY-MM-DD HH:mm:ss"),
        deliveryStartFrom: moment(deliveryStartFrom).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        deliveryEndFrom: moment(deliveryEndFrom).format("YYYY-MM-DD HH:mm:ss"),
        description: description,
        planPrice: planPrice,
        totalAmount: parseInt(totalAmount),
        minimumOrderQuantity: minimumOrderQuantity,
        allowedPaymentMethod:
          paymentMethod.length > 0 &&
          paymentMethod?.map((item) => {
            return {
              allowedMethod: item,
            };
          }),
        sendNotification: isSendNotification,
        customNotificationMessage: isSendNotification
          ? customMessageNotification
          : "",
      },
    });
  };
  useEffect(() => {
    if (isOperationSuccessful) {
      setPreOrderImage("");
      setPreOrderName("");
      setValidTill("");
      setValidFrom("");
      setDeliveryStartFrom("");
      setDeliveryEndFrom("");
      setDescription("");
      setPlanPrice("");
      setTotalAmount("");
      setMinimumOrderQuantity("");
      setPaymentMethod([]);
      setIsSendNotification("");
      setCustomMessageNotification("");
    }
  }, [isOperationSuccessful]);
  useEffect(() => {
    const Token = getCookie("token");
    let myDataform = new FormData();
    myDataform.append("attachment", preOrderImage);

    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_FAKE}/restaurant/v1/api/admin/add-delivery-user/image`,
      // url: "https://app.gurugfood.com:8443/restaurant/v1/api/admin/add-delivery-user/image",
      data: myDataform,
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => {
        setImageUrl(response.data.imageUrl);
      })
      .catch((error) => {});
  }, [preOrderImage]);
  const onChange = (checkedValues) => {
    setPaymentMethod(checkedValues);
  };
  const onValidFromChange = (value, dateString) => {
    setValidFrom(value);
  };
  const onValidTillChange = (value, dateString) => {
    setValidTill(value);
  };

  const onDeliveryStartFromChange = (value, dateString) => {
    setDeliveryStartFrom(value);
  };
  const onDeliveryEndFromChange = (value, dateString) => {
    setDeliveryEndFrom(value);
  };
  const onChange1 = (e) => {
    setIsSendNotification(e.target.value);
  };
  return (
    <div>
      <div className="Modal--heading">Add Preorder Merchant</div>
      <div className="Modal--form">
        <form>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Preorder Name: </label>
              <input
                type="text"
                value={preorderName}
                placeholder="PreOrder Name"
                onChange={(e) => {
                  setPreOrderName(e.target.value);
                }}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">PreOrder Image: </label>
              <input
                type="file"
                placeholder="Preorder Image"
                onChange={(e) => setPreOrderImage(e.target.files[0])}
              />
            </p>
          </div>

          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Valid From: </label>
              <DatePicker
                value={validFrom}
                style={{
                  marginLeft: "2rem",
                  marginTop: "1rem",
                  borderRadius: "5px",
                  width: "53.5%",
                }}
                showTime
                onChange={onValidFromChange}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Valid Till: </label>
              <DatePicker
                value={validTill}
                style={{
                  marginLeft: "2rem",
                  marginTop: "1rem",
                  borderRadius: "5px",
                  width: "53.5%",
                }}
                showTime
                onChange={onValidTillChange}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Delivery Start Date: </label>
              <DatePicker
                value={deliveryStartFrom}
                style={{
                  marginLeft: "2rem",
                  marginTop: "1rem",
                  borderRadius: "5px",
                  width: "53.5%",
                }}
                showTime
                onChange={onDeliveryStartFromChange}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Delivery End Date: </label>
              <DatePicker
                value={deliveryEndFrom}
                style={{
                  marginLeft: "2rem",
                  marginTop: "1rem",
                  borderRadius: "5px",
                  width: "53.5%",
                }}
                showTime
                onChange={onDeliveryEndFromChange}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Description : </label>
              <input
                type="text"
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </p>
          </div>

          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Inital Paying Price : </label>
              <input
                type="number"
                placeholder="Initial Paying Price"
                value={planPrice}
                onChange={(e) => setPlanPrice(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Total Amount : </label>
              <input
                type="number"
                placeholder="Total Amount"
                value={totalAmount}
                onChange={(e) => setTotalAmount(e.target.value)}
              />
            </p>
          </div>

          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Minimum Order Quantity : </label>
              <input
                type="number"
                value={minimumOrderQuantity}
                placeholder="Minimum Order Quantity"
                onChange={(e) => setMinimumOrderQuantity(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Is Send Notification ?: </label>
              <Radio.Group
                style={{ marginLeft: "2rem", marginTop: "1rem" }}
                onChange={onChange1}
                value={isSendNotification}
              >
                <Radio value={true}>Yes</Radio>
                <Radio style={{ marginLeft: "2rem" }} value={false}>
                  No
                </Radio>
              </Radio.Group>
            </p>
          </div>
          {isSendNotification && (
            <>
              <div className="Modal--form--title">
                <p>
                  <label style={{ marginTop: "-4rem" }}>
                    Notification Message :{" "}
                  </label>
                  <textarea
                    type="text"
                    placeholder="Custom Message"
                    value={customMessageNotification}
                    onChange={(e) =>
                      setCustomMessageNotification(e.target.value)
                    }
                    style={{ height: "60px" }}
                  />
                </p>
              </div>
              <p style={{ textAlign: "center", color: "red" }}>
                Default Message will be sent if left empty !
              </p>
            </>
          )}
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Payment Methods : </label>
              <Checkbox.Group
                value={paymentMethod}
                onChange={onChange}
                style={{ marginLeft: "2rem", width: "25%", marginTop: "1rem" }}
              >
                <Row>
                  <Col span={8}>
                    <Checkbox value="KHALTI">Khalti</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="STRIPE">Stripe</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox
                      value="CASH_ON_DELIVERY"
                      style={{ width: "200px" }}
                    >
                      Cash On Delivery
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </p>
          </div>
          <div className="modal--container__group--buttons">
            <button
              style={{ marginBottom: "50px" }}
              type="submit"
              className="btn btn-primary"
              onClick={handleAddResellar}
            >
              {buttonText}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(AddPreOrderMerchant);
