import React, { useState, useEffect, useRef } from "react";
import { useDispatch, connect } from "react-redux";
import axios from "axios";
import { getCookie } from "../../../FrontendHelper";
import { Checkbox, Col, Row, Radio, DatePicker } from "antd";
import moment from "moment";
import LoadingComponent from "../../LoadingComponent";
import { toast } from "react-toastify";

const mapStateToProps = (store) => {
  const {
    buttonText,
    isEnabledUsers,
    searchUsersList,
    isLoadingOnLogin,
    isOperationSuccessful,
  } = store.mainState;
  return {
    buttonText,
    isEnabledUsers,
    searchUsersList,
    isLoadingOnLogin,
    isOperationSuccessful,
  };
};

function AddMenu({
  buttonText,
  searchUsersList,
  isLoadingOnLogin,
  isOperationSuccessful,
  categoryId,
}) {
  const [categoryName, setCategoryName] = useState("");
  const [categoryImage, setCategoryImage] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [originalPrice, setOriginalPrice] = useState("");
  const [isAvailable, setIsAvailable] = useState(true);
  const clearFileInputRef = useRef(null);

  const dispatch = useDispatch();

  const handleAddCategory = (e) => {
    console.log(
      "math ceil",
      Math.ceil(
        (((+originalPrice ?? 0) - (+price ?? 0)) / (+originalPrice ?? 0)) * 100
      )
    );
    e.preventDefault();
    if (!categoryImage || !categoryName || !description) {
      return toast.error("Please fill all the fields !!");
    }
    dispatch({
      type: "ADD_MENU_REQUEST",
      payload: {
        itemName: categoryName,
        imageUrl: categoryImage,
        description: description,
        categoryId: categoryId,
        originalPrice: originalPrice,
        discountPercentage: Math.ceil(
          (((+originalPrice ?? 0) - (+price ?? 0)) / (+originalPrice ?? 0)) *
            100
        ),
        price: price,

        isAvailable: isAvailable,
      },
    });
  };
  useEffect(() => {
    if (isOperationSuccessful) {
      setCategoryName("");
      setCategoryImage("");
      setDescription("");
      clearFileInputRef.current.value = "";
      setPrice("");
      setOriginalPrice("");
    }
  }, [isOperationSuccessful]);

  const imageChangeHandler = (e) => {
    const Token = getCookie("token");
    let myDataform = new FormData();
    myDataform.append("attachment", e.target.files[0]);
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_FAKE}/restaurant/v1/api/admin/add-delivery-user/image`,
      // url: "https://app.gurugfood.com:8443/restaurant/v1/api/admin/add-delivery-user/image",
      data: myDataform,
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    })
      .then((response) => {
        setCategoryImage(response.data.imageUrl);
      })
      .catch((error) => {});
  };
  const onChange2 = (e) => {
    setIsAvailable(e.target.value);
  };
  return (
    <div>
      <div className="Modal--heading">Add Menu</div>
      <div className="Modal--form">
        <form>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Item Name: </label>
              <input
                type="text"
                value={categoryName}
                placeholder="Item Name"
                onChange={(e) => setCategoryName(e.target.value)}
              />
            </p>
          </div>

          <div className="Modal--form--title">
            <p>
              <label htmlFor="title"> Item Image: </label>
              <input
                type="file"
                ref={clearFileInputRef}
                placeholder="Item Image"
                onChange={(e) => imageChangeHandler(e)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Description : </label>
              <input
                type="text"
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Original Price : </label>
              <input
                type="number"
                placeholder="Original Price"
                value={originalPrice}
                onChange={(e) => setOriginalPrice(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Actual Price : </label>
              <input
                type="number"
                placeholder="Actual Price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </p>
          </div>
          <div className="Modal--form--title">
            <p>
              <label htmlFor="title">Is Available: </label>
              <Radio.Group
                style={{ marginLeft: "2rem", marginTop: "1rem" }}
                onChange={onChange2}
                value={isAvailable}
              >
                <Radio value={true}>Yes</Radio>
                <Radio style={{ marginLeft: "2rem" }} value={false}>
                  No
                </Radio>
              </Radio.Group>
            </p>
          </div>

          <div className="modal--container__group--buttons">
            <button
              style={{ marginBottom: "50px" }}
              type="submit"
              className="btn btn-primary"
              onClick={handleAddCategory}
            >
              {buttonText}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, null)(AddMenu);
