import React, { Component } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import Geocode from "react-geocode";
Geocode.setApiKey("AIzaSyAKLUx_rnltQ2u9Xr39DcpX3UdRr293gCU");

const containerStyle = {
  position: 'relative',  
  width: '100%',
  height: '100%'
}

export class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // for google map places autocomplete
      address: '',

      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},

      mapCenter: {
        lat: 26.4524746,
        lng: 87.27178099999999
      },
      mapPosition: {
        lat: null,
        lng: null
      },
      markerPosition: {
        lat: null,
        lng: null,
      }
    };
  }
  



  handleChange = address => {
    this.setState({ address });
  };

  handleSelect = address => {
    this.props.handleAddress(address)
    this.setState({ address });
    
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        console.log('Success', latLng);
        this.props.handlelatLong(latLng)

        // update center state
        this.setState({ mapCenter: latLng });
      })
      .catch(error => console.error('Error', error));
  };

onMarkerDragEnd = (mapProps, map, three) => { 

  const { latLng } = three; 
  const draggedLat = latLng.lat(); 
  const draggedLng = latLng.lng(); 
  this.props.handlelatLong({ lat: draggedLat, lng: draggedLng })
    Geocode.fromLatLng(draggedLat, draggedLng).then(
      response => {
        const draggedAddress = response.results[0].formatted_address;
      this.props.handleAddress(draggedAddress)
        this.setState({
          address: (draggedAddress) ? draggedAddress : '',
          markerPosition: {
            lat: draggedLat,
            lng: draggedLng
          },
          mapPosition: {
            lat: draggedLat,
            lng: draggedLng
          },
        })
        
      },
      error => {
        console.error(error);
      }
    );
  };

  render() {
    return (
      <div className='googleMaps' style={{height: "400px",width: "100%",padding: "50px"}}>
        <PlacesAutocomplete
          value={this.state.address}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <input
                {...getInputProps({
                  placeholder: 'Search Places ...',
                  className: 'location-search-input',
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map(suggestion => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>

        <Map
          google={this.props.google}
          initialCenter={{
            lat: this.state.mapCenter.lat,
            lng: this.state.mapCenter.lng
          }}
          center={{
            lat: this.state.mapCenter.lat,
            lng: this.state.mapCenter.lng
          }}
          containerStyle={containerStyle}


        >
          <Marker
            position={{
              lat: this.state.mapCenter.lat,
              lng: this.state.mapCenter.lng
            }}
            draggable={true}
            onDragend={this.onMarkerDragEnd} 
            />
        </Map>
      </div>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: ('AIzaSyAKLUx_rnltQ2u9Xr39DcpX3UdRr293gCU')
})(MapContainer)
// import React, { Component } from 'react';
// import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
// import PlacesAutocomplete, {
//   geocodeByAddress,
//   getLatLng,
// } from 'react-places-autocomplete';
// import Geocode from "react-geocode";

// const containerStyle = {
//   position: 'relative',  
//   width: '100%',
//   height: '100%'
// }

// export class MapContainer extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       // for google map places autocomplete
//       address: '',

//       showingInfoWindow: false,
//       activeMarker: {},
//       selectedPlace: {},

//       mapCenter: {
//         lat: 49.2827291,
//         lng: -123.1207375
//       },
//       mapPosition: {
//         lat: null,
//         lng: null
//       },
//       markerPosition: {
//         lat: null,
//         lng: null,
//       }
//     };
//   }
  



//   handleChange = address => {
//     this.setState({ address });
//   };

//   handleSelect = address => {
//     console.log("the address in teh handle Select ",address)
//     this.setState({ address });
//     geocodeByAddress(address)
//       .then(results => getLatLng(results[0]))
//       .then(latLng => {
//         console.log('Success', latLng);

//         // update center state
//         this.setState({ mapCenter: latLng });
//       })
//       .catch(error => console.error('Error', error));
//   };
//   // onMarkerDragEnd = (event) => {
//   //   let newLat = event.latLng.lat(),
//   //     newLng = event.latLng.lng();

//   //   Geocode.fromLatLng(newLat, newLng).then(
//   //     response => {
//   //       const address = response.results[0].formatted_address,
//   //         addressArray = response.results[0].address_components,
//   //         city = this.getCity(addressArray),
//   //         area = this.getArea(addressArray),
//   //         state = this.getState(addressArray);
//   //       this.setState({
//   //         address: (address) ? address : '',
//   //         markerPosition: {
//   //           lat: newLat,
//   //           lng: newLng
//   //         },
//   //         mapPosition: {
//   //           lat: newLat,
//   //           lng: newLng
//   //         },
//   //       })
//   //     },
//   //     error => {
//   //       console.error(error);
//   //     }
//   //   );
//   // };

// onMarkerDragEnd = (mapProps, map, three) => { 
//   const { latLng } = three; 
//   const draggedLat = latLng.lat(); 
//   const draggedLng = latLng.lng(); 
  
//       Geocode.fromLatLng(draggedLat, draggedLng).then(
//       response => {
//         const address = response.results[0].formatted_address,
//           addressArray = response.results[0].address_components,
//           city = this.getCity(addressArray),
//           area = this.getArea(addressArray),
//           state = this.getState(addressArray);
//         this.setState({
//           address: (address) ? address : '',
//           markerPosition: {
//             lat: draggedLat,
//             lng: draggedLng
//           },
//           mapPosition: {
//             lat: draggedLat,
//             lng: draggedLng
//           },
//         })
        
//       },
//       error => {
//         console.error(error);
//       }
//     );
//   };

//   render() {
//     return (
//       <div id='googleMaps' style={{height: "400px",width: "70%",padding: "50px",backgroundColor:"pink"}}>
//         <PlacesAutocomplete
//           value={this.state.address}
//           onChange={this.handleChange}
//           onSelect={this.handleSelect}
//         >
//           {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
//             <div>
//               <input
//                 {...getInputProps({
//                   placeholder: 'Search Places ...',
//                   className: 'location-search-input',
//                 })}
//               />
//               <div className="autocomplete-dropdown-container">
//                 {loading && <div>Loading...</div>}
//                 {suggestions.map(suggestion => {
//                   const className = suggestion.active
//                     ? 'suggestion-item--active'
//                     : 'suggestion-item';
//                   // inline style for demonstration purpose
//                   const style = suggestion.active
//                     ? { backgroundColor: '#fafafa', cursor: 'pointer' }
//                     : { backgroundColor: '#ffffff', cursor: 'pointer' };
//                   return (
//                     <div
//                       {...getSuggestionItemProps(suggestion, {
//                         className,
//                         style,
//                       })}
//                     >
//                       <span>{suggestion.description}</span>
//                     </div>
//                   );
//                 })}
//               </div>
//             </div>
//           )}
//         </PlacesAutocomplete>

//         <Map
//           google={this.props.google}
//           initialCenter={{
//             lat: this.state.mapCenter.lat,
//             lng: this.state.mapCenter.lng
//           }}
//           center={{
//             lat: this.state.mapCenter.lat,
//             lng: this.state.mapCenter.lng
//           }}
//           containerStyle={containerStyle}


//         >
//           <Marker
//             position={{
//               lat: this.state.mapCenter.lat,
//               lng: this.state.mapCenter.lng
//             }}
//             draggable={true}
//             onDragend={this.onMarkerDragEnd} 
//             />
//         </Map>
//       </div>
//     )
//   }
// }

// export default GoogleApiWrapper({
//   apiKey: ('AIzaSyAKLUx_rnltQ2u9Xr39DcpX3UdRr293gCU')
// })(MapContainer)
