import React from 'react'
import Layout from '../../Layout/Layout'
import { Alert, Col, Divider, Row,Popconfirm,message } from 'antd';
import { useEffect,useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import CampaingHeading from './Headings/CampaignHeading';
import { Collapse } from 'antd';
import PreOrderHeading from './Headings/PreOrderHeading';
import LoadingComponent from '../LoadingComponent';
import {RiDeleteBack2Fill} from 'react-icons/ri'
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi'
import DatePicker from "react-datepicker";


import {BsX} from 'react-icons/bs'
import GetValidLocation from '../PreOrder/GetValidLocation';

const { Panel } = Collapse;


function AddCampaignOrderBatch() {
    const [currentPage, setCurrentPage] = useState(1)
    const [postsPerPage, setPostsPerPage] = useState(10);
    const [currentPage2, setCurrentPage2] = useState(1)
    const [postsPerPage2, setPostsPerPage2] = useState(10);
    const {campaignList,campaignOrderList,isLoadingOnLogin,isBatchCreated} =useSelector(state=> state.mainState)
    const [campaignId,setPreOrderId] = useState(null)
    const [myBatchedList,setMyBatchedList] = useState([])
    const [detailShowingItem,setDetailsShhowingItem] = useState(null)
    const [showDetailsModal,setShowDetailsModal] = useState(false)
    const [filterPreorderDate,setFilterPreorderDate] = useState(null)

    const [latitude, setLatitude] = useState("")
    const [longitude, setLongitude] = useState("")
    const [pickUpLocation,setPickupLocation] = useState("")

    const [batchData,setBatchData] = useState({
        packageName: "",
        commissionAmount:null,
        batchNote:"",
        maxDropOffRadius:"",
        bonusPerOrder:"",
        pickUpDate: ""

      })

    let pageNumbers1 = []
    for (let i = 1; i <= campaignOrderList.totalPage; i++) {
        pageNumbers1.push(i)
    }
    let pageNumbers2 = []
    for (let i = 1; i <= campaignList.totalPage; i++) {
        pageNumbers2.push(i)
    }

    const handlelatLong = (myvalue) => {
        setLatitude(myvalue.lat)
        setLongitude(myvalue.lng)
        console.log("my value",myvalue)
    }
    const handleAddress = (address) => {
        setPickupLocation(address)
        console.log("The location2",address)
    }

    // get current posts
    const indexOfLastPost = currentPage * postsPerPage
    const indexOfFirstPost = indexOfLastPost - postsPerPage
    // get current posts
    const indexOfLastPost2 = currentPage2 * postsPerPage2
    const indexOfFirstPost2 = indexOfLastPost2 - postsPerPage2

    const handleOnChange = (e)=> {
      const {name,value} = e.target
      setBatchData({...batchData,[name]: value})
    }

    const [showCreateBatchModal,setShowCreateBatchModal] = useState(false)
    const history = useHistory()

    const confirm = (e) => {
      e.preventDefault()
        let myLocalBatchedList = myBatchedList.reduce((myArr,item)=> {
            myArr.push({
                "batchId": item.id,
                "itemType": "CAMPAIGN_ORDER"
            })
            return myArr
        },[])
        dispatch({
            type: "CREATE_NEW_BATCH_REQUEST",
            payload: {
                "packageName": batchData.packageName,
                "packageItems": myLocalBatchedList,
                "commissionAmount":batchData.commissionAmount,
                "pickUpLocation": pickUpLocation,
                "pickUpLongitude": longitude,
                "pickUpLatitude": latitude,
                "pickUpDate":batchData.pickUpDate,
                "bonusPerOrder":batchData.bonusPerOrder,
                "batchNote":batchData.batchNote,
                "maxDropOffRadius":batchData.maxDropOffRadius,

            }
        })
      };

    const dispatch = useDispatch()

    const handleIndividualCheck = (item,status)=> {

        if(status) {
            setMyBatchedList([...myBatchedList,item])
        } else {
            
            let xx = myBatchedList.filter((bachedItem)=> bachedItem.id !== item.id)
            setMyBatchedList(xx)
            
        }

    }
    const handleRemoveBatchedOrder = (item)=> {
        toast.warning("Batched item removed !!")
        let i = document.getElementById(item.id)
        i.checked = false
        
        let xx = myBatchedList.filter((bachedItem)=> bachedItem.id !== item.id)
            setMyBatchedList(xx)
    }


    useEffect(()=> {

        dispatch({
            type: "FETCH_CAMPAIGN_LIST_REQUEST",
            payload: {postsPerPage,currentPage}
        })
    },[postsPerPage,currentPage])

    useEffect(() => {
        let finalPreorderDate = ""
        let sortingParam = "descending"
        let orderStatus = "PENDING"
        let currentPage = currentPage2
        let postsPerPage = postsPerPage2
        if(campaignId) {
          dispatch({
            type: "FETCH_CAMPAIGN_ORDER_REQUEST",
            payload: {
              campaignId,
                currentPage,postsPerPage,orderStatus,sortingParam,finalPreorderDate
            }
        })
        }


    }, [campaignId,currentPage2])
    useEffect(()=> {
        if(isBatchCreated) {
            setTimeout(()=> {
                history.push("/batch-package")
            },1000)
        }
    },[isBatchCreated])

    const preOrderListTable = () => (
        <>
        <Collapse bordered={false} accordion>
        <div className="create-new-batch-filter">
            <div className='filter-by__date' style={{display:"flex",gap: "50px"}}>
                            <p><label htmlFor="">Filter By Date :  <DatePicker placeholderText='Enter your date' className='filter-by__Date--input' dateFormat="yyyy-MM-dd" selected={filterPreorderDate}
                                onChange={(date) => setFilterPreorderDate(date)} /></label> </p>
                            <p><label htmlFor="">Filter By Location :  <select className='filter-by__Date--input'>
                                <option>Kathmandu</option>
                                <option>Kathmandu</option>
                                <option>Kathmandu</option>
                                <option>Kathmandu</option>
                                </select></label> </p>
                        </div>
            </div>
            {campaignList !== undefined && campaignList.data?.map((item,index) => <Panel key={index+1} header={<CampaingHeading item={item} setPreOrderId={setPreOrderId}  />}  >
             <div>
             <p className="privacy-loading--onFetching" style={{width: "100%"}}>{isLoadingOnLogin && <LoadingComponent />}</p>
             {campaignOrderList?.data?.length >=1 ? 
             <>
            <table style={{marginLeft: "2vw"}}>
                <thead>
                    <tr style={{backgroundColor: "pink"}}>
                        <th className='campaignItem--image'><input type='checkbox' disabled /></th>
                        <th className='campaignItem--image'>Order Id</th>
                        <th className='campaignItem--image'>Customer Name</th>
                        <th className='campaignItem--image'>Contact Number</th>
                        <th className='campaignItem--name'>Delivery Location</th>
                        <th className='campaignItem--desc'>Delivery Time</th>
                    </tr>
                </thead>
    
                <tbody>
                    {campaignOrderList?.data?.map((item)=> <tr>
                        <td><input type='checkbox' id={item.id} onChange={(e)=> handleIndividualCheck(item,e.target.checked)} /></td>
                        <td><span>{item.code}</span></td>
                        <td>{item.userFullName}</td>
                        <td>{item.contactNumber}</td>
                        <td>{item.deliveryLocation}</td>
                        <td>{item.createdOn}</td>
                    </tr>) }
           
                </tbody>
            </table>
                  <div className="pagination">
                  <p>Showing {indexOfFirstPost2} to {indexOfLastPost2 > campaignOrderList.totalData ? campaignOrderList.totalData : indexOfLastPost2} of {campaignOrderList.totalData} entries</p>
                  <nav aria-label="...">
                      <ul className="pagination">
                          {currentPage2 === 1 ? <li className="page-item">
                              <a className="page-link" href="#"><BiChevronLeft /></a>
                          </li> : <li className="page-item">
                              <a className="page-link" href="#"><BiChevronLeft onClick={() => setCurrentPage2(currentPage2 - 1)} /></a>
                          </li>}
                          {pageNumbers1.map((x) =>
                              <li key={x} className="page-item"><a className="page-link" href="#" onClick={() => setCurrentPage2
                                  (x)} >{x}</a></li>
                          )}
                          {currentPage2 == pageNumbers1.length ? <li className="page-item">
                              <a className="page-link" href="#"><BiChevronRight /></a>
                          </li> : <li className="page-item">
                              <a className="page-link" href="#"><BiChevronRight onClick={() => setCurrentPage2(currentPage2 + 1)} /></a>
                          </li>}
                      </ul>
                  </nav>

              </div>
              </>
            : <div style={{width: "100%",display: "flex",justifyContent:"center",alignItems: "center",height: "80px"}}>
                        <div style={{backgroundColor: "white",height: "50px",width: "maxContent",display: "flex",alignItems: "center",padding: "10px 20px",boxShadow: "box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;"}}>No order available in table</div>
            </div>}
        </div>
    </Panel>
)}
      </Collapse>
      <div style={{backgroundColor: "#FAFAFA"}}>
            <div className="pagination" >
                  <p>Showing {indexOfFirstPost} to {indexOfLastPost > campaignList.totalData ? campaignList.totalData : indexOfLastPost} of {campaignList.totalData} entries</p>
                  <nav aria-label="...">
                      <ul className="pagination">
                          {currentPage === 1 ? <li className="page-item">
                              <a className="page-link" href="#"><BiChevronLeft /></a>
                          </li> : <li className="page-item">
                              <a className="page-link" href="#"><BiChevronLeft onClick={() => setCurrentPage(currentPage - 1)} /></a>
                          </li>}
                          {pageNumbers2.map((x) =>
                              <li key={x} className="page-item"><a className="page-link" href="#" onClick={() => setCurrentPage(x)} >{x}</a></li>
                          )}
                          {currentPage == pageNumbers2.length ? <li className="page-item">
                              <a className="page-link" href="#"><BiChevronRight /></a>
                          </li> : <li className="page-item">
                              <a className="page-link" href="#"><BiChevronRight onClick={() => setCurrentPage(currentPage + 1)} /></a>
                          </li>}
                      </ul>
                  </nav>

              </div>
            </div>
            </>

        
    )
    
    const orderList = ()=> {
        return (
            <div style={{backgroundColor: "cadetblue",marginTop: "100px"}}>
                {preOrderListTable()}
            </div>
        )
    }
    const batchedList = ()=> {
        return (
            <div className="bachedList--container">
                <p>Batched Order List</p>
                <div className="bachedList--container__item">
                    {myBatchedList?.map((item)=> <div className='bachedList--container__item--list' key={item.id}>
                        <p onClick={()=> {
                          handleRemoveBatchedOrder(item)
                          
                        }}><RiDeleteBack2Fill className='riDeleteBack2Fill' /> </p>
                        <div onClick={()=> {
                          setDetailsShhowingItem(item)
                          setShowDetailsModal(true)
                        }}>
                            <p>{item.code}</p>
                            <p>{item.userFullName}</p>
                            <Divider style={{margin: "0px"}} />
                        </div>
                        
                    </div>)}
                </div>
                {myBatchedList.length >0 &&   <div className='bachedList--container--button'>

        <button onClick={()=> setShowCreateBatchModal(true)}>Batch Now</button>

                    
                </div>}
            </div>
        )
    }
  return (
    <Layout>
        <div>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
                <Col span={18}>{orderList()}</Col>
                <Col span={6}>{batchedList()}</Col>
            </Row>
        </div>
        <div className={`${showDetailsModal ? "delete--admin" : "delete--admin__hidden"}`} >
                                <div className="delete--admin--container" style={{justifyContent: "flex-start",minWidth: "500px",minHeight: "340px",maxHeight: "350px"}}>
                                <div style={{display: "flex",justifyContent: "space-between",alignItems: "center",padding: "10px 20px"}}>
                                            <h3 style={{marginTop: "20px"}}>Campaign Order Item Details</h3>
                                            <h3 style={{marginTop: "20px",cursor: "pointer"}} onClick={()=> setShowDetailsModal(false)}><BsX /></h3>
                                        </div>
                                        {/* <hr/> */}
                                        <Divider style={{margin: "0px"}} />
                                    <div className="" style={{display: "flex",flexDirection: "column",alignItems: "flex-start",marginLeft: "5vw",marginTop: "20px"}} >

                                    <p><span>Campaign Name :</span>{detailShowingItem?.campaignName} </p>
                                    <p><span>Customer Name :</span>{detailShowingItem?.userFullName}</p>
                                    <p><span>Customer Number :</span>{detailShowingItem?.deliveryContactNo}</p>
                                    <p><span>Delivery Location :</span> {detailShowingItem?.deliveryData?.deliveryAddress}</p>
                                    <p><span>Delivery Time :</span> {detailShowingItem?.deliveryData?.scheduledTime}</p>
                                    <p><span>Price : Rs. </span> {detailShowingItem?.campaignPrice}</p>
                
                                    </div>

                                </div>
                            </div>
                            <div className={`${showCreateBatchModal ? "delete--admin" : "delete--admin__hidden"}`} >
                                <div className="delete--admin--container" style={{justifyContent: "flex-start",minWidth: "600px",minHeight: "540px",maxHeight: "1000px"}}>
                                <div style={{display: "flex",justifyContent: "space-between",alignItems: "center",padding: "10px 20px"}}>
                                            <h3 style={{marginTop: "20px"}}>Create New Batch</h3>
                                            <h3 style={{marginTop: "20px",cursor: "pointer"}} onClick={()=> setShowCreateBatchModal(false)}><BsX /></h3>
                                        </div>

                                        <Divider style={{margin: "0px"}} />
                                        <div className="Modal--form" style={{marginTop: "15px"}}>
                                            <form>
                                          <div className='batch__Modal--form--title'>
                                            <p><label htmlFor="">Batch Name : <input type='text' name='packageName' value={batchData.packageName} placeholder="Batch name" onChange={handleOnChange} /></label></p>
                                          </div>
                                          <div className='batch__Modal--form--title'>
                                            <p><label htmlFor="">Batch Note : <input type='text' name='batchNote' value={batchData.batchNote} placeholder="Batch Note" onChange={handleOnChange} /></label></p>
                                          </div>
                                          <div className='batch__Modal--form--title'>
                                            <p><label htmlFor="">Bonus Per Order : <input type='number' name='bonusPerOrder' value={batchData.bonusPerOrder} placeholder="Bonus Per Order" onChange={handleOnChange} /></label></p>
                                          </div>
                                          <div className='batch__Modal--form--title'>
                                            <p><label htmlFor="">Pick Up Date : <input type='date' name='pickUpDate' value={batchData.pickUpDate} onChange={handleOnChange} /></label></p>
                                          </div>
                
                                          <div className='batch__Modal--form--title'>
                                            <p><label htmlFor="">Max Drop Radius(KM*) : <input type='number' name='maxDropOffRadius' placeholder='Max Drop Radius' value={batchData.maxDropOffRadius} onChange={handleOnChange} /></label></p>
                                          </div>
                                    
            
                                          <div className='batch__Modal--form--title'>
                                            <p><label htmlFor="">Commission(%) : <input type='number' name='commissionAmount' placeholder='Commission' value={batchData.commissionAmount} onChange={handleOnChange}  /></label></p>
                                          </div>
                                          <div className='batch__Modal--form--title'>
                                            <p style={{display: "flex"}}><label htmlFor="">Location : </label> <GetValidLocation handlelatLong={handlelatLong} handleAddress={handleAddress} /></p>
                                          </div>

                                          <div className='batch__Modal--form--button'>
                                            <button onClick={confirm}>Submit</button>
                                          </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
    </Layout>
  )
}

export default AddCampaignOrderBatch
