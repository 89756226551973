import React, { useState, useEffect } from 'react'
import { connect,useDispatch } from 'react-redux';
import {BsArrowRepeat} from 'react-icons/bs'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // ES6

import Layout from '../../Layout/Layout'
import Footer from '../Z_Footer/Footer'
import LoadingComponent from '../LoadingComponent';

const mapStateToProps = store=> {
    const {updateButtonText,termsAndConditionData,isLoadingOnLogin} = store.mainState;
    return {updateButtonText,termsAndConditionData,isLoadingOnLogin}
}

function Terms_nCondition({updateButtonText,termsAndConditionData,isLoadingOnLogin}) {
    const dispatch = useDispatch();
    const [modules, setModules] = useState()
    const [formats, setFormats] = useState()
    useEffect(() => {
        setModules({
            toolbar: [
                [{ 'header': [1, 2, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                ['link', 'image'],
                ['clean']
            ],
        })
        setFormats([
            'header',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link', 'image'
        ])
    }, [])
    const [privacyText, setPrivacyText] = useState("");

    useEffect(()=> {
        dispatch({
            type: "FETCH_TERMS_CONDITION_REQUEST"
        })
    },[])
    useEffect(()=> {
       setPrivacyText(termsAndConditionData)
    },[termsAndConditionData])
    const handlePrivacySubmit = (event)=> {
        let myPolicy = {
            termsAndCondition: privacyText
        }
        event.preventDefault();
         dispatch({
            type: "POST_TERMS_N_CONDITION_REQUEST",
            payload: {
                myPolicy
            }
        })
    }
    return (
        <Layout>
           <p className="privacy-loading--onFetching">{isLoadingOnLogin && <LoadingComponent />}</p>
            <div className="privacy--container">
            <div className="terms_n_condition--heading">
                Terms And Conditions
            </div>
         
               <form>
                 <ReactQuill theme="snow" value={privacyText} modules={modules} formats={formats} onChange={(e)=> setPrivacyText(e)} />
                <div className="privacy--updateButton">
                    <button onClick={handlePrivacySubmit} >{updateButtonText}</button>
                </div>
               </form>
                <Footer />
            </div>

        </Layout>
    )
}

export default connect(mapStateToProps,null) (Terms_nCondition)
