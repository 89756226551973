import React from 'react'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import {HiOutlineLocationMarker} from 'react-icons/hi'
import {AiOutlineUser} from 'react-icons/ai'
import {BsCheckCircle,BsReverseLayoutTextSidebarReverse} from 'react-icons/bs'
import { useHistory } from 'react-router';

function PreOrderHeading({item,setPreOrderId}) {
    const history = useHistory()

  return (
    <div style={{display: "flex",justifyContent: "space-between",width: "100%"}} onClick={()=> setPreOrderId(item.planId)}>
        <p className=''><img style={{height: "60px",width: "60px"}} src={item.planImage} alt="" /></p>
        <p className=''>{item.planName}</p>
        {/* <p>{item.description}</p> */}
        <p className=''><AiOutlineUser />{item.planContactPersonFullName}</p>
        <p className=''><HiOutlineLocationMarker /> {item.deliveryValidLocation}</p>
        <p className=''>Rs. {item.planPrice}</p>
    </div>
  )
}


export default PreOrderHeading