import { combineReducers } from "redux";
import { combineEpics } from "redux-observable";
import UpdateMenu from "../Components/Restaurant/Menu/UpdateMenu";
import {
  fetchDashboardDataEpic,
  fetchAdminDataEpic,
  authenticateAdminEpic,
  fetchPrivacyEpic,
  fetchTermsAndConditionEpic,
  postPrivacy_Epic,
  postTerms_n_condition_Epic,
  addNotificationEpic,
  fetchUsersListEpic,
  fetchUserByIdEpic,
  enableUsersEpic,
  disableUsersEpic,
  fetchCouponEpic,
  addCouponEpic,
  fetchRestaurantsEpic,
  addRestaurantEpic,
  updateRestaurantEpic,
  activivateRestaurantEpic,
  deActivivateRestaurantEpic,
  deleteRestaurantEpic,
  updateCouponEpic,
  activivateCouponEpic,
  deActivivateCouponEpic,
  postAdminDataEpic,
  updateAdminDataEpic,
  fetchReferalEpic,
  postReferalEpic,
  enableDisableAdminEpic,
  fetchAdvertisementEpic,
  postAdvertisementEpic,
  updateAdvertisementEpic,
  fetchAdminByIdEpic,
  enablePromotionEpic,
  disablePromotionEpic,
  fetchServiceRateEpic,
  addServiceRateEpic,
  fetchOrderListEpic,
  orderDetailsEpic,
  acceptOrderEpic,
  fetchDeliveryEpic,
  addDeliveryEpic,
  activateDeliveryEpic,
  deactivateDeliveryEpic,
  assignOrderToDEpic,
  fetchActiveDeliveryEpic,
  deleteDeliveryBoyEpic,
  fetchDeliveryDetailsByIdEpic,
  updateDeliveryEpic,
  acceptPaymentOfOrderEpic,
  fetchNotificationListEpic,
  rejectPaymentOfOrderEpic,
  approveOrderEpic,
  fetchExchangePointEpic,
  postExchangePointEpic,
  fetchOrderListByStatusEpic,
  receiveOrderEpic,
  cancelOrderEpic,
  reachedDeliveryDestinationEpic,
  deliveryCompletedEpic,
  fetchUserAnnouncemnetEpic,
  postUserAnnouncemnetEpic,
  updateUserAnnouncemnetEpic,
  fetchDeliveryFeeEpic,
  postDeliveryFeeEpic,
  activateAnnouncemnetEpic,
  de_activateAnnouncemnetEpic,
  fetchInternalAdsLinkEpic,
  searchRestaurantEpic,
  setOrderReadyEpic,
  settlementEpic,
  exportToExcellEpic,
  fetchDeliveryCommissionFeeEpic,
  postDeliveryCommissionFeeEpic,
  searchOderListEpic,
  fetchWalletDataEpic,
  verifyTransactionEpic,
  fetchVerifiedWalletDataEpic,
  addNewCutomerEpic,
  searchWalletDataEpic,
  searchVerifiedWalletDataEpic,
  searchCustomerByPhN,
  fetchActivRestaurantListEpic,
  fetchMenuItemWithoutResIdEpic,
  addItemToCartEpic,
  addDeliveryInfoEpic,
  placeOrderFromPhoneOrderEpic,
  getOperatingLocationEpic,
  addOperatingLocationEpic,
  editOperatingLocationEpic,
  markRestaurnatPopularEpic,
  markRestaurantNotPopularEpic,
  fetchActivMenuListEpic,
  updateOrderCartItemEpic,
  getAllPreOrderListEpic,
  getRequestedPreOrderListEpic,
  addPreOrderEpic,
  activatePreOrderEpic,
  de_activatePreOrderEpic,
  getActiveOperatingLocationEpic,
  checkPreCartItemEpic,
  fetchPhoneOrderListEpic,
  searchPreOrderRequestEpic,
  editPreOrderEpic,
  editRestarantEmailEpic,
  searchRestaurantNameEpic,
  fetchCategoryListEpic,
  fetchMenuListEpic,
  fetchCampaignListEpic,
  addCampaignListEpic,
  editCampaignListEpic,
  enableCampaignEpic,
  disableCampaignLEpic,
  enableCampaignItemEpic,
  disableCampaignItemEpic,
  fetchCampaignOrderEpic,
  changePreOrderStatusEpic,
  fetchDeliveryDrafEpic,
  acceptDeliveryDraftEpic,
  approveCampaignEpic,
  completeCampaignEpic,
  cancelCampaignOrderEpic,
  createNewBatchEpic,
  fetchNewBatchEpic,
  assignBatchPakageToDEpic,
  fetchPreOTimeslotEpic,
  addPreOTimeslotEpic,
  editPreOTimeslotEpic,
  campaignPhoneOrderEpic,
  chnageSettlementStatusEpic,
  getRequestedPreOrderListInBatchEpic,
  fetchBadgePackage,
  verifyDriver,
  updateBillNoEpic,
  addDeliveryMoreInfoEpic,
  getRequestedPreOrderListEpic1,
  markAsPopupEpic,
  removeFromPopupEpic,
  settlePreOrderEpic,
  fetchResellerEpic,
  addResellerEpic,
  updateResellerEpic,
  activateResellerEpic,
  deActivateResellerEpic,
  fetchPreOrderMerchantEpic,
  addPreOrderMerchantEpic,
  updatePreOrderMerchantEpic,
  fetchPreOrderMerchantListEpic,
  approvePreOrderMerchantEpic,
  dispatchPreOrderMerchantEpic,
  assignPreOrderMerchantEpic,
  getResellerByIdEpic,
  getOrdersFromAllCampaignEpic,
  getCODVerificationPendingEpic,
  activatepreordermerchantEpic,
  deActivatepreordermerchantEpic,
  getReconcilementEpic,
  approveReconcilementOrdersEpic,
  getArchivesEpic,
  reapproveReconcilementOrdersEpic,
  activateMenuCategoryEpic,
  deActivateMenuCategoryEpic,
  addMenuCategoryEpic,
  updateCategoryEpic,
  addMenuEpic,
  updateMenuEpic,
  activateMenuEpic,
  deActivateMenuEpic,
  assignOrderToMultipleDEpic,
  fetchKYClistEpic,
  verifyKycEpic,
  revokeKycVerificationEpic,
  fetchVerifiedKYClistEpic,
  fetchUnVerifiedKYClistEpic,
  pushIntoKycRevokeLogEpic,
  fetchKycRevokeHistoryLogEpic,
} from "./myEpics";
import orkReducer from "./orkReducer";

export const rootReducer = combineReducers({
  mainState: orkReducer,
});
export const rootEpic = combineEpics(
  fetchDashboardDataEpic,
  fetchAdminDataEpic,
  fetchAdminByIdEpic,
  postAdminDataEpic,
  updateAdminDataEpic,
  enableDisableAdminEpic,

  authenticateAdminEpic,
  fetchUsersListEpic,
  enableUsersEpic,
  fetchUserByIdEpic,
  disableUsersEpic,
  fetchCouponEpic,
  addCouponEpic,
  updateCouponEpic,
  activivateCouponEpic,
  deActivivateCouponEpic,

  fetchRestaurantsEpic,
  addRestaurantEpic,
  updateRestaurantEpic,
  activivateRestaurantEpic,
  deActivivateRestaurantEpic,
  deleteRestaurantEpic,

  postPrivacy_Epic,
  fetchPrivacyEpic,
  postTerms_n_condition_Epic,
  fetchTermsAndConditionEpic,
  addNotificationEpic,

  fetchReferalEpic,
  postReferalEpic,

  fetchAdvertisementEpic,
  fetchInternalAdsLinkEpic,
  postAdvertisementEpic,
  updateAdvertisementEpic,
  enablePromotionEpic,
  disablePromotionEpic,

  fetchServiceRateEpic,
  addServiceRateEpic,

  fetchWalletDataEpic,
  verifyTransactionEpic,
  searchWalletDataEpic,
  fetchVerifiedWalletDataEpic,
  searchVerifiedWalletDataEpic,

  fetchOrderListByStatusEpic,
  fetchOrderListEpic,
  searchOderListEpic,
  orderDetailsEpic,
  acceptOrderEpic,
  cancelOrderEpic,
  fetchActiveDeliveryEpic,
  fetchPhoneOrderListEpic,

  fetchDeliveryEpic,
  addDeliveryEpic,
  updateDeliveryEpic,
  activateDeliveryEpic,
  deactivateDeliveryEpic,
  assignOrderToDEpic,
  assignOrderToMultipleDEpic,
  deleteDeliveryBoyEpic,
  fetchDeliveryDetailsByIdEpic,

  fetchDeliveryFeeEpic,
  postDeliveryFeeEpic,
  fetchDeliveryCommissionFeeEpic,
  postDeliveryCommissionFeeEpic,

  receiveOrderEpic,
  reachedDeliveryDestinationEpic,
  deliveryCompletedEpic,

  acceptPaymentOfOrderEpic,
  rejectPaymentOfOrderEpic,

  approveOrderEpic,

  fetchNotificationListEpic,

  fetchExchangePointEpic,
  postExchangePointEpic,
  setOrderReadyEpic,

  fetchUserAnnouncemnetEpic,
  postUserAnnouncemnetEpic,
  updateUserAnnouncemnetEpic,
  activateAnnouncemnetEpic,
  de_activateAnnouncemnetEpic,
  // search restaurnt name
  searchRestaurantEpic,
  settlementEpic,
  exportToExcellEpic,

  // phone order
  searchCustomerByPhN,
  addNewCutomerEpic,
  fetchActivRestaurantListEpic,
  fetchMenuItemWithoutResIdEpic,
  addItemToCartEpic,
  addDeliveryInfoEpic,
  placeOrderFromPhoneOrderEpic,
  checkPreCartItemEpic,

  // get operating location
  getOperatingLocationEpic,
  getActiveOperatingLocationEpic,
  addOperatingLocationEpic,
  editOperatingLocationEpic,
  // mark popular /unmark popular
  markRestaurnatPopularEpic,
  markRestaurantNotPopularEpic,
  fetchActivMenuListEpic,
  // update order cart item from order details
  updateOrderCartItemEpic,

  // pre order
  getAllPreOrderListEpic,
  getRequestedPreOrderListEpic,
  addPreOrderEpic,
  activatePreOrderEpic,
  de_activatePreOrderEpic,
  searchPreOrderRequestEpic,
  editPreOrderEpic,

  editRestarantEmailEpic,
  searchRestaurantNameEpic,
  fetchCategoryListEpic,
  fetchMenuListEpic,

  // campaign
  fetchCampaignListEpic,
  addCampaignListEpic,
  editCampaignListEpic,
  enableCampaignEpic,
  disableCampaignLEpic,
  enableCampaignItemEpic,
  disableCampaignItemEpic,
  fetchCampaignOrderEpic,
  changePreOrderStatusEpic,

  fetchDeliveryDrafEpic,
  acceptDeliveryDraftEpic,

  approveCampaignEpic,
  completeCampaignEpic,
  cancelCampaignOrderEpic,
  // getOrderPreorderListEpic

  createNewBatchEpic,
  fetchNewBatchEpic,
  assignBatchPakageToDEpic,

  fetchPreOTimeslotEpic,
  // addPreOTimeslotEpic
  addPreOTimeslotEpic,
  editPreOTimeslotEpic,
  campaignPhoneOrderEpic,

  //settlement
  chnageSettlementStatusEpic,

  //Get preorder list batch request
  getRequestedPreOrderListInBatchEpic,
  fetchBadgePackage,
  verifyDriver,
  updateBillNoEpic,
  addDeliveryMoreInfoEpic,
  getRequestedPreOrderListEpic1,
  markAsPopupEpic,
  removeFromPopupEpic,
  settlePreOrderEpic,
  fetchResellerEpic,
  addResellerEpic,
  updateResellerEpic,
  activateResellerEpic,
  deActivateResellerEpic,
  fetchPreOrderMerchantEpic,
  addPreOrderMerchantEpic,
  updatePreOrderMerchantEpic,
  fetchPreOrderMerchantListEpic,
  approvePreOrderMerchantEpic,
  dispatchPreOrderMerchantEpic,
  assignPreOrderMerchantEpic,
  getResellerByIdEpic,
  getOrdersFromAllCampaignEpic,
  getCODVerificationPendingEpic,
  activatepreordermerchantEpic,
  deActivatepreordermerchantEpic,
  getReconcilementEpic,
  approveReconcilementOrdersEpic,
  getArchivesEpic,
  reapproveReconcilementOrdersEpic,
  activateMenuCategoryEpic,
  deActivateMenuCategoryEpic,
  addMenuCategoryEpic,
  updateCategoryEpic,
  addMenuEpic,
  updateMenuEpic,
  activateMenuEpic,
  deActivateMenuEpic,

  fetchKYClistEpic,
  fetchVerifiedKYClistEpic,
  fetchUnVerifiedKYClistEpic,
  verifyKycEpic,
  revokeKycVerificationEpic,
  pushIntoKycRevokeLogEpic,
  fetchKycRevokeHistoryLogEpic
);
