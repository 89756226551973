import React, { useState, useRef, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";
import { BsPhone } from "react-icons/bs";
import { IoLocationOutline } from "react-icons/io5";

const mapStateToProps = (store) => {
  const {
    updateButtonText,
    internalAdsLinks,
    isEnabledUsers,
    searchRestaurantList,
  } = store.mainState;
  return {
    updateButtonText,
    internalAdsLinks,
    isEnabledUsers,
    searchRestaurantList,
  };
};

function UpdateInternalType({
  updateButtonText,
  internalAdsLinks,
  isEnabledUsers,
  searchRestaurantList,
  item,
}) {
  const [advertisementId, setAdvertisementId] = useState(null);
  const [typeOfAdd, setTypeOfAdd] = useState("External");
  const clearFileInputRef = useRef();
  const dispatch = useDispatch();
  const [addsName, setAddsName] = useState("");
  const [addsDetails, setAddsDetails] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [addsImage, setAddsImage] = useState("");
  const [companyUrl, setCompanyUrl] = useState("");
  const [internalLink, setInternalLink] = useState("");
  const [searchKeyWord, setSearchKeyWord] = useState("");

  const [restaurantItem, setRestaurnatId] = useState(null);
  const [restaurantId, setRestuarntId] = useState(null);

  useEffect(() => {
    dispatch({ type: "FETCH_INTERNAL_ADS_LINK_REQUEST" });
  }, []);
  console.log("kjdslfj", restaurantId);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    if (addsImage !== "") {
      formData.append("advertisementId", advertisementId);
      formData.append("advertisementLinkType", "INTERNAL");
      formData.append("advertisementName", addsName);
      formData.append("advertisementDetails", addsDetails);
      formData.append("companyName", companyName);
      formData.append("content", addsImage);
      formData.append("internalLink", internalLink);
      if (restaurantItem !== null) {
        formData.append("restaurantId", restaurantItem.id);
      }
      dispatch({
        type: "UPDATE_ADVERTISEMENT_REQUEST",
        payload: formData,
      });
    } else {
      formData.append("advertisementId", advertisementId);
      formData.append("advertisementLinkType", "INTERNAL");
      formData.append("advertisementName", addsName);
      formData.append("advertisementDetails", addsDetails);
      formData.append("companyName", companyName);
      formData.append("companyUrl", companyUrl);
      formData.append("internalLink", internalLink);
      if (internalLink === "RESTAURANT") {
        if (restaurantItem !== null) {
          formData.append("restaurantId", restaurantItem.id);
        } else {
          formData.append("restaurantId", restaurantId);
        }
      }

      dispatch({
        type: "UPDATE_ADVERTISEMENT_REQUEST",
        payload: formData,
      });
    }
  };
  useEffect(() => {
    setAdvertisementId(item?.id);
    setTypeOfAdd(
      item?.advertisementLinkType === "INTERNAL" ? "Internal" : "External"
    );
    setAddsName(item?.advertisementName);
    setAddsDetails(item?.advertisementDetails);
    setCompanyName(item?.companyName);
    setCompanyUrl(item?.companyURL);
    setInternalLink(item?.internalLink);
    setRestuarntId(item?.restaurantId);
  }, [item]);
  useEffect(() => {
    let currentPage = 1;
    let postsPerPage = 50;
    let keyWord = searchKeyWord;
    dispatch({
      type: "FETCH_RESTAURANT_LIST_REQUEST",
      payload: { keyWord, currentPage, postsPerPage },
    });
  }, [searchKeyWord]);

  return (
    <div>
      <ToastContainer />
      <div className="Modal--heading">Edit Advertisement</div>
      <div className="addSelector--button">
        <div className="addSelector--button__container">
          {typeOfAdd === "External" ? (
            <div className={typeOfAdd === "External" && "external--link"}>
              External Link
            </div>
          ) : (
            <div className={typeOfAdd === "Internal" && "external--link"}>
              Internal Link
            </div>
          )}
        </div>
      </div>

      {typeOfAdd === "Internal" && (
        <div className="Modal--form">
          <form>
            <div className="Modal--form--title">
              <p>
                <label htmlFor="added_by">Internal Link: </label>
                <select
                  name=""
                  id=""
                  value={internalLink}
                  onChange={(e) => setInternalLink(e.target.value)}
                >
                  {internalAdsLinks !== undefined &&
                    internalAdsLinks.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                </select>
              </p>
            </div>
            {(typeOfAdd === "Internal") & (internalLink === "RESTAURANT") ? (
              <>
                <div className="searchMenu--box">
                  <input
                    type="text"
                    placeholder="Search Restaurant"
                    value={searchKeyWord}
                    onChange={(e) => setSearchKeyWord(e.target.value)}
                  />
                </div>
                {restaurantItem !== null && (
                  <div className="searchMenu--box--restaurant">
                    <p>
                      Selected Restaurant Name :{" "}
                      {restaurantItem?.restaurantName}
                    </p>
                    <p> Location : {restaurantItem.location}</p>
                  </div>
                )}

                <hr />
              </>
            ) : null}
            <div className="addAdvertisement--restaurant--container">
              {(typeOfAdd === "Internal") & (internalLink === "RESTAURANT")
                ? searchRestaurantList.data?.map((rest) => (
                    <>
                      <div
                        className="searchByRestaurant--body__item"
                        onClick={() => {
                          setRestaurnatId(rest);
                          dispatch({
                            type: "CLEAR_RESTAURANT_SEARCH_LIST",
                          });
                        }}
                      >
                        <div className="restaurant--details--container">
                          <p>{rest.restaurantName}</p>
                          <p>
                            <BsPhone className="my-ui--icons" />
                            {rest.phoneNumber}
                          </p>
                          <p>
                            <IoLocationOutline className="my-ui--icons" />
                            {rest.location}
                          </p>
                        </div>
                        <div className="restaurant--image--container">
                          <img src={rest.imageUrl} alt="Restaurant Image" />
                        </div>
                      </div>
                    </>
                  ))
                : null}
            </div>

            <div className="Modal--form--title">
              <p>
                <label htmlFor="added_by">Ads Image*: </label>
                <input
                  type="file"
                  accept="image/*"
                  ref={clearFileInputRef}
                  onChange={(e) => setAddsImage(e.target.files[0])}
                />
              </p>
            </div>
            <div className="Modal--form--title">
              <p>
                <label htmlFor="added_by">Advertisement Name*: </label>
                <input
                  type="text"
                  value={addsName}
                  placeholder="Advertisement Name..."
                  onChange={(e) => setAddsName(e.target.value)}
                />
              </p>
            </div>
            <div className="Modal--form--title">
              <p>
                <label htmlFor="added_by">Advertisement Details*: </label>
                <input
                  type="text"
                  value={addsDetails}
                  placeholder="Advertisement Details..."
                  onChange={(e) => setAddsDetails(e.target.value)}
                />
              </p>
            </div>
            <div className="Modal--form--title">
              <p>
                <label htmlFor="added_by">Company Name*: </label>
                <input
                  type="text"
                  placeholder="Company Name..."
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </p>
            </div>

            <div className="Modal--form--title">
              <p>
                <label htmlFor="title">Company Url: </label>
                <input
                  type="url"
                  required
                  placeholder="Enter Url"
                  value={companyUrl}
                  onChange={(e) => setCompanyUrl(e.target.value)}
                />
              </p>
            </div>

            <div className="modal--container__group--buttons">
              <button
                style={{ marginBottom: "50px" }}
                type="submit"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                {updateButtonText}
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default connect(mapStateToProps, null)(UpdateInternalType);
