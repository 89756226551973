import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import 'tippy.js/dist/tippy.css';
import LoadingComponent from '../LoadingComponent';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi'
import moment from 'moment';

const mapStateToProps = (store) => {
    const { isLoadingOnLogin ,resellerDetail} = store.mainState;
    return { isLoadingOnLogin,resellerDetail }
}

function ResellerDetail({ id,isLoadingOnLogin,resellerDetail }) {

    let pageNumbers = []

    // Pagination
    const [currentPage, setCurrentPage] = useState(1)
    const [postsPerPage, setPostsPerPage] = useState(10);

        // get current posts
        const indexOfLastPost = currentPage * postsPerPage
        const indexOfFirstPost = indexOfLastPost - postsPerPage

        for (let i = 1; i <= resellerDetail?.resellerTransaction?.totalPage; i++) {
            pageNumbers.push(i)
        }

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch({
            type: "FETCH_RESELLER_DETAIL_REQUEST",
            payload: {
                currentPage,postsPerPage,resellerId: id
            }
        })
    }, [id,currentPage])

    const str = () => (

        resellerDetail?.resellerTransaction?.data !== undefined && resellerDetail?.resellerTransaction.data.map((item,index) =>
            <tr key={item.id}>
                <td ><img className="myLargeImage" src={item.profileImage} alt="image" /></td>
                <td>{item.fullName}</td>
                <td>{item.earningHistoryFromThisUser.map(data  => {return <><p style={{fontSize:"12px",margin:"0"}}>Credit Balance : Rs {data.creditBalance}</p> <p style={{fontSize:"12px",margin:"0"}}>Description : {data.description} </p> <p style={{fontSize:"12px",margin:"0"}}>Transaction time :{moment( data.transactionTime).format("YYYY-MM-DD HH:mm:ss")}</p> </>})}</td>
                <td>{item.earnedFromThisUser}</td>
                <td>{moment( item.addedDateTime).format("YYYY-MM-DD HH:mm:ss")}</td>
            </tr>
        )
    )
    
    return (
        <div>
            <div className="Modal--heading">
                <span style={{ color: "#3e445e" }}>Reseller Transactions</span>
            </div>
            <p className="privacy-loading--onFetching">{isLoadingOnLogin && <LoadingComponent />}</p>

            <div className="table-container" style={{ margin: "30px" }}>
                
                <table className="table_x">
                    <thead>
                        <tr>
                            <th className="delivery--image">Image</th>
                            <th className="delivery--name">Full Name</th>
                            <th className="delivery--idNo">Earning from this user</th>
                            <th className="delivery--email">Earned From this user</th>
                            <th className="delivery--gender">Date</th>
                        </tr></thead>
                    <tbody>
                        {str()}
                    </tbody>
                </table>

                <div className="pagination">
                            <p>Showing {indexOfFirstPost} to {indexOfLastPost > resellerDetail?.resellerTransaction?.totalData ? resellerDetail?.resellerTransaction?.totalData : indexOfLastPost} of {resellerDetail?.resellerTransaction?.totalData} entries</p>
                            <nav aria-label="...">
                                <ul className="pagination">
                                    {currentPage === 1 ? <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronLeft /></a>
                                    </li> : <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronLeft onClick={() => setCurrentPage(currentPage - 1)} /></a>
                                    </li>}
                                    {pageNumbers.map((x) =>
                                        <li key={x} className="page-item"><a className="page-link" href="#" onClick={() => setCurrentPage(x)} >{x}</a></li>
                                    )}
                                    {currentPage == pageNumbers.length ? <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronRight /></a>
                                    </li> : <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronRight onClick={() => setCurrentPage(currentPage + 1)} /></a>
                                    </li>}
                                </ul>
                            </nav>

                        </div>
            
            </div>
        </div>
    )
}

export default connect(mapStateToProps, null)(ResellerDetail)
