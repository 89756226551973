import React, { useEffect, useState } from 'react'
import Layout from '../../Layout/Layout'
import { AiFillEdit } from 'react-icons/ai'
// import {RiAddBoxFill} from 'react-icons/ri'
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi'
import { BsX } from "react-icons/bs";
import { connect, useDispatch } from 'react-redux'
import LoadingComponent from '../LoadingComponent'
import Footer from '../Z_Footer/Footer'
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import EditDeliveryFee from './EditDeliveryFee'


const mapStateToProps = store => {
    const { buttonText, isLoadingOnLogin, deliveryFeeData, isToogleSidebar ,isEnabledUser} = store.mainState;
    return { buttonText, isLoadingOnLogin, deliveryFeeData, isToogleSidebar,isEnabledUser }
}

function GetDeliveryFee({ buttonText, isLoadingOnLogin, deliveryFeeData, isToogleSidebar ,isEnabledUser}) {
    const {baseDeliveryCharge,deliveryChargePerKm} = deliveryFeeData
    const [showEditModal, setShowEditModal] = useState(false)
    const [editingId, setEditingId] = useState(null)
    const [announcementBody,setAnnouncementBody] = useState("")
    const [baseDeliver_charge,setBaseDeliver_charge] = useState(null)
    const [deliveryCharge_perKm,setDeliveryCharge_perKm] = useState(null)

    // Pagination
    const [currentPage, setCurrentPage] = useState(1)
    const [postsPerPage, setPostsPerPage] = useState(10);
    const [currentPosts, setCurrentPosts] = useState([])

    let pageNumbers = []

    // get current posts
    const indexOfLastPost = currentPage * postsPerPage
    const indexOfFirstPost = indexOfLastPost - postsPerPage

    for (let i = 1; i <= 1; i++) {
        pageNumbers.push(i)
    }

    const [rate, setRate] = useState(null)
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({
            type: "FETCH_DELIVERY_FEE_REQUEST",
        })
    }, [])

    return (
        <Layout>
            <div>
                <div className="group_list">
                    <h4>Manage Delivery Fee</h4>
                </div>
                <div className="user_groups">
                    <div className="user_groups--button_n_search">
                        <div className="user_groups--button_n_search--button">
                            <h4>Delivery Fee</h4>
                            <button onClick={() => dispatch({ type: "ADD_DELIVERY_CHARGE_MODAL" })}>
                                Add <i className="bi bi-plus c-plus" />
                            </button>
                        </div>
                        <div className="user_groups--button_n_search--search">
                            {/* <input type="text" placeholder="Search" /> */}
                        </div>
                    </div>
                    <p className="privacy-loading--onFetching">{isLoadingOnLogin && <LoadingComponent />}</p>
                    <div className="table-container">
                        <table className="table_x">
                            <thead>
                                <tr>
                                    <th className="delivery-charge--title">Title</th>
                                    <th className="delivery-charge--amount">Amount</th>
                                    <th className="delivery-charge--addedBy">Updated By</th>
                                    <th className="delivery-charge--addedBy">Updated Date</th>
                                    <th className="delivery-charge--actions">Actions</th>
                                </tr></thead>
                            <tbody>
                                {baseDeliveryCharge !== undefined && <><tr>
                                    <td>BASIC DELIVERY CHARGE</td>
                                    <td>{baseDeliveryCharge.doubleConfigValue}</td>
                                    <td>{baseDeliveryCharge.lastUpdatedBy}</td>
                                    <td>{baseDeliveryCharge.lastUpdatedDateTime}</td>
                                    <td>
                                        <Tippy content="Edit" placement="top">
                                            <span>
                                                <AiFillEdit className="edit--icon" onClick={()=> {
                                                    setShowEditModal(true)
                                                    setBaseDeliver_charge(baseDeliveryCharge.doubleConfigValue)
                                                    setDeliveryCharge_perKm(deliveryChargePerKm.doubleConfigValue)
                                                }} /> </span>
                                        </Tippy>
                                    </td>
                                </tr>
                                <tr>
                                    <td>DELIVERY CHARGE PER KM</td>
                                    <td>{deliveryChargePerKm.doubleConfigValue}</td>
                                    <td>{deliveryChargePerKm.lastUpdatedBy}</td>
                                    <td>{deliveryChargePerKm.lastUpdatedDateTime}</td>
                                    <td>
                                        <Tippy content="Edit" placement="top">
                                            <span>
                                                <AiFillEdit className="edit--icon" onClick={()=> {
                                                    setShowEditModal(true)
                                                    setBaseDeliver_charge(baseDeliveryCharge.doubleConfigValue)
                                                    setDeliveryCharge_perKm(deliveryChargePerKm.doubleConfigValue)
                                                }} /> </span>
                                        </Tippy>
                                    </td>
                                </tr></>}
                            </tbody>
                        </table>
                        {/* edit deliver-charge modal*/}
                        <div className={`${showEditModal ? "mymodal modal_activated" : "mymodal"}`}>
                            <div className={`${!isToogleSidebar ? "manage_modal--container " : "manage_modal--container sidebarOpend_F_modal"}`}>
                                {deliveryCharge_perKm !== null && <EditDeliveryFee baseFee={baseDeliver_charge} perKmFee={deliveryCharge_perKm} />}
                            </div>
                            <div className={`${!isToogleSidebar ? "task--rectangle" : "task--rectangle withsidebarOpend"}`}>
                                <div className="task--rectangle__circle">
                                    <div className='circle'> <span onClick={() => {
                                        setShowEditModal(false)
                                        dispatch({
                                            type: "FETCH_DELIVERY_FEE_REQUEST",
                                        })
                                    }}><BsX /></span><p>Edit</p></div>
                                </div>
                            </div>
                        </div>
                        <div className="pagination">
                            <p>Showing 0 to 2 of 2 entries</p>
                            <nav aria-label="...">
                                <ul className="pagination">
                                    {currentPage === 1 ? <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronLeft /></a>
                                    </li> : <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronLeft onClick={() => setCurrentPage(currentPage - 1)} /></a>
                                    </li>}
                                    {pageNumbers.map((x) =>
                                        <li key={x} className="page-item"><a className="page-link" href="#" onClick={() => setCurrentPage(x)} >{x}</a></li>
                                    )}
                                    {currentPage == pageNumbers.length ? <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronRight /></a>
                                    </li> : <li className="page-item">
                                        <a className="page-link" href="#"><BiChevronRight onClick={() => setCurrentPage(currentPage + 1)} /></a>
                                    </li>}
                                </ul>
                            </nav>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        </Layout>
    )
}

export default connect(mapStateToProps, null)(GetDeliveryFee)

