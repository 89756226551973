import React from 'react'
import Layout from '../../Layout/Layout'

function AddNormalOrderBatch() {
  return (
    <Layout>
        <div>AddPreOrderBatch</div>
    </Layout>
  )
}

export default AddNormalOrderBatch